import { FC } from "react"
import { StatusModalProps } from "./modals/types"
import { MenuItemsKeys, StatusChangerModals } from "./constants"

interface UserAdminStatusChangerModalProps extends StatusModalProps {
  selectedModalKey: MenuItemsKeys
}

const UserAdminStatusChangerModal: FC<UserAdminStatusChangerModalProps> = (
  props,
) => {
  const Component = StatusChangerModals[props.selectedModalKey].modalComponent

  return <Component {...props} />
}

export default UserAdminStatusChangerModal
