import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategories } from "../../../../buyer/categories/actions"
import { getCategories as getCategoriesSelector } from "../../../../buyer/categories/selectors"
import { getQuestions } from "../../../../buyer/questions/selectors"
import { Category } from "../../../../buyer/categories/types"
import { Table } from "antd"
import CategoryView from "../../../../common/components/Category/Category"
import getTableColumns, { Column } from "../../../getTableColumns"

const CategoriesList = () => {
  const dispatch = useDispatch()
  const categories = useSelector(getCategoriesSelector)
  const questions = useSelector(getQuestions)

  const columns: Column = useMemo(
    () => getTableColumns(categories),
    [categories],
  )

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <Table
      tableLayout="auto"
      rowKey={(record: { [key: string]: any }) => record.id}
      dataSource={Object.values(categories)}
      columns={columns}
      pagination={{
        pageSize: 25,
      }}
      expandedRowRender={(category: Category) => (
        <CategoryView category={category} questions={questions} />
      )}
      loading={!Object.keys(categories).length}
      scroll={{ x: 1800 }}
    />
  )
}

export default CategoriesList
