import { gql } from "@apollo/client"

export type RemoveReviewFromSellerData = {
  removeReviewFromSeller: boolean
}
export type RemoveReviewFromSellerVariables = {
  sellerId: string
  reviewId: string
}

export const REMOVE_REVIEW_FROM_SELLER_MUTATION = gql`
  mutation RemoveReviewFromSeller($sellerId: String!, $reviewId: String!) {
    removeReviewFromSeller(sellerId: $sellerId, reviewId: $reviewId)
  }
`
