import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"
import { getMessageLogs } from "../../actions"
import { getColumns } from "../table"
import { MessageLogsState, MessageLog } from "../../types"

const MessageLogsList = () => {
  const dispatch = useDispatch()

  const messageLogs = useSelector(
    ({ messageLogs }: { messageLogs: MessageLogsState }) => {
      return messageLogs
    },
  )

  useEffect(() => {
    dispatch(getMessageLogs())
  }, [dispatch])

  const columns = getColumns()

  const expandedRowRender = () => {
    const renderExpandedRows = (messageLog: MessageLog) => {
      return <div dangerouslySetInnerHTML={{ __html: messageLog.message }} />
    }
    return renderExpandedRows
  }

  const messageLogsArray = Object.values(messageLogs.messageLogs)
  return (
    <section style={{ textAlign: "center" }}>
      <h2>MessageLogs</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: { [key: string]: any }) => record.id}
        dataSource={messageLogsArray}
        columns={columns || []}
        pagination={{
          pageSize: 25,
        }}
        expandedRowRender={expandedRowRender()}
        loading={!messageLogsArray || !messageLogsArray.length}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default MessageLogsList
