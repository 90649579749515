import React from "react"
import Text from "antd/lib/typography/Text"
import { Category } from "../../../buyer/categories/types"

interface Props {
  category?: Category | null
}

export const CategoryName = ({ category }: Props) => (
  <Text
    style={{
      textTransform: "capitalize",
    }}
  >
    {category ? category.name.en : ""}
  </Text>
)
