import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { User, UserData } from "../../fragments"

import { MUTATION } from "./mutation"

type Data = {
  createUser: User
}

type Variables = {
  data: Omit<
    UserData,
    "createdAt" | "updatedAt" | "deletedAt" | "lastAskedReviewTime"
  >
  isCreatedFromWeb: boolean
}

export const createUserThunk: ApolloThunkMutation<Data, [Variables]> = async (
  state,
  variables,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables,
  })

  return {
    createUserData: data?.createUser,
    createUserErrors: errors,
  }
}
