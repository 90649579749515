import React, { useEffect } from "react"
import _ from "lodash"

import { Row, Col, Typography, Badge } from "antd"
import { OptionQuestion } from "./optionQuestion"
import { InputQuestion } from "./inputQuestion"
import { DateQuestion } from "./dateQuestion"
import { ListPicker } from "./listPicker"

import { Question as IQuestion } from "../../types"

const { Title } = Typography

type QuestionContentProps = Required<
  Pick<QuestionProps, "question" | "isSubQuestion">
>

const QuestionContent = ({ question, isSubQuestion }: QuestionContentProps) => {
  switch (question.type) {
    case "checkbox":
    case "radio":
      return (
        <OptionQuestion question={question} isSubQuestion={isSubQuestion} />
      )
    case "number":
    case "number_input":
    case "textarea":
      return <InputQuestion question={question} isSubQuestion={isSubQuestion} />
    case "date":
      return <DateQuestion question={question} isSubQuestion={isSubQuestion} />
    case "list":
      return <ListPicker question={question} isSubQuestion={isSubQuestion} />
    default:
      return null
  }
}

type QuestionHeaderProps = Required<
  Pick<
    QuestionProps,
    "question" | "isSubQuestion" | "errorsForCategory" | "updateErrorVisibility"
  >
>
const QuestionHeader = ({
  question,
  isSubQuestion,
  errorsForCategory,
  updateErrorVisibility,
}: QuestionHeaderProps) => {
  useEffect(() => {
    if (!question.name) {
      return
    }

    updateErrorVisibility(question, isSubQuestion)
  }, [updateErrorVisibility, question, isSubQuestion])

  if (!question.name) {
    return null
  }

  return (
    <Row justify="space-between">
      <>
        {!isSubQuestion && (
          <Col xs={24}>
            <Title className="question-header-title" level={4}>
              {question.name.en}
              {question.showError &&
              _.includes(errorsForCategory, question.id) ? (
                <Badge
                  count="Mandatory"
                  style={{ marginLeft: 12, backgroundColor: "red" }}
                />
              ) : (
                <Badge
                  count="Ok"
                  style={{ marginLeft: 12, backgroundColor: "green" }}
                />
              )}
            </Title>
          </Col>
        )}
        {isSubQuestion && <Col xs={24}></Col>}
      </>
    </Row>
  )
}

interface QuestionProps {
  question?: IQuestion
  isSubQuestion: boolean
  errorsForCategory: string[]
  updateErrorVisibility: (question: IQuestion, isSubQuestion?: boolean) => void
}

const Question = ({
  question,
  isSubQuestion,
  errorsForCategory,
  updateErrorVisibility,
}: QuestionProps) => {
  if (!question) {
    return null
  }

  let questionComponents

  switch (question.type) {
    case "checkbox":
    case "radio":
      questionComponents = [
        <QuestionHeader
          key={`${question.id}_title`}
          question={question}
          isSubQuestion={isSubQuestion}
          errorsForCategory={errorsForCategory}
          updateErrorVisibility={updateErrorVisibility}
        />,
        <QuestionContent
          key={`${question.id}_question`}
          question={question}
          isSubQuestion={isSubQuestion}
        />,
      ]
      break
    case "date":
      questionComponents = [
        <QuestionHeader
          key={`${question.id}_title`}
          question={question}
          isSubQuestion={isSubQuestion}
          errorsForCategory={errorsForCategory}
          updateErrorVisibility={updateErrorVisibility}
        />,
        <QuestionContent
          key={`${question.id}_question`}
          question={question}
          isSubQuestion={isSubQuestion}
        />,
      ]
      break
    case "number":
    case "number_input":
    case "textarea":
    case "list":
      questionComponents = [
        <QuestionHeader
          key={`${question.id}_title`}
          question={question}
          isSubQuestion={isSubQuestion}
          errorsForCategory={errorsForCategory}
          updateErrorVisibility={updateErrorVisibility}
        />,
        <QuestionContent
          key={`${question.id}_question`}
          question={question}
          isSubQuestion={isSubQuestion}
        />,
      ]
      break
    default:
      questionComponents = null
      break
  }
  return <>{questionComponents}</>
}

export default Question
