import React from "react"
import { useDispatch } from "react-redux"

import { textChanged, numberChanged } from "../../../actions"

import {
  NumberInputQuestion as INumberInputQuestion,
  NumberQuestion as INumberQuestion,
  TextAreaQuestion as ITextAreaQuestion,
} from "../../../types"
import { Row, Col, Input } from "antd"
import FormItem from "antd/lib/form/FormItem"

interface InputQuestionProps {
  question: INumberInputQuestion | INumberQuestion | ITextAreaQuestion
  isSubQuestion?: boolean
}

const InputQuestion = ({ question, isSubQuestion }: InputQuestionProps) => {
  const dispatch = useDispatch()

  const value = question.value

  const placeholder = question.placeholder
    ? typeof question.placeholder === "object"
      ? question.placeholder.en
      : question.placeholder
    : isSubQuestion
    ? question.name
      ? question.name.en
      : ""
    : ""

  const onChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (value) {
      if (typeof value === "object" && question.type !== "number") {
        dispatch(
          textChanged({
            questionId: question.id,
            value: value.target.value,
          }),
        )
      } else {
        dispatch(
          numberChanged({
            questionId: question.id,
            value: +value.target.value,
          }),
        )
      }
    }
  }

  const inputProps = {
    onChange,
    value,
    ...(placeholder ? { placeholder } : {}),
  }

  return (
    <Row>
      <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
        <FormItem label={placeholder} labelAlign="left">
          {question.type === "number" ? (
            <Input size="large" type="number" {...inputProps} />
          ) : (
            <Input size="large" {...inputProps} />
          )}
        </FormItem>
      </Col>
    </Row>
  )
}

export default InputQuestion
