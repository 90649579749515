import { gql } from "@apollo/client"
import { GraphQLSellerProfile } from "../adapters/GraphQLSellerProfile"

export type GetSellerProfileData = {
  seller: GraphQLSellerProfile | null
}

export type GetSellerProfileVariables = {
  id: string
}

export const GET_SELLER_PROFILE_QUERY = gql`
  query GetSellerProfile($id: String!) {
    seller(id: $id) {
      id
      data {
        photoURL
        companyName
        companyEmail
        heroBIOText
        categories
        companyVAT
        langs
        city
        streetAddress
        postalCode
        yearsOfExperience
        workAreas
        certificate
        weeksOfSentPurchaseInvoices
      }
      rating
      reviews
    }
  }
`
