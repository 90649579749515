import { gql } from "@apollo/client"

export type ChatMessageDto = {
  id: string
  chatId: string
  content: string
  senderId: string
  createdAt: Date
  imageIds?: string[] | null
  imageCount?: number
  location?: Record<"latitude" | "longitude", number> | null
}

export const CHAT_MESSAGE_INFO_FRAGMENT = gql`
  fragment ChatMessageInfo on ChatMessageDto {
    id
    chatId
    content
    senderId
    createdAt
    imageIds
    imageCount
    location {
      latitude
      longitude
    }
  }
`
