import * as Yup from "yup"
import { isFieldRequired } from "./utils/isFieldRequired"

export const stringField: (
  min: number,
  max: number,
  isRequired: boolean,
) => Yup.Schema<string> = (min, max, isRequired) =>
  isFieldRequired(
    Yup.string().min(min, "Too Short!").max(max, "Too Long!"),
    isRequired,
  )
