import React, { useState } from "react"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { Category } from "../../../buyer/categories/types"
import { CreateQuestionForm, Questions } from "../../../buyer/questions/types"
import { Button, Col, Row, Modal } from "antd"
import CreateQuestion from "../../../categories/list/components/createQuestion"
import { useDispatch } from "react-redux"
import {
  createAndUpdateQuestion,
  removeQuestion,
} from "../../../buyer/questions/actions"
import { converterToCreatedQuestion } from "../../../buyer/questions/utils"

const { confirm } = Modal

interface Props {
  category: Category
  questions: Questions
}

interface VisibleState {
  edit: boolean
  create: boolean
}

const initialVisibleState: VisibleState = {
  edit: false,
  create: false,
}

const CategoryQuestions = ({ category, questions }: Props) => {
  const [visible, setVisible] = useState<VisibleState>(initialVisibleState)
  const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(
    undefined,
  )

  const dispatch = useDispatch()

  const handleOpenEditModal = (questionId: string) => {
    setVisible((prevState) => ({ ...prevState, edit: true }))
    setSelectedQuestion(questionId)
  }
  const handleCloseEditModal = () => {
    setVisible((prevState) => ({ ...prevState, edit: false }))
    setSelectedQuestion("")
  }

  const handleSubmit = async (values: CreateQuestionForm) => {
    dispatch(
      createAndUpdateQuestion(
        category.id,
        values,
        selectedQuestion
          ? selectedQuestion.replace(/[\w\s]+\//, "")
          : selectedQuestion,
      ),
    )
    setVisible(initialVisibleState)
    selectedQuestion && setSelectedQuestion("")
  }

  const handleRemoveQuestion = (questionId: string) =>
    dispatch(removeQuestion(category.id, questionId.replace(/[\w\s]+\//, "")))

  return (
    <>
      <Row style={{ paddingBottom: 12 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            setVisible((prevState) => ({ ...prevState, create: true }))
          }
        >
          Add Question
        </Button>
      </Row>
      <Row>
        {category.questions
          .sort((a, b) => a.localeCompare(b))
          .map((question) => (
            <Col key={question} span={8}>
              <div>
                <span>{question}</span>
                <EditOutlined
                  style={{ paddingLeft: 12 }}
                  onClick={() => handleOpenEditModal(question)}
                />
                <DeleteOutlined
                  style={{ paddingLeft: 8 }}
                  onClick={() =>
                    confirm({
                      title: "Are you sure you want to delete this question?",
                      onOk: () => handleRemoveQuestion(question),
                    })
                  }
                />
              </div>
            </Col>
          ))}
      </Row>
      <CreateQuestion
        open={visible.create}
        onCancel={() =>
          setVisible((prevState) => ({ ...prevState, create: false }))
        }
        onSubmit={handleSubmit}
      />
      {selectedQuestion && questions[selectedQuestion] && (
        <CreateQuestion
          open={visible.edit}
          onCancel={handleCloseEditModal}
          onSubmit={handleSubmit}
          initialState={converterToCreatedQuestion(questions[selectedQuestion])}
        />
      )}
    </>
  )
}

export default CategoryQuestions
