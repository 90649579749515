import { FC, useCallback, useMemo } from "react"
import { Descriptions, Input, Form, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { setOfferRequestVats } from "../../../../offerRequests/list/actions"
import { getVAT } from "../../../utils"
import { CategoriesState } from "../../../../buyer/categories/types"
import { OfferRequestsState } from "../../../../offerRequests/list/types"
import { VatInputMapper } from "./constants"
import { VatInput } from "./components"
import { VatForm } from "./types"

interface Props {
  offerRequestId: string
}

export const Vats: FC<Props> = (props) => {
  const { offerRequestId } = props

  const dispatch = useDispatch()

  const offerRequest = useSelector(
    ({ offerRequests }: { offerRequests: OfferRequestsState }) => {
      return offerRequests.offerRequests[offerRequestId]
    },
  )
  const category = useSelector(
    ({ categories }: { categories: CategoriesState }) => {
      return (
        offerRequest &&
        categories.categories[offerRequest.data.initial.category]
      )
    },
  )

  const vats = getVAT(offerRequest, category)

  const changeVats = useCallback(
    (values: VatForm) => {
      const customerVAT = Number(values.customerVAT) || undefined
      const serviceProviderVAT = Number(values.serviceProviderVAT) || undefined
      const hero24Cut = Number(values.hero24Cut) || undefined

      dispatch(
        setOfferRequestVats({
          id: offerRequestId,
          newVats: {
            hero24Cut,
            customerVAT,
            serviceProviderVAT,
          },
        }),
      )
    },
    [
      props.offerRequestId,
      vats.customerVAT,
      vats.hero24Cut,
      vats.serviceProviderVAT,
    ],
  )

  const inputs = useMemo(() => {
    return Object.values(VatInputMapper).map((props) => <VatInput {...props} />)
  }, [])

  if (!category) {
    return <Spin />
  }

  return (
    <Descriptions
      layout="horizontal"
      size="small"
      bordered
      style={{
        margin: 0,
        padding: 0,
        border: 0,
        background: "white",
      }}
    >
      <Descriptions.Item>
        <Form
          style={{ marginLeft: 24 }}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          layout="horizontal"
          initialValues={vats}
          onValuesChange={(_changedValue, values: VatForm) => {
            changeVats({
              ...values,
            })
          }}
        >
          {inputs}
        </Form>
      </Descriptions.Item>
    </Descriptions>
  )
}
