import { InputNumberProps } from "antd/lib/input-number"

import { Promotion } from "../../../types"
import getDiscountSymbol from "./getDiscountSymbol"

const getDiscountFieldProps = (
  discountFormat: Promotion["data"]["discountFormat"],
): InputNumberProps => {
  const discountSymbol = getDiscountSymbol(discountFormat)

  const fixedFormatProps = {
    formatter: (value: string | number | undefined) =>
      `${value}${discountSymbol}`,
  }
  const percentageFormatProps = {
    max: 99,
    formatter: (value: string | number | undefined) =>
      `${value}${discountSymbol}`,
  }

  return discountFormat === "fixed" ? fixedFormatProps : percentageFormatProps
}

export default getDiscountFieldProps
