import { gql } from "@apollo/client"
import { CHAT_MEMBER_INFO_FRAGMENT, ChatMemberDto } from "./ChatMemberInfo"
import { CHAT_MESSAGE_INFO_FRAGMENT, ChatMessageDto } from "./ChatMessageInfo"

export type ChatDto = {
  id: string
  messages: ChatMessageDto[]
  members: ChatMemberDto[]
  createdAt: Date
  offerId?: string | null
  offerRequestId?: string | null
  inviteAdmin?: boolean | null
  isAboutReclamation?: boolean | null
  seenByAdmin?: boolean | null
  reasonGiven?: boolean | null
}

export const CHAT_INFO_FRAGMENT = gql`
  ${CHAT_MEMBER_INFO_FRAGMENT}
  ${CHAT_MESSAGE_INFO_FRAGMENT}

  fragment ChatInfo on ChatDto {
    id
    messages {
      ...ChatMessageInfo
    }
    members {
      ...ChatMemberInfo
    }
    createdAt
    offerId
    offerRequestId
    inviteAdmin
    isAboutReclamation
    seenByAdmin
    reasonGiven
  }
`
