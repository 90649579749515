import React from "react"
import Text from "antd/lib/typography/Text"

interface Props {
  area?: string
}

export const Area = ({ area }: Props) => (
  <Text
    style={{
      textTransform: "capitalize",
    }}
  >
    {area || ""}
  </Text>
)
