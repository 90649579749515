import React from "react"
import { SearchOutlined } from "@ant-design/icons"
import { ColumnType } from "antd/lib/table"
import Text from "antd/lib/typography/Text"

import { ChatDto } from "../../../graphql/fragments/ChatInfo"
import { ChatsFilter, ChatsOrderBy } from "../../../types"
import { ChatsOrderColumn } from "../../../constants"
import SellerName from "../SellerName"
import CustomerName from "../CustomerName"
import SellerColumnFilter from "../SellerColumnFilter"
import CustomerColumnFilter from "../CustomerColumnFilter"
import OrderIdColumnFilter from "../OrderIdColumnFilter"

import { getAntOrderByColumn } from "./utils"
import { ChatColumnName } from "./constants"
import { ChatMemberRole } from "../../../graphql/constants"

type Column = ColumnType<ChatDto>

type Props = {
  ordersBy: ChatsOrderBy[]
  filter: ChatsFilter
}

const getColumns: (props: Props) => Column[] = ({ ordersBy, filter }) => {
  const orderIdColumn: Column = {
    title: "Order Id",
    key: ChatColumnName.OFFER_ID,
    render: (_value, { offerId, offerRequestId }) => (
      <Text>{offerId || offerRequestId}</Text>
    ),
    sorter: true,
    sortOrder: getAntOrderByColumn(ordersBy, ChatsOrderColumn.OFFER),
    filterDropdown: ({ confirm }) => <OrderIdColumnFilter confirm={confirm} />,
    filtered: !!filter.orderIdSearch?.length,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  }

  const customerNameColumn: Column = {
    title: "Customer",
    key: ChatColumnName.CUSTOMER,
    render: (_, { members }) => {
      const customer = members.find(({ role }) => role === ChatMemberRole.BUYER)

      return <CustomerName id={customer?.id || ""} />
    },
    filterDropdown: ({ confirm }) => <CustomerColumnFilter confirm={confirm} />,
    filtered: !!filter.customerIds?.length,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  }

  const sellerNameColumn: Column = {
    title: "Seller",
    key: ChatColumnName.SELLER,
    render: (_, { members }) => {
      const seller = members.find(({ role }) => role === ChatMemberRole.SELLER)

      return <SellerName id={seller?.id} />
    },
    filterDropdown: ({ confirm }) => <SellerColumnFilter confirm={confirm} />,
    filtered: !!filter.sellerIds?.length,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  }

  const adminInvitedColumn: Column = {
    title: "Admin requested",
    key: ChatColumnName.IS_ADMIN_INVITED,
    render: (_, { seenByAdmin, inviteAdmin }) => (
      <Text strong={!seenByAdmin}>
        {inviteAdmin ? (!seenByAdmin ? "REQUESTED" : "Request seen") : "-"}
      </Text>
    ),
    sorter: true,
    sortOrder: getAntOrderByColumn(ordersBy, ChatsOrderColumn.IS_ADMIN_INVITED),
    filters: [
      {
        text: "Show only where Hero24 invited",
        value: "invited",
      },
    ],
    filteredValue: filter.isAdminInvited ? ["invited"] : [],
  }

  const isReclamationColumn: Column = {
    title: "Reclamation",
    key: ChatColumnName.IS_ABOUT_RECLAMATION,
    render: (_, { isAboutReclamation }) => (
      <Text strong={!isAboutReclamation}>
        {isAboutReclamation ? "RECLAMATION" : "-"}
      </Text>
    ),
    sorter: true,
    sortOrder: getAntOrderByColumn(
      ordersBy,
      ChatsOrderColumn.IS_ABOUT_RECLAMATION,
    ),
    filters: [
      {
        text: "Show only reclamations",
        value: "invited",
      },
    ],
    filteredValue: filter.isAboutReclamation ? ["invited"] : [],
  }

  return [
    orderIdColumn,
    customerNameColumn,
    sellerNameColumn,
    adminInvitedColumn,
    isReclamationColumn,
  ]
}

export default getColumns
