import { Button, Col, Modal, Row } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { PackageDB } from "hero24-types"
import React, { useState } from "react"
import CreatePackage from "../../../categories/list/components/createPackage"
import {
  createAndUpdatePackage,
  removePackage,
} from "../../../buyer/packages/actions"
import { useDispatch } from "react-redux"
import { Category } from "../../../buyer/categories/types"
import { CreatePackageForm } from "../../../buyer/packages/types"
import { converterToCreatedPackage } from "../../../buyer/packages/utils"

const { confirm } = Modal

interface Props {
  category: Category
  packages?: {
    [id: string]: PackageDB
  }
}

interface VisibleState {
  edit: boolean
  create: boolean
}

const initialVisibleState: VisibleState = {
  edit: false,
  create: false,
}

const CategoryPackages = ({ category, packages }: Props) => {
  const [visible, setVisible] = useState<VisibleState>(initialVisibleState)
  const [selectedPackage, setSelectedPackage] = useState<string>("")
  const dispatch = useDispatch()

  const handleOpenEditModal = (packageId: string) => {
    setVisible((prevState) => ({ ...prevState, edit: true }))
    setSelectedPackage(packageId)
  }
  const handleCloseEditModal = () => {
    setVisible((prevState) => ({ ...prevState, edit: false }))
    setSelectedPackage("")
  }
  const handleCloseCreateModal = () =>
    setVisible((prevState) => ({ ...prevState, create: false }))

  const handleSubmit = async (values: CreatePackageForm) => {
    dispatch(createAndUpdatePackage(category.id, values, selectedPackage))
    setVisible(initialVisibleState)
    selectedPackage && setSelectedPackage("")
  }

  const handleRemovePackage = (packageId: string) =>
    dispatch(removePackage(category.id, packageId))

  return (
    <>
      <Row style={{ paddingBottom: 12 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            setVisible((prevState) => ({ ...prevState, create: true }))
          }
        >
          Add Package
        </Button>
      </Row>
      {packages && (
        <Row>
          {Object.keys(packages).map((packageId) => (
            <Col key={packageId} span={8}>
              <div>
                <span>{packageId}</span>
                <EditOutlined
                  style={{ paddingLeft: 12 }}
                  onClick={() => handleOpenEditModal(packageId)}
                />
                <DeleteOutlined
                  style={{ paddingLeft: 8 }}
                  onClick={() =>
                    confirm({
                      title: "Are you sure you want to delete this package?",
                      onOk: () => handleRemovePackage(packageId),
                    })
                  }
                />
              </div>
            </Col>
          ))}
        </Row>
      )}
      <CreatePackage
        open={visible.create}
        onCancel={handleCloseCreateModal}
        onSubmit={handleSubmit}
      />
      {packages && selectedPackage && (
        <CreatePackage
          open={visible.edit}
          onCancel={handleCloseEditModal}
          onSubmit={handleSubmit}
          initialState={converterToCreatedPackage(
            selectedPackage,
            packages[selectedPackage],
          )}
        />
      )}
    </>
  )
}

export default CategoryPackages
