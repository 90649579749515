import { OfferRequestQuestion } from "hero24-types"

import { QUESTION_FLAT_ID_NAME } from "../../../constants"
import {
  TranslationField,
  TranslationFieldAdapter,
} from "../../../../common/graphql/fragments"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

interface BaseOfferRequestQuestionDB<
  Type extends OfferRequestQuestion["type"] = OfferRequestQuestion["type"],
> extends Pick<OfferRequestQuestion, "id" | "name" | "order"> {
  [QUESTION_FLAT_ID_NAME]?: string
  type: Type
}

export type OfferRequestBaseQuestion<
  Type extends OfferRequestQuestion["type"] = OfferRequestQuestion["type"],
> = {
  id: string
  [QUESTION_FLAT_ID_NAME]?: string // undefined for the root question
  name?: Maybe<TranslationField>
  order: number
  type: Type
}

export const OfferRequestBaseQuestionAdapter = new FirebaseAdapter<
  BaseOfferRequestQuestionDB,
  OfferRequestBaseQuestion
>({
  toFirebase: (graphql) => ({
    id: graphql.id,
    [QUESTION_FLAT_ID_NAME]: graphql[QUESTION_FLAT_ID_NAME],
    name: graphql.name
      ? TranslationFieldAdapter.toFirebase(graphql.name)
      : null,
    order: graphql.order,
    type: graphql.type,
  }),
  toGraphQL: (firebase) => ({
    id: firebase.id,
    [QUESTION_FLAT_ID_NAME]: firebase[QUESTION_FLAT_ID_NAME],
    name: firebase.name && TranslationFieldAdapter.toGraphQL(firebase.name),
    order: firebase.order || 0,
    type: firebase.type,
  }),
})

export const OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW = `
  id
  depsId
  name {
    ...TranslationFieldInfo
  }
  order
  type
`
