import { gql } from "@apollo/client"
import { UserDB } from "hero24-types"

import { USER_DATA_FRAGMENT, UserData, UserDataAdapter } from "./UserDataInfo"
import { USER_OFFER_FRAGMENT, UserOffer, UserOfferAdapter } from "./UserOffer"
import { Maybe } from "../../../common/types"
import { FirebaseAdapter, convertListToMap } from "../../../common/utilities"

export type User = {
  id: string
  data: UserData
  stripeCustomerId?: Maybe<string>
  isCreatedFromWeb?: Maybe<boolean>
  isAdmin?: Maybe<boolean>
  netvisorCustomerId?: Maybe<number>
  netvisorSellerId?: Maybe<number>
  phoneVerified?: Maybe<boolean>
  offers?: Maybe<UserOffer[]>
  offerRequests?: Maybe<string[]>
  transactions?: Maybe<string[]>
  paymentTransactions?: Maybe<string[]>
  hasBuyerProfile?: Maybe<boolean>
  hasSellerProfile?: Maybe<boolean>
  isApprovedSeller?: Maybe<boolean>
  isBlocked?: Maybe<boolean>
  mergedUsers?: Maybe<string[]>
  mergedTo?: Maybe<string>
}

export const USER_FRAGMENT = gql`
  ${USER_DATA_FRAGMENT}
  ${USER_OFFER_FRAGMENT}

  fragment UserInfo on UserDto {
    id
    data {
      ...UserDataInfo
    }
    stripeCustomerId
    isCreatedFromWeb
    isAdmin
    netvisorCustomerId
    netvisorSellerId
    phoneVerified
    offers {
      ...UserOfferInfo
    }
    offerRequests
    transactions
    paymentTransactions
    hasBuyerProfile
    hasSellerProfile
    isApprovedSeller
    isBlocked
    mergedUsers
    mergedTo
  }
`

export const UserAdapter = new FirebaseAdapter<UserDB & { id: string }, User>({
  toFirebase: (graphql) => ({
    id: graphql.id,
    data: UserDataAdapter.toFirebase(graphql.data),
    stripeCustomerId: graphql.stripeCustomerId ?? undefined,
    isCreatedFromWeb: graphql.isCreatedFromWeb ?? undefined,
    isAdmin: graphql.isAdmin ?? undefined,
    netvisorCustomerId: graphql.netvisorCustomerId ?? undefined,
    netvisorSellerId: graphql.netvisorSellerId ?? undefined,
    phoneVerified: graphql.phoneVerified ?? undefined,
    offers: graphql.offers
      ? (UserOfferAdapter.toFirebase(graphql.offers) as {
          [id: string]: { offerRequestId: string }
        })
      : undefined,
    offerRequests: graphql.offerRequests
      ? convertListToMap(graphql.offerRequests, true)
      : undefined,
    transactions: graphql.transactions
      ? convertListToMap(graphql.transactions, true)
      : undefined,
    paymentTransactions: graphql.paymentTransactions
      ? convertListToMap(graphql.paymentTransactions, true)
      : undefined,
    hasBuyerProfile: graphql.hasBuyerProfile ?? undefined,
    hasSellerProfile: graphql.hasSellerProfile ?? undefined,
    isApprovedSeller: graphql.isApprovedSeller ?? undefined,
    isBlocked: graphql.isBlocked ?? undefined,
    mergedUsers: graphql.mergedUsers
      ? convertListToMap(graphql.mergedUsers, true)
      : undefined,
    mergedTo: graphql.mergedTo ?? undefined,
    hubSpotContactId: undefined,
  }),
  toGraphQL: (firebase) => ({
    id: firebase.id,
    data: UserDataAdapter.toGraphQL(firebase.data),
    stripeCustomerId: firebase.stripeCustomerId,
    isCreatedFromWeb: firebase.isCreatedFromWeb,
    isAdmin: firebase.isAdmin,
    netvisorCustomerId: firebase.netvisorCustomerId,
    netvisorSellerId: firebase.netvisorSellerId,
    phoneVerified: firebase.phoneVerified,
    offers:
      firebase.offers &&
      (UserOfferAdapter.toGraphQL(firebase.offers) as UserOffer[]),
    offerRequests:
      firebase.offerRequests && Object.keys(firebase.offerRequests),
    transactions: firebase.transactions && Object.keys(firebase.transactions),
    paymentTransactions:
      firebase.paymentTransactions && Object.keys(firebase.paymentTransactions),
    hasBuyerProfile: firebase.hasBuyerProfile,
    hasSellerProfile: firebase.hasSellerProfile,
    isApprovedSeller: firebase.isApprovedSeller,
    isBlocked: firebase.isBlocked,
    mergedUsers: firebase.mergedUsers && Object.keys(firebase.mergedUsers),
    mergedTo: firebase.mergedTo,
  }),
})
