import React from "react"
import { Button, Popconfirm, Input, Space } from "antd"
import { Seller } from "../../../types"
import { getAllSellerCategories } from "../../../utils"
import { ColumnsType } from "antd/lib/table"
import { SellerName } from "../../../../common/components/listComponent/Seller"
import { DateAndTime } from "../../../../common/components/listComponent/DateAndTime"
import Text from "antd/lib/typography/Text"
import { CategoryList } from "../../../../common/components/listComponent/CategoryList"
import { CategoriesState } from "../../../../buyer/categories/types"
import { AreaList } from "../../../../common/components/listComponent/AreaList"
import { SearchOutlined } from "@ant-design/icons"

const renderAction = (
  handleApproveSeller: (id: string, isApproved: boolean) => void,
  handleGetAllSellers: () => void,
) => {
  const RenderAction = (_: any, data: { [key: string]: any }) => {
    const {
      sellerData: { isApproved },
      id,
    } = data

    return (
      <Popconfirm
        title={isApproved ? "Remove approved status" : "Approve seller"}
        onConfirm={() => {
          handleApproveSeller(id, !isApproved)
          handleGetAllSellers()
        }}
        okButtonProps={isApproved ? { danger: true } : {}}
        okText={isApproved ? "Remove" : "Approve"}
        cancelText="Cancel"
      >
        <Button type="primary" ghost={isApproved} size="small">
          {isApproved ? "Approved" : "Approve"}
        </Button>
      </Popconfirm>
    )
  }

  return RenderAction
}

type Column = ColumnsType<Seller>

const getColumns: (
  sellers: Seller[],
  allCategories: CategoriesState["categories"],
  handleApproveSeller: (id: string, isApproved: boolean) => void,
  handleGetAllSellers: () => void,
) => Column = (
  sellers,
  allCategories,
  handleApproveSeller,
  handleGetAllSellers,
) => {
  const createdColumn: Column[0] = {
    title: "User Created",
    key: "userData.createdAt",
    render: (seller: Seller) => (
      <DateAndTime time={seller.userData.createdAt} />
    ),
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.userData.createdAt > sellerB.userData.createdAt ? -1 : 1,
  }

  const vatColumn: Column[0] = {
    title: "VAT",
    key: "sellerData.companyVAT",
    render: (seller: Seller) => <Text>{seller.sellerData.companyVAT}</Text>,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.sellerData.companyVAT.localeCompare(
        sellerB.sellerData.companyVAT,
      ),
  }

  const nameColumn: Column[0] = {
    title: "Company",
    key: "sellerData.companyName",
    render: (seller: Seller) => <SellerName seller={seller} />,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.sellerData.companyName.localeCompare(
        sellerB.sellerData.companyName,
      ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, seller: Seller) => {
      return typeof value === "string"
        ? seller.sellerData.companyName
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const emailColumn: Column[0] = {
    title: "Email",
    key: "selerData.companyEmail",
    render: (seller: Seller) => <Text>{seller.sellerData.companyEmail}</Text>,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.sellerData.companyEmail.localeCompare(
        sellerB.sellerData.companyEmail,
      ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by email`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, seller: Seller) => {
      return typeof value === "string"
        ? seller.sellerData.companyEmail
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  interface Filter {
    text: string
    value: string
  }
  const areaFiltersKeyVal: {
    [area: string]: Filter
  } = {}
  sellers.forEach((seller) => {
    seller.sellerData.workAreas?.forEach((area) => {
      areaFiltersKeyVal[area] = {
        value: area,
        text: area,
      }
    })
  })
  const workAreasColumn: Column[0] = {
    title: "Areas",
    render: ({ sellerData }: Seller) => (
      <AreaList areas={sellerData.workAreas} />
    ),
    key: "workareas",
    filters: Object.values(areaFiltersKeyVal).sort((a, b) =>
      a.value.localeCompare(b.value),
    ),
    onFilter: (value: any, seller: Seller) =>
      !!seller.sellerData.workAreas?.includes(value),
  }

  const servicesFiltersKeyVal = getAllSellerCategories(sellers)
  const servicesColumn: Column[0] = {
    title: "Services",
    render: ({ sellerData }: Seller) => {
      const sellerCategoryIds =
        sellerData.categories && Object.keys(sellerData.categories)
      const categories =
        sellerCategoryIds &&
        Object.entries(allCategories)
          .filter((entry) => sellerCategoryIds.includes(entry[0]))
          .map((entry) => entry[1])
      return <CategoryList categories={categories} />
    },
    key: "services",
    filters: Object.values(servicesFiltersKeyVal).sort((a, b) =>
      a.value.localeCompare(b.value),
    ),
    onFilter: (value: any, seller: Seller) =>
      !!seller.sellerData.categories &&
      Object.keys(seller.sellerData.categories).includes(value),
  }

  const actionColumn: Column[0] = {
    title: "Approved Status",
    key: "action",
    render: renderAction(handleApproveSeller, handleGetAllSellers),
  }

  return [
    createdColumn,
    vatColumn,
    nameColumn,
    emailColumn,
    servicesColumn,
    workAreasColumn,
    actionColumn,
  ]
}

export default getColumns
