import React, { FC } from "react"
import { ColumnsType } from "antd/lib/table"
import { Button, Popconfirm } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"

import { removeNewsThunk } from "../../../thunk-actions"
import { News } from "../../../graphql/fragments"
import { selectNewsRemovingById } from "../../../selectors"

const Actions: FC<{ news: News }> = ({ news }) => {
  const removing = useSelector(selectNewsRemovingById(news.id))

  const dispatch = useDispatch()

  const confirm = () => {
    dispatch(removeNewsThunk(news.id))
  }

  return (
    <Popconfirm
      title="Are you sure to delete this news?"
      onConfirm={confirm}
      okText="Yes"
      cancelText="No"
    >
      <Button shape="circle" icon={<DeleteOutlined />} loading={removing} />
    </Popconfirm>
  )
}

export default (): ColumnsType<News>[number] => ({
  title: "Actions",
  render: (_value, news) => {
    return <Actions news={news} />
  },
})
