import React, { useCallback, useEffect, useMemo } from "react"
import { Row, Table } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { FilterValue, SorterResult } from "antd/lib/table/interface"
import { TablePaginationConfig } from "antd/lib/table"
import isArray from "lodash/isArray"
import get from "lodash/get"

import {
  loadChats,
  markChatReclamationResolved,
  markChatSeen,
  sendChatMsg,
} from "../../actions"
import {
  selectChats,
  selectChatsFetching,
  selectChatsFilters,
  selectChatsOrderBy,
} from "../../selectors"
import { getColumns } from "../components/table"
import { ChatDto } from "../../graphql/fragments/ChatInfo"
import {
  useForceUpdater,
  usePagination,
  usePrevious,
} from "../../../common/hooks"
import { loadAllBriefSellers } from "../../../seller/actions"
import { getUsers } from "../../../user/actions"
import getExpandedRowRender from "../components/table/getExpandedRowRender"
import {
  removeChatsOrderBy,
  resetNewChatCount,
  setChatsOrderBy,
  updateChatsFilters,
} from "../../reducer"
import { SortOrder } from "../../../common/constants"
import {
  ChatColumnName,
  SortColumnBinding,
} from "../components/table/constants"
import { useSubscribeOnChatUpdates } from "../hooks/useSubscribeOnChatUpdates"
import { useSubscribeOnChatAdd } from "../hooks/useSubscribeOnChatAdd"
import ChatAddedButton from "../components/ChatAddedButton"

interface TableParams {
  pagination?: TablePaginationConfig
  sortField?: string
  sortOrder?: string
  filters?: Record<string, unknown>
}

const ChatsList = () => {
  const dispatch = useDispatch()

  const { limit, offset, page, setPage, setLimit, setOffset } =
    usePagination(25)

  const chats = useSelector(selectChats)

  useSubscribeOnChatUpdates()
  useSubscribeOnChatAdd()

  const filter = useSelector(selectChatsFilters)
  const ordersBy = useSelector(selectChatsOrderBy)
  const loading = useSelector(selectChatsFetching)

  const tableParams = useMemo<TableParams>(
    () => ({
      pagination: {
        current: page,
        pageSize: limit,
        total: chats?.total,
        defaultCurrent: 0,
        defaultPageSize: limit,
      },
    }),
    [page, limit, chats],
  )

  const [uniqueAnchor, updateUniqueAnchor] = useForceUpdater()
  const previousAnchor = usePrevious(uniqueAnchor)

  useEffect(() => {
    dispatch(
      loadChats(
        { limit, offset, ordersBy, filter },
        uniqueAnchor !== previousAnchor,
      ),
    )
  }, [dispatch, limit, offset, ordersBy, filter, uniqueAnchor, previousAnchor])

  useEffect(() => {
    dispatch(getUsers())
    dispatch(loadAllBriefSellers())
  }, [dispatch])

  const refetchPageHandler = useCallback(() => {
    setOffset(0)
    dispatch(resetNewChatCount())
    updateUniqueAnchor()
  }, [setOffset, dispatch, updateUniqueAnchor])

  const dataSource = useMemo(
    () => chats?.edges.map(({ node }) => node) || [],
    [chats],
  )

  const columns = useMemo(
    () =>
      getColumns({
        ordersBy,
        filter,
      }),
    [ordersBy, filter],
  )

  const onSendMsg = useCallback(
    (chat: ChatDto, content: string) => {
      dispatch(sendChatMsg(chat, content))
    },
    [dispatch],
  )

  const onMarkAsSeen = useCallback(
    (chatId: string, seen: boolean) => {
      dispatch(markChatSeen(chatId, seen))
    },
    [dispatch],
  )

  const onMarkAsResolved = useCallback(
    (chatId: string, resolved: boolean) => {
      dispatch(markChatReclamationResolved(chatId, resolved))
    },
    [dispatch],
  )

  const expandedRowRender = getExpandedRowRender({
    onSendMsg,
    onMarkAsSeen,
    onMarkAsResolved,
  })

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorters?: SorterResult<ChatDto> | SorterResult<ChatDto>[],
  ) => {
    const { pageSize, current } = pagination
    pageSize !== undefined && setLimit(pageSize as 10 | 25 | 50 | 100)
    current !== undefined && setPage(current)

    const sorter = isArray(sorters) ? sorters[0] : sorters

    if (sorter?.columnKey) {
      const column = SortColumnBinding[sorter.columnKey as ChatColumnName]

      if (column && sorter.order) {
        dispatch(
          setChatsOrderBy({
            column,
            order: sorter.order === "ascend" ? SortOrder.ASC : SortOrder.DESC,
          }),
        )
      }

      if (column && !sorter.order) {
        dispatch(
          removeChatsOrderBy({
            column,
          }),
        )
      }

      if (!column) {
        console.error(`Column "${column}" is missed`)
      }
    }

    dispatch(
      updateChatsFilters({
        isAdminInvited:
          get(filters, [ChatColumnName.IS_ADMIN_INVITED, 0]) === "invited"
            ? true
            : undefined,
        isAboutReclamation:
          get(filters, [ChatColumnName.IS_ABOUT_RECLAMATION, 0]) === "invited"
            ? true
            : undefined,
      }),
    )
  }

  return (
    <section style={{ textAlign: "center" }}>
      <h2>Chat list</h2>
      <Row justify="end" style={{ marginBottom: 5 }}>
        <ChatAddedButton refetch={refetchPageHandler}>
          Refetch page
        </ChatAddedButton>
      </Row>
      <Table<ChatDto>
        tableLayout="auto"
        rowKey={({ id }) => id}
        dataSource={dataSource}
        columns={columns}
        pagination={tableParams.pagination}
        expandedRowRender={expandedRowRender}
        onChange={tableChangeHandler}
        loading={loading}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default ChatsList
