import React, { FC } from "react"
import { Button } from "antd"
import { useSelector } from "react-redux"
import { PlusOutlined, MinusOutlined, LoadingOutlined } from "@ant-design/icons"

import { News } from "../../graphql/fragments"
import { selectNewsEditingById } from "../../selectors"

type Props = {
  expanded: boolean
  news: News
  onExpand: (
    record: News,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
}

export const NewsExpandIcon: FC<Props> = ({ expanded, news, onExpand }) => {
  const updating = useSelector(selectNewsEditingById(news.id))

  let icon = <LoadingOutlined spin />

  if (!updating) {
    icon = expanded ? <MinusOutlined /> : <PlusOutlined />
  }

  return <Button size="small" icon={icon} onClick={(e) => onExpand(news, e)} />
}
