import { gql } from "@apollo/client"
import { GraphQLPagination } from "../../../common/graphql/types"
import { BriefSeller } from "../../types"

export type GetBriefSellerProfilesData = {
  sellers: GraphQLPagination<BriefSeller>
}

export type GetBriefSellerProfilesVariables = {
  limit?: number
  offset?: number
}

export const GET_BRIEF_SELLER_PROFILES_QUERY = gql`
  query GetSellerProfiles($limit: Int, $offset: Int) {
    sellers(limit: $limit, offset: $offset) {
      edges {
        cursor
        node {
          id
          data {
            companyName
            companyEmail
          }
        }
      }
      total
      hasNextPage
      endCursor
    }
  }
`
