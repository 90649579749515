import firebase from "firebase/app"
import {
  OFFER_REQUEST_STATUS,
  OfferRequestDB,
  OfferRequestQuestion,
  REFUND_STATUS,
  STRIPE_PAYMENT_STATUS,
} from "hero24-types"
import { Dispatch } from "redux"

import { SuitableTimes } from "../../buyer/questions/types"
import { VATs } from "../../common/utils"
import { Store } from "../../core"
import { setIsApprovedByBuyerThunk } from "../graphql/mutation/setIsApprovedByBuyer"
import * as TYPES from "./actionTypes"
import { FIREBASE_PATH_OFFER_REQUESTS } from "./constants"
import { OfferRequest } from "./types"
import {
  SetIsApprovedByBuyerInput,
  Variables,
} from "../graphql/mutation/setIsApprovedByBuyer/mutation"

export interface GetOfferRequests {
  type: typeof TYPES.GET_OFFER_REQUESTS
  payload: {
    [key: string]: OfferRequest
  }
}

export const getAllOfferRequests = () => async (dispatch: Dispatch) => {
  const offerRequestsRef = await firebase
    .database()
    .ref(FIREBASE_PATH_OFFER_REQUESTS)

  const offerRequestsSnapshot = await offerRequestsRef
    .limitToLast(10000)
    .once("value")

  const offerRequests: {
    [id: string]: OfferRequestDB
  } = offerRequestsSnapshot.val()

  const offerRequestsState = Object.fromEntries(
    Object.entries(offerRequests).map((offerRequestEntry) => {
      return [
        offerRequestEntry[0],
        {
          id: offerRequestEntry[0],
          ...offerRequestEntry[1],
        },
      ]
    }),
  )

  dispatch({
    type: TYPES.GET_OFFER_REQUESTS,
    payload: offerRequestsState,
  })
}

export interface SetOfferRequestStatus {
  type: typeof TYPES.SET_OFFER_REQUEST_STATUS
  payload: {
    id: string
    status: Exclude<OFFER_REQUEST_STATUS, "completed" | "expired" | "accepted">
  }
}

export const setOfferRequestStatus =
  (payload: SetOfferRequestStatus["payload"]) => async (dispatch: Dispatch) => {
    const offerRequestRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRequestRef.once("value")
    if (offerRequest.exists()) {
      await offerRequestRef.child("data").update({ status: payload.status })
      dispatch({
        type: TYPES.SET_OFFER_REQUEST_STATUS,
        payload: payload,
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetOfferRequestTime {
  type: typeof TYPES.SET_OFFER_REQUEST_PREFERRED_TIME
  payload: {
    id: string
    questions: OfferRequestQuestion[]
  }
}

export const setOfferRequestTime =
  (payload: {
    id: string
    preferredTime?: number
    suitableTimes?: SuitableTimes
  }) =>
  async (dispatch: Dispatch) => {
    const offerRequestRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRequestRef.once("value")
    if (offerRequest.exists()) {
      const offerRequestValues: OfferRequestDB = offerRequest.val()
      const updatedQuestions = offerRequestValues.data.initial.questions.map(
        (question) => {
          if (question.type === "date" && payload.preferredTime) {
            question.preferredTime = payload.preferredTime || null
            question.suitableTimes = null
            question.suitableTimesCount = null
          } else if (question.type === "date" && payload.suitableTimes) {
            question.suitableTimes = payload.suitableTimes || null
            question.suitableTimesCount = payload.suitableTimes
              ? Object.keys(payload.suitableTimes).length
              : null
            question.preferredTime = null
          }
          return question
        },
      )
      await offerRequestRef
        .child("data")
        .child("initial")
        .child("questions")
        .set(updatedQuestions)
      dispatch({
        type: TYPES.SET_OFFER_REQUEST_PREFERRED_TIME,
        payload: {
          id: payload.id,
          questions: updatedQuestions,
        },
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetOfferRequestAddresses {
  type: typeof TYPES.SET_OFFER_REQUEST_ADDRESSES
  payload: {
    id: string
    addresses: OfferRequestDB["data"]["initial"]["addresses"]
  }
}

export const setOfferRequestAddresses =
  (payload: {
    id: string
    addresses: OfferRequestDB["data"]["initial"]["addresses"]
  }) =>
  async (dispatch: Dispatch) => {
    const offerRequestRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRequestRef.once("value")
    if (offerRequest.exists()) {
      await offerRequestRef
        .child("data")
        .child("initial")
        .child("addresses")
        .set(payload.addresses)

      dispatch({
        type: TYPES.SET_OFFER_REQUEST_ADDRESSES,
        payload,
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetOfferRequestQuestions {
  type: typeof TYPES.SET_OFFER_REQUEST_QUESTIONS
  payload: {
    questions: OfferRequestDB["data"]["initial"]["questions"]
    offerRequestId: string
  }
}

export const setOfferRequestQuestions =
  (
    questions: OfferRequestDB["data"]["initial"]["questions"],
    offerRequestId: string,
  ) =>
  async (dispatch: Dispatch) => {
    const offerRequestRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(offerRequestId)
    const offerRequest = await offerRequestRef.once("value")

    if (offerRequest.exists()) {
      await offerRequestRef
        .child("data")
        .child("initial")
        .child("questions")
        .set(questions)

      dispatch({
        type: TYPES.SET_OFFER_REQUEST_QUESTIONS,
        payload: { questions, offerRequestId },
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetOfferRequestVats {
  type: typeof TYPES.SET_OFFER_REQUEST_VATS
  payload: {
    id: string
    vats: VATs
  }
}

export const setOfferRequestVats =
  (payload: { id: string; newVats: Partial<VATs> }) =>
  async (dispatch: Dispatch) => {
    const offerRequestRef = firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRequestRef.once("value")
    if (offerRequest.exists()) {
      const { customerVAT, serviceProviderVAT, hero24Cut } = payload.newVats

      if (customerVAT) {
        await offerRequestRef
          .child("customerVAT")
          .set(payload.newVats.customerVAT)
      }

      if (serviceProviderVAT) {
        await offerRequestRef
          .child("serviceProviderVAT")
          .set(payload.newVats.serviceProviderVAT)
      }

      if (hero24Cut) {
        await offerRequestRef.child("hero24Cut").set(payload.newVats.hero24Cut)
      }

      dispatch({
        type: TYPES.SET_OFFER_REQUEST_VATS,
        payload,
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetOfferRequestRefund {
  type: typeof TYPES.SET_OFFER_REQUEST_REFUND
  payload: {
    id: string
    status?: REFUND_STATUS
    amount?: number
    message?: string
  }
}

export const setOfferRequestRefund =
  (payload: SetOfferRequestRefund["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRef.once("value")
    if (offerRequest.exists()) {
      const updatedValues: Partial<OfferRequestDB["refund"]> = {
        updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
        ...(payload.status !== undefined ? { status: payload.status } : {}),
        ...(payload.amount !== undefined ? { amount: payload.amount } : {}),
        ...(payload.message ? { message: payload.message } : {}),
      }
      await offerRef.child("refund").update(updatedValues)
      dispatch({
        type: TYPES.SET_OFFER_REQUEST_REFUND,
        payload: payload,
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }

export interface SetIsApprovedByBuyer {
  type: typeof TYPES.SET_OFFER_REQUEST_APPROVED_BY_BUYER
  payload: SetIsApprovedByBuyerInput
}

export const setIsApprovedByBuyer =
  (input: SetIsApprovedByBuyer["payload"]) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    await setIsApprovedByBuyerThunk(getState(), input)

    await dispatch({
      type: TYPES.SET_OFFER_REQUEST_APPROVED_BY_BUYER,
      payload: input,
    })
  }

export interface SetOfferRequestStripePayment {
  type: typeof TYPES.SET_OFFER_REQUEST_STRIPE_PAYMENT
  payload: {
    id: string
    status?: STRIPE_PAYMENT_STATUS
    link?: string
    message?: string
  }
}

// Temp fix until MangoPay is live
export const setOfferRequestStripePayment =
  (payload: SetOfferRequestStripePayment["payload"]) =>
  async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFER_REQUESTS)
      .child(payload.id)

    const offerRequest = await offerRef.once("value")
    if (offerRequest.exists()) {
      const updatedValues: Partial<OfferRequestDB["stripePayment"]> = {
        updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
        ...(payload.status !== undefined ? { status: payload.status } : {}),
        ...(payload.link !== undefined ? { link: payload.link } : {}),
        ...(payload.message ? { message: payload.message } : {}),
      }
      await offerRef.child("stripePayment").update(updatedValues)
      dispatch({
        type: TYPES.SET_OFFER_REQUEST_STRIPE_PAYMENT,
        payload: payload,
      })
    } else {
      throw new Error("OfferRequest not found! (should never happen)")
    }
  }
