import { Modal } from "antd"
import React, { FunctionComponent, useState } from "react"

interface Props<FormProps> {
  ButtonComponent: FunctionComponent<{ onClick: () => void }>
  FormComponent: FunctionComponent<FormProps>
  formProps?: FormProps
}

const ModalForm = <FormProps,>({
  FormComponent,
  ButtonComponent,
  formProps = {} as FormProps,
}: Props<FormProps>) => {
  const [isVisible, setIsVisible] = useState(false)

  const showModal = () => setIsVisible(true)

  const closeModal = () => setIsVisible(false)

  return (
    <>
      <Modal
        destroyOnClose
        open={isVisible}
        onCancel={closeModal}
        footer={false}
      >
        <FormComponent {...formProps} closeModal={closeModal} />
      </Modal>

      <ButtonComponent onClick={showModal} />
    </>
  )
}

export default ModalForm
