import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Popconfirm as AntdPopconfirm, PopconfirmProps } from "antd"
import { FC, PropsWithChildren } from "react"

export type Props = Pick<PopconfirmProps, "onConfirm" | "title"> & {
  loading: boolean
}

const popconfirmIcon: PopconfirmProps["icon"] = (
  <ExclamationCircleOutlined style={{ color: "red" }} />
)

const Popconfirm: FC<PropsWithChildren<Props>> = ({
  onConfirm,
  title,
  loading,
  children,
}) => {
  const okButtonProps: PopconfirmProps["okButtonProps"] = {
    loading,
    disabled: loading,
  }

  const cancelButtonProps: PopconfirmProps["cancelButtonProps"] = {
    disabled: loading,
  }

  return (
    <AntdPopconfirm
      title={title}
      icon={popconfirmIcon}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      onConfirm={onConfirm}
    >
      {children}
    </AntdPopconfirm>
  )
}

export default Popconfirm
