import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Table } from "antd"

import { getAllUsers } from "../../actions"
import * as TYPES from "../../actionTypes"

import { User, UsersState } from "../../types"
import { getColumns } from "../table"
import UserAdminStatusChanger from "./components/UserAdminStatusChanger"
import { ExpandedRow } from "./ExpandedRow"

const UserList = () => {
  const dispatch = useDispatch()

  const users = useSelector(({ users }: { users: UsersState }) => {
    return Object.values(users.users)
  })

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch({ type: TYPES.ARE_USERS_LOADED, payload: true })
  }, [dispatch])

  const columns = getColumns()

  return (
    <section
      style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
    >
      <UserAdminStatusChanger />
      <h2>Users List</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: User) => record.id}
        pagination={{
          pageSize: 25,
        }}
        dataSource={users}
        columns={columns || []}
        expandable={{
          expandedRowRender: (user: User) => <ExpandedRow user={user} />,
        }}
        loading={!users.length}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default UserList
