/* eslint-disable */
import firebase from "firebase/app"
import { ThunkDispatch } from "redux-thunk"
import { getCategories } from "../categories/actions"

import * as TYPES from "./actionTypes"
import { OfferRequest, OfferRequestCreateProps } from "./types"
import { OfferRequestDB } from "hero24-types"

import { FIREBASE_PATH } from "./constants"
import { Store } from "../../core/types"
import { saveFees } from "../../fees/actions"

import { selectApolloClient } from "../../apolloClient/selectors"
import { createOfferRequestThunk } from "../../offerRequests/graphql/mutation"
import { prepareOfferCreationInput } from "./utils"

type CreateOfferRequestInput = Parameters<typeof createOfferRequestThunk>[1]

export interface OfferRequestCreated {
  type: typeof TYPES.OFFER_REQUEST_CREATED
  payload: OfferRequest
}

export const offerRequestCreated: (
  offerRequest: OfferRequest,
) => OfferRequestCreated = (offerRequest) => {
  return {
    type: TYPES.OFFER_REQUEST_CREATED,
    payload: offerRequest,
  }
}

export const offerRequestCreate =
  (payload: OfferRequestCreateProps) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const storeState = getState()

    const input: CreateOfferRequestInput = prepareOfferCreationInput(
      storeState,
      payload,
    )

    if (input.data.initial.questions.length < 1) {
      console.error("OfferRequest questions can not be empty array:", input)
      return
    }

    try {
      const { createOfferRequestData: data } = await createOfferRequestThunk(
        getState(),
        input,
      )

      if (data) {
        dispatch(saveFees(data.id, data.data.initial.buyerProfile))

        const savedOfferRequestSnapshot: firebase.database.DataSnapshot =
          await firebase
            .database()
            .ref(FIREBASE_PATH)
            .child(data.id)
            .once("value") // tslint:disable-line:no-unsafe-any

        const savedOfferRequest: OfferRequestDB =
          await savedOfferRequestSnapshot.val() // tslint:disable-line:no-unsafe-any

        // After saving
        dispatch(
          offerRequestCreated({
            id: savedOfferRequestSnapshot.key as string,
            ...savedOfferRequest,
          }),
        )
      }
    } catch (err) {
      console.warn("Failed to create offer request")
      console.error(err)
      throw err
    }

    dispatch(getCategories())
  }

export interface SetDoerPickStrategy {
  type: typeof TYPES.SET_DOER_PICK_STRATEGY
  pickServiceProvider: OfferRequest["data"]["pickServiceProvider"]
}

export const setDoerPickStrategy: (
  pickServiceProvider: OfferRequest["data"]["pickServiceProvider"],
) => SetDoerPickStrategy = (pickServiceProvider) => {
  return {
    type: TYPES.SET_DOER_PICK_STRATEGY,
    pickServiceProvider,
  }
}

export interface SetPromotionDisabled {
  type: typeof TYPES.SET_PROMOTION_DISABLED
  payload: boolean
}

export const setPromotionDisabled: (
  payload: boolean,
) => SetPromotionDisabled = (payload) => {
  return {
    type: TYPES.SET_PROMOTION_DISABLED,
    payload,
  }
}
