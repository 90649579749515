import { Seller } from "./types"

export const normalizeSellers = (sellers: any): any => {
  if (Object.keys(sellers).length === 0) {
    return []
  }
  return Object.keys(sellers).map((key) => {
    return {
      id: key,
      sellerData: {
        ...sellers[key].data,
        isApproved: sellers[key].isApproved,
      },
      userData: sellers[key].userData,
    }
  })
}

interface Filter {
  text: string
  value: string
}
export const getAllSellerCategories = (sellers: Seller[]) => {
  const servicesFiltersKeyVal: {
    [area: string]: Filter
  } = {}
  sellers.forEach((seller) => {
    seller.sellerData.categories &&
      Object.keys(seller.sellerData.categories).forEach((category) => {
        servicesFiltersKeyVal[category] = {
          value: category,
          text: category,
        }
      })
  })
  return servicesFiltersKeyVal
}
