import { useDispatch, useSelector } from "react-redux"
import React from "react"

import { Descriptions, Collapse, Button } from "antd"
import { Subscription } from "../../../types"
import { DAY } from "../../../constants"

import { SUBSCRIPTION_BILLING } from "hero24-types"
import * as colors from "@ant-design/colors"

import {
  OfferRequests,
  OfferRequest,
} from "../../../../offerRequests/list/types"
import * as actions from "../../../actions"

import { DateAndTime, OfferRequestStatus } from "../../../../common/components"
import { getSubscriptionsLoadedError } from "../../../selectors"
import { getDateQuestion } from "../../../../offerRequests/utils"

interface Props {
  offers: OfferRequests
  subscription: Subscription
}

interface HistoryItemProps {
  offerRequest: OfferRequest
}

const ORDER_OFFSET_DAYS = 5 * DAY

function _offerRequestStartTime(offerRequest: OfferRequest) {
  const dateQuestion = getDateQuestion(offerRequest)
  const flexTimeSlots = Object.values(dateQuestion?.suitableTimes || {})

  flexTimeSlots.sort((a, b) => a.startTime - b.startTime)
  const prefferedFlexTime = flexTimeSlots[0] && flexTimeSlots[0]?.startTime

  return dateQuestion?.preferredTime || prefferedFlexTime
}

function SubscriptionHistoryItem({ offerRequest }: HistoryItemProps) {
  const preferredTime = _offerRequestStartTime(offerRequest)
  return (
    <>
      <Descriptions className={"stretch-row"}>
        <Descriptions.Item span={1} label={"Offer Request ID"}>
          {offerRequest?.id || ""}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={"Offer Request Status"}>
          <OfferRequestStatus offerRequest={offerRequest} readonly />
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Offer Request Start Date">
          <DateAndTime time={preferredTime} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

function SubscriptionHistoryEmpty() {
  return (
    <>
      <Descriptions>
        <Descriptions.Item span={1} label={"Offer Requests History"}>
          {"No Previous Offer Requests"}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export const SubscriptionView = ({ subscription, offers }: Props) => {
  const dispatch = useDispatch()

  const removeSubscription = () =>
    dispatch(actions.removeSubscription(subscription))

  const subscriptionsError = useSelector(getSubscriptionsLoadedError())

  React.useEffect(() => {
    if (subscriptionsError) {
      console.error(subscriptionsError)
    }
  }, [subscriptionsError])

  const offerRequest = offers && offers[subscription.activeRequest]
  const enableDangerZone = true

  const requestHistory = Object.keys(subscription.offerRequests || {})
    .filter((k) => k !== subscription.activeRequest)
    .map((k) => offers && offers[k])
    .filter((el) => !!el)

  const preferredTime = _offerRequestStartTime(offerRequest)
  const paymentStatus = subscription.billingStatus || ""

  const getStatusColor = (status: SUBSCRIPTION_BILLING) => {
    switch (status) {
      case "paid":
        return colors.green.primary
      case "unpaid":
        return colors.red.primary
      default:
        return "black"
    }
  }

  let offerNextDate = subscription.currentPeriodEnd
  if (subscription.interval !== "daily") {
    offerNextDate += ORDER_OFFSET_DAYS
  }

  return (
    <>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={`https://dashboard.stripe.com/subscriptions/${subscription.stripeSubscriptionId}`}
      >{`https://dashboard.stripe.com/subscriptions/${subscription.stripeSubscriptionId}`}</a>
      <Descriptions>
        <Descriptions.Item label="Subscription ID">
          {subscription.id}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"}>
        <Descriptions.Item span={1} label="Subscription Period">
          <DateAndTime time={subscription.currentPeriodStart} />
          {" - "}
          <DateAndTime time={subscription.currentPeriodEnd} />
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Subscription Period Status">
          <span
            style={{
              fontSize: 14,
              color: getStatusColor(paymentStatus),
            }}
          >
            {paymentStatus.toUpperCase()}
          </span>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"}>
        <Descriptions.Item span={1} label={"Current Offer Request ID"}>
          {offerRequest?.id || ""}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={"Current Offer Request Status"}>
          <OfferRequestStatus offerRequest={offerRequest} readonly />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"}>
        <Descriptions.Item span={1} label="Current Offer Request Start Date">
          <DateAndTime time={preferredTime} />
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Next Offer Request Start Date">
          <DateAndTime time={offerNextDate} />
        </Descriptions.Item>
      </Descriptions>
      {requestHistory.length ? (
        <Collapse>
          <Collapse.Panel header="Offer Requests History" key="history">
            {requestHistory.map((el, idx) => (
              <SubscriptionHistoryItem
                key={"offer-request-" + idx}
                offerRequest={el}
              />
            ))}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <SubscriptionHistoryEmpty />
      )}
      {enableDangerZone ? (
        <Descriptions
          className={"stretch-row danger-zone"}
          layout={"horizontal"}
        >
          <Descriptions.Item span={3} style={{ paddingBottom: "15px" }}>
            {"Danger Zone"}
          </Descriptions.Item>
          <Descriptions.Item span={3}>
            <Button
              style={{ backgroundColor: colors.red.primary }}
              onClick={removeSubscription}
            >
              {"Delete Subscription"}
            </Button>
          </Descriptions.Item>
        </Descriptions>
      ) : null}
    </>
  )
}
