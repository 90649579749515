import { PercentageOutlined } from "@ant-design/icons"
import { Col, Row, Typography } from "antd"
import { FC } from "react"
import { CustomerVatInput } from "./components/CustomerVatInput"
import { Hero24CutInput, ServiceProviderVatInput } from "./components"

const { Title } = Typography

const Vat: FC = () => {
  return (
    <>
      <Row gutter={[24, 12]} className="title-block">
        <Col>
          <PercentageOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            VAT (TAX)
          </Title>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <CustomerVatInput />
        <ServiceProviderVatInput />
        <Hero24CutInput />
      </Row>
    </>
  )
}

export default Vat
