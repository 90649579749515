import { gql } from "@apollo/client"

export type UnbindUserOfferRequestsData = {
  unbindUserOfferRequests: boolean
}

export type UnbindUserOfferRequestsVariables = {
  offerRequestIds: string[]
  userId: string
}

export const UNBIND_USER_OFFER_REQUESTS_MUTATION = gql`
  mutation UnbindUserOfferRequests(
    $offerRequestIds: [String!]!
    $userId: String!
  ) {
    unbindUserOfferRequests(offerRequestIds: $offerRequestIds, userId: $userId)
  }
`
