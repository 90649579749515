import React, { useState } from "react"
import { Offer } from "../../../offers/list/types"
import { User } from "../../../user/types"
import { Seller } from "../../../seller/types"
import { sendReceiptToEmail } from "../../../offers/list/actions"
import { Button, notification } from "antd"

interface Props {
  offer: Offer
  user: User
  seller: Seller
  isSeller: boolean
}

export const SendReceiptToEmail = (props: Props) => {
  const [isPdfLoading, setIsPdfLoading] = useState(false)

  const email = props.isSeller
    ? props.seller.sellerData.companyEmail
    : props.user.data.email

  const notificationMsg = props.isSeller
    ? `Doer receipt sent to ${props.seller.sellerData.companyEmail}`
    : `Customer receipt sent to ${props.user.data.email}`

  const btnText = props.isSeller
    ? `Send doer receipt to ${props.seller.sellerData.companyEmail}`
    : `Send customer receipt to ${props.user.data.email}`

  const sendEmail = async () => {
    try {
      setIsPdfLoading(true)
      const result = await sendReceiptToEmail(email, "receipt", {
        offerId: props.offer.id,
        isSeller: props.isSeller,
      })
      result
        ? notification.success({
            message: notificationMsg,
            duration: 3,
          })
        : notification.success({
            message: "Error sending receipt",
            duration: 3,
          })
    } catch (error) {
      notification.success({
        message: "Something went wrong",
        duration: 3,
      })
    } finally {
      setIsPdfLoading(false)
    }
  }

  return (
    <Button
      type="primary"
      size="large"
      disabled={isPdfLoading}
      onClick={sendEmail}
    >
      {isPdfLoading ? "Loading..." : btnText}
    </Button>
  )
}
