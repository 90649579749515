import { useDispatch, useSelector } from "react-redux"
import { selectApolloClient } from "../../../apolloClient/selectors"
import { useEffect } from "react"
import {
  CHAT_SEEN_BY_ADMIN_UPDATED_SUBSCRIPTION,
  ChatSeenByAdminUpdatedData,
} from "../../graphql/subscriptions/ChatSeenByAdminUpdated"
import {
  decrementUnseenChatsCount,
  incrementUnseenChatsCount,
} from "../../reducer"

export const useSubscribeOnChatSeenByAdminUpdates = () => {
  const apolloClient = useSelector(selectApolloClient)
  const dispatch = useDispatch()

  useEffect(() => {
    const subscription = apolloClient
      .subscribe<ChatSeenByAdminUpdatedData>({
        query: CHAT_SEEN_BY_ADMIN_UPDATED_SUBSCRIPTION,
      })
      .subscribe({
        next: ({ data }) => {
          if (!data) {
            return
          }

          const { previous, current } = data.seenByAdminUpdated

          // call it only if true => false and false || null => true
          if (!!previous !== current) {
            current && dispatch(decrementUnseenChatsCount()) // if chat is seen now, we should decrement unseen count
            !current && dispatch(incrementUnseenChatsCount()) // if chat is not seen now, we should increment unseen count
          }
        },
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [apolloClient, dispatch])
}
