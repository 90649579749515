import React from "react"
import { AuditOutlined } from "@ant-design/icons"

import { PURCHASE_INVOICE_ROUTE } from "./routes"

export default [
  {
    icon: <AuditOutlined />,
    name: "Purchase invoice",
    path: PURCHASE_INVOICE_ROUTE,
  },
]
