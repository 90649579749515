import { AddressesAnswered as AddressesAnsweredDB } from "hero24-types"

import {
  DeliveryAddresses,
  DeliveryAddressesAdapter,
} from "./DeliveryAddressesInfo"
import { BasicAddresses, BasicAddressesAdapter } from "./BasicAddressesInfo"
import { FirebaseAdapter } from "../../../../common/utilities"
import { Maybe } from "../../../../common/types"

export type AddressesAnsweredInput = {
  basic?: Maybe<BasicAddresses>
  delivery?: Maybe<DeliveryAddresses>
}

export const AddressesAnsweredInputAdapter = new FirebaseAdapter<
  AddressesAnsweredDB,
  AddressesAnsweredInput
>({
  toFirebase: (graphql) => {
    if (graphql.basic) {
      return BasicAddressesAdapter.toFirebase(graphql.basic)
    }

    if (graphql.delivery) {
      return DeliveryAddressesAdapter.toFirebase(graphql.delivery)
    }

    throw new Error("Address not recognized")
  },
  toGraphQL: (firebase) => {
    let delivery: DeliveryAddresses | null = null
    let basic: BasicAddresses | null = null

    if (firebase.type === "basic") {
      basic = BasicAddressesAdapter.toGraphQL(
        firebase as AddressesAnsweredDB & { type: "basic" },
      )
    }

    if (firebase.type === "delivery") {
      delivery = DeliveryAddressesAdapter.toGraphQL(
        firebase as AddressesAnsweredDB & { type: "delivery" },
      )
    }

    return { delivery, basic }
  },
})
