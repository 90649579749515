import React from "react"
import { Button, List, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getFeesToSave } from "../../../fees/selectors"
import { removeFeeToBeSaved } from "../../../fees/actions"

export const Fees = () => {
  const dispatch = useDispatch()
  const onRemove = (index: number) => {
    dispatch(removeFeeToBeSaved(index))
  }
  const fees = useSelector(getFeesToSave)
  if (!fees || fees.length < 1) {
    return null
  }
  return (
    <List
      size="small"
      bordered
      dataSource={fees}
      renderItem={(fee, index) => (
        <List.Item>
          <Typography.Text strong>
            {fee.quantity * fee.unitPrice}€{" "}
            <Typography.Text>{fee.description}</Typography.Text> ({fee.quantity}{" "}
            * {fee.unitPrice}€)
          </Typography.Text>
          <Button onClick={() => onRemove(index)}>Remove</Button>
        </List.Item>
      )}
    />
  )
}
