import { ApolloThunkQuery } from "../../../../apolloClient/types"
import { apolloQuery } from "../../../../apolloClient/utils"
import { User } from "../../fragments"
import { QUERY } from "./query"

type Variables = {
  id: string
}

type Data = {
  user: User | null
}

export const getUserThunk: ApolloThunkQuery<Data, [string]> = async (
  state,
  id,
) => {
  const { data, error } = await apolloQuery<Data, Variables>({
    state,
    query: QUERY,
    variables: {
      id,
    },
  })

  return {
    userData: data?.user,
    userError: error,
  }
}
