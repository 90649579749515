export enum VatName {
  CUSTOMER_VAT = "customerVAT",
  SERVICE_PROVIDER_VAT = "serviceProviderVAT",
  HERO24_CUT = "hero24Cut",
}

export interface VatForm {
  serviceProviderVAT: string
  customerVAT: string
  hero24Cut: string
}
