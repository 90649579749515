import { PromotionDB } from "hero24-types"

const getDiscountSymbol = (
  discountFormat: PromotionDB["data"]["discountFormat"],
) => {
  switch (discountFormat) {
    case "fixed":
      return "€"

    case "percentage":
      return "%"

    default:
      return "%"
  }
}

export default getDiscountSymbol
