import { RecordType, TypeSafeRequired } from "../types"
import { omitUndefined } from "./omitUndefined"
import { BaseFirebaseAdapter } from "./BaseFirebaseAdapter"

export class FirebaseAdapter<
  FirebaseType extends RecordType,
  GraphQLType extends RecordType,
> implements
    BaseFirebaseAdapter<
      TypeSafeRequired<FirebaseType>,
      TypeSafeRequired<GraphQLType>
    >
{
  constructor(
    private readonly adapter: BaseFirebaseAdapter<
      TypeSafeRequired<FirebaseType>,
      TypeSafeRequired<GraphQLType>
    >,
  ) {}

  toFirebase = (graphql: GraphQLType): TypeSafeRequired<FirebaseType> => {
    return omitUndefined(this.adapter.toFirebase(graphql))
  }

  toGraphQL = (firebase: FirebaseType): TypeSafeRequired<GraphQLType> => {
    return this.adapter.toGraphQL(firebase)
  }
}
