import { grey } from "@ant-design/colors"
import { CSSProperties } from "react"

export const metaStyle: CSSProperties = {
  fontSize: "0.7rem",
  color: grey.primary,
}

export const metaStyleDisabled: CSSProperties = {
  fontSize: "0.7rem",
}
