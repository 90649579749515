import React from "react"
import { Form, Input } from "antd"

import { FormikTouched, FormikErrors } from "formik"

const FormItem = Form.Item
const { TextArea } = Input

interface InputProps {
  name: string
  value?: string
  defaultValue?: string
  disabled?: boolean
  row?: number
}

export interface RenderTextAreaProps {
  input?: InputProps
  label?: string
  placeholder?: string
  meta: {
    touched: boolean | FormikTouched<any> | undefined
    error: string | FormikErrors<any> | undefined
  }
}

const RenderTextArea = ({
  input,
  label,
  meta: { touched, error },
  placeholder,
}: RenderTextAreaProps): React.ReactElement => {
  let validateStatus:
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating"
    | undefined = ""
  if (touched && error) {
    validateStatus = "error"
  }

  return (
    <FormItem
      label={label}
      validateStatus={validateStatus}
      help={touched && error}
    >
      <div>
        <TextArea {...input} placeholder={label || placeholder} />
      </div>
    </FormItem>
  )
}

export default RenderTextArea
