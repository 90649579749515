import { UserDB } from "hero24-types"
import { Dispatch } from "redux"

import { Store } from "../../core"
import { getUsersThunk } from "../../user/graphql/queries"
import { UserAdapter } from "../../user/graphql/fragments"
import { editUserDataThunk } from "../../user/graphql/mutations"
import * as TYPES from "./actionTypes"
import * as USER_TYPES from "../../user/actionTypes"
import { User } from "./types"
import { editUserAdminStatusThunk } from "../../user/graphql/mutations/editUserAdminStatus"

export interface GetUsersList {
  type: typeof TYPES.GET_USERS_LIST
  payload: Record<string, User>
}

export const getAllUsers =
  () => async (dispatch: Dispatch, getState: () => Store) => {
    const state = getState()
    const { usersData } = await getUsersThunk(state, {})
    const edges = usersData?.edges || []

    const users = Object.fromEntries(
      edges.map(({ node, cursor }) => [cursor, UserAdapter.toFirebase(node)]),
    )

    const payload = Object.fromEntries(
      Object.entries(users).sort((userEntryA, userEntryB) => {
        if (userEntryA[1]?.data?.phone === userEntryB[1]?.data?.phone) {
          return 0
        }

        return (userEntryA[1]?.data?.phone || "").localeCompare(
          userEntryB[1]?.data?.phone || "",
        )
      }),
    )

    dispatch({ type: USER_TYPES.GET_USERS, payload })

    if (!state.users.areUsersLoaded) {
      dispatch({ type: TYPES.GET_USERS_LIST, payload })
    }
  }

export interface EditUser {
  type: typeof TYPES.EDIT_USER
  payload: {
    values: Pick<
      UserDB["data"],
      | "email"
      | "name"
      | "firstName"
      | "lastName"
      | "phone"
      | "iban"
      | "language"
    >
    id: string
  }
}

export const editUser =
  (props: EditUser["payload"]) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    await editUserDataThunk(getState(), {
      data: props.values,
      userId: props.id,
    })

    dispatch({
      type: TYPES.EDIT_USER,
      payload: props,
    })
  }

export interface EditUserAdminStatus {
  type: typeof USER_TYPES.EDIT_USER_ADMIN_STATUS
  payload: { isAdmin: boolean; id: string }
}

export const editUserAdminStatus =
  (props: EditUserAdminStatus["payload"]) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    await editUserAdminStatusThunk(getState(), {
      input: {
        id: props.id,
        isAdmin: props.isAdmin,
      },
    })

    dispatch({
      type: USER_TYPES.EDIT_USER_ADMIN_STATUS,
      payload: props,
    })
  }

export interface AddUser {
  type: typeof TYPES.ADD_USER
  payload: User
}

export interface AreUsersLoaded {
  type: typeof TYPES.ARE_USERS_LOADED
  payload: boolean
}

export interface EditAdminPermissionsInAllUsers {
  type: typeof TYPES.EDIT_ADMIN_PERMISSIONS_IN_ALL_USERS
  payload: { id: string; isAdmin: boolean }
}
