import { Store } from "../core/types"

export const getAuthUser = (state: Store) => state.auth.user

export const isCustomerAgentUser = (state: Store) =>
  state.auth.user && state.auth.user.isAdmin

export const isFetchingUser = (state: Store) => state.auth.isFetching

export const isUserAdmin = (state: Store) => {
  const user = getAuthUser(state)
  return user && ["kalle@hero24.com", "juho@hero24.com"].includes(user.email)
}

export const isUserSuperAdmin = (state: Store) => {
  const user = getAuthUser(state)
  return user && ["kalle@hero24.com"].includes(user.email)
}
