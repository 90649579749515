import { EditFilled } from "@ant-design/icons"
import { Button, Popover, message } from "antd"

import { PaidStatus } from "hero24-types"
import { useCallback, useMemo, useState } from "react"
import { OfferRequest } from "../../../../buyer/createOfferRequest/types"
import { PaymentService } from "../../../../payments/constants"
import ModalForm from "../../ModalForm"
import {
  ApprovedByBuyer,
  ApprovedByBuyerCallback,
  ChangePaymentMethodModal,
  PrepaidStatus,
  PrepayWithPrefix,
  approvedByBuyerCallbackMessage,
} from "./components"
import { CHANGE_PAYMENT_METHOD_POPOVER_CONTENT } from "./constants"

interface Props {
  offerRequest: OfferRequest
}

type PaymentMethodValues = Pick<
  OfferRequest["data"]["initial"],
  "prePayWith" | "postpaid" | "sendInvoiceWith"
>

export const Prepaid = ({ offerRequest }: Props) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [prePayWith, setPrePayWith] = useState(
    offerRequest.data.initial.prePayWith,
  )
  const [prepaid, setPrepaid] = useState(offerRequest.data.initial.prepaid)
  const [sendInvoiceWith, setSendInvoiceWith] = useState(
    offerRequest.data.initial.sendInvoiceWith,
  )

  const approvedByBuyerCallback = useCallback<ApprovedByBuyerCallback>(
    (status): void => {
      messageApi.open({
        type: status,
        content: approvedByBuyerCallbackMessage[status],
      })
    },
    [messageApi],
  )

  // updated offer request is needed for immediate updates without subscriptions
  const updatedOfferRequest = useMemo(
    () => ({
      ...offerRequest,
      data: {
        ...offerRequest.data,
        initial: {
          ...offerRequest.data.initial,
          prePayWith,
          prepaid,
          sendInvoiceWith,
        },
      },
    }),
    [prePayWith, prepaid, sendInvoiceWith, offerRequest],
  )

  const { status } = offerRequest.data

  const isNotPrepaidOpenOfferRequest =
    status === "open" && prepaid !== PaidStatus.PAID

  const savePaymentMethodHandler = useCallback(
    (updates: PaymentMethodValues) => {
      setPrePayWith(updates.prePayWith)
      setPrepaid(
        updates.prePayWith ===
          (PaymentService.STRIPE || PaymentService.NETVISOR) &&
          updates.postpaid === "yes"
          ? PaidStatus.PAID
          : PaidStatus.WAITING,
      )
      setSendInvoiceWith(updates.sendInvoiceWith)
    },
    [],
  )

  if (prePayWith) {
    return (
      <>
        {contextHolder}
        <PrepayWithPrefix prePayWith={prePayWith}>
          <PrepaidStatus offerRequest={updatedOfferRequest} />
          <ApprovedByBuyer
            offerRequest={offerRequest}
            callback={approvedByBuyerCallback}
          />
          {isNotPrepaidOpenOfferRequest && (
            <ModalForm
              FormComponent={ChangePaymentMethodModal}
              ButtonComponent={({ onClick }) => (
                <Popover
                  trigger="hover"
                  content={CHANGE_PAYMENT_METHOD_POPOVER_CONTENT}
                >
                  <Button
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={onClick}
                    style={{ margin: 5 }}
                  />
                </Popover>
              )}
              formProps={{
                offerRequest: updatedOfferRequest,
                onSave: savePaymentMethodHandler,
              }}
            />
          )}
        </PrepayWithPrefix>
      </>
    )
  }

  return <>-</>
}
