import { Button, Modal } from "antd"
import React, { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CreateNewsForm from "./CreateNewsForm"
import { createNewsThunk } from "../thunk-actions"
import { selectNewsCreating } from "../selectors"

const NewsAdderButton: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const creating = useSelector(selectNewsCreating)

  const dispatch = useDispatch()

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsModalOpen(true)}
        loading={creating}
      >
        Create news
      </Button>
      <Modal
        title="Create news"
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <CreateNewsForm
          onSubmit={({
            image,
            description,
            link,
            title,
            startAt,
            endAt,
            label,
          }) => {
            dispatch(
              createNewsThunk(
                {
                  title,
                  description,
                  startAt,
                  endAt,
                  link: link || undefined,
                  label,
                },
                image,
              ),
            )

            setIsModalOpen(false)
          }}
          loading
        />
      </Modal>
    </>
  )
}
export default NewsAdderButton
