import React from "react"
import { Timeline } from "antd"
import Text from "antd/lib/typography/Text"
import { ChangeLog } from "hero24-types"
import { UsersState } from "../../../../users/list/types"
import { DateAndTime } from "../../listComponent/DateAndTime"

export const FeeLogItem = ({
  log,
  users,
}: {
  log: ChangeLog
  users: UsersState
}) => {
  const user = log.authId && users.users[log.authId]
  return (
    <Timeline.Item key={log.time}>
      <DateAndTime time={log.time} short />:{" "}
      {log.key === "createdAt" ? (
        <>
          <Text>Fee created</Text>
          <br />
          <Text>
            {user
              ? `${
                  user.data.name ||
                  `${user.data.firstName} ${user.data.lastName}`
                }`
              : "automatically "}{" "}
          </Text>
        </>
      ) : (
        <>
          <Text>Fee.{log.key}</Text>
          <br />
          <Text>
            {user
              ? `${
                  user.data.name ||
                  `${user.data.firstName} ${user.data.lastName}`
                }`
              : "automatically "}{" "}
            changed from {log.before} to {log.after}
          </Text>
        </>
      )}
      {user ? (
        <>
          <br />
          <Text>{user.data.email}</Text>
        </>
      ) : null}
    </Timeline.Item>
  )
}
