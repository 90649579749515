import { gql } from "@apollo/client"

export type GetIsSellerApprovedData = {
  isSellerApproved: boolean
}
export type GetIsSellerApprovedVariables = {
  id: string
}

export const IS_SELLER_APPROVED_QUERY = gql`
  query GetIsSellerApproved($id: String!) {
    isSellerApproved(id: $id)
  }
`
