import { gql } from "@apollo/client"
import { PackageDB } from "hero24-types"

import { FirebaseAdapter } from "../../../../common/utilities"
import { Maybe } from "../../../../common/types"
import {
  TRANSLATION_FIELD_FRAGMENT,
  TranslationField,
  TranslationFieldAdapter,
} from "../../../../common/graphql/fragments"

export type Package = {
  id: string
  name: TranslationField
  description: TranslationField
  order: number
  pricePerHour: number
  recommendedDuration?: Maybe<number>
  citiesIncluded?: Maybe<string[]>
  citiesExcluded?: Maybe<string[]>
}

export const PackageAdapter = new FirebaseAdapter<
  PackageDB & { id: string },
  Package
>({
  toFirebase: (graphql) => ({
    id: graphql.id,
    cities_excluded: graphql.citiesExcluded ?? undefined,
    cities_included: graphql.citiesIncluded ?? undefined,
    description: TranslationFieldAdapter.toFirebase(graphql.description),
    name: TranslationFieldAdapter.toFirebase(graphql.name),
    order: graphql.order,
    pricePerHour: graphql.pricePerHour,
    recommendedDuration: graphql.recommendedDuration ?? undefined,
  }),
  toGraphQL: (firebase) => ({
    id: firebase.id,
    citiesExcluded: firebase.cities_excluded,
    citiesIncluded: firebase.cities_included,
    description: TranslationFieldAdapter.toGraphQL(firebase.description),
    name: TranslationFieldAdapter.toGraphQL(firebase.name),
    order: firebase.order,
    pricePerHour: firebase.pricePerHour,
    recommendedDuration: firebase.recommendedDuration,
  }),
})

export const PACKAGE_FRAGMENT = gql`
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment PackageInfo on PackageDto {
    id
    name {
      ...TranslationFieldInfo
    }
    description {
      ...TranslationFieldInfo
    }
    order
    pricePerHour
    recommendedDuration
    citiesIncluded
    citiesExcluded
  }
`
