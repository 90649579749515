import React from "react"

import { SyncOutlined } from "@ant-design/icons"
import { Col, Row, Select, Switch, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { SUBSCRIPTION_PERIOD } from "../../../../payments/constants"
import { SUBSCRIPTION_INTERVAL } from "hero24-types"

import {
  setSubscriptionEnabled,
  setSubscriptionType,
} from "../../../../payments/actions"

import {
  getSubscriptionStatus,
  getSubscriptionType,
} from "../../../../payments/selectors"

const { Title } = Typography
const { Option } = Select

const Subscription = () => {
  const dispatch = useDispatch()

  const isSubscriptionEnabled = useSelector(getSubscriptionStatus)
  const subscriptionType = useSelector(getSubscriptionType)

  const onChangeSubscriptionType = (value: SUBSCRIPTION_INTERVAL) =>
    dispatch(setSubscriptionType(value))

  const onChangeSubscription = (value: boolean) =>
    dispatch(setSubscriptionEnabled(value))

  return (
    <section className="section">
      <Row gutter={[24, 12]} className="title-block" justify="space-between">
        <Col>
          <SyncOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Subscription
          </Title>
        </Col>
        <Col>
          <Switch
            checked={isSubscriptionEnabled}
            onChange={onChangeSubscription}
          />
        </Col>
      </Row>
      {isSubscriptionEnabled && (
        <>
          <Row gutter={[24, 12]}>
            <Col xs={24} lg={24}>
              <Row gutter={[24, 12]}>
                <Col xs={24}>
                  <Title className="question-header-title" level={4}>
                    Recurring frequency
                  </Title>
                </Col>
              </Row>
              <Row gutter={[24, 12]}>
                <Col xs={24}>
                  <Select
                    size="large"
                    value={subscriptionType}
                    onChange={onChangeSubscriptionType}
                    style={{ width: "100%" }}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                  >
                    {Object.entries(SUBSCRIPTION_PERIOD).map((value) => (
                      <Option value={value[1]} key={value[1]}>
                        {value[0]}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </section>
  )
}

export default Subscription
