import React, { FC, PropsWithChildren } from "react"
import { Badge, Button, Tooltip } from "antd"
import { useSelector } from "react-redux"
import { selectNewChatCount } from "../../selectors"

type Props = PropsWithChildren<{
  refetch: () => void
}>

const ChatAddedButton: FC<Props> = ({ refetch, children }) => {
  const newChatCount = useSelector(selectNewChatCount)
  const hasNewChats = newChatCount !== 0

  return (
    <Badge count={newChatCount}>
      <Tooltip
        title="If new chats is created, you should refresh the page to find them"
        trigger={hasNewChats ? ["hover"] : []}
      >
        <Button type="primary" disabled={!hasNewChats} onClick={refetch}>
          {children}
        </Button>
      </Tooltip>
    </Badge>
  )
}
export default ChatAddedButton
