import firebase from "firebase/app"
import { Dispatch } from "redux"
import { EARNINGS_STATUS, OfferDB, OFFER_STATUS, WorkTime } from "hero24-types"

import {
  GET_OFFERS,
  SET_OFFER_STATUS,
  SET_OFFER_AGREED_TIME,
  SET_OFFER_EARNINGS,
  SET_OFFER_STARTED_TIME,
  SET_OFFER_COMPLETED_TIME,
  SET_WORKHOURS,
  OFFER_UPDATED,
} from "./actionTypes"
import { Offer } from "./types"
import { FIREBASE_PATH_OFFERS } from "./constants"

export interface GetOffers {
  type: typeof GET_OFFERS
  payload: {
    [key: string]: Offer
  }
}

export const updateOfferData = async (
  id: string,
  newOfferData: OfferDB,
  dispatch: Dispatch,
) => {
  dispatch({
    type: OFFER_UPDATED,
    payload: {
      id,
      ...newOfferData,
    },
  })
}

export const subscribeToOffersUpdate = () => async (dispatch: Dispatch) => {
  const ref: firebase.database.Reference = firebase
    .database()
    .ref(`${FIREBASE_PATH_OFFERS}`)

  ref.on("child_changed", async (snapshot: firebase.database.DataSnapshot) => {
    const id = snapshot.key as string
    const newChatData: OfferDB = snapshot.val()

    await updateOfferData(id, newChatData, dispatch)
  })
}

export const unsubscribeFromOffersUpdate = () => async () => {
  const ref: firebase.database.Reference = firebase
    .database()
    .ref(FIREBASE_PATH_OFFERS)

  ref.off("child_changed")
}

export const getAllOffers = () => async (dispatch: Dispatch) => {
  const offersRef = await firebase.database().ref(FIREBASE_PATH_OFFERS)

  const offersSnapshot = await offersRef.once("value")

  const offers: {
    [id: string]: OfferDB
  } = offersSnapshot.val()

  const offersState = Object.fromEntries(
    Object.entries(offers).map((offerEntry) => {
      return [
        offerEntry[0],
        {
          id: offerEntry[0],
          ...offerEntry[1],
        },
      ]
    }),
  )

  const action: GetOffers = {
    type: GET_OFFERS,
    payload: offersState,
  }

  dispatch(action)
}

export interface SetOfferStatus {
  type: typeof SET_OFFER_STATUS
  payload: {
    id: string
    status: Exclude<OFFER_STATUS, "expired">
  }
}

export const setOfferStatus =
  (payload: SetOfferStatus["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(payload.id)

    const offer = await offerRef.once("value")
    if (offer.exists()) {
      await offerRef.update({ status: payload.status })
      dispatch({
        type: SET_OFFER_STATUS,
        payload: payload,
      })
    } else {
      throw new Error("Offer not found! (should never happen)")
    }
  }

export interface SetOfferAgreedTime {
  type: typeof SET_OFFER_AGREED_TIME
  payload: {
    id: string
    agreedTime: OfferDB["data"]["initial"]["agreedStartTime"]
  }
}

export const setOfferAgreedTime =
  (payload: SetOfferAgreedTime["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(payload.id)

    const offer = await offerRef.once("value")
    if (offer.exists()) {
      await offerRef
        .child("data/initial/agreedStartTime")
        .set(payload.agreedTime)
      dispatch({
        type: SET_OFFER_AGREED_TIME,
        payload: payload,
      })
    } else {
      throw new Error("Offer not found! (should never happen)")
    }
  }

export interface SetOfferStartedTime {
  type: typeof SET_OFFER_STARTED_TIME
  payload: {
    id: string
    actualStartTime: OfferDB["data"]["actualStartTime"]
  }
}

export const setOfferStartedTime =
  (payload: SetOfferStartedTime["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(payload.id)

    const offer = await offerRef.once("value")
    if (offer.exists()) {
      await offerRef.child("data/actualStartTime").set(payload.actualStartTime)
      dispatch({
        type: SET_OFFER_STARTED_TIME,
        payload: payload,
      })
    } else {
      throw new Error("Offer not found! (should never happen)")
    }
  }

export interface SetOfferCompletedTime {
  type: typeof SET_OFFER_COMPLETED_TIME
  payload: {
    id: string
    actualCompletedTime: OfferDB["data"]["actualStartTime"]
  }
}

export const setOfferCompletedTime =
  (payload: SetOfferCompletedTime["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(payload.id)

    const offer = await offerRef.once("value")
    if (offer.exists()) {
      await offerRef
        .child("data/actualCompletedTime")
        .set(payload.actualCompletedTime)
      dispatch({
        type: SET_OFFER_COMPLETED_TIME,
        payload: payload,
      })
    } else {
      throw new Error("Offer not found! (should never happen)")
    }
  }

export interface SetOfferEarnings {
  type: typeof SET_OFFER_EARNINGS
  payload: {
    id: string
    status?: EARNINGS_STATUS
    message?: string
  }
}
export const setOfferEarnings =
  (payload: SetOfferEarnings["payload"]) => async (dispatch: Dispatch) => {
    const offerRef = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(payload.id)

    const offer = await offerRef.once("value")
    if (offer.exists()) {
      const updatedValues: Partial<OfferDB["earnings"]> = {
        updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
        ...(payload.status !== undefined ? { status: payload.status } : {}),
        ...(payload.message ? { message: payload.message } : {}),
      }
      await offerRef.child("earnings").update(updatedValues)
      dispatch({
        type: SET_OFFER_EARNINGS,
        payload: payload,
      })
    } else {
      throw new Error("Offer not found! (should never happen)")
    }
  }

export interface SetWorkhoursProps
  extends Required<
    Pick<
      OfferDB["data"],
      "actualStartTime" | "actualCompletedTime" | "workTime" | "pauseDurationMS"
    >
  > {
  offerId: string
}
export interface SetWorkhours extends SetWorkhoursProps {
  type: typeof SET_WORKHOURS
}
export const setWorkhours =
  (props: SetWorkhoursProps) => async (dispatch: Dispatch) => {
    const offerRef: firebase.database.Reference = await firebase
      .database()
      .ref(FIREBASE_PATH_OFFERS)
      .child(props.offerId)

    // mark start and end time
    const updatedTimes: Pick<
      OfferDB["data"],
      "actualCompletedTime" | "actualStartTime" | "isPaused"
    > = {
      actualStartTime: props.actualStartTime,
      actualCompletedTime: props.actualCompletedTime,
      isPaused: false,
    }
    await offerRef.child("data").update(updatedTimes)

    // mark workhours completed
    const updatedWorkTimes = props.workTime.map((workTime) => {
      if (!workTime.endTime) {
        const updatedWorkTime: WorkTime = {
          ...workTime,
          endTime: props.actualCompletedTime,
        }
        return updatedWorkTime
      } else {
        return workTime
      }
    })
    await offerRef.child("data").child("workTime").set(updatedWorkTimes)

    dispatch({
      type: SET_WORKHOURS,
      ...props,
    } as SetWorkhours)
  }

export interface OfferUpdated {
  type: typeof OFFER_UPDATED
  payload: Offer
}

export const approveOfferExtension =
  (offerId: string) => async (dispatch: Dispatch) => {
    const { data }: { data: OfferDB } = await firebase
      .functions()
      .httpsCallable("callableAdminExtension-approve")({ offerId })
    dispatch({
      type: OFFER_UPDATED,
      payload: {
        ...data,
        id: offerId,
      },
    })
  }

export const removeOfferExtension =
  (offerId: string) => async (dispatch: Dispatch) => {
    const { data }: { data: OfferDB } = await firebase
      .functions()
      .httpsCallable("callableAdminExtension-remove")({ offerId })
    dispatch({
      type: OFFER_UPDATED,
      payload: {
        ...data,
        id: offerId,
      },
    })
  }

export const sendReceiptToEmail = async <T>(
  to: string,
  template: string,
  data: T,
) => {
  try {
    const response = await firebase
      .functions()
      .httpsCallable("httpsMailIndex-sendEmail")({
      template,
      to,
      ...data,
    })

    const result = response.data

    if (!result) {
      throw new Error("Invalid response from sendEmail")
    }

    return result
  } catch (e) {
    console.error(e)

    return null
  }
}
