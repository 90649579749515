import React from "react"
import { CategoriesList } from "./components/containers"
import { AppstoreOutlined } from "@ant-design/icons"
import AuthRoute from "../../common/AuthRoute"

export default {
  sideBarItems: [
    {
      icon: <AppstoreOutlined />,
      name: "Categories and Packages",
      path: "categories_list",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/categories_list"
      path="/categories_list"
      render={() => <CategoriesList />}
    />,
  ],
}
