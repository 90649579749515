import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

import { selectApolloClient } from "../../../apolloClient/selectors"
import { incrementNewChatCount, incrementUnseenChatsCount } from "../../reducer"
import {
  CHAT_ADDED_SUBSCRIPTION,
  ChatAddedData,
} from "../../graphql/subscriptions/ChatAdded"

export const useSubscribeOnChatAdd = () => {
  const dispatch = useDispatch()
  const apolloClient = useSelector(selectApolloClient)

  useEffect(() => {
    const subscription = apolloClient
      .subscribe<ChatAddedData>({
        query: CHAT_ADDED_SUBSCRIPTION,
      })
      .subscribe({
        next: ({ data }) => {
          if (data) {
            const { chatAdded } = data
            dispatch(incrementNewChatCount())

            if (chatAdded.inviteAdmin && !chatAdded.seenByAdmin) {
              dispatch(incrementUnseenChatsCount())
            }
          }
        },
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [apolloClient, dispatch])
}
