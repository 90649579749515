import React from "react"
import { Button } from "antd"
import { EditOutlined } from "@ant-design/icons"

interface Props {
  onClick: () => void
}

const OpenEditPromotionFormBtn = ({ onClick }: Props) => (
  <Button
    shape="circle"
    type="primary"
    onClick={onClick}
    icon={<EditOutlined />}
  />
)

export default OpenEditPromotionFormBtn
