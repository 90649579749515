import { gql } from "@apollo/client"

export type ChatSeenByAdminUpdatedData = {
  seenByAdminUpdated: {
    previous: boolean | null
    current: boolean
    chatId: string
  }
}

export const CHAT_SEEN_BY_ADMIN_UPDATED_SUBSCRIPTION = gql`
  subscription SeenByAdminUpdated {
    seenByAdminUpdated {
      chatId
      previous
      current
    }
  }
`
