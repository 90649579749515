import omit from "lodash/omit"
import { ThunkDispatch } from "redux-thunk"
import { ApolloClient } from "@apollo/client"
import { UserDB, BuyerProfileDB } from "hero24-types"

import * as TYPES from "./actionTypes"
import * as USERS_TYPES from "../users/list/actionTypes"
import { Store } from "../core"
import { selectApolloClient } from "../apolloClient/selectors"
import { handleSearchUsers } from "./utils"
import { Times, UserState, UsersDB } from "./types"
import { UsersState } from "../users/list/types"
import {
  GetBuyerProfileData,
  GetBuyerProfileVariables,
  GET_BUYER_PROFILE_QUERY,
} from "../buyer/graphql/queries/GetBuyerProfile"
import {
  CreateBuyerProfileData,
  CreateBuyerProfileVariables,
  CREATE_BUYER_PROFILE_MUTATION,
} from "../buyer/graphql/mutation/CreateBuyerProfile"
import {
  EditBuyerProfileData,
  EditBuyerProfileVariables,
  EDIT_BUYER_PROFILE_MUTATION,
} from "../buyer/graphql/mutation/EditBuyerProfile"
import { createUserThunk } from "./graphql/mutations"
import { getUsersThunk } from "./graphql/queries"
import { UserAdapter, UserDataAdapter } from "./graphql/fragments"
import { Action, Dispatch } from "redux"
import { editUserAdminStatusThunk } from "./graphql/mutations/editUserAdminStatus"
import { createAdminThunk } from "./graphql/mutations/createAdmin"
import { User as UserInfo } from "../user/graphql/fragments"

const generateDisplayName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName.slice(0, 1)}.`
}

export interface HandleLoading {
  type: typeof TYPES.USER_LOADING
  payload: boolean
}

export const handleLoading = (payload: boolean): HandleLoading => ({
  type: TYPES.USER_LOADING,
  payload,
})

export interface SelectUser {
  type: typeof TYPES.SELECT_USER
  payload: string
}

export const selectUser: (id: string) => SelectUser = (id) => ({
  type: TYPES.SELECT_USER,
  payload: id,
})

export interface GetUsers {
  type: typeof TYPES.GET_USERS
  payload: UsersDB
}

export const getUsers =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const state = getState()

    const { usersData } = await getUsersThunk(state, {})

    if (!usersData) {
      throw new Error("Users isn't found")
    }

    const users: UsersDB = Object.fromEntries(
      usersData.edges.map(({ node, cursor }) => [
        cursor,
        UserAdapter.toFirebase(node),
      ]),
    )

    dispatch({ type: TYPES.GET_USERS, payload: users })
  }

export const searchUsers =
  (value: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const users: UsersDB = await handleSearchUsers(value, dispatch, getState())

    dispatch({
      type: TYPES.GET_USERS,
      payload: users,
    })
  }

interface CreateBuyerProfile {
  firstName: string
  lastName: string
  id: string
}

const createBuyerProfile = async (
  payload: CreateBuyerProfile,
  apolloClient: ApolloClient<unknown>,
) => {
  const buyerProfile: BuyerProfileDB["data"] = {
    photoURL: "",
    displayName: generateDisplayName(payload.firstName, payload.lastName),
  }

  const {
    data: { buyer: buyerProfileDto },
  } = await apolloClient.query<GetBuyerProfileData, GetBuyerProfileVariables>({
    query: GET_BUYER_PROFILE_QUERY,
    variables: { id: payload.id },
  })

  // if Buyer does not exist
  if (!buyerProfileDto) {
    await apolloClient.mutate<
      CreateBuyerProfileData,
      CreateBuyerProfileVariables
    >({
      mutation: CREATE_BUYER_PROFILE_MUTATION,
      variables: {
        id: payload.id,
        data: buyerProfile,
      },
    })
  } else {
    await apolloClient.mutate<EditBuyerProfileData, EditBuyerProfileVariables>({
      mutation: EDIT_BUYER_PROFILE_MUTATION,
      variables: {
        id: payload.id,
        data: buyerProfile,
      },
    })
  }
}

export const createUser =
  (userData: Omit<UserDB["data"], "createdAt">, callback?: () => void) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const state = getState()

    const users: UsersDB = await handleSearchUsers(
      userData.email,
      dispatch,
      state,
    )

    if (Object.keys(users).length > 0) {
      dispatch({
        type: TYPES.GET_USERS,
        payload: users,
      })
      return
    }

    const apolloClient = selectApolloClient(state)

    dispatch(handleLoading(true))

    const { createUserData } = await createUserThunk(state, {
      data: omit(
        UserDataAdapter.toGraphQL({ ...userData, createdAt: Date.now() }),
        ["createdAt", "updatedAt", "deletedAt", "lastAskedReviewTime"],
      ),
      isCreatedFromWeb: true,
    })

    if (!createUserData) {
      throw new Error(`New user wasn't been created`)
    }

    const {
      id,
      data: { firstName, lastName },
    } = createUserData

    createBuyerProfile(
      {
        id,
        firstName: firstName || "",
        lastName: lastName || "",
      },
      apolloClient,
    )

    const user = UserAdapter.toFirebase(createUserData)

    dispatch(selectUser(id))

    dispatch({ type: TYPES.GET_USERS, payload: { [user.id]: user } })
    dispatch({ type: USERS_TYPES.ADD_USER, payload: user })

    dispatch(handleLoading(false))

    if (callback) {
      callback()
    }
  }

export const createAdmin =
  (
    userData: Omit<UserDB["data"], Times>,
    callback?: (createAdminData: UserInfo) => void,
  ) =>
  async (
    _dispatch: ThunkDispatch<UserState | UsersState, void, Action<void>>,
    getState: () => Store,
  ) => {
    const state = getState()
    const { responseData } = await createAdminThunk(state, {
      data: omit(
        UserDataAdapter.toGraphQL({ ...userData, createdAt: Date.now() }),
        ["createdAt", "updatedAt", "deletedAt", "lastAskedReviewTime"],
      ),
    })
    if (!responseData) {
      throw new Error(`New user wasn't been created`)
    }

    responseData.isAdmin = true

    if (callback) {
      callback(responseData)
    }
  }

export interface EditUserAdminStatus {
  type: typeof TYPES.EDIT_USER_ADMIN_STATUS
  payload: { isAdmin: boolean; id: string }
}

export const editUserAdminStatus =
  (props: EditUserAdminStatus["payload"]) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    await editUserAdminStatusThunk(getState(), {
      input: {
        isAdmin: props.isAdmin,
        id: props.id,
      },
    })

    await dispatch({ type: TYPES.EDIT_USER_ADMIN_STATUS, payload: props })
  }
