import { createSelector } from "reselect"
import { OfferRequestsState } from "./types"

export const getOfferRequests = (state: {
  offerRequests: OfferRequestsState
}) => state.offerRequests.offerRequests

export const getOfferRequestsArray = createSelector(
  [getOfferRequests],
  (offerRequests) => {
    return Object.values(offerRequests)
  },
)

export const getOfferRequestById = (id: string) =>
  createSelector([getOfferRequests], (offerRequests) => offerRequests[id])

export const getOfferRequestByIds = (ids: string[]) =>
  createSelector([getOfferRequests], (offerRequests) =>
    ids.map((id) => offerRequests[id]),
  )
