import firebase from "firebase/app"
import moment from "moment"

import { FIREBASE_PATH_PROMOTIONS } from "../constants"
import { isPlannedPromotion } from "../utils"

const removePromotion = async (id: string, startDate: number) => {
  const ref = firebase.database().ref(FIREBASE_PATH_PROMOTIONS).child(id)

  if (isPlannedPromotion(startDate)) {
    ref.remove()
  } else {
    await ref.child("data").update({
      endDate: moment().valueOf(),
    })
  }
}

export default removePromotion
