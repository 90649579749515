import { Button, DatePicker, Form, Row, Upload } from "antd"
import { UploadFile } from "antd/lib/upload/interface"
import { FormikProps, withFormik } from "formik"
import { NewsDB } from "hero24-types"
import { omit } from "lodash"
import moment from "moment"
import * as Yup from "yup"

import { RenderField, RenderTextArea } from "../../common/components"
import { FieldAdapter as Field } from "../../common/form"

type Props = {
  onSubmit: (values: NewsDB & { image: File | Blob }, cb: () => void) => void
  loading: boolean
}

type NewsInputs = NewsDB & { image?: File; uploadFile?: UploadFile }

const CreateNewsForm = (props: FormikProps<NewsInputs>) => {
  const { values, handleSubmit, setFieldValue, errors } = props

  return (
    <Form name="user" onSubmitCapture={handleSubmit}>
      <Field
        name="title"
        component={RenderField}
        type="text"
        label="Title"
        value={values.title}
      />
      <Field
        name="description"
        component={RenderTextArea}
        type="text"
        label="Description"
        value={values.description}
      />
      <Field
        name="label"
        component={RenderField}
        type="text"
        label="Label"
        value={values.label}
      />
      <Field
        name="link"
        component={RenderField}
        type="text"
        label="External link"
        value={values.link || ""}
      />
      <Form.Item
        validateStatus={(errors.startAt || errors.endAt) && "error"}
        help={errors.startAt || errors.endAt}
        label="Start Date / End Date"
      >
        <DatePicker.RangePicker
          value={
            [
              typeof values.startAt === "number"
                ? moment(values.startAt)
                : null,
              typeof values.endAt === "number" ? moment(values.startAt) : null,
            ] as any // TODO: handle the odd types
          }
          onChange={(range) => {
            setFieldValue("startAt", range?.[0] ? +range[0] : null)
            setFieldValue("endAt", range?.[1] ? +range[1] : null)
          }}
          allowClear={false}
          allowEmpty={[false, false]}
        />
      </Form.Item>
      <Form.Item
        validateStatus={errors.image ? "error" : ""}
        help={errors.image}
      >
        <Upload
          customRequest={async ({ file, onSuccess }) =>
            setTimeout(() => {
              onSuccess && onSuccess("ok")
              setFieldValue("uploadFile", file)
              setFieldValue("image", file)
            }, 0)
          }
          multiple={false}
          fileList={values.uploadFile ? [values.uploadFile] : []}
          accept=".png, .jpg, image/png, image/jpg"
        >
          <Button type="dashed">Upload image</Button>
        </Upload>
      </Form.Item>

      <Row justify="end">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Row>
    </Form>
  )
}

const ValidationSchema = Yup.object().shape<NewsInputs>({
  description: Yup.string().min(2, "Too Short!").required("Required"),
  title: Yup.string().min(2, "Too Short!").required("Required"),
  label: Yup.string().min(2, "Too Short!").required("Required"),
  endAt: Yup.number().required("Required"),
  startAt: Yup.number().required("Required"),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: Yup.string().url("Must be real link").nullable() as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: Yup.mixed().required().label("File") as any,
})

const createNewsFormWithFormik = withFormik<Props, NewsInputs>({
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(
      omit(values, "uploadFile") as NewsDB & { image: File | Blob },
      resetForm,
    )
  },
  mapPropsToValues: () => ({
    title: "",
    description: "",
    link: "",
    endAt: Date.now(),
    startAt: Date.now(),
    label: "",
  }),
  validationSchema: ValidationSchema,
  displayName: "CreateNewsForm",
  enableReinitialize: true,
})

export default createNewsFormWithFormik(CreateNewsForm)
