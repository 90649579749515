import React from "react"
import { Table } from "antd"

import { ColumnsType } from "antd/lib/table"
import { DateAndTime } from "../../../../../common/components/listComponent/DateAndTime"
import Text from "antd/lib/typography/Text"
import { EditOutlined } from "@ant-design/icons"

import { Seller, Review } from "../../../../types"

type Column = ColumnsType<Review>

const expandedRow = (openModal: (review: Review) => void) => {
  const getColumns = (): Column => {
    const nameColumn: Column[0] = {
      title: "Customer Name",
      key: "data.initial.name",
      render: (review: Review) => <Text>{review.data.initial.name}</Text>,
      sorter: (reviewA, reviewB) => {
        const lhsName = reviewA.data.initial.name
        const rhsName = reviewB.data.initial.name

        if (lhsName === rhsName) {
          return 0
        } else if (lhsName === undefined) {
          return 1
        } else if (rhsName === undefined) {
          return -1
        }

        return lhsName.localeCompare(rhsName)
      },
    }

    const createdColumn: Column[0] = {
      title: "Date",
      key: "data.initial.createdAt",
      render: (review: Review) => (
        <DateAndTime time={review.data.initial.createdAt} />
      ),
      defaultSortOrder: "ascend",
      sorter: (reviewA: Review, reviewB: Review) =>
        reviewA.data.initial.createdAt > reviewB.data.initial.createdAt
          ? -1
          : 1,
    }

    const ratingColumn: Column[0] = {
      title: "Review rating",
      key: "data.initial.rating",
      align: "center",
      render: (review: Review) => <Text>{review.data.initial.rating}</Text>,
      sorter: (reviewA: Review, reviewB: Review) =>
        reviewA.data.initial.rating > reviewB.data.initial.rating ? -1 : 1,
    }

    const msgColumn: Column[0] = {
      title: "Message",
      key: "data.initial.text",
      render: (review: Review) => <Text>{review.data.initial.text}</Text>,
      sorter: (reviewA: Review, reviewB: Review) =>
        reviewA.data.initial.text.localeCompare(reviewB.data.initial.text),
    }

    const editColumn: Column[0] = {
      title: "Action",
      key: "data.initial.text",
      render: (review: Review) => {
        return <EditOutlined onClick={() => openModal(review)} />
      },
    }

    return [nameColumn, createdColumn, ratingColumn, msgColumn, editColumn]
  }

  const renderExpandedRows = (seller: Seller) => {
    if (seller.reviews) {
      return (
        <Table<Review>
          tableLayout="auto"
          rowKey={(record) => record.data.initial.createdAt}
          dataSource={seller.reviews || []}
          columns={getColumns() || []}
          pagination={false}
          scroll={{ x: 800 }}
        />
      )
    }
    return null
  }

  return renderExpandedRows
}

export default expandedRow
