import { gql } from "@apollo/client"
import { UserDB } from "hero24-types"
import { FirebaseAdapter } from "../../../common/utilities"

export type UserOffer = {
  offerId: string
  offerRequestId: string
}

export const USER_OFFER_FRAGMENT = gql`
  fragment UserOfferInfo on UserOfferDto {
    offerId
    offerRequestId
  }
`

export const UserOfferAdapter = new FirebaseAdapter<
  Required<UserDB>["offers"],
  UserOffer[]
>({
  toFirebase: (graphql) =>
    Object.fromEntries(
      graphql.map(({ offerId, offerRequestId }) => [
        offerId,
        { offerRequestId },
      ]),
    ),
  toGraphQL: (firebase) =>
    Object.keys(firebase).map((offerId) => ({
      offerId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      offerRequestId: firebase[offerId]!.offerRequestId,
    })),
})
