import * as TYPES from "./actionTypes"
import { OfferRequestDB, SUBSCRIPTION_INTERVAL } from "hero24-types"
import { SendingChannel } from "./constants"

export interface SetPlatformProvider {
  type: typeof TYPES.SET_PLATFORM_PROVIDER
  payload?: OfferRequestDB["data"]["initial"]["platformProvider"]
}

export const setPlatformProvider: (
  platformProvider?: OfferRequestDB["data"]["initial"]["platformProvider"],
) => SetPlatformProvider = (platformProvider) => {
  return {
    type: TYPES.SET_PLATFORM_PROVIDER,
    payload: platformProvider,
  }
}
export interface SetPrePayWith {
  type: typeof TYPES.SET_PRE_PAY_WITH
  payload?: OfferRequestDB["data"]["initial"]["prePayWith"]
}

export const setPrePay: (
  paymentType?: OfferRequestDB["data"]["initial"]["prePayWith"],
) => SetPrePayWith = (paymentType) => {
  return {
    type: TYPES.SET_PRE_PAY_WITH,
    payload: paymentType,
  }
}

export interface SetPostpaid {
  type: typeof TYPES.SET_POST_PAID
  payload?: OfferRequestDB["data"]["initial"]["postpaid"]
}

export const setPostpaid: (
  paymentType?: OfferRequestDB["data"]["initial"]["postpaid"],
) => SetPostpaid = (paymentType) => {
  return {
    type: TYPES.SET_POST_PAID,
    payload: paymentType,
  }
}

export interface SetInvoice {
  type: typeof TYPES.SET_INVOICE
  payload: SendingChannel
}

export const setInvoice: (invoiceType: SendingChannel) => SetInvoice = (
  invoiceType,
) => {
  return {
    type: TYPES.SET_INVOICE,
    payload: invoiceType,
  }
}

export interface SetFixedPrice {
  type: typeof TYPES.SET_FIXED_PRICE
  payload: number
}

export const setFixedPrice: (fixedPriceType: number) => SetFixedPrice = (
  fixedPriceType,
) => {
  return {
    type: TYPES.SET_FIXED_PRICE,
    payload: fixedPriceType,
  }
}

export interface SetFixedDuration {
  type: typeof TYPES.SET_TOTAL_DURATION
  payload: number
}

export const setFixedDuration: (
  fixedDurationType: number,
) => SetFixedDuration = (fixedDurationType) => {
  return {
    type: TYPES.SET_TOTAL_DURATION,
    payload: fixedDurationType,
  }
}

export interface SetMinimumDuration {
  type: typeof TYPES.SET_MINIMUM_DURATION
  payload: number
}

export const setMinimumDuration: (
  fixedDurationType: number,
) => SetMinimumDuration = (minimumDuration) => {
  return {
    type: TYPES.SET_MINIMUM_DURATION,
    payload: minimumDuration,
  }
}

export interface SetCustomerVAT {
  type: typeof TYPES.SET_CUSTOMER_VAT
  payload: number
}

export const setCustomerVAT: (
  customerVatPercentage: number,
) => SetCustomerVAT = (customerVatPercentage) => {
  return {
    type: TYPES.SET_CUSTOMER_VAT,
    payload: customerVatPercentage,
  }
}

export interface SetServiceProviderVAT {
  type: typeof TYPES.SET_SERVICE_PROVIDER_VAT
  payload: number
}

export const setServiceProviderVAT: (
  customerVatPercentage: number,
) => SetServiceProviderVAT = (customerVatPercentage) => {
  return {
    type: TYPES.SET_SERVICE_PROVIDER_VAT,
    payload: customerVatPercentage,
  }
}

export interface SetHero24Cut {
  type: typeof TYPES.SET_HERO24_CUT
  payload: number
}
export const setHero24Cut: (hero24CUTPercentage: number) => SetHero24Cut = (
  hero24CutPercentage,
) => {
  return {
    type: TYPES.SET_HERO24_CUT,
    payload: hero24CutPercentage,
  }
}

export interface ResetSubscription {
  type: typeof TYPES.RESET_SUBSCRIPTION
}

export const resetSubscriptionState: () => ResetSubscription = () => {
  return {
    type: TYPES.RESET_SUBSCRIPTION,
  }
}

export interface SetSubscriptionEnabled {
  type: typeof TYPES.SET_SUBSCRIPTION_ENABLED
  payload: boolean
}

export const setSubscriptionEnabled: (
  enabled: boolean,
) => SetSubscriptionEnabled = (enabled) => {
  return {
    type: TYPES.SET_SUBSCRIPTION_ENABLED,
    payload: enabled,
  }
}

export interface SetSubscriptionInterval {
  type: typeof TYPES.SET_SUBSCRIPTION_INTERVAL
  payload: SUBSCRIPTION_INTERVAL
}

export const setSubscriptionType: (
  subscriptionType: SUBSCRIPTION_INTERVAL,
) => SetSubscriptionInterval = (subscriptionType) => {
  return {
    type: TYPES.SET_SUBSCRIPTION_INTERVAL,
    payload: subscriptionType,
  }
}
