import React from "react"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"

import { updateErrorVisibility as updateErrorVisibilityAction } from "../../actions"
import { getQuestionById, getErrorsForCategory } from "../../selectors"

import Question from "./Question"

import { Store } from "../../../../core/types"
import { Question as IQuestion } from "../../types"
import { getSelectedCategory } from "../../../categories/selectors"
import { Category } from "../../../categories/types"

interface OwnProps {
  questionId: string
  isSubQuestion?: boolean
  category?: Category
}

export default (props: OwnProps) => {
  const dispatch = useDispatch()
  const question = useSelector((state: Store) =>
    getQuestionById(state, props.questionId),
  )
  const selectedCategory = props.category || useSelector(getSelectedCategory)

  const errorsForCategory = useSelector(
    getErrorsForCategory(selectedCategory?.id),
  )
  const isSubQuestion = props.isSubQuestion || false

  const updateErrorVisibility = (question: IQuestion) => {
    const { id, showError } = question

    if (!showError && _.includes(errorsForCategory, id)) {
      dispatch(
        updateErrorVisibilityAction({
          questionId: id,
          showError: true,
        }),
      )
    }
    if (showError && !_.includes(errorsForCategory, id)) {
      dispatch(
        updateErrorVisibilityAction({
          questionId: id,
          showError: false,
        }),
      )
    }
  }

  return (
    <Question
      question={question}
      errorsForCategory={errorsForCategory}
      isSubQuestion={isSubQuestion}
      updateErrorVisibility={updateErrorVisibility}
    />
  )
}
