import firebase from "firebase/app"
import { Dispatch } from "redux"

import { Image } from "./types"
import { Store } from "../core/types"

import * as TYPES from "./actionTypes"

import { FIREBASE_PATH_IMAGES } from "./constants"
import { ImageDB } from "hero24-types"

export interface GetImage {
  type: typeof TYPES.GET_IMAGE
  payload: Image
}

export const getImage =
  (imageId: string) => async (dispatch: Dispatch, getState: () => Store) => {
    const store = getState()

    if (store.images[imageId]) return

    const ref: firebase.database.Reference = firebase
      .database()
      .ref(FIREBASE_PATH_IMAGES)
      .child(imageId)

    ref.on("value", async (snapshot) => {
      const image: ImageDB = snapshot.val()

      if (!image.data.storagePath) return

      const storageRef: firebase.storage.Reference = firebase
        .storage()
        .ref(image.data.storagePath)

      const globalPath: string = await storageRef.getDownloadURL()

      dispatch({
        type: TYPES.GET_IMAGE,
        payload: {
          data: image.data,
          id: imageId,
          globalPath,
        },
      } as GetImage)

      ref.off()
    })
  }
