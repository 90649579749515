import { Dispatch } from "redux"
import firebase from "firebase/app"
import { CategoriesDB, CategoryDB } from "hero24-types"
import * as TYPES from "./actionTypes"
import { FIREBASE_PATH, AddressTypes } from "./constants"
import { Address } from "hero24-types"
import { Category } from "./types"
import { normalizeCategories } from "./utils"

export interface GetCategories {
  type: typeof TYPES.GET_CATEGORIES
  payload: CategoriesDB
}

export const getCategories = () => async (dispatch: Dispatch) => {
  // tslint:disable-next-line:no-unsafe-any
  const ref: firebase.database.Reference = await firebase
    .database()
    .ref(FIREBASE_PATH)

  const categoriesSnapshot: firebase.database.DataSnapshot = await ref.once(
    "value",
  ) // tslint:disable-line:no-unsafe-any

  const categories: CategoriesDB = categoriesSnapshot.val()

  dispatch({
    type: TYPES.GET_CATEGORIES,
    payload: categories,
  })
}

export interface UpdateCategory {
  type: typeof TYPES.UPDATE_CATEGORY
  payload: Category
}

export const updateCategory =
  (id: string, category: Partial<CategoryDB>) => async (dispatch: Dispatch) => {
    await firebase.database().ref(FIREBASE_PATH).child(id).update(category)

    const ref: firebase.database.Reference = await firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(id)

    const categorySnapshot = await ref.once("value")

    const categoryDB = categorySnapshot.val()

    const payload = normalizeCategories({ [id]: categoryDB })[id]

    dispatch({
      type: TYPES.UPDATE_CATEGORY,
      payload,
    })
  }

export interface SelectCategory {
  type: typeof TYPES.SELECT_CATEGORY
  payload: string
}

export const selectCategory: (id: string) => SelectCategory = (id) => ({
  type: TYPES.SELECT_CATEGORY,
  payload: id,
})

export interface AddressUpdatedProps {
  categoryId: string
  addressType: AddressTypes
  address: Address
}
export interface AddressUpdated {
  type: typeof TYPES.CATEGORIES_ADDRESS_UPDATED
  payload: AddressUpdatedProps
}
export const addressUpdated: (
  payload: AddressUpdatedProps,
) => AddressUpdated = (payload) => {
  return {
    type: TYPES.CATEGORIES_ADDRESS_UPDATED,
    payload,
  }
}
