import {
  DefaultContext,
  MutationOptions,
  OperationVariables,
} from "@apollo/client"

import { Store } from "../../core"
import { selectApolloClient } from "../selectors"

export type ApolloMutationOptions<
  TData,
  TVariables extends OperationVariables = OperationVariables,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext extends Record<string, any> = DefaultContext,
> = MutationOptions<TData, TVariables, TContext> & {
  state: Store
}

export const apolloMutation = <
  Data,
  Variables extends OperationVariables,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext extends Record<string, any> = DefaultContext,
>({
  state,
  ...options
}: ApolloMutationOptions<Data | null, Variables, TContext>) => {
  const apolloClient = selectApolloClient(state)

  return apolloClient.mutate<Data | null, Variables, TContext>(options)
}
