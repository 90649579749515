import _ from "lodash"
import {
  CreateQuestionForm,
  ListPicker,
  NumberInputQuestion,
  NumberQuestion,
  Question,
  QuestionOption,
  QuestionOptions,
  Questions,
  TextAreaQuestion,
} from "./types"
import {
  CategoriesDB,
  OfferRequestQuestion,
  QuestionOptionsDB,
  QuestionsDB,
} from "hero24-types"
import { additionalQuestions, defaultQuestions } from "./constants"

/*
Takes 'categories' object and returns a new 'questions' object where categoryId and questionId are used as a key.
From:
{
    categoryKey: {
        questions: {
            questionKey1: {
                ...,
                questions: {
                    questionKey12: { ... }
                }
            },
            questionKey2: {
                options: {
                    optionKey21: {
                        questionKey211: { ... }
                    }
                }
            }
        }
    }
}
To:
{
    'categoryKey/questionKey1': { ... },
    'categoryKey/questionKey1/questionKey12': { ... },
    'categoryKey/questionKey2': { ... }
    'categoryKey/questionKey2/optionKey21/questionKey211': { ... }
}
*/

export const normalizeQuestions: (
  questions: QuestionsDB,
  keyPrefix: string,
) => Questions = (questions, keyPrefix) => {
  // "questionsKeyVal" will hold { questionId: questionObject } of all the questions (flat, not a tree, see example above)
  const questionsKeyVal: Questions = {}
  _.forEach(questions, (question, questionKey) => {
    const newKey = `${keyPrefix}/${questionKey}`
    let normalizedQuestion: Question

    if (question.type === "radio" || question.type === "checkbox") {
      const options: QuestionOptions = {}
      // normalize options sub-questions
      _.forEach(question.options, (option, optionKey) => {
        const newOptionKey = `${newKey}/${optionKey}`
        const flattenOption: QuestionOption = {
          id: newOptionKey,
          name: option.name,
          order: option.order,
          checked: option.checked,
        }
        const optionQuestions = question.options[optionKey].questions
        if (optionQuestions) {
          // add subQuestions to 'questionsKeyVal'
          const optionSubQuestionsKeyVal = normalizeQuestions(
            optionQuestions,
            newOptionKey,
          )
          Object.assign(questionsKeyVal, optionSubQuestionsKeyVal)

          // flatten "question.options.option.questions" to an array of ids
          flattenOption.questions = Object.keys(optionQuestions).map(
            (optionQuestionKey) => `${newOptionKey}/${optionQuestionKey}`,
          )
        }
        options[optionKey] = flattenOption
      })
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
        options,
      }

      questionsKeyVal[newKey] = normalizedQuestion
    } else if (
      question.type === "textarea" ||
      question.type === "number_input" ||
      question.type === "number" ||
      question.type === "list"
    ) {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
        placeholder: question.placeholder,
      } as TextAreaQuestion | NumberInputQuestion | NumberQuestion | ListPicker
      questionsKeyVal[newKey] = normalizedQuestion
    } else if (question.type === "image") {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
      }
      questionsKeyVal[newKey] = normalizedQuestion
    } else if (question.type === "date") {
      normalizedQuestion = {
        ...question,
        id: newKey,
        name: question.name,
      }
      questionsKeyVal[newKey] = normalizedQuestion
    }
  })

  return questionsKeyVal
}

/**
 * Convert the OfferRequestQuestion to the QuestionsDB
 */
export const converterToQuestionsDB = (questions: OfferRequestQuestion[]) => {
  const categoryQuestions: QuestionsDB = {}

  questions.forEach(({ id, ...question }: any) => {
    if (question.type === "radio" || question.type === "checkbox") {
      const nestedQuestion: QuestionOptionsDB = {}

      question.options.forEach(({ id: subId, ...subQuestion }: any) => {
        nestedQuestion[subId.replace(/[\w\s]+\//gi, "")] = {
          ...subQuestion,
          ...(subQuestion.questions
            ? { questions: converterToQuestionsDB(subQuestion.questions) }
            : {}),
        }
      })

      categoryQuestions[id.replace(/[\w\s]+\//gi, "")] = {
        ...question,
        options: nestedQuestion,
      }
    } else {
      categoryQuestions[id.replace(/[\w\s]+\//gi, "")] = question
    }
  })
  return categoryQuestions
}

export const normalizeQuestionsFromCategories: (
  categories: CategoriesDB,
) => Questions = (categories: CategoriesDB) => {
  const questionsKeyVal: Questions = {}
  _.forEach(categories, (category, categoryKey) => {
    Object.assign(
      questionsKeyVal,
      normalizeQuestions(category.questions, categoryKey),
    )
  })
  return questionsKeyVal
}

export const normalizeCreatedQuestion = (values: CreateQuestionForm) => {
  const name = values.name.en || values.name.fi ? values.name : null

  const placeholder =
    values.placeholder.en || values.placeholder.fi ? values.placeholder : null

  // eslint-disable-next-line camelcase
  const extra_placeholder =
    values.extra_placeholder.en || values.extra_placeholder.fi
      ? values.extra_placeholder
      : null

  const order = _.toNumber(values.order)
  const position = values.position ? _.toNumber(values.position) : null
  const defaultValue = values.defaultValue || null
  const options = _.keys(values.options).length
    ? _.values(values.options).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: _.omit(curr, "id"),
        }),
        {},
      )
    : null

  return _.pick(
    {
      ...values,
      name,
      order,
      position,
      placeholder,
      // eslint-disable-next-line camelcase
      extra_placeholder,
      defaultValue,
      options,
    },
    [
      ...defaultQuestions.filter((key) => key !== "id"),
      ...additionalQuestions[values.type],
    ],
  )
}

export const converterToCreatedQuestion = (
  question: Question,
): Partial<CreateQuestionForm> => {
  const convertedQuestion = {
    ...question,
    id: question.id.replace(/[\w\s]+\//, ""),
    order: String(question.order),
    position: question.position ? String(question.position) : "",
    defaultValue:
      (question.type === "textarea" ||
        question.type === "number" ||
        question.type === "number_input") &&
      question.defaultValue
        ? String(question.defaultValue)
        : "",
    options:
      question.type === "radio" || question.type === "checkbox"
        ? _.values(question.options).reduce((acc, curr) => {
            const id = _.last(curr.id.split("/")) as string
            return {
              ...acc,
              [id]: { ..._.pick(curr, ["name", "order"]), id },
            }
          }, {})
        : {},
  }

  return _.keys(convertedQuestion).reduce((acc, curr) => {
    const value = convertedQuestion[curr as keyof typeof convertedQuestion]
    return value !== undefined ? { ...acc, [curr]: value } : acc
  }, {}) // Remove undefined fields that were assigned in normalizeQuestionsFromCategories method
}
