import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { User, UserData } from "../../fragments"
import { MUTATION } from "./mutation"

type Data = {
  editUserData: User
}

type Variables = {
  data: Omit<Partial<UserData>, "createdAt" | "updatedAt" | "deletedAt">
  userId: string
}

export const editUserDataThunk: ApolloThunkMutation<Data, [Variables]> = async (
  state,
  variables,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables,
  })

  return {
    editUserDataData: data?.editUserData,
    editUserDataErrors: errors,
  }
}
