import { SellerProfileDB } from "hero24-types"
import { GraphQLSellerProfileData } from "./GraphQLSellerProfileData"
import { omitUndefined } from "../../../common/utilities/omitUndefined"

export abstract class GraphQLSellerProfile {
  abstract id: string
  abstract data: GraphQLSellerProfileData
  abstract rating?: number
  abstract reviews?: string[]

  static convertFromFirebaseType(
    sellerProfile: SellerProfileDB,
    id: string,
  ): GraphQLSellerProfile {
    return {
      id,
      rating: sellerProfile.rating,
      reviews: sellerProfile.reviews && Object.keys(sellerProfile.reviews),
      data: GraphQLSellerProfileData.convertFromFirebaseType(
        sellerProfile.data,
      ),
    }
  }

  static convertToFirebaseType(
    sellerProfile: GraphQLSellerProfile,
  ): SellerProfileDB {
    return omitUndefined({
      rating: sellerProfile.rating,
      reviews: sellerProfile.reviews
        ? Object.fromEntries(
            sellerProfile.reviews.map((id) => [id, true] as const),
          )
        : undefined,
      data: GraphQLSellerProfileData.converToFirebaseType(sellerProfile.data),
    })
  }
}
