import { FeeDB } from "hero24-types"

export const convertToDecimalNumber = (value: number) => {
  return Number(Number(value).toFixed(2))
}

export const getFeeUnitPriceWithhero24Cut = (
  fee: Pick<FeeDB, "platformFeePercentage" | "data">,
) => {
  const platformFeePercentageAsDecimal = fee.platformFeePercentage / 100
  return convertToDecimalNumber(
    fee.data.unitPrice / (1 - platformFeePercentageAsDecimal),
  )
}

export const getFeeTotalWithHero24Cut = (
  fee: Pick<FeeDB, "platformFeePercentage" | "data">,
) => {
  const materialCost = fee.data.quantity * getFeeUnitPriceWithhero24Cut(fee)
  return convertToDecimalNumber(materialCost)
}

export const sumUpUnpaidFees = (fees: FeeDB[]) => {
  let sum = 0
  fees.forEach((fee) => {
    if (fee?.status === "pending") {
      sum += convertToDecimalNumber(getFeeTotalWithHero24Cut(fee))
      sum -= fee.amountPaid || 0
    }
  })
  return sum
}
