import { UPDATE_PROMOTIONS, SET_PROMOTIONS } from "./actions/types"
import { Promotions, PromotionsReducer } from "../types"

export const INITIAL_STATE: Promotions = []

const promotions: PromotionsReducer = (
  state = INITIAL_STATE,
  { type, payload },
) => {
  switch (type) {
    case UPDATE_PROMOTIONS: {
      return [...payload]
    }

    case SET_PROMOTIONS: {
      return [...payload]
    }

    default:
      return state
  }
}

export default { promotions }
