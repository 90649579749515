import React from "react"
import { green, red } from "@ant-design/colors"
import { SUBSCRIPTION_BILLING } from "hero24-types"
import { Subscription } from "../../../types"

interface Props {
  subscription: Subscription
}

const getStatusColor = (status: SUBSCRIPTION_BILLING) => {
  switch (status) {
    case "paid":
      return green.primary
    case "unpaid":
      return red.primary
    default:
      return "black"
  }
}

export const BillingStatus = ({ subscription }: Props) => {
  if (!subscription) return null
  return (
    <span
      style={{
        fontSize: 14,
        color: getStatusColor(subscription.billingStatus || ""),
      }}
    >
      {(subscription.billingStatus || "").toUpperCase()}
    </span>
  )
}
