import React, { useRef, useState } from "react"
import { Button, Col, Modal, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import CreateFee from "./createFee"
import { Fees } from "./fees"
import Title from "antd/lib/typography/Title"
import { useDispatch } from "react-redux"
import { addFeeToBeSaved } from "../../../fees/actions"
import { FeeInputs } from "../../../fees/types"

export const FeeStep = () => {
  const resetFormRef = useRef<{ resetForm: () => void }>(null)

  const [showForm, setShowForm] = useState(false)
  const isLoading = false
  const dispatch = useDispatch()

  const onSubmit = (data: FeeInputs) => {
    dispatch(addFeeToBeSaved(data))
    setShowForm(false)
  }

  return (
    <>
      <Modal
        title="Add new material fee"
        open={showForm}
        onCancel={() => setShowForm(false)}
        footer={false}
      >
        <CreateFee
          onSubmit={onSubmit}
          resetFormRef={resetFormRef}
          loading={isLoading}
        />
      </Modal>
      <section className="section">
        <Row gutter={[24, 12]} className="title-block">
          <Col>
            {/* <EuroOutlined className="sub-title-icon" /> */}
            <Title className="sub-title" level={4}>
              Material Fees
            </Title>
          </Col>
        </Row>
        <Row
          gutter={[24, 12]}
          className="title-block"
          align="middle"
          justify="space-between"
        >
          <Col xs={12} lg={12}>
            <Button
              block
              // className="button-with-header"
              icon={<PlusOutlined />}
              type="dashed"
              onClick={() => setShowForm(true)}
            >
              Add material fee
            </Button>
          </Col>
        </Row>
        <Fees />
      </section>
    </>
  )
}
