import { createAction } from "redux-act"

import { News } from "./graphql/fragments"
import { NewsPagination } from "./types"
import { GraphQLPagination } from "../common/graphql/types"

export const addNewsResult =
  createAction<GraphQLPagination<News>>("Set news list")

export const setNewsPagination = createAction<Pick<NewsPagination, "total">>(
  "Set news pagination",
)

export const setNewsPaginationOffset = createAction<number>(
  "Set news pagination offset",
)

export const setNewsListLoading = createAction<boolean>("Set news list loading")

export const setNewsCreating = createAction<boolean>("Set news creating")

export const setNewsEditing = createAction<{
  id: string
  updating: boolean
}>("Set news updating")

export const setNewsRemoving = createAction<{
  id: string
  removing: boolean
}>("Set news removing")

export const newsCreated = createAction<News>("News created")

export const newsEdited = createAction<News>("News deleted")

export const newsRemoved = createAction<string>("News updated")
