import React from "react"
import { Form, Select } from "antd"

import { FormikTouched, FormikErrors } from "formik"

const FormItem = Form.Item

interface SelectProps {
  name: string
  value?: string
  defaultValue?: string
  disabled?: boolean
}

export interface RenderSelectProps {
  input?: SelectProps
  label?: string
  placeholder?: string
  meta: {
    touched: boolean | FormikTouched<any> | undefined
    error: string | FormikErrors<any> | undefined
  }
  children?: React.ReactElement[]
}

const RenderSelect = ({
  input,
  label,
  children,
  meta: { touched, error },
}: RenderSelectProps) => {
  let validateStatus:
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating"
    | undefined = ""
  if (touched) {
    validateStatus = error ? "error" : "success"
  }

  return (
    <FormItem
      label={label}
      validateStatus={validateStatus}
      help={touched && error}
    >
      <Select {...input} size="large">
        {children || null}
      </Select>
    </FormItem>
  )
}

export default RenderSelect
