import { MenuItemsKeys } from "../components/containers/components/constants"
import * as TYPES from "../actionTypes"
import { User } from "../../../user/types"
import { User as UserInfo } from "../../../user/graphql/fragments"
import { Dispatch } from "redux"

interface DispatchUpdatedUserArgs {
  selectedUser: User | UserInfo
  key: MenuItemsKeys
  dispatch: Dispatch
}

export const dispatchUpdatedUser = (args: DispatchUpdatedUserArgs) => {
  const { selectedUser, key, dispatch } = args

  switch (key) {
    case MenuItemsKeys.EXISTING: {
      dispatch({
        type: TYPES.EDIT_ADMIN_PERMISSIONS_IN_ALL_USERS,
        payload: { id: selectedUser.id, isAdmin: !selectedUser.isAdmin },
      })

      break
    }

    case MenuItemsKeys.NEW_USER: {
      dispatch({
        type: TYPES.ADD_USER,
        payload: selectedUser,
      })

      break
    }

    default: {
      throw new Error("There is no such dispatch action!")
    }
  }
}
