import { Table } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getCategories } from "../../../../buyer/categories/actions"
import { CategoriesState } from "../../../../buyer/categories/types"
import { EditQuestionModal } from "../../../../common/components/OfferRequest/Questions.tsx"
import { Store } from "../../../../core/types"
import { getAllFees } from "../../../../fees/actions"
import { FeesState } from "../../../../fees/types"
import { getAllSellers } from "../../../../seller/actions"
import { getAllUsers } from "../../../../users/list/actions"
import { getAllOfferRequests } from "../../actions"
import { getOfferRequestsArray } from "../../selectors"
import { OfferRequest } from "../../types"
import { getColumns } from "../table"
import { ExpandedRow, ExpandedRowProps } from "./ExpandedRow"

const OfferRequestList = () => {
  const dispatch = useDispatch()
  const [isEditQuestions, setIsEditQuestions] = useState<boolean>(false)
  const [offerRequestId, setOfferRequestId] = useState<string>("")

  const offerRequests = useSelector(getOfferRequestsArray)
  const users = useSelector((store: Store) => {
    return store.users
  })
  const categories = useSelector(
    ({ categories }: { categories: CategoriesState }) => {
      return categories
    },
  )
  const fees = useSelector(({ fees }: { fees: FeesState }) => {
    return fees.allFees
  })

  const openQuestionsModal: ExpandedRowProps["openQuestionsModal"] = (
    offerRequestId,
  ) => {
    setOfferRequestId(offerRequestId)
    setIsEditQuestions(true)
  }

  const closeQuestionsModal = () => {
    setIsEditQuestions(false)
  }

  useEffect(() => {
    dispatch(getAllOfferRequests())
    dispatch(getAllUsers())
    dispatch(getAllSellers())
    dispatch(getCategories())
    dispatch(getAllFees())
  }, [dispatch])

  const columns = getColumns(categories, users, fees)

  return (
    <section style={{ textAlign: "center" }}>
      <h2>OfferRequests</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: OfferRequest) => record.id}
        dataSource={offerRequests}
        columns={columns || []}
        pagination={{
          pageSize: 25,
        }}
        expandable={{
          expandedRowRender: (offerRequests: OfferRequest) => (
            <ExpandedRow
              offerRequest={offerRequests}
              openQuestionsModal={openQuestionsModal}
            />
          ),
        }}
        loading={!offerRequests || !offerRequests.length}
        scroll={{ x: 800 }}
      />
      <EditQuestionModal
        isOpen={isEditQuestions}
        closeModal={closeQuestionsModal}
        offerRequestId={offerRequestId}
      />
    </section>
  )
}

export default OfferRequestList
