import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { editReview } from "../../../actions"

import EditReviewForm from "./EditReviewForm"
import { Modal } from "antd"

import { EditReviewForm as IEditReviewForm, Review } from "../../../types"

interface EditReviewProps {
  isOpen: boolean
  closeModal: () => void
  review?: Review
}

const EditReviewModal = ({ isOpen, closeModal, review }: EditReviewProps) => {
  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (review) {
      const { text, rating } = review.data.initial
      setInitialValues({ text, rating: String(rating) })
    }
  }, [review])

  if (!review) {
    return null
  }

  const onEditReview = ({
    id,
    text,
    rating,
    removeFromSeller,
  }: {
    id: string
    text?: string
    rating?: string
    removeFromSeller?: boolean
  }) => {
    dispatch(editReview({ id, text, rating, removeFromSeller }))
  }

  const onSubmit: (values: IEditReviewForm, resetForm: () => void) => void = (
    values,
    resetForm,
  ) => {
    const { text, rating, removeFromSeller } = values
    onEditReview({ id: review.id, text, rating, removeFromSeller })
    resetForm()
    closeModal()
  }

  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <Modal
      title="Edit review"
      open={isOpen}
      onCancel={handleCloseModal}
      footer={false}
    >
      <EditReviewForm onSubmit={onSubmit} initialValues={initialValues} />
    </Modal>
  )
}

export default EditReviewModal
