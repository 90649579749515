import { Col, Row, Typography } from "antd"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { ModalForm } from "../../../common/components"
import { PromotionList } from "../../containers"
import AddPromotionForm, {
  AddPromotionFormProps,
} from "../../containers/AddPromotionForm"
import OpenAddPromotionFormBtn from "../../containers/AddPromotionForm/OpenAddPromotionFormBtn"
import { unsubscribeFromPromotions } from "../../queries"
import { subscribeToPromotions } from "../../store/actions"

const { Title } = Typography

const Main = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(subscribeToPromotions)

    return () => unsubscribeFromPromotions()
  }, [dispatch])

  return (
    <Row gutter={[15, 15]}>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Title level={4}>Promotions</Title>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify="end">
          <Col>
            <ModalForm<AddPromotionFormProps>
              FormComponent={AddPromotionForm}
              ButtonComponent={OpenAddPromotionFormBtn}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <PromotionList />
      </Col>
    </Row>
  )
}

export default Main
