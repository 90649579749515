import React, { FC, useState, useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"
import { Moment } from "moment-timezone"

import {
  getAllOffers,
  unsubscribeFromOffersUpdate,
  subscribeToOffersUpdate,
} from "../../actions"
import { getColumns } from "../table"
import { Offer, OffersState } from "../../types"
import { SellerState } from "../../../../seller/types"
import { getAllSellers } from "../../../../seller/actions"
import { UsersState } from "../../../../users/list/types"
import { getAllUsers } from "../../../../users/list/actions"
import { getAllOfferRequests } from "../../../../offerRequests/list/actions"
import { OfferRequestsState } from "../../../../offerRequests/list/types"
import { getCategories } from "../../../../buyer/categories/actions"
import { CategoriesState } from "../../../../buyer/categories/types"
import { OfferView } from "../../../../common/components/Offer/Offer"
import { getPurchaseInvoiceOffersSelector } from "../../selectors"
import { TableRowSelection } from "antd/lib/table/interface"
import TotalRevenue from "../table/TotalRevenue"

type Props = {
  purchaseInvoiceMode?: boolean
  filterData?: {
    week: Moment | null
    sellerId?: string
  }
  onChangeRowSelectionHandler?: TableRowSelection<Offer>["onChange"]
  selectedRowKeys?: string[]
}

const OfferList: FC<Props> = (props) => {
  const {
    purchaseInvoiceMode,
    filterData,
    onChangeRowSelectionHandler,
    selectedRowKeys,
  } = props

  const dispatch = useDispatch()

  const purchaseInvoiceOffersSelector = useMemo(
    () =>
      getPurchaseInvoiceOffersSelector(filterData?.sellerId, filterData?.week),
    [filterData],
  )
  const offers = useSelector(
    filterData
      ? purchaseInvoiceOffersSelector
      : ({ offers }: { offers: OffersState }) => {
          return offers.offers
        },
  )

  const sellers = useSelector(({ seller }: { seller: SellerState }) => {
    return seller
  })

  const customers = useSelector(({ users }: { users: UsersState }) => {
    return users
  })

  const offerRequests = useSelector(
    ({ offerRequests }: { offerRequests: OfferRequestsState }) => {
      return offerRequests
    },
  )

  const categories = useSelector(
    ({ categories }: { categories: CategoriesState }) => {
      return categories
    },
  )

  const [offersCurrentData, setOffersCurrentData] = useState<Offer[] | null>(
    null,
  )

  useEffect(() => {
    dispatch(subscribeToOffersUpdate())
    dispatch(getAllOffers())
    dispatch(getAllSellers())
    dispatch(getAllUsers())
    dispatch(getAllOfferRequests())
    dispatch(getCategories())

    return () => {
      unsubscribeFromOffersUpdate()
    }
  }, [dispatch])

  const columns = useMemo(
    () =>
      getColumns(
        categories,
        sellers,
        customers,
        offerRequests,
        purchaseInvoiceMode,
      ),
    [categories, customers, offerRequests, purchaseInvoiceMode, sellers],
  )

  const expandedRowRender = useCallback((offer: Offer) => {
    return <OfferView offer={offer} />
  }, [])

  const offerArray = useMemo(() => offers && Object.values(offers), [offers])

  return (
    <section style={{ textAlign: "center" }}>
      <h2>Offers</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: Offer) => record.id}
        dataSource={offerArray}
        columns={columns || []}
        pagination={{
          pageSize: 25,
          showTotal: (total, range) => (
            <span style={{ marginRight: 10 }}>
              Showing {range[0]}-{range[1]} of {total}
            </span>
          ),
        }}
        footer={(data) => <TotalRevenue offers={offersCurrentData || data} />}
        expandedRowRender={!purchaseInvoiceMode ? expandedRowRender : undefined}
        loading={!purchaseInvoiceMode && !offerArray?.length}
        {...(purchaseInvoiceMode
          ? {
              rowSelection: {
                onChange: onChangeRowSelectionHandler,
                selectedRowKeys,
              },
            }
          : {})}
        onChange={(_pagination, filters, _sorter, extra) => {
          if (filters["offer.data?.actualStartTime"]) {
            setOffersCurrentData(extra.currentDataSource)
            return
          }

          setOffersCurrentData(null)
        }}
        scroll={{ x: 1800 }}
      />
    </section>
  )
}

export default OfferList
