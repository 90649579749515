import { createReducer, createAction } from "redux-act"

import { GetChatsData } from "./graphql/queries/GetChats"
import { ChatState, ChatsFilter, ChatsOrderBy } from "./types"
import { ChatsOrderColumn } from "./constants"
import { SortOrder } from "../common/constants"
import { ChatDto } from "./graphql/fragments/ChatInfo"

const INITIAL_STATE: ChatState = {
  newChatCount: 0,
  unseenChatsCount: 0,
  fetching: false,
  chatsFilter: {
    isAdminInvited: true,
  },
  chatsOrderBy: [
    { column: ChatsOrderColumn.IS_ADMIN_INVITED, order: SortOrder.DESC },
  ],
}

export const setChats = createAction<GetChatsData["chats"]>("Set chats data")
export const setFetching = createAction<boolean>("Set chats loading")
export const updateChat = createAction<ChatDto>("Update chat in the chats")

export const setChatsOrderBy = createAction<ChatsOrderBy>("Add chats sorter")
export const removeChatsOrderBy = createAction<Pick<ChatsOrderBy, "column">>(
  "Remove chats sorter",
)
export const updateChatsFilters =
  createAction<Partial<ChatsFilter>>("Set chats filter")

export const incrementNewChatCount = createAction("Increment new chat count")
export const resetNewChatCount = createAction("Reset new chat count to 0")

export const setUnseenChatsCount = createAction<number>(
  "Set unseen chats count",
)
export const incrementUnseenChatsCount = createAction(
  "Increment unseen chats count",
)
export const decrementUnseenChatsCount = createAction(
  "Decrement unseen chats count",
)

const reducer = createReducer<ChatState>(
  {
    [setChats.getType()]: (state, chats: GetChatsData["chats"]) => ({
      ...state,
      chats,
    }),
    [setFetching.getType()]: (state, fetching: boolean) => ({
      ...state,
      fetching,
    }),
    [setChatsOrderBy.getType()]: (state, orderBy: ChatsOrderBy) => {
      return {
        ...state,
        chatsOrderBy: [orderBy],
      }
    },
    [removeChatsOrderBy.getType()]: (state, orderBy: ChatsOrderBy) => ({
      ...state,
      chatsOrderBy: state.chatsOrderBy.filter(
        ({ column }) => orderBy.column !== column,
      ),
    }),
    [updateChatsFilters.getType()]: (state, chatsFilters: ChatsFilter) => ({
      ...state,
      chatsFilter: {
        ...state.chatsFilter,
        ...chatsFilters,
      },
    }),
    [updateChat.getType()]: (state, chat: ChatDto) => ({
      ...state,
      chats: state.chats && {
        ...state.chats,
        edges: state.chats.edges.map(({ cursor, node }) => ({
          cursor,
          node: cursor === chat.id ? chat : node,
        })),
      },
    }),
    [incrementNewChatCount.getType()]: (state) => ({
      ...state,
      newChatCount: state.newChatCount + 1,
    }),
    [resetNewChatCount.getType()]: (state) => ({
      ...state,
      newChatCount: 0,
    }),
    [setUnseenChatsCount.getType()]: (state, unseenChatsCount: number) => ({
      ...state,
      unseenChatsCount,
    }),
    [incrementUnseenChatsCount.getType()]: (state) => ({
      ...state,
      unseenChatsCount: state.unseenChatsCount + 1,
    }),
    [decrementUnseenChatsCount.getType()]: (state) => ({
      ...state,
      unseenChatsCount: state.unseenChatsCount - 1,
    }),
  },
  INITIAL_STATE,
)

export default { chat: reducer }
