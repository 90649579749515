import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

interface Props {
  onClick: () => void
}

const OpenAddPromotionFormBtn = ({ onClick }: Props) => (
  <Button
    type="primary"
    onClick={onClick}
    icon={<PlusOutlined />}
    style={{ width: 150 }}
  >
    Add new
  </Button>
)

export default OpenAddPromotionFormBtn
