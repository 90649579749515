import React from "react"
import { Moment } from "moment-timezone"
import Text from "antd/lib/typography/Text"
import { formatDateAndTime } from "../../utils"

interface Props {
  time?: number | Moment
  short?: boolean
}

export const DateAndTime = (props: Props) => {
  return (
    <Text>
      {props.time
        ? formatDateAndTime(
            props.time,
            props.short ? "dateAndTimeFormatNoYear" : "dateAndTimeFormat",
          )
        : "-"}
    </Text>
  )
}
