import { FC } from "react"
import { OfferRequestDB } from "hero24-types"
import { Typography } from "antd"

import { getPrepaidStatusColor } from "../utils"

const { Text } = Typography

type Props = Pick<
  OfferRequestDB["data"]["initial"],
  "prePayWith" | "prepaid" | "sendInvoiceWith" | "postpaid"
>

export const PrepaidStatusView: FC<Props> = (props) => {
  const { prepaid, prePayWith, sendInvoiceWith, postpaid } = props

  return (
    <Text
      style={{ color: getPrepaidStatusColor(prepaid) }}
      title={`(${prePayWith}/${sendInvoiceWith})/${postpaid})`}
    >
      {prepaid || "-"}
    </Text>
  )
}
