import React from "react"
import _ from "lodash"
import {
  OfferRequestQuestionOption,
  OfferRequestQuestion,
  OfferRequestNumberQuestion,
  OfferRequestNumberInputQuestion,
} from "hero24-types"
import { DetailsRow } from "./DetailsRow"
import { Descriptions, Col } from "antd"

interface Props {
  questions: OfferRequestQuestion[]
}

export const Questions = (props: Props) => {
  const getTitle = (question: OfferRequestQuestion) => {
    return question.name ? question.name.en : ""
  }

  const getQuestionPlaceholder = (
    question: OfferRequestNumberQuestion | OfferRequestNumberInputQuestion,
  ) => {
    if (question.type === "number_input" && question.extra_placeholder) {
      return question.extra_placeholder.en
    }
    return question.placeholder ? question.placeholder.en : ""
  }

  const renderStandardDetailsRow = (values: string[]) => {
    return (
      <>
        {_.map(values, (value, idx) => (
          <span key={idx}>{value}</span>
        ))}
      </>
    )
  }

  const renderSubQuestionsRow = (question: OfferRequestQuestion) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const Values = renderQuestionValues(question)
    if (!Values) {
      return null
    }

    return (
      <Descriptions style={{ marginLeft: 20 }}>
        <Descriptions.Item label={question.name ? question.name.en : ""}>
          {Values}
        </Descriptions.Item>
      </Descriptions>
    )
  }

  const renderOptionsRow = (options: OfferRequestQuestionOption[]) => {
    if (!options.length) {
      return (
        <Col xs={24}>
          <span>-</span>
        </Col>
      )
    }

    return (
      <Col xs={24}>
        {_.map(options, (option, idx) => (
          <div key={idx}>
            <span>{option.name && option.name.en}</span>
            {option.questions
              ? Object.values(option.questions).map((question) =>
                  renderSubQuestionsRow(question),
                )
              : null}
          </div>
        ))}
      </Col>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderImageRow = (imageIds: string[]) => {
    return (
      <div>
        Images not supported yet{/* <ImagesRow imageIds={imageIds} /> */}
      </div>
    )
  }

  const renderQuestionValues = (question: OfferRequestQuestion) => {
    switch (question.type) {
      case "checkbox": {
        const selectedOptions = _.filter(
          question.options,
          (option) => !!option.checked,
        )
        const orderedOptions = _.orderBy(selectedOptions, ["order", "asc"])
        return renderOptionsRow(orderedOptions)
      }
      case "radio": {
        const option =
          question.selectedOption &&
          question.options.find((o) => o.id === question.selectedOption)
        return option ? renderOptionsRow([option]) : undefined
      }
      case "number_input":
        return question.value
          ? renderStandardDetailsRow([
              `${question.value} ${getQuestionPlaceholder(question)}`,
            ])
          : null
      case "number":
        return question.value
          ? renderStandardDetailsRow([
              `${question.value} ${getQuestionPlaceholder(question)}`,
            ])
          : null
      case "textarea":
        return question.value
          ? renderStandardDetailsRow([question.value])
          : undefined
      case "image":
        return question.images
          ? renderImageRow(Object.keys(question.images))
          : undefined
      case "list":
        return question.value
          ? renderStandardDetailsRow([`${question.value}h`])
          : null
      default:
        return null
    }
  }

  const renderRow = (question: OfferRequestQuestion) => {
    const values = renderQuestionValues(question) || "-"
    if (!values) {
      return null
    }
    return (
      <DetailsRow key={question.id} title={getTitle(question)} value={values} />
    )
  }
  return (
    <>
      {props.questions
        .filter((question) => question.type !== "date")
        .sort((questionA, questionB) => questionA.order - questionB.order)
        .map((question) => renderRow(question))}
    </>
  )
}
