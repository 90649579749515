import React, { FC } from "react"
import { useSelector } from "react-redux"
import { selectBriefSellerById } from "../../../seller"
import { Typography } from "antd"

type Props = {
  id?: string
}

const SellerName: FC<Props> = ({ id }) => {
  const briefSeller = useSelector(selectBriefSellerById(id))

  const sellerName = briefSeller?.data.companyName
  const sellerEmail = briefSeller?.data.companyEmail

  return (
    <>
      {sellerName && (
        <Typography.Title level={5}>{sellerName}</Typography.Title>
      )}
      {sellerEmail && <Typography.Text>{sellerEmail}</Typography.Text>}
    </>
  )
}

export default SellerName
