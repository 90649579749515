import { Promotions } from "../../types"
import { SET_PROMOTIONS } from "./types"

export interface SetPromotions {
  type: typeof SET_PROMOTIONS
  payload: Promotions
}

const setPromotions = (promotions: Promotions): SetPromotions => {
  return { type: SET_PROMOTIONS, payload: promotions }
}

export default setPromotions
