import { Button, Popconfirm, Space } from "antd"
import { ColumnProps } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { DeleteOutlined } from "@ant-design/icons"

import { PROMOTION_DATE_FORMAT } from "../../../constants"
import {
  PromotionStatus as PromotionItemStatus,
  PromotionWithCategory,
} from "../../../types"
import {
  isActivePromotion,
  isExpiredPromotion,
  isPlannedPromotion,
} from "../../../utils"
import removePromotion from "../../../queries/removePromotion"
import { ModalForm } from "../../../../common/components"
import OpenEditPromotionFormBtn from "../../EditPromotionForm/OpenEditPromotionFormBtn"
import EditPromotionForm from "../../EditPromotionForm"
import PromotionStatus from "../../../components/PromotionStatus"
import { Categories } from "../../../../buyer/categories/types"
import { getDiscountSymbol } from "../../../components/PromotionForm/utils"

type Column = ColumnProps<PromotionWithCategory>

const getStatusFilters = (): Column["filters"] => [
  { text: <PromotionStatus status="active" />, value: "active" },
  { text: <PromotionStatus status="planned" />, value: "planned" },
  { text: <PromotionStatus status="expired" />, value: "expired" },
]

const getCategoryFilters = (categories: Categories): Column["filters"] => {
  return Object.values(categories).map(({ id, name }) => ({
    text: name.en,
    value: id,
  }))
}

const renderActionsColumn: Column["render"] = (_, record) => {
  const { startDate, endDate } = record.data

  if (isPlannedPromotion(startDate) || isActivePromotion(startDate, endDate)) {
    return (
      <Space>
        <ModalForm
          ButtonComponent={OpenEditPromotionFormBtn}
          FormComponent={EditPromotionForm}
          formProps={{ promotion: record }}
        />
        <Popconfirm
          title={`You are about to deactivate this promotion. Are you sure?`}
          onConfirm={() => removePromotion(record.id, startDate)}
        >
          <Button
            shape="circle"
            type="primary"
            danger
            icon={<DeleteOutlined />}
          ></Button>
        </Popconfirm>
      </Space>
    )
  }
}

const renderStatusColumn: Column["render"] = (_, record) => {
  const { startDate, endDate } = record.data

  return <PromotionStatus startDate={startDate} endDate={endDate} />
}

const statusSorter: Column["sorter"] = (a, b) => {
  const { startDate: startDateA, endDate: endDateA } = a.data
  const { startDate: startDateB, endDate: endDateB } = b.data

  if (isActivePromotion(startDateB, endDateB)) {
    if (isActivePromotion(startDateA, endDateA)) {
      return 0
    }

    return -1
  }

  if (isPlannedPromotion(startDateB)) {
    if (isActivePromotion(startDateA, endDateA)) {
      return 1
    }

    if (isPlannedPromotion(startDateA)) {
      return 0
    }
  }

  return 1
}

const onStatusFilter: Column["onFilter"] = (value, record) => {
  const { startDate, endDate } = record.data

  if (value === "active" && isActivePromotion(startDate, endDate)) {
    return true
  }

  if (value === "planned" && isPlannedPromotion(startDate)) {
    return true
  }

  if (value === "expired" && isExpiredPromotion(endDate)) {
    return true
  }

  return false
}

const getColumns = (categories: Categories): Column[] => {
  return [
    {
      title: "Category",
      dataIndex: ["category", "name", "en"],
      sorter: (a, b) => a.category.name.en.localeCompare(b.category.name.en),
      filterMultiple: true,
      filters: getCategoryFilters(categories),
      onFilter: (value, { data: { categoryId } }) => value === categoryId,
    },
    {
      title: "Start date",
      dataIndex: ["data", "startDate"],
      render: (timestamp: number) =>
        timestamp && moment(timestamp).format(PROMOTION_DATE_FORMAT),
      sorter: (a, b) => a.data.startDate - b.data.startDate,
      align: "center",
    },
    {
      title: "End date",
      dataIndex: ["data", "endDate"],
      render: (timestamp: number) =>
        timestamp && moment(timestamp).format(PROMOTION_DATE_FORMAT),
      sorter: (a, b) => a.data.endDate - b.data.endDate,
      align: "center",
    },
    {
      title: "Discount",
      dataIndex: ["data", "discount"],
      render: (value: string, record) => {
        const discountSymbol = getDiscountSymbol(record.data.discountFormat)

        return value && `${value}${discountSymbol}`
      },
      sorter: (a, b) => a.data.discount - b.data.discount,
      align: "center",
    },
    {
      title: "Description",
      dataIndex: ["data", "description"],
    },
    {
      title: "Status",
      render: renderStatusColumn,
      sorter: statusSorter,
      align: "center",
      defaultSortOrder: "descend",
      filterMultiple: true,
      filters: getStatusFilters(),
      onFilter: onStatusFilter,
      defaultFilteredValue: ["active", "planned"] as PromotionItemStatus[],
    },
    {
      title: "Actions",
      render: renderActionsColumn,
    },
  ]
}

export default getColumns
