import { gql } from "@apollo/client"
import { GraphQLPagination } from "../../../common/graphql/types"
import { GraphQLSellerProfile } from "../adapters/GraphQLSellerProfile"

export type GetSellerProfilesData = {
  sellers: GraphQLPagination<GraphQLSellerProfile>
}

export type GetSellerProfilesVariables = {
  limit?: number
  offset?: number
}

export const GET_SELLER_PROFILES_QUERY = gql`
  query GetSellerProfiles($limit: Int, $offset: Int) {
    sellers(limit: $limit, offset: $offset) {
      edges {
        cursor
        node {
          id
          data {
            photoURL
            companyName
            companyEmail
            heroBIOText
            categories
            companyVAT
            langs
            city
            streetAddress
            postalCode
            yearsOfExperience
            workAreas
            certificate
            weeksOfSentPurchaseInvoices
          }
          rating
          reviews
        }
      }
      total
      hasNextPage
      endCursor
    }
  }
`
