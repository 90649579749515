import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setPlatformProvider } from "../../../../payments/actions"
import { PlatformProviderService } from "../../../../payments/constants"
import { selectPlatformProvider } from "../../../../payments/selectors"
import { PlatformProviderPicker } from "../../../../common/components/OfferRequest/PlatformProviderPicker"

const PlatformProvider = () => {
  const dispatch = useDispatch()

  const platformProvider = useSelector(selectPlatformProvider)

  const setPlatformProviderHandler = useCallback(
    (platformProviderService: PlatformProviderService) =>
      dispatch(setPlatformProvider(platformProviderService)),
    [dispatch],
  )

  return (
    <section className="section">
      <PlatformProviderPicker
        platformProvider={platformProvider}
        setPlatformProvider={setPlatformProviderHandler}
      />
    </section>
  )
}

export default PlatformProvider
