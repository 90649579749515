import { gql } from "@apollo/client"
import { BuyerProfileDB } from "hero24-types"

export type CreateBuyerProfileData = {
  createBuyer: BuyerProfileDB | null
}

export type CreateBuyerProfileVariables = {
  id: string
  data: BuyerProfileDB["data"]
}

export const CREATE_BUYER_PROFILE_MUTATION = gql`
  mutation CreateBuyerProfile($id: String!, $data: BuyerProfileDataInput!) {
    createBuyer(id: $id, data: $data) {
      data {
        displayName
        photoURL
        isCreatedFromWeb
      }
      hasMadeApprovedRequest
    }
  }
`
