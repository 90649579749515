import { gql } from "@apollo/client"

export type UnattachCategoryFromSellerData = {
  unattachCategoryFromSeller: boolean
}
export type UnattachCategoryFromSellerVariables = {
  sellerId: string
  categoryId: string
}

export const UNATTACH_CATEGORY_FROM_SELLER_MUTATION = gql`
  mutation UnattachCategoryFromSeller(
    $sellerId: String!
    $categoryId: String!
  ) {
    unattachCategoryFromSeller(sellerId: $sellerId, categoryId: $categoryId)
  }
`
