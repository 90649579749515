export const NAME = "categories"
export const FIREBASE_PATH = "categories"

export type AddressTypes = "main" | "from" | "to"
export const ADDRESS_TYPES: AddressTypes[] = ["main", "from", "to"]
export type AddressFields = "city" | "streetAddress" | "postalCode"
export const ADDRESS_FIELDS: AddressFields[] = [
  "city",
  "streetAddress",
  "postalCode",
]
