import { createReducer } from "redux-act"
import { NewsState } from "./types"
import {
  newsCreated,
  newsRemoved,
  newsEdited,
  setNewsCreating,
  addNewsResult,
  setNewsListLoading,
  setNewsRemoving,
  setNewsEditing,
  setNewsPagination,
  setNewsPaginationOffset,
} from "./actions"

const INITIAL_STATE: NewsState = {
  newsList: [],
  newsListFetching: false,
  newsCreating: false,
  newsRemoving: {},
  newsEditing: {},
  pagination: {
    total: 0,
    offset: 0,
    limit: 25,
  },
}

const reducer = createReducer((on) => {
  on(addNewsResult, (state, result) => ({
    ...state,
    newsList: result.edges.map((edge) => edge.node),
    pagination: {
      ...state.pagination,
      total: result.total,
    },
  }))

  on(setNewsPagination, (state, pagination) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...pagination,
    },
  }))

  on(setNewsPaginationOffset, (state, offset) => ({
    ...state,
    pagination: {
      ...state.pagination,
      offset,
    },
  }))

  on(setNewsListLoading, (state, newsListFetching) => ({
    ...state,
    newsListFetching,
  }))

  on(setNewsCreating, (state, newsCreating) => ({
    ...state,
    newsCreating,
  }))

  on(setNewsEditing, (state, { id, updating }) => ({
    ...state,
    newsEditing: {
      ...state.newsEditing,
      [id]: updating,
    },
  }))

  on(setNewsRemoving, (state, { id, removing }) => ({
    ...state,
    newsRemoving: {
      ...state.newsRemoving,
      [id]: removing,
    },
  }))

  on(newsCreated, (state, createdNews) => {
    if (state.newsList.some(({ id }) => id === createdNews.id)) {
      return state
    }

    return {
      ...state,
      newsList: [createdNews, ...state.newsList],
    }
  })

  on(newsEdited, (state, updatedNews) => ({
    ...state,
    newsList: state.newsList.map((news) =>
      news.id === updatedNews.id ? updatedNews : news,
    ),
  }))

  on(newsRemoved, (state, id) => ({
    ...state,
    newsList: state.newsList.filter((news) => news.id !== id),
  }))
}, INITIAL_STATE)

export default { news: reducer }
