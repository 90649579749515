type Props = {
  pricePerHour: number
  duration: number
}

export const getGrossEarnings = (props: Props) => {
  const { pricePerHour, duration } = props

  return pricePerHour * duration
}
