import { PackageDB } from "hero24-types"
import { CreatePackageForm } from "./types"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"

export const normalizeCreatedPackage = (
  values: CreatePackageForm,
): PackageDB => {
  const includedCities = _.values(values.citiesIncluded)
  const excludedCities = _.values(values.citiesExcluded)

  return {
    ..._.pick(values, ["name", "description"]),
    order: _.toNumber(values.order),
    pricePerHour: _.toNumber(values.pricePerHour),
    recommendedDuration: _.toNumber(values.recommendedDuration),
    // eslint-disable-next-line camelcase
    ...(includedCities.length && { cities_included: includedCities }),
    // eslint-disable-next-line camelcase
    ...(excludedCities.length && { cities_excluded: excludedCities }),
  }
}

export const converterToCreatedPackage = (
  packageId: string,
  categoryPackage: PackageDB,
): CreatePackageForm => ({
  ...categoryPackage,
  id: packageId,
  order: _.toString(categoryPackage.order),
  pricePerHour: _.toString(categoryPackage.pricePerHour),
  recommendedDuration: _.toString(categoryPackage.recommendedDuration),
  citiesIncluded: (categoryPackage.cities_included || []).reduce(
    (acc, curr) => ({ ...acc, [uuidv4()]: curr }),
    {},
  ),
  citiesExcluded: (categoryPackage.cities_excluded || []).reduce(
    (acc, curr) => ({ ...acc, [uuidv4()]: curr }),
    {},
  ),
})
