import { AppThunkAction } from "../../common/types"
import { newsEdited } from "../actions"
import { subscribeOnNewsUpdated } from "../graphql/subscriptions"

let unsubscribe: null | (() => void) = null

export const subscribeOnNewsUpdatesThunk: AppThunkAction =
  () => (dispatch, getState) => {
    unsubscribe && unsubscribe()

    const { newsUpdatedUnsubscribe } = subscribeOnNewsUpdated(getState(), {
      next: ({ data }) => {
        if (data?.newsUpdated) {
          dispatch(newsEdited(data.newsUpdated))
        }
      },
    })

    unsubscribe = newsUpdatedUnsubscribe
  }
