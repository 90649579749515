import { Button, Col, Modal, Row } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { RecommendedDB } from "hero24-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Category } from "../../../buyer/categories/types"
import CreateRecommended from "../../../categories/list/components/createRecommended"
import { CreateRecommendedForm } from "../../../buyer/recommended/types"
import {
  createAndUpdateRecommended,
  removeRecommended,
} from "../../../buyer/recommended/actions"

const { confirm } = Modal

interface Props {
  category: Category
  recommended?: {
    [id: string]: RecommendedDB
  }
}

interface VisibleState {
  edit: boolean
  create: boolean
}

const initialVisibleState: VisibleState = {
  edit: false,
  create: false,
}

const CategoryRecommended = ({ category, recommended }: Props) => {
  const [visible, setVisible] = useState<VisibleState>(initialVisibleState)
  const [selectedRecommended, setSelectedRecommended] = useState<string>("")

  const dispatch = useDispatch()

  const handleOpenEditModal = (recommendedId: string) => {
    setVisible((prevState) => ({ ...prevState, edit: true }))
    setSelectedRecommended(recommendedId)
  }
  const handleCloseEditModal = () => {
    setVisible((prevState) => ({ ...prevState, edit: false }))
    setSelectedRecommended("")
  }

  const handleSubmit = async (values: CreateRecommendedForm) => {
    dispatch(
      createAndUpdateRecommended(category.id, values, selectedRecommended),
    )
    setVisible(initialVisibleState)
    selectedRecommended && setSelectedRecommended("")
  }

  const handleRemovePackage = (recommendedId: string) =>
    dispatch(removeRecommended(category.id, recommendedId))

  return (
    <>
      <Row style={{ paddingBottom: 12 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            setVisible((prevState) => ({ ...prevState, create: true }))
          }
        >
          Add Recommended
        </Button>
      </Row>
      {recommended && (
        <Row>
          {Object.keys(recommended).map((recommendedId) => (
            <Col key={recommendedId} span={8}>
              <div>
                <span>{recommendedId}</span>
                <EditOutlined
                  style={{ paddingLeft: 12 }}
                  onClick={() => handleOpenEditModal(recommendedId)}
                />
                <DeleteOutlined
                  style={{ paddingLeft: 8 }}
                  onClick={() =>
                    confirm({
                      title:
                        "Are you sure you want to delete this recommended?",
                      onOk: () => handleRemovePackage(recommendedId),
                    })
                  }
                />
              </div>
            </Col>
          ))}
        </Row>
      )}
      <CreateRecommended
        open={visible.create}
        onCancel={() =>
          setVisible((prevState) => ({ ...prevState, create: false }))
        }
        onSubmit={handleSubmit}
      />
      {recommended && selectedRecommended && (
        <CreateRecommended
          open={visible.edit}
          onCancel={handleCloseEditModal}
          onSubmit={handleSubmit}
          initialState={{
            ...recommended[selectedRecommended],
            id: selectedRecommended,
          }}
        />
      )}
    </>
  )
}

export default CategoryRecommended
