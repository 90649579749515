import { gql } from "@apollo/client"

export type GetUnseenAdminChatsCountData = {
  unseenAdminChatsCount: number
}

export const GET_UNSEEN_CHATS_COUNT_QUERY = gql`
  query GetUnseenAdminChatsCount {
    unseenAdminChatsCount
  }
`
