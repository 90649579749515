import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategories, selectCategory } from "../../categories/actions"
import { CategoriesState, Category } from "../../categories/types"
import { Typography, Row, Col, Card, Skeleton } from "antd"
import { SwitcherOutlined } from "@ant-design/icons"

const { Title } = Typography
const { Meta } = Card

interface Categories {
  categories: CategoriesState
}

const CategoryStep = () => {
  const [selected, setSelected] = useState("")
  const dispatch = useDispatch()
  const categories = useSelector(
    ({ categories }: Categories) => categories.categories,
  )

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  const handleChange = useCallback(
    (value: string): void => {
      setSelected(value)
      dispatch(selectCategory(value))
    },
    [dispatch],
  )

  const loading = useMemo(() => {
    return !Object.keys(categories).length
  }, [categories])

  const options = useMemo(() => {
    return Object.values(categories).map((category: Category) => {
      return (
        <Col key={category.id} xs={24} lg={12}>
          <Card
            hoverable
            bodyStyle={{
              border:
                selected === category.id
                  ? "2px solid #ea4435"
                  : "2px solid transparent",
            }}
            onClick={() => handleChange(category.id)}
          >
            <Meta title={category.name.en} />
          </Card>
        </Col>
      )
    })
  }, [categories, selected, handleChange])

  if (loading) {
    return (
      <section className="section">
        <Row className="title-block" align="middle" justify="space-between">
          <Col>
            <SwitcherOutlined className="sub-title-icon" />
            <Title className="sub-title" level={4}>
              Select Category
            </Title>
          </Col>
        </Row>
        <Skeleton />
      </section>
    )
  }

  return (
    <section className="section">
      <Row className="title-block" align="middle" justify="space-between">
        <Col>
          <SwitcherOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Select Category
          </Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>{options}</Row>
    </section>
  )
}

export default CategoryStep
