import { Store } from "../core/types"

export const getFeesToSave = (state: Store) => state.fees.feesToSave

export const getAllFees = (state: Store) => state.fees.allFees

export const getFeesByIds = (ids: string[]) => (state: Store) =>
  Object.entries(state.fees.allFees || {})
    .filter(([id]) => ids.includes(id))
    .map(([id, fee]) => ({ id, ...fee }))
