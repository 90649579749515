import map from "lodash/map"
import { Store } from "../../../core"
import {
  AddressesAnsweredInputAdapter,
  OfferRequestQuestionInputAdapter,
} from "../../../offerRequests/graphql/fragments"
import { OfferRequestCreationInput } from "../../../offerRequests/graphql/mutation"
import { offerRequestQuestionsToArray } from "../../../offerRequests/utils"
import { OfferRequestCreateProps } from "../types"
import { PaymentService } from "../../../payments/constants"
import { offerRequestSubscription } from "../../../payments/utils"
import { PaidStatus } from "hero24-types"

export const prepareOfferCreationInput = (
  store: Store,
  payload: OfferRequestCreateProps,
): OfferRequestCreationInput => {
  const questions = map(
    offerRequestQuestionsToArray(payload.questions),
    OfferRequestQuestionInputAdapter.toGraphQL,
  )

  const {
    payments: {
      platformProvider,
      prePayWith,
      hero24Cut,
      customerVAT,
      serviceProviderVAT,
      fixedPrice,
      fixedDuration,
      minimumDuration,
      isSubscriptionEnabled,
      subscriptionType,
      postpaid: postpaidInout,
      sendInvoiceWith: sendInvoiceWithInput,
    },
    offerRequest: { pickServiceProvider },
  } = store

  const sendInvoiceWith =
    prePayWith === PaymentService.STRIPE ? sendInvoiceWithInput : undefined

  const prepaid =
    prePayWith === (PaymentService.STRIPE || PaymentService.NETVISOR) &&
    postpaidInout === "yes"
      ? PaidStatus.PAID
      : PaidStatus.WAITING

  const postpaid =
    prePayWith === (PaymentService.STRIPE || PaymentService.NETVISOR) &&
    postpaidInout === "yes"
      ? "yes"
      : "no"

  return {
    data: {
      initial: {
        promotionDisabled: payload.promotionDisabled,
        addresses: AddressesAnsweredInputAdapter.toGraphQL(payload.addresses),
        buyerProfile: payload.buyerProfile,
        category: payload.category.id,
        questions,
        fixedPrice,
        fixedDuration,
        platformProvider,
        prePayWith,
        sendInvoiceWith,
        prepaid,
        postpaid,
      },
      pickServiceProvider: {
        pickStrategy: pickServiceProvider?.pickStrategy || "first",
        preferred: Object.keys(pickServiceProvider?.preferred || {}),
      },
    },
    subscription: isSubscriptionEnabled
      ? offerRequestSubscription(subscriptionType)
      : undefined,
    hero24Cut,
    customerVat: customerVAT,
    minimumDuration,
    serviceProviderVAT: !payload.category.isServiceProviderVATLocked
      ? serviceProviderVAT
      : undefined,
  }
}
