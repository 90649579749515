import { gql } from "@apollo/client"
import { BuyerProfileDB } from "hero24-types"

export type EditBuyerProfileData = {
  editBuyer: BuyerProfileDB | null
}

export type EditBuyerProfileVariables = {
  id: string
  data: BuyerProfileDB["data"]
}

export const EDIT_BUYER_PROFILE_MUTATION = gql`
  mutation EditBuyerProfile(
    $id: String!
    $data: PartialBuyerProfileDataInput!
  ) {
    editBuyer(id: $id, data: $data) {
      data {
        displayName
        photoURL
        isCreatedFromWeb
      }
      hasMadeApprovedRequest
    }
  }
`
