import React from "react"
import AuthRoute from "../../common/AuthRoute"
import reducer from "./reducer"
import { MessageLogsList } from "./components/containers"
import { ProjectOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <ProjectOutlined />,
      name: "Messagelogs (realtime)",
      path: "messagelogs",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/messagelogs"
      path="/messagelogs"
      render={() => <MessageLogsList />}
    />,
  ],
  reducer,
}
