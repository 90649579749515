import { gql } from "@apollo/client"
import { Address as AddressDB, SupportedLanguages, UserDB } from "hero24-types"

import { Maybe } from "../../../common/types"
import { FirebaseAdapter, convertListToMap } from "../../../common/utilities"
import {
  USER_DATA_ACTIVE_ROUTE_FRAGMENT,
  UserDataActiveRoute,
  UserDataActiveRouteAdapter,
} from "./UserDataActiveRouteInfo"
import {
  USER_DATA_ADDRESS_FRAGMENT,
  UserDataAddress,
  UserDataAddressAdapter,
} from "./UserDataAddressInfo"

export type UserData = {
  email: string
  emailVerified?: Maybe<boolean>
  pushToken?: Maybe<string[]>
  name: string
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  photoURL: string
  language?: Maybe<string>
  isActive?: Maybe<boolean>
  activeRoute?: Maybe<UserDataActiveRoute>
  addresses?: Maybe<UserDataAddress[]>
  phone?: Maybe<string>
  iban?: Maybe<string>
  birthDate?: Maybe<number>
  certificate?: Maybe<string>
  insurance?: Maybe<string>
  ssn?: Maybe<string>
  createdAt: number
  updatedAt?: Maybe<number>
  deletedAt?: Maybe<number>
  hasAccountMergeBeenAsked?: Maybe<boolean>
  selectedAppLanguage?: Maybe<SupportedLanguages>
  lastAskedReviewTime?: Maybe<number>
}

export const USER_DATA_FRAGMENT = gql`
  ${USER_DATA_ACTIVE_ROUTE_FRAGMENT}
  ${USER_DATA_ADDRESS_FRAGMENT}

  fragment UserDataInfo on UserDataDto {
    email
    emailVerified
    pushToken
    name
    firstName
    lastName
    photoURL
    language
    isActive
    phone
    iban
    birthDate
    certificate
    insurance
    ssn
    createdAt
    updatedAt
    deletedAt
    hasAccountMergeBeenAsked
    selectedAppLanguage
    lastAskedReviewTime
    activeRoute {
      ...UserDataActiveRouteInfo
    }
    addresses {
      ...UserDataAddressInfo
    }
  }
`

export const UserDataAdapter = new FirebaseAdapter<UserDB["data"], UserData>({
  toFirebase: (graphql) => ({
    activeRoute: graphql.activeRoute
      ? UserDataActiveRouteAdapter.toFirebase(graphql.activeRoute)
      : undefined,
    addresses: graphql.addresses
      ? (UserDataAddressAdapter.toFirebase(graphql.addresses) as Record<
          string,
          AddressDB
        >)
      : undefined,
    email: graphql.email,
    emailVerified: graphql.emailVerified || false, // Todo: check the database
    pushToken: graphql.pushToken
      ? convertListToMap(graphql.pushToken, true)
      : undefined,
    name: graphql.name,
    firstName: graphql.firstName ?? undefined,
    lastName: graphql.lastName ?? undefined,
    photoURL: graphql.photoURL,
    language: graphql.language || "", // Todo: check the database
    isActive: graphql.isActive ?? undefined,
    phone: graphql.phone || "",
    iban: graphql.iban ?? undefined,
    birthDate: graphql.birthDate ?? undefined,
    certificate: graphql.certificate ?? undefined,
    insurance: graphql.insurance ?? undefined,
    ssn: graphql.ssn ?? undefined,
    createdAt: graphql.createdAt,
    updatedAt: graphql.updatedAt ?? undefined,
    deletedAt: graphql.deletedAt ?? undefined,
    hasAccountMergeBeenAsked: graphql.hasAccountMergeBeenAsked ?? undefined,
    selectedAppLanguage: graphql.selectedAppLanguage ?? undefined,
    lastAskedReviewTime: graphql.lastAskedReviewTime ?? undefined,
  }),
  toGraphQL: (firebase) => ({
    activeRoute:
      firebase.activeRoute &&
      UserDataActiveRouteAdapter.toGraphQL(firebase.activeRoute),
    addresses: firebase.addresses
      ? (UserDataAddressAdapter.toGraphQL(
          firebase.addresses,
        ) as UserDataAddress[])
      : undefined,
    email: firebase.email,
    emailVerified: firebase.emailVerified,
    pushToken: Object.keys(firebase.pushToken || {}),
    name: firebase.name,
    firstName: firebase.firstName,
    lastName: firebase.lastName,
    photoURL: firebase.photoURL,
    language: firebase.language,
    isActive: firebase.isActive,
    phone: firebase.phone,
    iban: firebase.iban,
    birthDate: firebase.birthDate,
    certificate: firebase.certificate,
    insurance: firebase.insurance,
    ssn: firebase.ssn,
    createdAt: firebase.createdAt,
    updatedAt: firebase.updatedAt,
    deletedAt: firebase.deletedAt,
    hasAccountMergeBeenAsked: firebase.hasAccountMergeBeenAsked,
    selectedAppLanguage: firebase.selectedAppLanguage,
    lastAskedReviewTime: firebase.lastAskedReviewTime,
  }),
})
