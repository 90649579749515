import React from "react"
import * as Yup from "yup"
import { withFormik, FormikProps } from "formik"
import { Form, Button, Row, Col } from "antd"

import { FieldAdapter as Field } from "../../../common/form"
import { RenderTextArea } from "../../../common/components"

import { CreateMsgFormValues } from "../../types"

interface CreateMsgFormProps {
  onSubmit: (values: CreateMsgFormValues) => void
}

const CreateMsgForm = (
  props: CreateMsgFormProps & FormikProps<CreateMsgFormValues>,
) => {
  const { values, isValid, handleSubmit } = props

  return (
    <Form name="msg" onSubmitCapture={handleSubmit}>
      <Field
        name="content"
        component={RenderTextArea}
        placeholder="Write a message..."
        value={values.content}
      />
      <Row gutter={24} justify="end">
        <Col>
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            disabled={!isValid}
          >
            Send
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ValidationSchema = Yup.object().shape<CreateMsgFormValues>({
  content: Yup.string().min(2, "Too Short!").required("Required"),
})

const createMsgFormWithFormik = withFormik<
  CreateMsgFormProps,
  CreateMsgFormValues
>({
  mapPropsToValues: () => ({
    content: "",
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values)
    resetForm()
  },
  validationSchema: ValidationSchema,
  displayName: "CreateMsgForm",
})

export default createMsgFormWithFormik(CreateMsgForm)
