import React, { FC, useMemo } from "react"
import { Col, Descriptions, Row } from "antd"
import { useSelector } from "react-redux"
import flatten from "lodash/flatten"
import {
  convertToDecimalNumber,
  HERO24_CUT_DEFAULT,
  getGrossEarnings,
  percentageToFraction,
} from "../../../../common/utilities/price"
import { getFeesByIds } from "../../../../fees/selectors"
import { getFeeTotalWithHero24Cut } from "../../../../fees/utils"
import { getOfferRequestByIds } from "../../../../offerRequests/list/selectors"
import { Offer } from "../../types"
import { getCategories } from "../../../../buyer/categories/selectors"
import { getActualOfferDuration } from "../../../../common/utils"

const TotalRevenue: FC<{ offers: readonly Offer[] }> = ({ offers }) => {
  const offerRequests = useSelector(
    getOfferRequestByIds(
      offers.map((offer) => offer.data.initial.offerRequest),
    ),
  )

  const fees = useSelector(
    getFeesByIds(
      flatten(
        offerRequests?.map((offerRequest) =>
          Object.keys(offerRequest?.fees || {}),
        ),
      ),
    ),
  )

  const grossPurchaseRevenue = useMemo(
    () =>
      offers.reduce((total, offer) => {
        const offerRequest = offerRequests?.find(
          (offerRequest) =>
            offer.data.initial.offerRequest === offerRequest?.id,
        )

        const hero24Cut = offerRequest?.hero24Cut ?? HERO24_CUT_DEFAULT

        return convertToDecimalNumber(
          total +
            getGrossEarnings(offer.data.initial.purchase) * (hero24Cut / 100),
        )
      }, 0),
    [offers, offerRequests],
  )

  const hero24PurchaseRevenue = useMemo(
    () => convertToDecimalNumber(grossPurchaseRevenue),
    [grossPurchaseRevenue],
  )

  const categories = useSelector(getCategories)

  const materalRevenue = useMemo(
    () =>
      (fees || [])
        .map((fee) => {
          const grossRevenue = getFeeTotalWithHero24Cut(fee)

          return {
            hero24Revenue: convertToDecimalNumber(
              grossRevenue * percentageToFraction(fee.platformFeePercentage),
            ),
            grossRevenue,
          }
        })
        .reduce(
          (total, current) => ({
            grossRevenue: total.grossRevenue + current.grossRevenue,
            hero24Revenue: total.hero24Revenue + current.hero24Revenue,
          }),
          {
            grossRevenue: 0,
            hero24Revenue: 0,
          },
        ),
    [fees],
  )

  const totalGrossRevenue = convertToDecimalNumber(
    materalRevenue.grossRevenue + grossPurchaseRevenue,
  )

  const totalHero24Revenue = convertToDecimalNumber(
    materalRevenue.hero24Revenue + hero24PurchaseRevenue,
  )

  const completedRevenue = useMemo(
    () =>
      offers.reduce(
        (total, offer) => {
          const offerRequest = offerRequests?.find(
            (offerRequest) =>
              offer.data.initial.offerRequest === offerRequest?.id,
          )
          const category = categories[offerRequest?.data.initial.category || ""]
          const hero24Cut = offerRequest?.hero24Cut ?? HERO24_CUT_DEFAULT

          const workedDuration = getActualOfferDuration(offer, category)
          const grossCompletedRevenue =
            workedDuration &&
            getGrossEarnings({
              duration: workedDuration.asHours(),
              pricePerHour: offer.data.initial.purchase.pricePerHour,
            })
          const hero24CompletedRevenue =
            grossCompletedRevenue &&
            convertToDecimalNumber(grossCompletedRevenue * (hero24Cut / 100))

          return {
            gross: convertToDecimalNumber(
              total.gross + (grossCompletedRevenue || 0),
            ),
            hero24: convertToDecimalNumber(
              total.hero24 + (hero24CompletedRevenue || 0),
            ),
          }
        },
        { gross: 0, hero24: 0 },
      ),
    [categories, offerRequests, offers],
  )

  return (
    <Row justify="space-between">
      <Col xs={12}>
        <Descriptions column={1}>
          <Descriptions.Item label="Total gross fee revenue">
            {materalRevenue.grossRevenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total gross purchase revenue">
            {grossPurchaseRevenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total completed purchase revenue">
            {completedRevenue.gross} €
          </Descriptions.Item>
          <Descriptions.Item label="Total gross revenue">
            {totalGrossRevenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total completed revenue">
            {convertToDecimalNumber(
              completedRevenue.gross + materalRevenue.grossRevenue,
            )}{" "}
            €
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col xs={12}>
        <Descriptions column={1}>
          <Descriptions.Item label="Total hero24 fee revenue">
            {materalRevenue.hero24Revenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total hero24 purchase revenue">
            {hero24PurchaseRevenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total hero24 completed purchase revenue">
            {completedRevenue.hero24} €
          </Descriptions.Item>
          <Descriptions.Item label="Total hero24 revenue">
            {totalHero24Revenue} €
          </Descriptions.Item>
          <Descriptions.Item label="Total hero24 completed revenue">
            {convertToDecimalNumber(
              completedRevenue.hero24 + materalRevenue.hero24Revenue,
            )}{" "}
            €
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

export default TotalRevenue
