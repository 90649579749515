import moment from "moment"

import { FormPromotionData } from ".."
import { Promotion } from "../../../types"

const PromotionToFormPromotion = (promotion: Promotion): FormPromotionData => {
  const { startDate, endDate, ...data } = promotion.data

  return {
    ...data,
    startDate: moment(startDate),
    endDate: moment(endDate),
  }
}

export default PromotionToFormPromotion
