import * as TYPES from "./actionTypes"
import { UsersState, UsersReducer } from "./types"

export const INITIAL_STATE: UsersState = {
  users: {},
  areUsersLoaded: false,
}

const users: UsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_USERS_LIST: {
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      }
    }
    case TYPES.EDIT_USER: {
      const user = state.users[action.payload.id]
      if (!user) {
        return state
      }
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...user,
            data: {
              ...user.data,
              ...action.payload.values,
            },
          },
        },
      }
    }
    case TYPES.ADD_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: action.payload,
        },
      }
    }
    case TYPES.ARE_USERS_LOADED: {
      return {
        ...state,
        areUsersLoaded: action.payload,
      }
    }
    case TYPES.EDIT_ADMIN_PERMISSIONS_IN_ALL_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            isAdmin: action.payload.isAdmin,
          },
        },
      }
    }
    default:
      return state
  }
}

export default { users }
