import { AppThunkAction } from "../../common/types"
import { subscribeOnNewsAdded } from "../graphql/subscriptions"
import { newsCreated } from "../actions"

let unsubscribe: null | (() => void) = null

export const subscribeOnNewsAddingThunk: AppThunkAction =
  () => (dispatch, getState) => {
    unsubscribe && unsubscribe()

    const { newsAddedUnsubscribe } = subscribeOnNewsAdded(getState(), {
      next: ({ data }) => {
        if (data?.newsAdded) {
          dispatch(newsCreated(data.newsAdded))
        }
      },
    })

    unsubscribe = newsAddedUnsubscribe
  }
