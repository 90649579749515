import { gql } from "@apollo/client"

import { USER_FRAGMENT } from "../../fragments"

export const MUTATION = gql`
  ${USER_FRAGMENT}

  mutation CreateUser($data: UserDataInput!, $isCreatedFromWeb: Boolean) {
    createUser(data: $data, isCreatedFromWeb: $isCreatedFromWeb) {
      ...UserInfo
    }
  }
`
