import * as TYPES from "./actionTypes"
import {
  PlatformProviderService,
  PaymentService,
  SUBSCRIPTION_PERIOD,
  SendingChannel,
} from "./constants"
import { PaymentsState, PaymentsReducer as Payments } from "./types"

export const INITIAL_STATE: PaymentsState = {
  fixedPrice: undefined,
  fixedDuration: undefined,
  platformProvider: PlatformProviderService.HERO_PAYMENTS_OY,
  sendInvoiceWith: SendingChannel.EMAIL,
  prePayWith: PaymentService.STRIPE,
  postpaid: "no",
  customerVAT: undefined,
  serviceProviderVAT: undefined,
  hero24Cut: 25,
  isSubscriptionEnabled: false,
  subscriptionType: SUBSCRIPTION_PERIOD.Daily,
}

const payments: Payments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_FIXED_PRICE: {
      return {
        ...state,
        fixedPrice: action.payload,
      }
    }

    case TYPES.SET_TOTAL_DURATION: {
      return {
        ...state,
        fixedDuration: action.payload,
      }
    }

    case TYPES.SET_MINIMUM_DURATION: {
      return {
        ...state,
        minimumDuration: action.payload,
      }
    }

    case TYPES.SET_PLATFORM_PROVIDER: {
      return {
        ...state,
        platformProvider: action.payload,
      }
    }

    case TYPES.SET_PRE_PAY_WITH: {
      return {
        ...state,
        prePayWith: action.payload,
      }
    }

    case TYPES.SET_POST_PAID: {
      return {
        ...state,
        postpaid: action.payload,
      }
    }

    case TYPES.SET_INVOICE: {
      return {
        ...state,
        sendInvoiceWith: action.payload,
      }
    }

    case TYPES.SET_CUSTOMER_VAT: {
      return {
        ...state,
        customerVAT: action.payload,
      }
    }

    case TYPES.SET_SERVICE_PROVIDER_VAT: {
      return {
        ...state,
        serviceProviderVAT: action.payload,
      }
    }

    case TYPES.SET_HERO24_CUT: {
      return {
        ...state,
        hero24Cut: action.payload,
      }
    }

    case TYPES.RESET_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionType: INITIAL_STATE.subscriptionType,
        isSubscriptionEnabled: INITIAL_STATE.isSubscriptionEnabled,
      }
    }

    case TYPES.SET_SUBSCRIPTION_ENABLED: {
      return {
        ...state,
        isSubscriptionEnabled: action.payload,
      }
    }

    case TYPES.SET_SUBSCRIPTION_INTERVAL: {
      return {
        ...state,
        subscriptionType: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default { payments }
