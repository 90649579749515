import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"

import { FeeStatusEnum } from "../../../components/OfferRequest/Fees/components/types"
import { MUTATION } from "./mutation"

type Data = {
  [DEFAULT_GRAPHQL_RESPONSE]: boolean
}

export type ChangeFeeStatusInput = {
  id: string
  status: FeeStatusEnum
}

type Variables = {
  input: ChangeFeeStatusInput
}

export const changeFeeStatusThunk: ApolloThunkMutation<
  Data,
  [Variables]
> = async (state, variables) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables,
  })

  return {
    responseData: data?.[DEFAULT_GRAPHQL_RESPONSE],
    responseErrors: errors,
  }
}
