import { Alert, Modal } from "antd"
import { Maybe } from "graphql/jsutils/Maybe"
import { UserDB } from "hero24-types"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import { FC, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import CreateUserForm from "../../../../../../buyer/createOfferRequest/createUserStep/CreateUserForm"
import { CreateUserForm as ICreateUserForm } from "../../../../../../buyer/createOfferRequest/createUserStep/types"
import { createAdmin } from "../../../../../../user/actions"
import { MenuItemsKeys, StatusChangerModals } from "../constants"
import { StatusModalProps } from "./types"
import {
  errorMessageCreateAdmin,
  errorPhoneNumber,
  getSuccessMessageCreate,
  popconfirm,
} from "./constants"
import { User } from "../../../../../../user/graphql/fragments"

const CreateAdminUserModal: FC<StatusModalProps> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const [createdAdmin, setCreatedAdmin] = useState<Maybe<User>>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Maybe<string>>(null)

  const onSubmit: (
    values: ICreateUserForm,
    resetForm: () => void,
  ) => Promise<void> = async (values) => {
    setLoading(true)
    setError(null)

    const phone = parsePhoneNumberFromString(values.phone)

    if (!phone) {
      setError(errorPhoneNumber)
      return
    }

    const userData: Omit<UserDB["data"], "createdAt"> = {
      email: values.email,
      emailVerified: true,
      name: `${values.firstName} ${values.lastName}`,
      firstName: values.firstName,
      lastName: values.lastName,
      language: values.language || "en",
      photoURL: "",
      phone: phone.format("E.164"),
    }

    try {
      await dispatch(
        createAdmin(userData, (createdAdmin) => setCreatedAdmin(createdAdmin)),
      )
    } catch (e) {
      setError(errorMessageCreateAdmin)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = useCallback(async () => {
    closeModal({ selectedUser: createdAdmin, key: MenuItemsKeys.NEW_USER })
  }, [closeModal, createdAdmin])

  return (
    <Modal
      title={StatusChangerModals.NEW_USER.modalTitle}
      open={open}
      onCancel={handleCloseModal}
      footer={false}
    >
      {createdAdmin?.data.email ? (
        <Alert
          message={getSuccessMessageCreate(createdAdmin.data.email)}
          type="success"
        />
      ) : (
        <CreateUserForm
          onSubmit={onSubmit}
          isAlreadyExists={false}
          loading={loading}
          isPopconfirmPresent={true}
          popconfirmTitle={popconfirm.createTitle}
          popconfirmOkText={popconfirm.okText}
          popconfirmCancelText={popconfirm.cancelText}
        />
      )}
      {error && <Alert message={error} type="error" style={{ marginTop: 8 }} />}
    </Modal>
  )
}

export default CreateAdminUserModal
