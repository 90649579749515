import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { OfferRequest } from "../../fragments"
import { MUTATION } from "./mutation"
import { OfferRequestCreationInput } from "./types"

type Data = {
  createOfferRequest: OfferRequest
}

type Variables = {
  input: OfferRequestCreationInput
}

export const createOfferRequestThunk: ApolloThunkMutation<
  Data,
  [Variables["input"]],
  "data" | "errors"
> = async (state, input) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables: { input },
    fetchPolicy: "network-only",
  })

  return {
    createOfferRequestData: data?.createOfferRequest,
    createOfferRequestErrors: errors,
  }
}

export * from "./types"
