import React from "react"
import { Button, DatePicker, Input, Space } from "antd"
import { ColumnsType, ColumnType } from "antd/lib/table"
import { RangePickerProps } from "antd/lib/date-picker"
import { SearchOutlined } from "@ant-design/icons"
import moment from "moment-timezone"

import { Offer } from "../../types"
import { SellerState } from "../../../../seller/types"
import { UsersState } from "../../../../users/list/types"
import { OfferRequestsState } from "../../../../offerRequests/list/types"
import { OfferStatus } from "../../../../common/components/listComponent/OfferStatus"
import {
  getDateQuestion,
  isPrepaidSuccessfully,
} from "../../../../offerRequests/utils"
import { SellerName } from "../../../../common/components/listComponent/Seller"
import { UserName } from "../../../../common/components/listComponent/UserName"
import { CategoriesState } from "../../../../buyer/categories/types"
import { CategoryName } from "../../../../common/components/listComponent/Category"
import { Note } from "../../../../common/components/listComponent/Note"
import { getStartEndString, formatDateAndTime } from "../../../../common/utils"
import { earningsStatuses } from "../../../../common/components/Offer/Earnings"
import { ColumnText } from "../../../../common/components/listComponent/ColumnText"
import { Prepaid } from "../../../../common/components/listComponent/Prepaid"
import Revenue from "./Revenue"

type Column = ColumnsType<Offer>

const getColumns = (
  categories: CategoriesState,
  sellers: SellerState,
  customers: UsersState,
  offerRequests: OfferRequestsState,
  purchaseInvoiceMode?: boolean,
) => {
  const agreedDateColumn: Column[number] = {
    title: "Offered date",
    render: (offer: Offer) => (
      <ColumnText>
        {formatDateAndTime(
          offer.data?.initial.agreedStartTime,
          "dateAndTimeFormat",
        )}
      </ColumnText>
    ),
    key: "offer.data?.initial.agreedStartTime",
    defaultSortOrder: "descend",
    sorter: (offerA: Offer, offerB: Offer) => {
      const deadlineA = offerA.data?.initial.agreedStartTime
      const deadlineB = offerB.data?.initial.agreedStartTime
      return deadlineA > deadlineB ? 1 : -1
    },
  }

  const startedCompletedColumn: Column[number] = {
    title: "Started/Completed",
    render: (offer: Offer) => {
      return (
        <ColumnText>
          {offer.data?.actualStartTime
            ? getStartEndString({
                startTime: offer.data?.actualStartTime,
                endTime: offer.data?.actualCompletedTime,
              })
            : "-"}
        </ColumnText>
      )
    },
    key: "offer.data?.actualStartTime",
    sorter: (offerA: Offer, offerB: Offer) => {
      const startedA = offerA.data?.actualStartTime || 0
      const startedB = offerB.data?.actualStartTime || 0
      return startedA > startedB ? 1 : -1
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const rawRange =
        typeof selectedKeys[0] === "string"
          ? JSON.parse(selectedKeys[0])
          : [null, null]

      const range = [
        rawRange[0] && moment(rawRange[0]),
        rawRange[1] && moment(rawRange[1]),
      ]

      return (
        <>
          <Space direction="vertical" style={{ padding: 8 }}>
            <DatePicker.RangePicker
              size="small"
              value={range as RangePickerProps["value"]}
              onChange={(value) => setSelectedKeys([JSON.stringify(value)])}
              placeholder={["Begin of range", "End of range"]}
            />

            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </Space>
        </>
      )
    },
    onFilter: (value: unknown, offer: Offer) => {
      const rawRange =
        typeof value === "string" ? JSON.parse(value) : [null, null]

      const range = [
        rawRange[0] ? moment(rawRange[0]) : null,
        rawRange[1] ? moment(rawRange[1]) : null,
      ]

      const startTime = offer.data?.actualStartTime || 0
      const endTime = offer.data?.actualCompletedTime || 0

      const isBetween = (time: number) =>
        !!(range[0]?.isSameOrBefore(time) && range[1]?.isSameOrAfter(time))

      return isBetween(endTime) || isBetween(startTime)
    },
  }

  const prePaidColumn: Column[0] = {
    key: "Prepaid",
    title: "Payment",
    render: (offer: Offer) => {
      const offerRequest =
        offerRequests.offerRequests[offer.data?.initial.offerRequest]

      return offerRequest ? <Prepaid offerRequest={offerRequest} /> : null
    },
  }

  const statusColumn: ColumnType<Offer> = {
    title: "Status",
    render: (offer: Offer) => (
      <OfferStatus
        offer={offer}
        offerRequest={
          offerRequests.offerRequests[offer.data?.initial.offerRequest]
        }
        purchaseInvoiceMode={purchaseInvoiceMode}
      />
    ),
    key: "offer.data?.status",
    sorter: (offerA: Offer, offerB: Offer) => {
      return offerB.status.localeCompare(offerA.status)
    },
    filters: [
      { text: "OPEN", value: "open" },
      { text: "ACCEPTED", value: "accepted" },
      { text: "COMPLETED", value: "completed" },
      { text: "CANCELLED", value: "cancelled" },
      { text: "EXPIRED", value: "expired" },
    ],
    onFilter: (value: unknown, offer: Offer) => offer.status === value,
  }

  const doerColumn: Column[0] = {
    title: "Seller",
    render: (offer: Offer) => (
      <SellerName
        seller={sellers?.sellers[offer.data?.initial.sellerProfile]}
      />
    ),
    key: "offer.data?.initial.sellerProfile",
    sorter: (offerA: Offer, offerB: Offer) => {
      const aName =
        sellers?.sellers[offerA.data?.initial.sellerProfile]?.sellerData
          .companyName || offerA.data?.initial.sellerProfile
      const bName =
        sellers?.sellers[offerB.data?.initial.sellerProfile]?.sellerData
          .companyName || offerB.data?.initial.sellerProfile
      return aName.localeCompare(bName)
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by buyers name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, offer: Offer) => {
      const user =
        sellers.sellers && sellers.sellers[offer.data?.initial.sellerProfile]
      return typeof value === "string" && user
        ? user.sellerData.companyName
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const buyerColumn: Column[0] = {
    title: "Customer",
    render: (offer: Offer) => (
      <UserName user={customers?.users[offer.data?.initial.buyerProfile]} />
    ),
    key: "offer.data?.initial.buyerProfile",
    sorter: (offerA: Offer, offerB: Offer) => {
      const aName =
        customers?.users[offerA.data?.initial.buyerProfile]?.data?.name ||
        offerA.data?.initial.buyerProfile
      const bName =
        customers?.users[offerB.data?.initial.buyerProfile]?.data?.name ||
        offerB.data?.initial.buyerProfile
      return aName.localeCompare(bName)
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by buyers name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, offer: Offer) => {
      const user =
        customers.users && customers.users[offer.data?.initial.buyerProfile]
      return typeof value === "string" && user
        ? user.data?.name.toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const categoryColumn = {
    title: "Category",
    render: (offer: Offer) => (
      <CategoryName
        category={
          categories.categories[
            offerRequests?.offerRequests[offer.data?.initial.offerRequest]?.data
              .initial.category
          ]
        }
      />
    ),
    key: "offer.data?.initial.offerRequest",
    sorter: (offerA: Offer, offerB: Offer) => {
      const aName =
        offerRequests?.offerRequests[offerA.data?.initial.offerRequest]?.data
          .initial.category || offerA.data?.initial.offerRequest
      const bName =
        offerRequests?.offerRequests[offerB.data?.initial.offerRequest]?.data
          .initial.category || offerB.data?.initial.offerRequest
      return aName.localeCompare(bName)
    },
    filters: Object.values(categories.categories)
      .sort((a, b) => (a.name.en || "").localeCompare(b.name.en || ""))
      .map((category) => ({
        text: category.name.en,
        value: category.id,
      })),
    onFilter: (value: string, offer: Offer) => {
      const offerRequest =
        offerRequests?.offerRequests[offer.data?.initial.offerRequest]
      const category =
        offerRequest &&
        categories.categories[offerRequest.data?.initial.category]
      return category && category.id === value
    },
  }

  const subscriptionColumn: Column[0] = {
    key: "subscription",
    title: "Subscription",
    render: (offer: Offer) => {
      const offerRequest =
        offerRequests?.offerRequests[offer.data?.initial.offerRequest]
      return offerRequest?.subscription != null
        ? offerRequest.subscription.subscriptionType.toUpperCase()
        : "---"
    },
    filters: ["daily", "weekly", "bi-weekly", "monthly"].map((el) => ({
      text: el.toUpperCase(),
      value: el,
    })),
    onFilter: (value: unknown, offer: Offer) => {
      const offerRequest =
        offerRequests?.offerRequests[offer.data?.initial.offerRequest]
      return offerRequest?.subscription?.subscriptionType === value
    },
  }

  const noteTexts = {
    pendingExtension: "Pending extension!",
    hasPaidExtensions: "Paid extra hours!",
    needManualAccepting:
      "Accept Manually! Pprepaid gig but the time is different than what customer requested)",
  }
  const getNoteText = (offer: Offer) => {
    const offerRequest =
      offerRequests?.offerRequests[offer.data?.initial.offerRequest]
    if (
      offer.status === "open" &&
      offerRequest &&
      isPrepaidSuccessfully(offerRequest)
    ) {
      const dateQuestion = getDateQuestion(offerRequest)
      if (dateQuestion) {
        const offeredTime = offer.data?.initial.agreedStartTime
        if (
          dateQuestion.preferredTime &&
          dateQuestion.preferredTime !== offeredTime
        ) {
          return noteTexts.needManualAccepting
        }
      } else {
        return "Invalid offerRequest"
      }
    } else if (offer.timeToExtend) {
      return noteTexts.pendingExtension
    } else if (offer.data?.extensions?.length) {
      return noteTexts.hasPaidExtensions
    }
  }

  const noteColumn = {
    title: "Note!",
    render: (offer: Offer) => {
      const noteText = getNoteText(offer)
      if (noteText) {
        return (
          <Note
            type={
              noteText === noteTexts.needManualAccepting ||
              noteText === noteTexts.pendingExtension
                ? "warning"
                : "success"
            }
            note={noteText}
          />
        )
      }
      return ""
    },
    key: "note",
    filters: Object.values(noteTexts)
      .sort((a, b) => a.localeCompare(b))
      .map((noteText) => ({
        text: noteText,
        value: noteText,
      })),
    onFilter: (value: string, offer: Offer) => value === getNoteText(offer),
  }

  const paidColumn = {
    title: "Earning",
    render: (offer: Offer) => (
      <ColumnText>
        {offer.earnings?.status ? earningsStatuses[offer.earnings?.status] : ""}
      </ColumnText>
    ),
    filters: Object.entries(earningsStatuses).map((status) => ({
      text: status[1],
      value: status[0],
    })),
    onFilter: (value: string, offer: Offer) => offer.earnings?.status === value,
  }

  const revenueColumn: Column[number] = {
    title: "Revenue €",
    render: (offer: Offer) => <Revenue offer={offer} />,
    width: 350,
  }

  const columns: ColumnsType<Offer> = []

  if (!purchaseInvoiceMode) {
    columns.push(noteColumn as Column[number])
  }

  return columns.concat([
    agreedDateColumn,
    startedCompletedColumn,
    prePaidColumn,
    statusColumn,
    doerColumn,
    categoryColumn as Column[number],
    buyerColumn,
    subscriptionColumn,
    paidColumn as Column[number],
    revenueColumn,
  ])
}

export default getColumns
