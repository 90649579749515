import React, { FC, useState } from "react"
import DebounceSelect from "../../../common/components/DebounceSelect"
import { Button, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { selectBriefSellers } from "../../../seller"
import { updateChatsFilters } from "../../reducer"

interface SellerValue {
  label: string
  value: string
}

type Props = {
  confirm: () => void
}

const SellerColumnFilter: FC<Props> = ({ confirm }) => {
  const [sellerValues, setSellerValues] = useState<SellerValue[]>([])

  const sellers = useSelector(selectBriefSellers)

  const dispatch = useDispatch()

  const clearFilters = () => {
    dispatch(
      updateChatsFilters({
        sellerIds: undefined,
      }),
    )
    setSellerValues([])
  }

  const fetchSellerOptions = async (value: string): Promise<SellerValue[]> =>
    Object.values(sellers)
      .filter(({ data }) =>
        data.companyName.toLowerCase().includes(value.toLowerCase()),
      )
      .map(({ data, id }) => ({ label: data.companyName, value: id }))

  return (
    <div style={{ padding: 8 }}>
      <DebounceSelect
        mode="multiple"
        value={sellerValues}
        placeholder="Search by seller name"
        fetchOptions={fetchSellerOptions}
        onChange={(newValue) => {
          setSellerValues(newValue as SellerValue[])
        }}
        style={{ width: 200, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            dispatch(
              updateChatsFilters({
                sellerIds: sellerValues.length
                  ? sellerValues.map(({ value }) => value)
                  : undefined,
              }),
            )
            confirm()
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
}
export default SellerColumnFilter
