import React from "react"
import AuthRoute from "../../common/AuthRoute"
import reducer from "./reducer"
import { OfferRequestList } from "./components/containers"
import { EuroOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <EuroOutlined />,
      name: "Orders by Customers",
      path: "offer-requests",
    },
  ],
  routes: [
    <AuthRoute
      // exact
      key="/offer-requests/:userId"
      path="/offer-requests"
      render={() => <OfferRequestList />}
    />,
  ],
  reducer,
}
