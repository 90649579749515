import { ThunkDispatch } from "redux-thunk"
import firebase from "firebase/app"
import { FIREBASE_PATH } from "../categories/constants"
import { getCategories } from "../categories/actions"
import { CreatePackageForm } from "./types"
import { normalizeCreatedPackage } from "./utils"

export const createAndUpdatePackage =
  (categoryId: string, values: CreatePackageForm, initialPackageId?: string) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const packageDB = normalizeCreatedPackage(values)

    const packagesRef: firebase.database.Reference = firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("packages")

    if (initialPackageId && initialPackageId !== values.id)
      await packagesRef.child(initialPackageId).remove()

    await packagesRef.child(values.id).set(packageDB)

    dispatch(getCategories())
  }

export const removePackage =
  (categoryId: string, packageId: string) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("packages")
      .child(packageId)
      .remove()

    dispatch(getCategories())
  }
