import { gql } from "@apollo/client"

export type SetChatReclamationResolvedVariables = {
  isAboutReclamation: boolean
  chatId: string
}

export const SET_CHAT_RECLAMATION_RESOLVED_MUTATION = gql`
  mutation SetChatReclamationResolved(
    $chatId: String!
    $isAboutReclamation: Boolean!
  ) {
    setChatReclamationResolved(
      chatId: $chatId
      isAboutReclamation: $isAboutReclamation
    )
  }
`
