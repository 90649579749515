import { gql } from "@apollo/client"

import { USER_FRAGMENT, User, UserData } from "../../fragments"
import { Times } from "../../../types"
import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"

export type Data = {
  [DEFAULT_GRAPHQL_RESPONSE]: User
}

export type Variables = {
  data: Omit<UserData, Times>
}

export const MUTATION = gql`
  ${USER_FRAGMENT}

  mutation CreateAdmin($data: UserDataInput!) {
    ${DEFAULT_GRAPHQL_RESPONSE}: createAdmin(data: $data) {
      ...UserInfo
    }
  }
`
