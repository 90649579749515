import React, { useState } from "react"
import { Row, Col, Select, InputNumber } from "antd"
import { isNumber } from "lodash"
import { OfferRequest } from "../../../offerRequests/list/types"
import { useDispatch } from "react-redux"
import { setOfferRequestRefund } from "../../../offerRequests/list/actions"
import TextArea from "antd/lib/input/TextArea"
import Text from "antd/lib/typography/Text"
import { REFUND_STATUS } from "hero24-types"

interface Props {
  offerRequest: OfferRequest
}

export const refundStatuses: { [key in REFUND_STATUS]: string } = {
  full: "Full Refund",
  partial: "Partial Refund",
}

export const Refund = (props: Props) => {
  const [refundStatus, setRefundStatus] = useState<REFUND_STATUS | undefined>(
    props.offerRequest.refund?.status,
  )

  const [refundAmount, setRefundAmount] = useState<number | undefined>(
    props.offerRequest.refund?.amount,
  )

  const [refundMessages, setRefundMessage] = useState<string>(
    props.offerRequest.refund?.message || "",
  )

  const dispatch = useDispatch()

  const changeRefundStatus = (status: REFUND_STATUS) => {
    dispatch(
      setOfferRequestRefund({
        id: props.offerRequest.id,
        status,
        amount: refundAmount,
        message: refundMessages,
      }),
    )
    setRefundStatus(status)
  }

  const changeRefundAmount = (amount: number | string | null | undefined) => {
    if (isNumber(amount)) {
      dispatch(
        setOfferRequestRefund({
          id: props.offerRequest.id,
          status: refundStatus,
          amount,
          message: refundMessages,
        }),
      )
      setRefundAmount(amount)
    }
  }

  const changeRefundMessage = (message: string) => {
    dispatch(
      setOfferRequestRefund({
        id: props.offerRequest.id,
        status: refundStatus,
        amount: refundAmount,
        message,
      }),
    )
    setRefundMessage(message)
  }

  return (
    <>
      <Row>
        <Col span={8}>
          <Text>Refund Status</Text>
          <br />
          <Select
            defaultValue={refundStatus}
            onSelect={changeRefundStatus}
            placeholder="Select status"
            dropdownMatchSelectWidth={true}
            style={{ width: 200 }}
          >
            {Object.entries(refundStatuses).map((status) => (
              <Select.Option
                key={status[0]}
                value={status[0]}
                style={{ textTransform: "capitalize" }}
              >
                {status[1]}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Text>Amount to refund</Text>
          <InputNumber
            style={{ width: "75%" }}
            type="number"
            value={refundAmount}
            onChange={changeRefundAmount}
          />
        </Col>
        <Col span={8}>
          <Text>Message for Customer</Text>
          <TextArea
            value={refundMessages}
            rows={4}
            onChange={(e) => changeRefundMessage(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
