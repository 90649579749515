import { gql } from "@apollo/client"
import {
  OfferRequestDB,
  AddressesAnswered as AddressesAnsweredDB,
  PaidStatus,
} from "hero24-types"

import {
  ADDRESSES_ANSWERED_FRAGMENT,
  AddressesAnswered,
  AddressesAnsweredAdapter,
} from "../AddressAnswered"
import {
  OFFER_REQUEST_QUESTION_FRAGMENT,
  OfferRequestQuestion,
  OfferRequestQuestionAdapter,
} from "../OfferRequestQuestion/OfferRequestQuestionInfo"
import { PACKAGE_FRAGMENT, Package, PackageAdapter } from "./PackageInfo"
import { offerRequestQuestionsToTree } from "../../../utils/offerRequestQuestionsToTree"
import { offerRequestQuestionsToArray } from "../../../utils/offerRequestQuestionsToArray"
import { PlainOfferRequestQuestion } from "../../../types"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

type OfferRequestDataInitialDB = OfferRequestDB["data"]["initial"]

export type OfferRequestDataInitial = {
  buyerProfile: string
  fixedDuration?: Maybe<number>
  fixedPrice?: Maybe<number>
  createdAt: number
  platformProvider?: Maybe<"hero_payments_oy" | "hero24_oy" | "hero24_b2b_oy">
  prePayWith?: Maybe<"stripe" | "netvisor">
  sendInvoiceWith?: Maybe<"sms" | "email">
  prepaid?: Maybe<PaidStatus>
  postpaid?: Maybe<"no" | "yes">
  questions: OfferRequestQuestion[]
  addresses: AddressesAnswered
  category: string
  package?: Maybe<Package>
  promotionDisabled?: Maybe<boolean>
}

export const OfferRequestDataInitialAdapter = new FirebaseAdapter<
  OfferRequestDataInitialDB,
  OfferRequestDataInitial
>({
  toFirebase(graphql) {
    const questions = graphql.questions.map((question) =>
      OfferRequestQuestionAdapter.toFirebase(question),
    )

    return {
      prepaid: graphql.prepaid ?? undefined,
      postpaid: graphql.postpaid ?? undefined,
      promotionDisabled: graphql.promotionDisabled ?? undefined,
      fixedDuration: graphql.fixedDuration ?? undefined,
      fixedPrice: graphql.fixedPrice ?? undefined,
      platformProvider: graphql.platformProvider ?? undefined,
      prePayWith: graphql.prePayWith ?? undefined,
      sendInvoiceWith: graphql.sendInvoiceWith ?? undefined,
      buyerProfile: graphql.buyerProfile,
      addresses: AddressesAnsweredAdapter.toFirebase(
        graphql.addresses,
      ) as AddressesAnsweredDB,
      category: graphql.category,
      createdAt: graphql.createdAt,
      package: graphql.package
        ? PackageAdapter.toFirebase(graphql.package)
        : undefined,
      questions: offerRequestQuestionsToTree(
        questions as PlainOfferRequestQuestion[],
      ),
    }
  },
  toGraphQL(firebase) {
    const questions = offerRequestQuestionsToArray(firebase.questions)

    return {
      fixedPrice: firebase.fixedPrice,
      platformProvider: firebase.platformProvider,
      prePayWith: firebase.prePayWith,
      sendInvoiceWith: firebase.sendInvoiceWith,
      prepaid: firebase.prepaid,
      postpaid: firebase.postpaid,
      promotionDisabled: firebase.promotionDisabled,
      buyerProfile: firebase.buyerProfile,
      category: firebase.category,
      fixedDuration: firebase.fixedDuration,
      createdAt: firebase.createdAt,
      questions: questions.map(
        (question) =>
          OfferRequestQuestionAdapter.toGraphQL(
            question,
          ) as OfferRequestQuestion,
      ),
      addresses: AddressesAnsweredAdapter.toGraphQL(
        firebase.addresses,
      ) as AddressesAnswered,
      package: firebase.package
        ? PackageAdapter.toGraphQL(firebase.package)
        : undefined,
    }
  },
})

export const OFFER_REQUEST_DATA_INITIAL_FRAGMENT = gql`
  ${OFFER_REQUEST_QUESTION_FRAGMENT}
  ${ADDRESSES_ANSWERED_FRAGMENT}
  ${PACKAGE_FRAGMENT}

  fragment OfferRequestDataInitialInfo on OfferRequestDataInitialDto {
    questions {
      ...OfferRequestQuestionInfo
    }
    addresses {
      ...AddressesAnsweredInfo
    }
    package {
      ...PackageInfo
    }
    buyerProfile
    fixedDuration
    fixedPrice
    createdAt
    platformProvider
    prePayWith
    sendInvoiceWith
    prepaid
    postpaid
    category
    promotionDisabled
  }
`
