import { OfferRequestDB, REFUND_STATUS } from "hero24-types"

import { gql } from "@apollo/client"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

type RefundDB = Exclude<OfferRequestDB["refund"], undefined>

export type OfferRequestRefund = {
  updatedAt: number
  amount: number
  message?: Maybe<string>
  status: REFUND_STATUS
}

export const OfferRequestRefundAdapter = new FirebaseAdapter<
  RefundDB,
  OfferRequestRefund
>({
  toFirebase: ({ amount, message, status, updatedAt }) => ({
    amount,
    status,
    updatedAt,
    message: message ?? undefined,
  }),
  toGraphQL: ({ amount, message, status, updatedAt }) => ({
    amount,
    status,
    updatedAt,
    message,
  }),
})

export const OFFER_REQUEST_REFUND_FRAGMENT = gql`
  fragment OfferRequestRefundInfo on OfferRequestRefundDto {
    updatedAt
    amount
    message
    status
  }
`
