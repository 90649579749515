import {
  TRANSLATION_FIELD_FRAGMENT,
  TranslationField,
  TranslationFieldAdapter,
} from "../../../../common/graphql/fragments"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"
import { PlainOfferRequestQuestion } from "../../../types"
import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"

import { gql } from "@apollo/client"

type QuestionType = "number_input"

type PlainOfferRequestNumberInputQuestion = PlainOfferRequestQuestion & {
  type: QuestionType
}

export interface OfferRequestNumberInputQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  placeholder?: Maybe<TranslationField>
  extra_placeholder?: Maybe<TranslationField>
  value?: Maybe<string>
}

export const OfferRequestNumberInputQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestNumberInputQuestion,
  OfferRequestNumberInputQuestion
>({
  toFirebase: (external) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(external),
    type: "number_input" as QuestionType,
    placeholder: external.placeholder
      ? TranslationFieldAdapter.toFirebase(external.placeholder)
      : null,
    extra_placeholder: external.extra_placeholder
      ? TranslationFieldAdapter.toFirebase(external.extra_placeholder)
      : null,
    value: external.value ?? null,
  }),
  toGraphQL: (internal) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(internal),
    type: "number_input" as QuestionType,
    placeholder:
      internal.placeholder &&
      TranslationFieldAdapter.toGraphQL(internal.placeholder),
    extra_placeholder:
      internal.extra_placeholder &&
      TranslationFieldAdapter.toGraphQL(internal.extra_placeholder),
    value: internal.value,
  }),
})

export const OFFER_REQUEST_NUMBER_INPUT_QUESTION_FRAGMENT = gql`
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestNumberInputQuestionInfo on OfferRequestNumberInputQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    placeholder {
      ...TranslationFieldInfo
    }
    extra_placeholder {
      ...TranslationFieldInfo
    }
    value
  }
`
