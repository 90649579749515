import { gql } from "@apollo/client"

import { OFFER_REQUEST_FRAGMENT, OfferRequest } from "../fragments"

export type GetOfferRequestData = {
  offerRequest?: OfferRequest
}

export type GetOfferRequestVariables = {
  id: string
}

export const GET_OFFER_REQUEST_QUERY = gql`
  ${OFFER_REQUEST_FRAGMENT}

  query GetOfferRequest($id: String!) {
    offerRequest(id: $id) {
      ...OfferRequestInfo
    }
  }
`
