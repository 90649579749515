import { FC } from "react"

import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import { NetvisorPrepaidStatus } from "./NetvisorPrepaidStatus"
import { StripePrepaidStatus } from "./StripePrepaidStatus"
import { PaymentService } from "../../../../../payments/constants"

type Props = {
  offerRequest: OfferRequest
}

const PREPAID_STATUS_COMPONENT: Record<PaymentService, FC<Props>> = {
  [PaymentService.NETVISOR]: NetvisorPrepaidStatus,
  [PaymentService.STRIPE]: StripePrepaidStatus,
}

const MissedPrepaidStatus: FC<Props> = () => <>-</>

export const PrepaidStatus: FC<Props> = ({ offerRequest }) => {
  const { prePayWith } = offerRequest.data.initial

  const PrepaidStatusComponent = prePayWith
    ? PREPAID_STATUS_COMPONENT[prePayWith]
    : MissedPrepaidStatus

  return <PrepaidStatusComponent offerRequest={offerRequest} />
}
