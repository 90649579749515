import React, { FC, useMemo } from "react"
import { Descriptions } from "antd"
import { useSelector } from "react-redux"
import {
  convertToDecimalNumber,
  HERO24_CUT_DEFAULT,
  getGrossEarnings,
  percentageToFraction,
} from "../../../../common/utilities/price"
import { getFeesByIds } from "../../../../fees/selectors"
import { getFeeTotalWithHero24Cut } from "../../../../fees/utils"
import { getOfferRequestById } from "../../../../offerRequests/list/selectors"
import { Offer } from "../../types"
import { getCategoryById } from "../../../../buyer/categories/selectors"
import { getActualOfferDuration } from "../../../../common/utils"

const Revenue: FC<{ offer: Offer }> = ({ offer }) => {
  const offerRequest = useSelector(
    getOfferRequestById(offer.data.initial.offerRequest),
  )

  const category = useSelector(
    getCategoryById(offerRequest?.data.initial.category),
  )

  const hero24Cut = useMemo(
    () => offerRequest?.hero24Cut ?? HERO24_CUT_DEFAULT,
    [offerRequest],
  )

  const grossPurchaseRevenue = getGrossEarnings(offer.data.initial.purchase)
  const hero24PurchaseRevenue = convertToDecimalNumber(
    grossPurchaseRevenue * (hero24Cut / 100),
  )

  const fees = useSelector(getFeesByIds(Object.keys(offerRequest?.fees || {})))
  const materalRevenue = useMemo(
    () =>
      (fees || [])
        .map((fee) => {
          const total = getFeeTotalWithHero24Cut(fee)

          return {
            grossRevenue: convertToDecimalNumber(total),
            hero24Revenue: convertToDecimalNumber(
              total * percentageToFraction(fee.platformFeePercentage),
            ),
          }
        })
        .reduce(
          (total, current) => ({
            grossRevenue: total.grossRevenue + current.grossRevenue,
            hero24Revenue: total.hero24Revenue + current.hero24Revenue,
          }),
          {
            grossRevenue: 0,
            hero24Revenue: 0,
          },
        ),
    [fees],
  )

  const workedDuration = getActualOfferDuration(offer, category)
  const grossCompletedRevenue =
    workedDuration &&
    getGrossEarnings({
      duration: workedDuration.asHours(),
      pricePerHour: offer.data.initial.purchase.pricePerHour,
    })
  const hero24CompletedRevenue =
    grossCompletedRevenue &&
    convertToDecimalNumber(grossCompletedRevenue * (hero24Cut / 100))

  return (
    <Descriptions column={1}>
      <Descriptions.Item label="Gross fee revenue">
        {convertToDecimalNumber(materalRevenue.grossRevenue)} €
      </Descriptions.Item>
      <Descriptions.Item label="Gross purchase revenue">
        {convertToDecimalNumber(grossPurchaseRevenue)} €
      </Descriptions.Item>
      <Descriptions.Item label="Gross completed revenue">
        {grossCompletedRevenue
          ? `${convertToDecimalNumber(grossCompletedRevenue)} €`
          : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Hero24 fee revenue">
        {materalRevenue.hero24Revenue} €
      </Descriptions.Item>
      <Descriptions.Item label="Hero24 purchase revenue">
        {hero24PurchaseRevenue} €
      </Descriptions.Item>
      <Descriptions.Item label="Hero24 completed revenue">
        {hero24CompletedRevenue ? `${hero24CompletedRevenue} €` : "-"}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default Revenue
