import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  InputNumber,
  Button,
  Input,
} from "antd"
import FormItem from "antd/lib/form/FormItem"
import { Moment } from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { getCategories } from "../../../buyer/categories/selectors"
import { FormField } from "../../../common/form"
import { PROMOTION_DATE_FORMAT } from "../../constants"
import {
  selectActivePromotions,
  selectPlannedPromotions,
} from "../../store/selectors"
import { Promotion } from "../../types"
import { isActivePromotion } from "../../utils"
import {
  getCategoriesOptions,
  getDiscountFieldProps,
  isInvalidEndDate,
  isInvalidStartDate,
  PromotionToFormPromotion,
} from "./utils"

export type FormPromotionData = Omit<
  Promotion["data"],
  "startDate" | "endDate"
> & {
  startDate: Moment
  endDate: Moment
}

interface Props {
  promotion?: Promotion
  onFinish: (data: FormPromotionData) => void
}

const { TextArea } = Input
const { useForm } = Form
const { Option } = Select

const PromotionForm = ({ promotion, onFinish }: Props) => {
  const [form] = useForm()

  const initialValues = promotion
    ? PromotionToFormPromotion(promotion)
    : ({ discountFormat: "fixed" } as FormPromotionData)

  const [discountFormat, setDiscountFormat] = useState(
    initialValues.discountFormat,
  )
  const [startDate, setStartDate] = useState<Moment | null>(
    initialValues.startDate || null,
  )
  const [, forceUpdate] = useState({})

  const categories = useSelector(getCategories)
  const activePromotions = useSelector(selectActivePromotions)
  const plannedCategories = useSelector(selectPlannedPromotions)

  useEffect(() => {
    forceUpdate({})
    form.setFields([{ name: "discountFormat", touched: true }])
  }, [form])

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      initialValues={initialValues}
    >
      <Row gutter={15}>
        <Col span={10}>
          <FormField label="Category" required name="categoryId">
            <Select
              disabled={!!promotion}
              options={getCategoriesOptions(
                categories,
                activePromotions,
                plannedCategories,
              )}
            />
          </FormField>
        </Col>
        <Col span={7}>
          <FormField label="Discount format" required name="discountFormat">
            <Select
              onChange={(value) =>
                setDiscountFormat(value as Promotion["data"]["discountFormat"])
              }
            >
              <Option value="fixed">Fixed</Option>
              <Option value="percentage">Percentage</Option>
            </Select>
          </FormField>
        </Col>
        <Col span={7}>
          <FormField label="Discount" required name="discount">
            <InputNumber
              min={1}
              {...getDiscountFieldProps(discountFormat)}
              style={{ width: "100%" }}
            />
          </FormField>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormField label="Start date" required name="startDate">
            <DatePicker
              style={{ width: "100%" }}
              disabled={
                promotion &&
                isActivePromotion(
                  promotion.data.startDate,
                  promotion.data.endDate,
                )
              }
              format={PROMOTION_DATE_FORMAT}
              disabledDate={(startDate) => isInvalidStartDate(startDate)}
              onChange={(value) => {
                setStartDate(value)
                form.setFields([{ name: "endDate", value: null }])
                form.validateFields(["endDate"])
              }}
            />
          </FormField>
        </Col>
        <Col span={12}>
          <FormField label="End date" required name="endDate">
            <DatePicker
              style={{ width: "100%" }}
              disabled={!promotion && !startDate}
              format={PROMOTION_DATE_FORMAT}
              disabledDate={(endDate) =>
                isInvalidEndDate(endDate, startDate as Moment)
              }
            />
          </FormField>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormField label="Description" required name="description">
            <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
          </FormField>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={6}>
          <FormItem shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                type="primary"
                block
                disabled={
                  !form.isFieldsTouched(!promotion) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {!promotion ? "Add" : "Save"}
              </Button>
            )}
          </FormItem>
        </Col>
      </Row>
    </Form>
  )
}

export default PromotionForm
