import { SubscriptionsState } from "./types"
import * as fromActions from "./actionTypes"

export type SubscriptionsReducer = (
  state: SubscriptionsState,
  action: fromActions.GetSubscriptions | fromActions.RemoveSubscriptionActions,
) => SubscriptionsState

const INITIAL_STATE: SubscriptionsState = {
  subscriptions: {},
  loadingError: null,
}

const subscriptions: SubscriptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fromActions.GET_SUBSCRIPTIONS: {
      return {
        ...state,
        loadingError: null,
        subscriptions: action.payload,
      }
    }
    case fromActions.DEL_SUBSCRIPTIONS_SUCCESS: {
      if (state.subscriptions[action.payload.subscriptionId]) {
        const newState = {
          ...state,
          loadingError: null,
          subscriptions: {
            ...state.subscriptions,
          },
        }
        delete newState.subscriptions[action.payload.subscriptionId]
        return newState
      }
      return state
    }
    case fromActions.DEL_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        loadingError: action.payload.error,
      }
    }
    default:
      return state
  }
}

export default { subscriptions }
