import React, { useEffect } from "react"
import { Route, RouteProps, useLocation, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isCustomerAgentUser } from "../auth/selectors"
import { setEntryPathIfNotExist } from "./actions"

const AuthRoute = (props: RouteProps) => {
  const isAdmin = useSelector(isCustomerAgentUser)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setEntryPathIfNotExist(location.pathname))
  }, [location.pathname, dispatch])

  return !isAdmin ? <Redirect to="/" /> : <Route {...props} />
}

export default AuthRoute
