import { FC } from "react"
import { Table } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { News } from "../../graphql/fragments"
import columns from "./columns"
import expandedRowRender from "./expandedRowRender"
import {
  selectNewsList,
  selectNewsListFetching,
  selectNewsPagination,
} from "../../selectors"
import { NewsExpandIcon } from "./NewsExpandIcon"
import { computePageNumber } from "../../utils"
import { setNewsPaginationOffset } from "../../actions"

const NewsList: FC = () => {
  const loadingAllNews = useSelector(selectNewsListFetching)
  const newsList = useSelector(selectNewsList)
  const { limit, offset, total } = useSelector(selectNewsPagination)

  const dispatch = useDispatch()

  return (
    <Table<News>
      columns={columns()}
      dataSource={newsList}
      loading={loadingAllNews}
      rowKey={(news) => news.id}
      pagination={{
        total,
        pageSize: limit,
        current: computePageNumber({ offset, limit }),
        onChange: (page) => {
          dispatch(setNewsPaginationOffset((page - 1) * limit))
        },
      }}
      expandable={{
        expandedRowRender,
        expandIcon: ({ expanded, record, onExpand }) => (
          <NewsExpandIcon
            expanded={expanded}
            news={record}
            onExpand={onExpand}
          />
        ),
      }}
    />
  )
}

export default NewsList
