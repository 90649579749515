import { gql } from "@apollo/client"
import { NEWS_FRAGMENT } from "../../fragments"

export const QUERY = gql`
  ${NEWS_FRAGMENT}

  query GetNewsList($offset: Int, $limit: Int) {
    newsList(offset: $offset, limit: $limit) {
      edges {
        node {
          ...NewsInfo
        }
        cursor
      }
      total
      hasNextPage
      endCursor
    }
  }
`
