import { Store } from "../../core/types"
import { isActivePromotion, isPlannedPromotion } from "../utils"

export const selectPromotions = ({ promotions }: Store) => promotions

export const selectActivePromotions = ({ promotions }: Store) =>
  promotions.filter(({ data: { startDate, endDate } }) =>
    isActivePromotion(startDate, endDate),
  )

export const selectPlannedPromotions = ({ promotions }: Store) =>
  promotions.filter(({ data: { startDate } }) => isPlannedPromotion(startDate))
