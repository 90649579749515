import { gql } from "@apollo/client"

import { PlainOfferRequestQuestion } from "../../../types"
import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"
import {
  OFFER_REQUEST_QUESTION_OPTION_FRAGMENT,
  OfferRequestQuestionOption,
  OfferRequestQuestionOptionAdapter,
} from "./OfferRequestQuestionOptionInfo"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"
import { TRANSLATION_FIELD_FRAGMENT } from "../../../../common/graphql/fragments"

type QuestionType = "radio"

type PlainOfferRequestRadioQuestion = PlainOfferRequestQuestion & {
  type: QuestionType
}

export interface OfferRequestRadioQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  selectedOption?: Maybe<string>
  options: OfferRequestQuestionOption[]
}

export const OfferRequestRadioQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestRadioQuestion,
  OfferRequestRadioQuestion
>({
  toFirebase: (graphql) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(graphql),
    type: "radio" as QuestionType,
    selectedOption: graphql.selectedOption || null,
    options: graphql.options.map((option) =>
      OfferRequestQuestionOptionAdapter.toFirebase(option),
    ),
  }),
  toGraphQL: (firebase) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(firebase),
    type: "radio" as QuestionType,
    selectedOption: firebase.selectedOption,
    options: firebase.options.map((option) =>
      OfferRequestQuestionOptionAdapter.toGraphQL(option),
    ),
  }),
})

export const OFFER_REQUEST_RADIO_QUESTION_FRAGMENT = gql`
  ${OFFER_REQUEST_QUESTION_OPTION_FRAGMENT}
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestRadioQuestionInfo on OfferRequestRadioQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    selectedOption
    options {
      ...OfferRequestQuestionOptionInfo
    }
  }
`
