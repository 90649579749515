import React from "react"
import * as H from "history"
import { RouteProps } from "react-router-dom"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { getAuthUser } from "../selectors"

interface UserBlockedRouteProps {
  renderComponent: React.ReactElement
  exact?: boolean
  path: H.LocationDescriptor
  redirect: string
  signInWithGoogle?: () => void
}

export const UserBlockedRoute = (
  props: Partial<RouteProps & UserBlockedRouteProps>,
) => {
  const { renderComponent, exact, path, redirect, ...restProps } = props
  const user = useSelector(getAuthUser)

  if (!renderComponent || !redirect) {
    return null
  }

  if (user && !user.isAdmin) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(routeProps) => {
          return React.cloneElement(renderComponent, {
            ...routeProps,
            ...restProps,
          })
        }}
      />
    )
  } else {
    return <Redirect to={redirect} />
  }
}
