import firebase from "firebase/app"

import { FIREBASE_PATH_PROMOTIONS } from "../constants"

const unsubscribeFromPromotions = () => {
  const ref = firebase.database().ref(FIREBASE_PATH_PROMOTIONS)

  ref.off()
}

export default unsubscribeFromPromotions
