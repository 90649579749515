import { FC, useCallback, useMemo } from "react"
import { Col, InputNumber, Row, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { isNumber } from "lodash"

import { getSelectedHero24Cut } from "../../../../../../payments/selectors"
import { Maybe } from "../../../../../../common/types"
import { setHero24Cut } from "../../../../../../payments/actions"

const { Title } = Typography

const DEFAULT_CUT = 25

export const Hero24CutInput: FC = () => {
  const dispatch = useDispatch()

  const selectedHero24Cut = useSelector(getSelectedHero24Cut)

  const onChange = useCallback((value: Maybe<number | string>) => {
    if (isNumber(value)) {
      dispatch(setHero24Cut(value))
    }
  }, [])

  const value = useMemo(() => {
    return selectedHero24Cut ?? DEFAULT_CUT
  }, [selectedHero24Cut])

  return (
    <Col xs={24} lg={12}>
      <Row gutter={[24, 12]}>
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            Hero24 Cut
          </Title>
        </Col>
        <Col xs={24}>
          <InputNumber
            size="large"
            style={{ width: "100%" }}
            type="number"
            min={0}
            max={100}
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Col>
  )
}
