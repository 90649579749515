import React, { useCallback, useEffect, useState } from "react"
import { Descriptions, Card, Button, Modal } from "antd"
import { formatDateAndTime } from "../../utils"
import { Offer } from "../../../offers/list/types"
import { PurchaseForm } from "./PurchaseForm"
import { updateOfferPurchase } from "../../actions"
import { useDispatch } from "react-redux"
import {
  approveOfferExtension,
  removeOfferExtension,
} from "../../../offers/list/actions"

interface Props {
  offer: Offer
}

export const Purchases = (props: Props) => {
  const id = props.offer.id
  const initialPurchase = props.offer.data.initial.purchase
  const extensions = props.offer.data.extensions
    ? props.offer.data.extensions
    : []

  const [approving, setApproving] = useState(false)
  const [removing, setRemoving] = useState(false)

  const [pricePerHour, setPricePerHour] = useState(initialPurchase.pricePerHour)
  const [duration, setDuration] = useState(initialPurchase.duration)

  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const dispatch = useDispatch()
  const approveExtension = useCallback(async () => {
    setApproving(true)
    dispatch(approveOfferExtension(props.offer.id))
  }, [dispatch, props.offer.id])
  const removeExtension = useCallback(async () => {
    setRemoving(true)
    dispatch(removeOfferExtension(props.offer.id))
  }, [dispatch, props.offer.id])

  useEffect(() => {
    setApproving(false)
  }, [props.offer.timeToExtend])

  return (
    <Card style={{ background: "transparent" }} bordered={false} size="small">
      <Modal
        title="Edit purchase (First change it on offer request)"
        open={showEditModal}
        onCancel={() => setShowEditModal(false)}
        footer={false}
      >
        <PurchaseForm
          onSubmit={(data) => {
            dispatch(
              updateOfferPurchase(data, () => {
                const { pricePerHour = 0, duration = 0 } = data
                setShowEditModal(false)
                setPricePerHour(pricePerHour)
                setDuration(duration)
              }),
            )
          }}
          purchase={{ id, duration, pricePerHour }}
        />
      </Modal>
      <Descriptions
        key={initialPurchase.createdAt}
        layout="horizontal"
        size="small"
        bordered
        column={2}
        style={{
          background: "white",
        }}
        title="Initial purchase by customer"
      >
        <Descriptions.Item label="Time">
          {formatDateAndTime(initialPurchase.createdAt, "dateAndTimeFormat")}
        </Descriptions.Item>
        <Descriptions.Item label="Hours">
          {initialPurchase.duration}h
        </Descriptions.Item>
        <Descriptions.Item label="Price per Hour">
          {initialPurchase.pricePerHour} €/h
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {initialPurchase.pricePerHour * initialPurchase.duration}€
        </Descriptions.Item>
      </Descriptions>
      <Button
        onClick={() => setShowEditModal(true)}
        style={{ width: "100%", margin: "15px 0px" }}
      >
        Edit
      </Button>
      {extensions.map((purchase) => (
        <Descriptions
          key={purchase.createdAt}
          layout="horizontal"
          size="small"
          bordered
          column={2}
          style={{
            background: "white",
          }}
          title="Paid extension"
        >
          <Descriptions.Item label="Time">
            {formatDateAndTime(purchase.createdAt, "dateAndTimeFormat")}
          </Descriptions.Item>
          <Descriptions.Item label="Hours">
            {purchase.duration}h
          </Descriptions.Item>
          <Descriptions.Item label="Price per Hour">
            {purchase.pricePerHour} €/h
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {purchase.pricePerHour * purchase.duration}€
          </Descriptions.Item>
        </Descriptions>
      ))}
      {props.offer.timeToExtend ? (
        <Descriptions
          key="extension-request"
          layout="horizontal"
          size="small"
          bordered
          column={2}
          style={{
            background: "white",
          }}
          title="Pending extension request from doer"
          extra={
            <>
              <Button
                type="primary"
                disabled={approving}
                onClick={approveExtension}
              >
                {approving ? "Approving..." : "Approve"}
              </Button>
              <Button
                style={{ marginLeft: 15 }}
                type="default"
                disabled={removing}
                onClick={removeExtension}
              >
                {removing ? "Removing..." : "Remove"}
              </Button>
            </>
          }
        >
          <Descriptions.Item label="Hours">
            {props.offer.timeToExtend}h
          </Descriptions.Item>
          <Descriptions.Item label="Price per Hour">
            {props.offer.data.initial.purchase.pricePerHour} €/h
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {props.offer.data.initial.purchase.pricePerHour *
              props.offer.timeToExtend}
            €
          </Descriptions.Item>
          <Descriptions.Item label="Reason to extend">
            {props.offer.reasonToExtend}
          </Descriptions.Item>
        </Descriptions>
      ) : null}
    </Card>
  )
}
