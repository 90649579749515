import { FC, memo } from "react"
import { OfferRequest } from "../../types"
import { OfferRequestView } from "../../../../common/components/OfferRequest/OfferRequest"

export interface ExpandedRowProps {
  offerRequest: OfferRequest
  openQuestionsModal: (offerRequestId: string) => void
}

export const ExpandedRow: FC<ExpandedRowProps> = memo(
  ({ offerRequest, openQuestionsModal }) => {
    return (
      <OfferRequestView
        offerRequest={offerRequest}
        openQuestionsModal={openQuestionsModal}
      />
    )
  },
)
