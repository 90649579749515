import React from "react"
import { Subscription } from "../../../subscriptions/types"

interface Props {
  subscription: Subscription
}

const SubscriptionType = ({ subscription }: Props) => {
  if (!subscription) return null
  return (
    <span
      style={{
        fontSize: 14,
      }}
    >
      {subscription.interval.toUpperCase()}
    </span>
  )
}

export default SubscriptionType
