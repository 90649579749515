import { Button, MenuProps } from "antd"
import { MenuItemsKeys, StatusChangerModals } from "../constants"
import { useMemo } from "react"

export const useDropdownMenuItems = () => {
  const dropDownMenuItems: MenuProps["items"] = useMemo(
    () =>
      (Object.keys(StatusChangerModals) as MenuItemsKeys[]).map((key) => ({
        label: (
          <Button type="primary" block>
            {StatusChangerModals[key].buttonTitle}
          </Button>
        ),
        key,
      })),
    [],
  )

  return dropDownMenuItems
}
