import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AutoComplete, Button, DatePicker, Popover, Row, Space } from "antd"
import moment, { Moment } from "moment-timezone"
import { TableRowSelection } from "antd/lib/table/interface"

import { getSellerByIdSelector, getSellers } from "../../seller"
import { OfferList } from "../../offers/list/components/containers"
import { PURCHASE_INVOICE_WEEK_FORMAT } from "../constants"
import { useImportPurchaseInvoiceBatch } from "../api"
import { Offer } from "../../offers/list/types"
import {
  subscribeToOffersUpdate,
  unsubscribeFromOffersUpdate,
} from "../../offers/list/actions"

const PurchaseInvoice: FC = () => {
  const sellers = useSelector(getSellers)

  const [week, setWeek] = useState<Moment | null>(null)
  const [sellerId, setSellerId] = useState<string>()
  const [offerIds, setOfferIds] = useState<string[]>([])

  const seller = useSelector(getSellerByIdSelector(sellerId))

  const { importPurchaseInvoiceBatch } = useImportPurchaseInvoiceBatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(subscribeToOffersUpdate())

    return () => {
      unsubscribeFromOffersUpdate()
    }
  }, [dispatch])

  const [sellerSearch, setSellerSearch] = useState("")
  const [sellerValue, setSellerValue] = useState("")

  const sellerOptions = useMemo(
    () =>
      Object.entries(sellers)
        .map(([id, value]) => ({
          id,
          userName: value.userData.name.trim(),
          companyName: value.sellerData.companyName.trim(),
        }))
        .filter(({ userName, companyName }) =>
          [userName, companyName, userName]
            .join(" ")
            .toLowerCase()
            .includes(sellerSearch.toLowerCase()),
        )
        .map(({ id, userName, companyName }) => ({
          label: userName ? `${userName} (${companyName})` : companyName,
          value: id,
        }))
        .sort((left, right) => left.label.localeCompare(right.label)),
    [sellers, sellerSearch],
  )

  const sellerIdChangeHandler = useCallback(
    (value) => {
      const seller = sellers[value]

      const userName = seller.userData.name.trim()
      const companyName = seller.sellerData.companyName.trim()

      setSellerValue(userName ? `${userName} (${companyName})` : companyName)
      setSellerId(value.toString())
    },
    [sellers],
  )

  const dateRangeOnChangeHandler = useCallback((value: Moment | null) => {
    setWeek(value)
  }, [])

  const onSubmitHandler = useCallback(async () => {
    if (offerIds.length && week) {
      await importPurchaseInvoiceBatch({
        offerIds,
        week: week.format(PURCHASE_INVOICE_WEEK_FORMAT),
        weekComparingLocale: moment.locale(),
      })
      setWeek(null)
      setSellerId(undefined)
    }
  }, [importPurchaseInvoiceBatch, offerIds, week])

  const onChangeRowSelectionHandler: TableRowSelection<Offer>["onChange"] =
    useCallback((selectedKeys) => {
      setOfferIds(selectedKeys)
    }, [])

  const getDisabledDate = useCallback(
    (date: Moment) =>
      !!Object.keys(
        seller?.sellerData.weeksOfSentPurchaseInvoices || {},
      ).includes(date.format(PURCHASE_INVOICE_WEEK_FORMAT)),
    [seller],
  )

  const filterData = useMemo(
    () => ({
      sellerId,
      week,
    }),
    [sellerId, week],
  )
  return (
    <section style={{ textAlign: "center" }}>
      <h2>Purchase invoice</h2>
      <Row style={{ marginBottom: 15 }}>
        <Space>
          <DatePicker
            style={{ width: 350 }}
            value={week}
            picker={"week"}
            onChange={dateRangeOnChangeHandler}
            disabledDate={getDisabledDate}
          />
          <AutoComplete
            options={sellerOptions}
            style={{ width: 250, textAlign: "left" }}
            value={sellerValue}
            onChange={setSellerValue}
            onSelect={sellerIdChangeHandler}
            onSearch={setSellerSearch}
            searchValue={sellerSearch}
            placeholder="Select a seller"
            allowClear
            onClear={() => setSellerId(undefined)}
          />
          <Popover
            title={"You have to select a week, a seller, and offers first"}
          >
            <Button
              disabled={!seller || !week || !offerIds.length}
              onClick={onSubmitHandler}
            >
              Create a purchase invoice
            </Button>
          </Popover>
        </Space>
      </Row>
      <OfferList
        filterData={filterData}
        purchaseInvoiceMode
        onChangeRowSelectionHandler={onChangeRowSelectionHandler}
        selectedRowKeys={offerIds}
      />
    </section>
  )
}

export default PurchaseInvoice
