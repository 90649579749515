import { gql } from "@apollo/client"

import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"

export type Data = {
  [DEFAULT_GRAPHQL_RESPONSE]: boolean
}

export type SetIsApprovedByBuyerInput = {
  isApprovedByBuyer: boolean
  id: string
}

export type Variables = { input: SetIsApprovedByBuyerInput }

export const MUTATION = gql`
  mutation SetIsApprovedByBuyer($input: OfferRequestSetIsApprovedByBuyerInput!) {
    ${DEFAULT_GRAPHQL_RESPONSE}: setIsApprovedByBuyer(input: $input)
  }
`
