import React from "react"
import { ColumnsType } from "antd/lib/table"
import { MessageLog } from "../../types"
import { formatDateAndTime } from "../../../../common/utils"
import { ColumnText } from "../../../../common/components/listComponent/ColumnText"
import { Button, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"

type Column = ColumnsType<MessageLog>

const getColumns = () => {
  const dateColumn: Column[0] = {
    title: "Date",
    render: (log: MessageLog) => (
      <ColumnText>
        {formatDateAndTime(log.createdAt, "dateAndTimeFormat")}
      </ColumnText>
    ),
    key: "paymentTransaction.createdAt",
    defaultSortOrder: "descend",
    sorter: (logA: MessageLog, logB: MessageLog) => {
      const a = logA.createdAt
      const b = logB.createdAt
      return a && b ? (a > b ? 1 : -1) : a ? 1 : -1
    },
  }

  const statusColumn: Column[0] = {
    title: "Status",
    render: (log: MessageLog) => <ColumnText>{log.status}</ColumnText>,
    key: "paymentTransaction.status",
  }

  const receiverColumn: Column[0] = {
    title: "Receiver",
    render: (log: MessageLog) => <ColumnText>{log.receiverId}</ColumnText>,
    key: "paymentTransaction.buyerId",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by id`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, log: MessageLog) => {
      return typeof value === "string"
        ? log.receiverId.toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const messageColumn: Column[0] = {
    title: "Message",
    render: (log: MessageLog) => (
      <ColumnText>{log.message.substr(0, 20)}...</ColumnText>
    ),
    key: "message",
  }

  const phoneColumn: Column[0] = {
    title: "Phone",
    render: (log: MessageLog) => (
      <ColumnText>{log.channel === "sms" && log.phoneNumber}</ColumnText>
    ),
    key: "phone",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by phone`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, log: MessageLog) => {
      return typeof value === "string" && log.channel === "sms"
        ? log.phoneNumber.toString().toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const emailColumn: Column[0] = {
    title: "Email",
    render: (log: MessageLog) => (
      <ColumnText>{log.channel === "email" && log.email}</ColumnText>
    ),
    key: "email",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by phone`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, log: MessageLog) => {
      return typeof value === "string" && log.channel === "email"
        ? log.email.toString().toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  return [
    dateColumn,
    statusColumn,
    receiverColumn,
    emailColumn,
    phoneColumn,
    messageColumn,
  ]
}

export default getColumns
