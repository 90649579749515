import { OfferRequestDB, STRIPE_PAYMENT_STATUS } from "hero24-types"

import { gql } from "@apollo/client"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

type StripePaymentDB = Exclude<OfferRequestDB["stripePayment"], undefined>

export type OfferRequestStripePayment = {
  updatedAt: number
  status?: STRIPE_PAYMENT_STATUS
  link?: Maybe<string>
  message?: Maybe<string>
}

export const OfferRequestStripePaymentAdapter = new FirebaseAdapter<
  StripePaymentDB,
  OfferRequestStripePayment
>({
  toFirebase: ({ updatedAt, status, link, message }) => ({
    updatedAt,
    status: status ?? undefined,
    link: link ?? undefined,
    message: message ?? undefined,
  }),
  toGraphQL: ({ updatedAt, status, link, message }) => ({
    updatedAt,
    status,
    link,
    message,
  }),
})

export const OFFER_REQUEST_STRIPE_PAYMENT_FRAGMENT = gql`
  fragment OfferRequestStripePaymentInfo on OfferRequestStripePaymentDto {
    updatedAt
    status
    link
    message
  }
`
