import React from "react"
import { Seller } from "../../../types"
import { ColumnsType } from "antd/lib/table"
import { SellerName } from "../../../../common/components/listComponent/Seller"
import Text from "antd/lib/typography/Text"
import { SearchOutlined } from "@ant-design/icons"
import { Button, Input, Space } from "antd"

type Column = ColumnsType<Seller>

const getColumns: (sellers: Seller[]) => Column = () => {
  const nameColumn: Column[0] = {
    title: "Company Name",
    key: "sellerData.companyName",
    render: (seller: Seller) => <SellerName seller={seller} />,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.sellerData.companyName.localeCompare(
        sellerB.sellerData.companyName,
      ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by company name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, seller: Seller) => {
      return typeof value === "string"
        ? seller.sellerData.companyName
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const emailColumn: Column[0] = {
    title: "Email",
    key: "selerData.companyEmail",
    render: (seller: Seller) => <Text>{seller.sellerData.companyEmail}</Text>,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.sellerData.companyEmail.localeCompare(
        sellerB.sellerData.companyEmail,
      ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by company name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, seller: Seller) => {
      return typeof value === "string"
        ? seller.sellerData.companyEmail
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const phoneColumn: Column[0] = {
    title: "Phone",
    key: "userData.phone",
    render: (seller: Seller) => <Text>{seller.userData.phone}</Text>,
    sorter: (sellerA: Seller, sellerB: Seller) =>
      sellerA.userData.phone.localeCompare(sellerB.userData.phone),
  }

  const averageColumn: Column[0] = {
    title: "Average review",
    key: "averageReview",
    align: "center",
    render: (seller: Seller) => <Text>{seller.averageReview?.toFixed(2)}</Text>,
    defaultSortOrder: "descend",
    sorter: (sellerA: Seller, sellerB: Seller) =>
      (sellerA.averageReview || 0) > (sellerB.averageReview || 0) ? -1 : 1,
  }

  return [nameColumn, emailColumn, phoneColumn, averageColumn]
}

export default getColumns
