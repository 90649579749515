import {
  TRANSLATION_FIELD_FRAGMENT,
  TranslationField,
  TranslationFieldAdapter,
} from "../../../../common/graphql/fragments"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"
import { PlainOfferRequestQuestionOption } from "../../../types"

import { gql } from "@apollo/client"

export interface OfferRequestQuestionOption {
  id: string
  name?: Maybe<TranslationField>
  order?: Maybe<number>
  // it will be used to tackle circular deps in graphql. This is custom ID, generated on the go.
  questions?: Maybe<string[]>
  checked?: Maybe<boolean>
}

export const OfferRequestQuestionOptionAdapter = new FirebaseAdapter<
  PlainOfferRequestQuestionOption,
  OfferRequestQuestionOption
>({
  toFirebase: (graphql) => ({
    id: graphql.id,
    name: graphql.name
      ? TranslationFieldAdapter.toFirebase(graphql.name)
      : null,
    questions: graphql.questions || null,
    order: graphql.order ?? null,
    checked: graphql.checked ?? null,
  }),
  toGraphQL: (firebase) => ({
    id: firebase.id,
    checked: firebase.checked,
    name: firebase.name && TranslationFieldAdapter.toGraphQL(firebase.name),
    order: firebase.order,
    questions: firebase.questions,
  }),
})

export const OFFER_REQUEST_QUESTION_OPTION_FRAGMENT = gql`
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestQuestionOptionInfo on OfferRequestQuestionOptionDto {
    id
    name {
      ...TranslationFieldInfo
    }
    order
    questions
    checked
  }
`
