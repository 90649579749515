import { Select, Spin } from "antd"
import { DebouncedFunc } from "lodash"
import { FC, useMemo } from "react"
import { User } from "../../../user/types"
import renderSearchSelectOption from "./components/SearchSelectOption"

interface SearchUserProps {
  handleChange: (value: string) => void
  onSearchUsers: DebouncedFunc<(value: string) => void>
  users: User[]
  loading: boolean
  disabled?: boolean
  selectedUserId?: string
}

const SearchUser: FC<SearchUserProps> = ({
  handleChange,
  onSearchUsers,
  selectedUserId,
  loading,
  users,
  disabled = false,
}) => {
  const selectOptions = useMemo(() => {
    const options = Object.values(users).map((user) =>
      renderSearchSelectOption({ user, users }),
    )

    return options.length > 0 ? options : null
  }, [users])

  return (
    <Select
      size="large"
      showSearch
      disabled={disabled}
      value={selectedUserId}
      placeholder="Search user by email"
      style={{ width: "100%" }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearchUsers}
      onChange={handleChange}
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : null}
      dropdownMatchSelectWidth={false}
    >
      {selectOptions}
    </Select>
  )
}

export default SearchUser
