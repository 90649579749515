import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { durationChange } from "../../../actions"

import { Select, Row, Col } from "antd"

import { ListPicker as IListPicker } from "../../../types"

const { Option } = Select

interface ListPickerProps {
  question: IListPicker
  isSubQuestion?: boolean
}

const durations: number[] = [...Array(200)].map((item, idx) => 0.5 * (idx + 1))

const ListPicker = ({ question, isSubQuestion }: ListPickerProps) => {
  const dispatch = useDispatch()

  const placeholder = question.placeholder
    ? typeof question.placeholder === "object"
      ? question.placeholder.en
      : question.placeholder
    : isSubQuestion
    ? question.name
      ? question.name.en
      : ""
    : ""

  const options = useMemo(() => {
    return durations.map((item: number, idx: number) => {
      return (
        <Option key={idx} value={item}>
          {item}
        </Option>
      )
    })
  }, [])

  const handleChange = (value: number): void => {
    dispatch(
      durationChange({
        questionId: question.id,
        value,
      }),
    )
  }

  return (
    <Row>
      <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
        <Select
          size="large"
          value={question.value}
          placeholder={placeholder}
          onChange={handleChange}
          style={{ width: "100%" }}
          filterOption={false}
          defaultActiveFirstOption={false}
        >
          {options.length > 0 ? options : null}
        </Select>
      </Col>
    </Row>
  )
}

export default ListPicker
