import firebase from "firebase/app"
import { Dispatch } from "redux"
import { GET_PAYMENT_TRANSACTIONS } from "./actionTypes"
import { PaymentTransaction } from "./types"
import { FIREBASE_PATH_PAYMENT_TRANSACTIONS } from "./constants"
import { PaymentTransactionDB } from "hero24-types"

export interface GetPaymentTransactions {
  type: typeof GET_PAYMENT_TRANSACTIONS
  payload: {
    [key: string]: PaymentTransaction
  }
}

export const getAllPaymentTransactions = () => async (dispatch: Dispatch) => {
  const paymentTransactionsRef = await firebase
    .database()
    .ref(FIREBASE_PATH_PAYMENT_TRANSACTIONS)

  const paymentTransactionsSnapshot = await paymentTransactionsRef
    .limitToLast(10000)
    .once("value")

  const paymentTransactions: {
    [id: string]: PaymentTransactionDB
  } = paymentTransactionsSnapshot.val()

  const paymentTransactionsState = Object.fromEntries(
    Object.entries(paymentTransactions).map((paymentTransactionEntry) => {
      return [
        paymentTransactionEntry[0],
        {
          id: paymentTransactionEntry[0],
          ...paymentTransactionEntry[1],
        },
      ]
    }),
  )

  const action: GetPaymentTransactions = {
    type: GET_PAYMENT_TRANSACTIONS,
    payload: paymentTransactionsState,
  }

  dispatch(action)
}
