import { gql } from "@apollo/client"
import { OfferRequestDB, OFFER_REQUEST_STATUS } from "hero24-types"

import {
  OFFER_REQUEST_DATA_CHANGES_ACCEPTED_FRAGMENT,
  OfferRequestDataChangesAccepted,
  OfferRequestDataChangesAcceptedAdapter,
} from "./OfferRequestDataChangesAcceptedInfo"
import {
  OFFER_REQUEST_DATA_INITIAL_FRAGMENT,
  OfferRequestDataInitial,
  OfferRequestDataInitialAdapter,
} from "./OfferRequestDataInitialInfo"
import {
  OFFER_REQUEST_DATA_PICK_SERVICE_PROVIDER_FRAGMENT,
  OfferRequestDataPickServiceProvider,
  OfferRequestDataPickServiceProviderAdapter,
} from "./OfferRequestDataPickServiceProviderInfo"
import {
  OFFER_REQUEST_DATA_REQUESTED_CHANGES_FRAGMENT,
  OfferRequestDataRequestedChanges,
  OfferRequestDataRequestedChangesAdapter,
} from "./OfferRequestDataRequestedChangesInfo"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

type OfferRequestDataDB = OfferRequestDB["data"]

export type OfferRequestData = {
  actualStartTime?: Maybe<number>
  reviewed?: Maybe<boolean>
  status: OFFER_REQUEST_STATUS
  initial: OfferRequestDataInitial
  lastAgreedStartTime?: Maybe<number>
  requestedChanges?: Maybe<OfferRequestDataRequestedChanges>
  changesAccepted?: Maybe<OfferRequestDataChangesAccepted>
  pickServiceProvider?: Maybe<OfferRequestDataPickServiceProvider>
}

export const OfferRequestDataAdapter = new FirebaseAdapter<
  OfferRequestDataDB,
  OfferRequestData
>({
  toFirebase: (graphql) => ({
    reviewed: graphql.reviewed ?? undefined,
    status: graphql.status,
    changesAccepted: graphql.changesAccepted
      ? OfferRequestDataChangesAcceptedAdapter.toFirebase(
          graphql.changesAccepted,
        )
      : undefined,
    initial: OfferRequestDataInitialAdapter.toFirebase(graphql.initial),
    requestedChanges: graphql.requestedChanges
      ? OfferRequestDataRequestedChangesAdapter.toFirebase(
          graphql.requestedChanges,
        )
      : undefined,
    pickServiceProvider: graphql.pickServiceProvider
      ? OfferRequestDataPickServiceProviderAdapter.toFirebase(
          graphql.pickServiceProvider,
        )
      : undefined,
    actualStartTime: graphql.actualStartTime ?? undefined,
    lastAgreedStartTime: graphql.lastAgreedStartTime ?? undefined,
  }),
  toGraphQL: (firebase) => ({
    reviewed: firebase.reviewed,
    changesAccepted: firebase.changesAccepted
      ? OfferRequestDataChangesAcceptedAdapter.toGraphQL(
          firebase.changesAccepted,
        )
      : undefined,
    status: firebase.status,
    initial: OfferRequestDataInitialAdapter.toGraphQL(firebase.initial),
    requestedChanges: firebase.requestedChanges
      ? OfferRequestDataRequestedChangesAdapter.toGraphQL(
          firebase.requestedChanges,
        )
      : null,

    pickServiceProvider:
      firebase.pickServiceProvider &&
      OfferRequestDataPickServiceProviderAdapter.toGraphQL(
        firebase.pickServiceProvider,
      ),
    actualStartTime: firebase.actualStartTime,
    lastAgreedStartTime: firebase.lastAgreedStartTime,
  }),
})

export const OFFER_REQUEST_DATA_FRAGMENT = gql`
  ${OFFER_REQUEST_DATA_INITIAL_FRAGMENT}
  ${OFFER_REQUEST_DATA_REQUESTED_CHANGES_FRAGMENT}
  ${OFFER_REQUEST_DATA_CHANGES_ACCEPTED_FRAGMENT}
  ${OFFER_REQUEST_DATA_PICK_SERVICE_PROVIDER_FRAGMENT}

  fragment OfferRequestDataInfo on OfferRequestDataDto {
    status
    reviewed
    actualStartTime
    lastAgreedStartTime
    initial {
      ...OfferRequestDataInitialInfo
    }
    requestedChanges {
      ...OfferRequestDataRequestedChangesInfo
    }
    changesAccepted {
      ...OfferRequestDataChangesAcceptedInfo
    }
    pickServiceProvider {
      ...OfferRequestDataPickServiceProviderInfo
    }
  }
`
