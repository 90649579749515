import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { News } from "../../fragments"
import { MUTATION } from "./mutation"

type Data = {
  removeNews: News
}

type Variables = {
  id: string
}

type Args = [string]

export const removeNews: ApolloThunkMutation<Data, Args> = async (
  state,
  id,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables: {
      id,
    },
  })

  return {
    removeNewsData: data?.removeNews,
    removeNewsErrors: errors,
  }
}
