import firebase from "firebase/app"
import React, { useEffect, useState } from "react"
import { Timeline } from "antd"
import { ChangeLog } from "hero24-types"
import { useSelector } from "react-redux"
import { Store } from "../../../../core/types"
import { FeeLogItem } from "./FeeLog"

interface Props {
  id: string
}

const LOGS_PATH = "feesLogs"

const subscribeToFeesLogs = (
  id: string,
  setLogs: (logs: ChangeLog[]) => void,
) => {
  const ref = firebase.database().ref(LOGS_PATH).child(id)
  ref.on("value", (logsSnapshot) => {
    const logs = logsSnapshot.val() as { [key: string]: ChangeLog } | null
    if (logs) {
      setLogs(Object.entries(logs).map((logEntry) => logEntry[1]))
    }
  })
}

const unSubscribeToFeesLogs = (id: string) => {
  firebase.database().ref(LOGS_PATH).child(id).off()
}

export const FeesLog = ({ id }: Props) => {
  const [feesLog, setFeesLog] = useState<ChangeLog[]>([])
  const users = useSelector((store: Store) => {
    return store.users
  })

  useEffect(() => {
    subscribeToFeesLogs(id, (logs) => setFeesLog(logs))
    return () => {
      unSubscribeToFeesLogs(id)
    }
  }, [id, setFeesLog])

  const allLogs = feesLog.sort((logA, logB) => (logA.time > logB.time ? 1 : -1))

  return (
    <Timeline mode="left">
      {allLogs.map((log) => (
        <FeeLogItem log={log} users={users} />
      ))}
    </Timeline>
  )
}
