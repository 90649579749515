import firebase from "firebase/app"

import { FIREBASE_PATH_OFFER_REQUESTS } from "../../../../../offerRequests/list/constants"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import { FIREBASE_NULL } from "../../../../constants"
import {
  PaymentService,
  SendingChannel,
} from "../../../../../payments/constants"
import { PaidStatus } from "hero24-types"

export const markOfferRequestPrepaid = async (offerRequestId: string) => {
  await firebase
    .database()
    .ref(FIREBASE_PATH_OFFER_REQUESTS)
    .child(offerRequestId)
    .child("data")
    .child("initial")
    .child("prepaid")
    .set(PaidStatus.PAID)
}

type ChangeOfferRequestPrePayWithValues = {
  prePayWith: PaymentService
  sendInvoiceWith: SendingChannel
  postpaid: "yes" | "no"
}

type ChangeOfferRequestPrePayWithProps = {
  offerRequestId: string
  before: Partial<ChangeOfferRequestPrePayWithValues>
  after: ChangeOfferRequestPrePayWithValues
}

// TODO: make this logic simple during payments migrating on server
export const changeOfferRequestPrePayWith = async (
  props: ChangeOfferRequestPrePayWithProps,
) => {
  const { offerRequestId, before, after } = props

  const { prePayWith, sendInvoiceWith, postpaid } = after

  const initialDataRef = firebase
    .database()
    .ref(FIREBASE_PATH_OFFER_REQUESTS)
    .child(offerRequestId)
    .child("data")
    .child("initial")

  let prepaid: PaidStatus = PaidStatus.WAITING

  if (
    prePayWith === (PaymentService.STRIPE || PaymentService.NETVISOR) &&
    postpaid === "yes"
  ) {
    prepaid = PaidStatus.PAID
  }

  const updates: Partial<OfferRequest["data"]["initial"]> = {
    prePayWith,
    postpaid,
    sendInvoiceWith,
    prepaid,
  }

  // This is temporary way for changing payment method
  // We have 2 handler on server: prePayWith.onCreate and sendInvoiceWith.onUpdate
  // They both sends email during creation/changes
  // So if sendInvoiceWith exist and its different from their updated value we don't need to do any additional things
  // The updates will send message
  if (before.sendInvoiceWith && before.sendInvoiceWith !== sendInvoiceWith) {
    await initialDataRef.update(updates)
    return
  }
  // If sendInvoiceWith doesn't exist, we should remove prePayWith and create it again to trigger the creation handler
  // Which will send message to buyer
  updates.prePayWith = FIREBASE_NULL

  await initialDataRef.update(updates)
  await initialDataRef.update({ prePayWith })
}
