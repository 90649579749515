import { Store } from "../core/types"
import _ from "lodash"

export const getSelectedFixedPrice = (state: Store) => state.payments.fixedPrice

export const getSelectedFixedDuration = (state: Store) =>
  state.payments.fixedDuration

export const getSelectedServiceProviderVAT = (state: Store) =>
  state.payments.serviceProviderVAT

export const getSelectedCustomerVAT = (state: Store) =>
  state.payments.customerVAT

export const getSelectedHero24Cut = (state: Store) => state.payments.hero24Cut

export const getSelectedMinimumDuration = (state: Store) =>
  state.payments.minimumDuration

export const getSubscriptionType = (state: Store) =>
  state.payments.subscriptionType

export const getSubscriptionStatus = (state: Store) =>
  state.payments.isSubscriptionEnabled

export const hasValidFixedPriceSelected = (state: Store) =>
  state.payments.fixedPrice && _.isNumber(state.payments.fixedPrice)

export const hasValidFixedDurationSelected = (state: Store) =>
  state.payments.fixedDuration && _.isNumber(state.payments.fixedDuration)

export const selectPlatformProvider = (state: Store) =>
  state.payments.platformProvider
export const selectPrePayWith = (state: Store) => state.payments.prePayWith
export const selectPostpaid = (state: Store) => state.payments.postpaid
export const selectSendInvoiceWith = (state: Store) =>
  state.payments.sendInvoiceWith
