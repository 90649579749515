import React, { useState } from "react"
import moment from "moment-timezone"
import { Row, Col, DatePicker } from "antd"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { getDateQuestion } from "../../../offerRequests/utils"
import { useDispatch } from "react-redux"
import { setOfferRequestTime } from "../../../offerRequests/list/actions"

interface Props {
  offerRequest: OfferRequest
}

export const PreferredTime = (props: Props) => {
  const dateQuestion = getDateQuestion(props.offerRequest)
  const [preferredTime, setPreferredTime] = useState(
    dateQuestion?.preferredTime,
  )
  const dispatch = useDispatch()
  const changePreferredTime = (preferredTime: number) => {
    dispatch(
      setOfferRequestTime({
        id: props.offerRequest.id,
        preferredTime,
      }),
    )
    setPreferredTime(preferredTime)
  }

  return (
    <Row>
      <Col xs={24}>
        <DatePicker
          style={{ minWidth: 150 }}
          showTime
          onChange={(newPreferredTime) => {
            if (newPreferredTime) {
              changePreferredTime(Number(newPreferredTime.format("x")))
            }
          }}
          value={preferredTime ? moment(preferredTime, "x") : null}
          disabled={["completed", "accepted"].includes(
            props.offerRequest.data.status,
          )}
          clearIcon={false}
          showSecond={false}
        />
      </Col>
    </Row>
  )
}
