import React from "react"
import Text from "antd/lib/typography/Text"
import { User } from "../../../user/types"

interface Props {
  user?: User
}

export const UserPhone = ({ user }: Props) => (
  <Text
    style={{
      textTransform: "capitalize",
    }}
  >
    {user ? user.data.phone : ""}
  </Text>
)
