import React from "react"

import PromotionForm, {
  FormPromotionData,
} from "../../components/PromotionForm"
import { FormPromotionToPromotionDB } from "../../components/PromotionForm/utils"
import { addPromotion } from "../../queries"

export interface AddPromotionFormProps {
  closeModal?: () => void
}

const AddPromotionForm = ({ closeModal }: AddPromotionFormProps) => {
  const onFinish = (data: FormPromotionData) => {
    const promotionDB = FormPromotionToPromotionDB(data)

    addPromotion(promotionDB)

    closeModal && closeModal()
  }

  return <PromotionForm onFinish={onFinish} />
}

export default AddPromotionForm
