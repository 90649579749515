import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Modal, notification, Popover } from "antd"

import { QuestionList } from "../../../../buyer/questions/components"
import { getOfferRequestCategory } from "../../../../buyer/categories/selectors"
import { setOfferRequestQuestions } from "../../../../offerRequests/list/actions"
import {
  categoryHasInvalidQuestions,
  getOfferRequestQuestions,
  getQuestionsForOfferRequest,
} from "../../../../buyer/questions/selectors"
import { updateQuestions } from "../../../../buyer/questions/actions"

interface EditQuestionProps {
  isOpen: boolean
  closeModal: () => void
  offerRequestId: string
}

const EditQuestionModal = ({
  isOpen,
  closeModal,
  offerRequestId,
}: EditQuestionProps) => {
  const dispatch = useDispatch()

  const category = useSelector(getOfferRequestCategory(offerRequestId))
  const questions = useSelector(getOfferRequestQuestions(offerRequestId))
  const categoryQuestions = useSelector(
    getQuestionsForOfferRequest(category?.id),
  )
  const hasQuestionErrors = useSelector(
    categoryHasInvalidQuestions(category?.id),
  )

  useEffect(() => {
    if (questions && category) {
      dispatch(updateQuestions(questions, category))
    }
  }, [dispatch, questions, category])

  if (!questions || !category || !categoryQuestions) {
    return null
  }

  const onSubmit = () => {
    closeModal()

    dispatch(setOfferRequestQuestions(categoryQuestions, offerRequestId))

    notification.success({
      message: "Questions was changed successfully",
      duration: 3,
    })
  }

  const onCancel = () => {
    closeModal()
  }

  const renderSubmitBtn = () => {
    return hasQuestionErrors ? (
      <Popover content="Please fill the missing details">
        <Button
          style={{ marginTop: 24 }}
          size="large"
          type="primary"
          block
          disabled
        >
          Submit
        </Button>
      </Popover>
    ) : (
      <Button
        style={{ marginTop: 24 }}
        size="large"
        type="primary"
        block
        onClick={onSubmit}
      >
        Submit
      </Button>
    )
  }

  return (
    <Modal
      title="Edit questions"
      open={isOpen}
      onCancel={onCancel}
      footer={false}
    >
      <QuestionList category={category} excludeRegExp={/.*when/} />
      {renderSubmitBtn()}
    </Modal>
  )
}

export default EditQuestionModal
