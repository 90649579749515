import { gql } from "@apollo/client"
import { AddressesAnswered } from "hero24-types"
import { FirebaseAdapter } from "../../../../common/utilities"
import {
  ADDRESS_FRAGMENT,
  Address,
  AddressAdapter,
} from "../../../../common/graphql/fragments"

export type BasicAddresses = {
  type: "basic"
  main: Address
}

export const BasicAddressesAdapter = new FirebaseAdapter<
  AddressesAnswered & { type: "basic" },
  BasicAddresses
>({
  toFirebase: ({ type, main }) => ({
    type,
    main: AddressAdapter.toFirebase(main),
  }),
  toGraphQL: ({ type, main }) => ({
    type,
    main: AddressAdapter.toGraphQL(main),
  }),
})

export const BASIC_ADDRESSES_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}

  fragment BasicAddressesInfo on BasicAddressesDto {
    type
    main {
      ...AddressInfo
    }
  }
`
