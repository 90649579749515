import * as TYPES from "./actionTypes"
import { MessageLogsState, MessageLogsReducer } from "./types"

export const INITIAL_STATE: MessageLogsState = {
  messageLogs: {},
}

const messageLogs: MessageLogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.NEW_MESSAGE_LOG: {
      return {
        ...state,
        messageLogs: {
          ...state.messageLogs,
          [action.payload.id]: action.payload,
        },
      }
    }
    default:
      return state
  }
}

export default { messageLogs }
