import React, { FC, useCallback, useState } from "react"
import { Input, Space, Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { updateChatsFilters } from "../../reducer"

type Props = {
  confirm: () => void
}

const OrderIdColumnFilter: FC<Props> = ({ confirm }) => {
  const [orderIdSearch, setOrderIdSearch] = useState("")
  const dispatch = useDispatch()

  const confirmHandler = useCallback(() => {
    dispatch(
      updateChatsFilters({
        orderIdSearch,
      }),
    )
    confirm()
  }, [orderIdSearch, dispatch, confirm])

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search by Order Id`}
        value={orderIdSearch}
        onChange={(e) => setOrderIdSearch(e.target.value || "")}
        onPressEnter={confirmHandler}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={confirmHandler}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setOrderIdSearch("")
            dispatch(
              updateChatsFilters({
                orderIdSearch: undefined,
              }),
            )
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
}
export default OrderIdColumnFilter
