import routes from "./routes"
import sideBarItems from "./sideBarItems"
import { mergeModules } from "../core"
import reducer from "./reducer"

export default mergeModules({
  sideBarItems,
  routes,
  reducer,
})
