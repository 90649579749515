import { ThunkDispatch } from "redux-thunk"

import { handleLoading } from "./actions"
import { User, UsersDB } from "./types"
import { Store } from "../core"
import { getUsersThunk } from "./graphql/queries"
import { UserAdapter } from "./graphql/fragments"

export const normalizeUsers = (users: UsersDB): User[] => {
  if (Object.keys(users).length === 0) {
    return []
  }
  return Object.keys(users).map((key) => {
    return {
      id: key,
      mergedTo: users[key].mergedTo,
      data: users[key].data,
    }
  })
}

export const handleSearchUsers = async (
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>,
  state: Store,
) => {
  if (!value) {
    return {}
  }

  try {
    dispatch(handleLoading(true))

    const { usersData } = await getUsersThunk(state, {
      search: value,
    })

    if (!usersData) {
      throw new Error("Users is not found")
    }

    return Object.fromEntries(
      usersData.edges.map(({ node, cursor }) => [
        cursor,
        UserAdapter.toFirebase(node),
      ]),
    )
  } finally {
    dispatch(handleLoading(false))
  }
}
