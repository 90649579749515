import { SortOrder } from "../../../../common/constants"
import { ChatsOrderColumn } from "../../../constants"
import { ChatsOrderBy } from "../../../types"

export const getAntOrder = (
  order?: SortOrder,
): "ascend" | "descend" | undefined => {
  if (order === SortOrder.ASC) {
    return "ascend"
  }

  if (order === SortOrder.DESC) {
    return "descend"
  }
}

export const getAntOrderByColumn = (
  ordersBy: ChatsOrderBy[],
  targetColumn: ChatsOrderColumn,
) => getAntOrder(ordersBy.find(({ column }) => column === targetColumn)?.order)
