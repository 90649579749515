import { ApolloThunkQuery } from "../../../../apolloClient/types"
import { apolloQuery } from "../../../../apolloClient/utils"
import { GraphQLPagination } from "../../../../common/graphql/types"
import { News } from "../../fragments"
import { QUERY } from "./query"

type Data = {
  newsList: GraphQLPagination<News>
}

type Variables = { offset?: number; limit?: number }

type Args = [Variables]

type Fields = "data" | "error"

export const getNewsList: ApolloThunkQuery<Data, Args, Fields> = async (
  state,
  variables,
) => {
  const { data, error } = await apolloQuery<Data, Variables>({
    state,
    query: QUERY,
    variables,
  })

  return {
    newsListData: data?.newsList,
    newsListError: error,
  }
}
