import { Link } from "react-router-dom"
import { Menu, MenuProps } from "antd"
import { SideBar } from "../../core/types"

interface SiderMenuProps {
  sideBarItems?: SideBar[]
}

type MenuItem = Required<MenuProps>["items"][number]

const SiderMenu = ({ sideBarItems }: SiderMenuProps) => {
  const items: MenuItem[] =
    sideBarItems?.map<MenuItem>(({ icon, name, path }) => ({
      key: path,
      icon,
      label: (
        <Link style={{ color: "#ffffff" }} to={`/${path}`}>
          {name}
        </Link>
      ),
    })) || []

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["offer_request"]}
      defaultOpenKeys={["sub1"]}
      items={items}
    />
  )
}

export default SiderMenu
