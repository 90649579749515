import React from "react"
import { Descriptions } from "antd"
import Text from "antd/lib/typography/Text"
import { User } from "../../../users/list/types"
import { UserInfo } from "./Info"
import { DateAndTime } from "../listComponent/DateAndTime"

interface Props {
  user: User
}

export const UserView = (props: Props) => {
  return (
    <>
      <Descriptions title="Ids">
        <Descriptions.Item label="UserID">{props.user.id}</Descriptions.Item>
        <Descriptions.Item label="NetvisorCustomerID">
          {props.user.netvisorCustomerId}
        </Descriptions.Item>
        <Descriptions.Item label="NetvisorSellerID">
          {props.user.netvisorSellerId}
        </Descriptions.Item>
        <Descriptions.Item label="StripeCustomerIdD">
          {props.user.stripeCustomerId}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Info">
        <Descriptions.Item>
          {props.user.data.deletedAt ? (
            <Text style={{ fontWeight: 800 }}>
              All the personal information deleted by user at{" "}
              <DateAndTime time={props.user.data.deletedAt} />
            </Text>
          ) : (
            ""
          )}
          <br />
          <br />
          <br />
          <UserInfo user={props.user} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
