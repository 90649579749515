import { gql } from "@apollo/client"
import { SuitableTimes } from "hero24-types"

import { PlainOfferRequestQuestion } from "../../../types"
import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"
import {
  SUITABLE_TIME_FRAGMENT,
  SuitableTime,
  SuitableTimeAdapter,
} from "../SuitableTimeInfo"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"
import { TRANSLATION_FIELD_FRAGMENT } from "../../../../common/graphql/fragments"

type QuestionType = "date"

type PlainOfferRequestDateQuestion = Extract<
  PlainOfferRequestQuestion,
  { type: QuestionType }
>

export interface OfferRequestDateQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  preferredTime?: Maybe<number>
  suitableTimesCount?: Maybe<number>
  suitableTimes?: Maybe<SuitableTime[]>
}

export const OfferRequestDateQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestDateQuestion,
  OfferRequestDateQuestion
>({
  toFirebase: (graphql) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(graphql),
    type: "date" as QuestionType,
    preferredTime: graphql.preferredTime ?? null,
    suitableTimesCount: graphql.suitableTimesCount ?? null,
    suitableTimes: graphql.suitableTimes
      ? (SuitableTimeAdapter.toFirebase(graphql.suitableTimes) as SuitableTimes)
      : null,
  }),
  toGraphQL: (firebase) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(firebase),
    type: "date" as QuestionType,
    preferredTime: firebase.preferredTime,
    suitableTimesCount: firebase.suitableTimesCount,
    suitableTimes:
      firebase.suitableTimes &&
      (SuitableTimeAdapter.toGraphQL(firebase.suitableTimes) as SuitableTime[]),
  }),
})

export const OFFER_REQUEST_DATE_QUESTION_FRAGMENT = gql`
  ${SUITABLE_TIME_FRAGMENT}
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestDateQuestionInfo on OfferRequestDateQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    preferredTime
    suitableTimesCount
    suitableTimes {
      ...SuitableTimeInfo
    }
  }
`
