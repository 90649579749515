import React from "react"
import { DollarOutlined } from "@ant-design/icons"

import { mergeModules } from "../core"
import AuthRoute from "../common/AuthRoute"
import reducer from "./store/reducer"
import { Main } from "./pages"

const moduleItem = {
  sideBarItems: [
    {
      icon: <DollarOutlined />,
      name: "Promotions",
      path: "promotions",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/promotions"
      path="/promotions"
      render={() => <Main />}
    />,
  ],
  reducer,
}
export default mergeModules(moduleItem)
