import React, { useEffect, useMemo } from "react"
import { Badge, Col, InputNumber, Row, Switch, Typography } from "antd"
import { EuroOutlined } from "@ant-design/icons"
import {
  setFixedDuration,
  setFixedPrice,
  setMinimumDuration,
} from "../../../../payments/actions"
import { useDispatch, useSelector } from "react-redux"
import {
  getSelectedFixedDuration,
  getSelectedFixedPrice,
  getSelectedMinimumDuration,
  hasValidFixedDurationSelected,
  hasValidFixedPriceSelected,
  getSubscriptionStatus,
} from "../../../../payments/selectors"
import { getSelectedCategory } from "../../../categories/selectors"
import { printSubscriptionDiscount } from "../../utils"
import { Store } from "../../../../core"
import { setPromotionDisabled } from "../../actions"
import { selectActivePromotions } from "../../../../promotions/store/selectors"

const { Title } = Typography

export const PriceAndDuration = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPromotionDisabled(false))
  }, [dispatch])

  const selectedCategory = useSelector(getSelectedCategory)

  const selectedFixedPrice = useSelector(getSelectedFixedPrice)

  const isSelectedFixedPriceValid = useSelector(hasValidFixedPriceSelected)

  const selectedFixedDuration = useSelector(getSelectedFixedDuration)

  const promotionDisabled = useSelector(
    (state: Store) => state.offerRequest.promotionDisabled,
  )

  const isSelectedFixedDurationValid = useSelector(
    hasValidFixedDurationSelected,
  )

  const minimumDuration = useSelector(getSelectedMinimumDuration)
  const isSubscriptionEnabled = useSelector(getSubscriptionStatus)

  const promotions = useSelector(selectActivePromotions)

  const shouldShowApplyPromotionSwitch = useMemo(
    () =>
      promotions?.some(
        (promotion) => promotion.data.categoryId === selectedCategory?.id,
      ),
    [promotions, selectedCategory],
  )

  const onChangeFixedPrice = (value: number | string | null | undefined) => {
    if (value && typeof value === "number") {
      dispatch(setFixedPrice(value))
    }
  }

  const onChangeFixedDuration = (value: number | string | null | undefined) => {
    if (value && typeof value === "number") {
      dispatch(setFixedDuration(value))
    }
  }

  const onChangeMinimumDuration = (
    value: number | string | null | undefined,
  ) => {
    if (value && typeof value === "number") {
      dispatch(setMinimumDuration(value))
    }
  }

  const setPromotionDisabledHandler = (promotionApplied: boolean) => {
    dispatch(setPromotionDisabled(!promotionApplied))
  }

  return (
    <section className="section">
      {/* Price and duration section */}
      <Row gutter={[24, 12]} className="title-block">
        <Col>
          <EuroOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Price & Duration
          </Title>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col xs={24} lg={12}>
          <Row gutter={[24, 12]} justify="space-between">
            <Col>
              <Title className="question-header-title" level={4}>
                Price/Hour (€)
              </Title>
            </Col>
            <Col>
              {isSelectedFixedPriceValid ? (
                <Badge
                  count="Ok"
                  style={{ marginLeft: 12, backgroundColor: "green" }}
                />
              ) : (
                <Badge
                  count="Mandatory"
                  style={{ marginLeft: 12, backgroundColor: "red" }}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                type="number"
                min={3}
                step={0.01}
                value={selectedFixedPrice}
                onChange={onChangeFixedPrice}
              />
              {isSubscriptionEnabled &&
                selectedFixedPrice &&
                selectedCategory?.subscriptions && (
                  <div style={{ fontSize: 13, marginTop: 10 }}>
                    {`Your subscription discount ${printSubscriptionDiscount(
                      selectedCategory?.subscriptions?.default,
                    )}`}
                  </div>
                )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={[24, 12]} justify="space-between">
            <Col>
              <Title className="question-header-title" level={4}>
                Total Duration (h)
              </Title>
            </Col>
            <Col>
              {isSelectedFixedDurationValid ? (
                <Badge
                  count="Ok"
                  style={{ marginLeft: 12, backgroundColor: "green" }}
                />
              ) : (
                <Badge
                  count="Mandatory"
                  style={{ marginLeft: 12, backgroundColor: "red" }}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                type="number"
                min={1}
                step={0.5}
                value={selectedFixedDuration}
                onChange={onChangeFixedDuration}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={[24, 12]} justify="space-between">
            <Col>
              <Title className="question-header-title" level={4}>
                Minimum duration that will be charged (h)
              </Title>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                type="number"
                min={1}
                step={0.5}
                value={
                  minimumDuration === undefined
                    ? selectedCategory?.minimumDuration
                    : minimumDuration
                }
                onChange={onChangeMinimumDuration}
              />
            </Col>
          </Row>
        </Col>
        {shouldShowApplyPromotionSwitch && (
          <Col xs={24} lg={12}>
            <Row gutter={[24, 12]} justify="space-between">
              <Col>
                <Title className="question-header-title" level={4}>
                  Apply promotion
                </Title>
              </Col>
            </Row>
            <Row gutter={[24, 12]}>
              <Col xs={24}>
                <Switch
                  checked={!promotionDisabled}
                  onChange={setPromotionDisabledHandler}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </section>
  )
}
