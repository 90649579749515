import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import firebase from "firebase/app"
import SiderMenu from "./SideBarMenu"
import { Layout, Dropdown, Button, Row, Col, MenuProps } from "antd"
import logo from "../../../logo.png"
import { getAuthUser } from "../../auth/selectors"
import { SideBar } from "../../core/types"
import { useSelector } from "react-redux"
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownFilled,
} from "@ant-design/icons"

const { Header, Content, Sider } = Layout

const PreAuth = ({
  children,
  signOut,
}: {
  children: React.ReactElement
  signOut?: () => void
}) => {
  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      {children}
      <br />
      {signOut ? (
        <Button
          className="login-button"
          type="default"
          size="large"
          onClick={signOut}
        >
          Logout
        </Button>
      ) : null}
    </Layout>
  )
}

interface PostAuthProps {
  children: React.ReactElement
  displayName: string
  signOut: () => void
  sideBarItems?: SideBar[]
}

const PostAuth = ({
  children,
  displayName,
  signOut,
  sideBarItems,
}: PostAuthProps) => {
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const handleLogout = () => {
    signOut()
  }

  const items: MenuProps["items"] = [
    {
      key: "logout",
      onClick: handleLogout,
      label: "Logout",
    },
  ]

  return (
    <Layout>
      <Sider
        width={250}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <Link to="/">
            <img
              style={
                collapsed
                  ? {
                      width: "100%",
                      height: "100%",
                    }
                  : {
                      width: "50%",
                      height: "50%",
                    }
              }
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
        <SiderMenu sideBarItems={sideBarItems} />
      </Sider>
      <Layout style={collapsed ? { marginLeft: 80 } : { marginLeft: 250 }}>
        <Header style={{ background: "#fff", padding: 0 }}>
          <Row align="middle" justify="space-between">
            <Col>
              {collapsed ? (
                <MenuUnfoldOutlined className="trigger" onClick={toggle} />
              ) : (
                <MenuFoldOutlined className="trigger" onClick={toggle} />
              )}
            </Col>
            <Col>
              <Dropdown menu={{ items }}>
                <Button type="link">
                  {displayName} <CaretDownFilled />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            height: "100%",
            margin: "24px 16px",
            padding: 24,
            background: "#fff",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

interface PageLayout {
  children: React.ReactElement
  signOut?: () => void
  sideBarItems?: SideBar[]
}

const PageLayout = (props: PageLayout) => {
  const { children, signOut, sideBarItems } = props

  const user = useSelector(getAuthUser)

  useEffect(() => {
    const adminUrlRef = firebase.database().ref("settings").child("adminUrl")

    adminUrlRef.on("value", (adminUrlSnapshot) => {
      try {
        const adminUrl: string | null = adminUrlSnapshot.val()

        if (adminUrl) {
          window.location.replace(adminUrl)
        }
      } catch (err) {
        console.error(err)
      }
    })

    return () => adminUrlRef.off()
  }, [])

  return (
    <Layout>
      {signOut && user && user.isAdmin ? (
        <PostAuth
          displayName={user.displayName}
          signOut={signOut}
          sideBarItems={sideBarItems}
        >
          {children}
        </PostAuth>
      ) : (
        <PreAuth signOut={signOut}>{children}</PreAuth>
      )}
    </Layout>
  )
}

export default PageLayout
