import { createSelector } from "reselect"
import { SubscriptionsState } from "./types"

export const getSubscriptionsState = (state: {
  subscriptions: SubscriptionsState
}) => state.subscriptions

export const getSubscriptionsList = createSelector(
  [getSubscriptionsState],
  (state) => {
    return Object.values(state.subscriptions)
  },
)

export const getSubscriptionsById = (id: string) =>
  createSelector([getSubscriptionsState], (state) => state.subscriptions[id])

export const getSubscriptionsLoadedError = () =>
  createSelector([getSubscriptionsState], (state) => state.loadingError)
