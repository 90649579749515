import { Dropdown, MenuProps, Space } from "antd"
import { FC, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { MenuItemsKeys } from "./constants"
import UserAdminStatusChangerModal from "./UserAdminStatusChangerModal"
import { useDropdownMenuItems } from "./hooks"
import { dispatchUpdatedUser } from "../../../utils"
import { CloseModalArgs } from "./modals/types"

const UserAdminStatusChanger: FC = () => {
  const dispatch = useDispatch()

  const [selectedModalKey, setSelectedModalKey] =
    useState<MenuItemsKeys | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const dropDownMenuItems = useDropdownMenuItems()

  const onDropDownItemClick: MenuProps["onClick"] = ({ key }) => {
    setSelectedModalKey(key as MenuItemsKeys)
  }

  useEffect(() => {
    setIsModalOpen(true)
  }, [selectedModalKey])

  const handleCloseModal = useCallback(
    async ({ selectedUser, key }: CloseModalArgs) => {
      setSelectedModalKey(null)
      setIsModalOpen(false)

      if (selectedUser) {
        dispatchUpdatedUser({ selectedUser, key, dispatch })
      }
    },
    [dispatch],
  )

  return (
    <>
      {selectedModalKey && (
        <UserAdminStatusChangerModal
          open={isModalOpen}
          closeModal={handleCloseModal}
          selectedModalKey={selectedModalKey}
        />
      )}
      <Dropdown.Button
        danger
        type="default"
        menu={{ items: dropDownMenuItems, onClick: onDropDownItemClick }}
        trigger={["click"]}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>Users' admin permissions</Space>
        </a>
      </Dropdown.Button>
    </>
  )
}

export default UserAdminStatusChanger
