import { gql } from "@apollo/client"
import { Address as AddressDB } from "hero24-types"

import { FirebaseAdapter } from "../../utilities"

export type Address = {
  city: string
  postalCode: string
  streetAddress: string
}

export const ADDRESS_FRAGMENT = gql`
  fragment AddressInfo on AddressDto {
    city
    postalCode
    streetAddress
  }
`

export const AddressAdapter = new FirebaseAdapter<AddressDB, Address>({
  toFirebase: ({ city, postalCode, streetAddress }) => ({
    city,
    streetAddress,
    postalCode,
  }),
  toGraphQL: ({ city, postalCode, streetAddress }) => ({
    city,
    streetAddress,
    postalCode,
  }),
})
