import React from "react"

const PageNotFound = () => {
  return (
    <section style={{ textAlign: "center" }}>
      <h2>Oops Page Not found.</h2>
    </section>
  )
}

export default PageNotFound
