import React from "react"
import _ from "lodash"
import moment, { Moment } from "moment-timezone"
import { Row, Col, Typography } from "antd"
import CalendarButton from "./CalendarButton"

const { Text } = Typography

const dateFormat = "YYYY-MM-DD"
const timeSlots = [
  ["08", "12"],
  ["12", "16"],
  ["16", "20"],
  ["20", "22"],
  ["22", "24"],
  ["24", "06"],
  ["06", "08"],
]

interface Props {
  day: Moment
  selectedDates: string[]
  isFirst: boolean
  onTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => void
}

const CalendarDay = ({
  day,
  selectedDates,
  isFirst,
  onTimeSelected,
}: Props) => {
  const formattedDate = day.format(dateFormat)
  const currentHour = moment().hour()

  return (
    <Col span={25}>
      <Row gutter={2} style={{ marginBottom: 15 }}>
        <Col span={3} style={{ width: 100 }}>
          <Text type="secondary">{_.capitalize(day.format("dddd D.M."))}</Text>
        </Col>
        {_.map(timeSlots, (time) => {
          const startHour = time[0]
          const endHour = time[1]
          const dateKey = `${formattedDate}_${startHour}_${endHour}`
          return (
            <Col span={3} style={{ width: 100 }}>
              <CalendarButton
                enabled={!isFirst || currentHour < Number(endHour)}
                selected={_.includes(selectedDates, dateKey)}
                onClick={() =>
                  onTimeSelected({
                    dateKey,
                    day: formattedDate,
                    startTime: day
                      .clone()
                      .set("hour", Number(startHour))
                      .set("minute", 0)
                      .set("second", 0)
                      .set("millisecond", 0),
                    endTime: day
                      .clone()
                      .set("hour", Number(endHour))
                      .set("minute", 0)
                      .set("second", 0)
                      .set("millisecond", 0),
                  })
                }
                label={`${startHour}-${endHour}`}
              />
            </Col>
          )
        })}
      </Row>
    </Col>
  )
}

export default CalendarDay
