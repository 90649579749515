import React, { FC } from "react"
import { useSelector } from "react-redux"
import { Typography } from "antd"

import { getUserById } from "../../../user/selectors"
import { Store } from "../../../core"

type Props = {
  id?: string
}

const CustomerName: FC<Props> = ({ id }) => {
  const user = useSelector((state: Store) => getUserById(state, id))

  const customerName = user?.data.name
  const customerEmail = user?.data.email

  return (
    <>
      {customerName && (
        <Typography.Title level={5}>{customerName}</Typography.Title>
      )}
      {customerEmail && <Typography.Text>{customerEmail}</Typography.Text>}
    </>
  )
}
export default CustomerName
