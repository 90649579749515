import { gql } from "@apollo/client"

import { OfferRequestDB } from "hero24-types"

import {
  OFFER_REQUEST_QUESTION_FRAGMENT,
  OfferRequestQuestion,
  OfferRequestQuestionAdapter,
} from "../OfferRequestQuestion/OfferRequestQuestionInfo"
import { offerRequestQuestionsToTree } from "../../../utils/offerRequestQuestionsToTree"
import { offerRequestQuestionsToArray } from "../../../utils/offerRequestQuestionsToArray"
import { PlainOfferRequestQuestion } from "../../../types"
import { FirebaseAdapter } from "../../../../common/utilities"

type ChangedQuestionsDB = Required<
  OfferRequestDB["data"]
>["requestedChanges"]["changedQuestions"]

export type OfferRequestChangedQuestions = {
  before: OfferRequestQuestion[]
  after: OfferRequestQuestion[]
}

export const OfferRequestChangedQuestionsAdapter = new FirebaseAdapter<
  ChangedQuestionsDB,
  OfferRequestChangedQuestions
>({
  toFirebase(graphql) {
    const before = graphql.before.map(
      (question) =>
        OfferRequestQuestionAdapter.toFirebase(
          question,
        ) as PlainOfferRequestQuestion,
    )

    const after = graphql.after.map(
      (question) =>
        OfferRequestQuestionAdapter.toFirebase(
          question,
        ) as PlainOfferRequestQuestion,
    )

    return {
      before: offerRequestQuestionsToTree(before),
      after: offerRequestQuestionsToTree(after),
    }
  },
  toGraphQL(firebase) {
    const beforeQuestions = offerRequestQuestionsToArray(firebase.before)
    const afterQuestions = offerRequestQuestionsToArray(firebase.after)

    return {
      before: beforeQuestions.map((question) =>
        OfferRequestQuestionAdapter.toGraphQL(question),
      ) as OfferRequestQuestion[],
      after: afterQuestions.map((question) =>
        OfferRequestQuestionAdapter.toGraphQL(question),
      ) as OfferRequestQuestion[],
    }
  },
})

export const OFFER_REQUEST_CHANGED_QUESTIONS_FRAGMENT = gql`
  ${OFFER_REQUEST_QUESTION_FRAGMENT}

  fragment OfferRequestChangedQuestionsInfo on OfferRequestDataRequestedChangesChangedQuestionsDto {
    before {
      ...OfferRequestQuestionInfo
    }
    after {
      ...OfferRequestQuestionInfo
    }
  }
`
