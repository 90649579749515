export const defaultQuestions: string[] = [
  "type",
  "id",
  "name",
  "order",
  "showError",
  "position",
  "optional",
]

export const additionalQuestions: { [key: string]: string[] } = {
  radio: ["options"],
  checkbox: ["options"],
  textarea: ["placeholder", "defaultValue"],
  list: ["placeholder"],
  number: ["placeholder", "defaultValue"],
  // eslint-disable-next-line camelcase
  number_input: ["placeholder", "defaultValue", "extra_placeholder"],
}

export const types = [
  "textarea",
  "radio",
  "checkbox",
  "list",
  "number",
  "number_input",
  "image",
  "date",
]
