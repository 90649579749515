import { OfferRequestsState, OfferRequestsReducer } from "./types"
import {
  GET_OFFER_REQUESTS,
  SET_OFFER_REQUEST_STATUS,
  SET_OFFER_REQUEST_PREFERRED_TIME,
  SET_OFFER_REQUEST_ADDRESSES,
  SET_OFFER_REQUEST_QUESTIONS,
  SET_OFFER_REQUEST_VATS,
  SET_OFFER_REQUEST_APPROVED_BY_BUYER,
} from "./actionTypes"
import { OfferRequest } from "../../buyer/createOfferRequest/types"
import { SAVED_FEES } from "../../fees/actionTypes"

export const INITIAL_STATE: OfferRequestsState = {
  offerRequests: {},
}

const offerRequests: OfferRequestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OFFER_REQUESTS: {
      return {
        ...state,
        offerRequests: action.payload,
      }
    }
    case SET_OFFER_REQUEST_STATUS: {
      const offerRequest = state.offerRequests[action.payload.id]
      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          data: {
            ...offerRequest.data,
            status: action.payload.status,
          },
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.id]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    case SET_OFFER_REQUEST_PREFERRED_TIME: {
      const offerRequest = state.offerRequests[action.payload.id]
      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          data: {
            ...offerRequest.data,
            initial: {
              ...offerRequest.data.initial,
              questions: action.payload.questions,
            },
          },
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.id]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    case SET_OFFER_REQUEST_ADDRESSES: {
      const offerRequest = state.offerRequests[action.payload.id]
      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          data: {
            ...offerRequest.data,
            initial: {
              ...offerRequest.data.initial,
              addresses: action.payload.addresses,
            },
          },
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.id]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    case SET_OFFER_REQUEST_VATS: {
      const offerRequest = state.offerRequests[action.payload.id]
      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          ...action.payload,
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.id]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    case SAVED_FEES: {
      const current = state.offerRequests[action.offerRequestId]
      if (!current) {
        return state
      }
      return {
        ...state,
        offerRequests: {
          ...state.offerRequests,
          [action.offerRequestId]: {
            ...current,
            fees: {
              ...(current.fees || {}),
              ...Object.fromEntries(action.feeIds.map((id) => [id, true])),
            },
          },
        },
      }
    }
    case SET_OFFER_REQUEST_QUESTIONS: {
      const offerRequest = state.offerRequests[action.payload.offerRequestId]

      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          data: {
            ...offerRequest.data,
            initial: {
              ...offerRequest.data.initial,
              questions: action.payload.questions,
            },
          },
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.offerRequestId]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    case SET_OFFER_REQUEST_APPROVED_BY_BUYER: {
      const offerRequest = state.offerRequests[action.payload.id]
      if (offerRequest) {
        const updatedOfferRequest: OfferRequest = {
          ...offerRequest,
          isApprovedByBuyer: action.payload.isApprovedByBuyer,
        }
        return {
          ...state,
          offerRequests: {
            ...state.offerRequests,
            [action.payload.id]: updatedOfferRequest,
          },
        }
      }
      return state
    }
    default:
      return state
  }
}

export default { offerRequests }
