import { OfferRequestSubscription, SUBSCRIPTION_INTERVAL } from "hero24-types"
import moment from "moment"

export const offerRequestSubscription: (
  subscriptionType: SUBSCRIPTION_INTERVAL,
) => OfferRequestSubscription = (subscriptionType) =>
  ({
    subscriptionType: subscriptionType,
  } as OfferRequestSubscription)

export const get24Hours = () =>
  new Array(24).fill(null).map((el, idx) => {
    const date = moment({ hour: idx + 1 })
    const utcOffset = moment().utcOffset()
    return {
      label: `${date.format("HH:mm")} (UTC+${utcOffset / 60})`,
      value: Number(date.format("H")),
    }
  })

export const getWeekDays = () =>
  new Array(7).fill(null).map((el, idx) => {
    const date = moment().day(idx + 1)
    return { label: date.format("dddd"), value: Number(date.format("e")) }
  })

export const getMonthDays = () =>
  new Array(31).fill(null).map((el, idx) => {
    const label = moment()
      .date(idx + 1)
      .format("Do")
    return { label, value: idx + 1 }
  })
