import { FC, useCallback, useEffect, useState } from "react"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import { PaymentMethodPicker } from "../../../../../payments/components"
import { Button, Typography } from "antd"
import { object, string, ValidationError } from "yup"
import {
  PaymentService,
  SendingChannel,
} from "../../../../../payments/constants"
import { changeOfferRequestPrePayWith } from "../utils"

const { Text } = Typography

type UpdatedPaymentMethod = Parameters<
  typeof changeOfferRequestPrePayWith
>[0]["after"]

type Props = {
  closeModal?: () => void
  onSave: (updated: UpdatedPaymentMethod) => void
  offerRequest: OfferRequest
}

const validationSchema = object({
  prePayWith: string().oneOf(Object.values(PaymentService)).required(),
  postpaid: string().oneOf(["yes", "no"]).required(),
  sendInvoiceWith: string().oneOf(Object.values(SendingChannel)).required(),
})

export const ChangePaymentMethodModal: FC<Props> = ({
  offerRequest,
  closeModal,
  onSave,
}) => {
  const [prePayWith, setPrePayWith] = useState(
    offerRequest.data.initial.prePayWith || PaymentService.STRIPE,
  )
  const [postpaid, setPostpaid] = useState(
    offerRequest.data.initial.postpaid || "no",
  )
  const [sendInvoiceWith, setSendInvoiceWith] = useState(
    offerRequest.data.initial.sendInvoiceWith || SendingChannel.EMAIL,
  )

  const [error, setError] = useState("")

  useEffect(() => {
    setError("")
  }, [prePayWith, postpaid, sendInvoiceWith])

  const saveHandler = useCallback(async () => {
    try {
      const before = {
        postpaid: offerRequest.data.initial.postpaid,
        prePayWith: offerRequest.data.initial.prePayWith,
        sendInvoiceWith: offerRequest.data.initial.sendInvoiceWith,
      }

      const after = { prePayWith, postpaid, sendInvoiceWith }

      await validationSchema.validate(after)
      await changeOfferRequestPrePayWith({
        after,
        before,
        offerRequestId: offerRequest.id,
      })

      onSave(after)

      closeModal && closeModal()
    } catch (error) {
      if (ValidationError.isError(error)) {
        setError(error.message)
      } else {
        setError("Something went wrong")
      }

      console.error(error)
    }
  }, [offerRequest, prePayWith, postpaid, sendInvoiceWith, closeModal, onSave])

  return (
    <>
      <PaymentMethodPicker
        postpaid={postpaid === "yes"}
        setPostpaid={(isPostpaid) => setPostpaid(isPostpaid ? "yes" : "no")}
        prePayWith={prePayWith}
        setPrePayWith={setPrePayWith}
        sendInvoiceWith={sendInvoiceWith}
        setSendInvoiceWith={setSendInvoiceWith}
      />
      <Button onClick={saveHandler}>Save</Button>
      <Text type="danger" style={{ display: "block", marginTop: 10 }}>
        {error}
      </Text>
    </>
  )
}
