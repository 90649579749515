import { Dispatch } from "redux"

import { Store } from "../core"
import { selectApolloClient } from "../apolloClient/selectors"
import { setChats, setFetching, setUnseenChatsCount } from "./reducer"
import {
  GET_CHATS_QUERY,
  GetChatsData,
  GetChatsVariables,
} from "./graphql/queries/GetChats"
import {
  GET_UNSEEN_CHATS_COUNT_QUERY,
  GetUnseenAdminChatsCountData,
} from "./graphql/queries/GetUnseenChatsCount"
import {
  SET_CHAT_SEEN_BY_ADMIN_MUTATION,
  SetChatSeenByAdminVariables,
} from "./graphql/mutation/SetChatSeenByAdmin"
import {
  SET_CHAT_RECLAMATION_RESOLVED_MUTATION,
  SetChatReclamationResolvedVariables,
} from "./graphql/mutation/SetChatReclamationResolved"
import { ChatDto } from "./graphql/fragments/ChatInfo"
import {
  CREATE_CHAT_MESSAGE_MUTATION,
  CreateChatMessageData,
  CreateChatMessageVariables,
} from "./graphql/mutation/CreateChatMessage"
import {
  ADD_MEMBER_TO_CHAT_MUTATION,
  AddMemberToChatData,
  AddMemberToChatVariables,
} from "./graphql/mutation/AddMemberToChat"
import { ChatMemberRole } from "./graphql/constants"

export const loadChats =
  (
    { offset, limit, filter, ordersBy }: GetChatsVariables,
    forceRefetch = false,
  ) =>
  async (dispatch: Dispatch, getStore: () => Store) => {
    const store = getStore()
    const apolloClient = selectApolloClient(store)

    dispatch(setFetching(true))

    try {
      const { data } = await apolloClient.query<
        GetChatsData,
        GetChatsVariables
      >({
        query: GET_CHATS_QUERY,
        variables: {
          offset,
          limit,
          filter,
          ordersBy,
        },
        fetchPolicy: forceRefetch ? "network-only" : undefined,
      })

      dispatch(setChats(data.chats))
    } finally {
      dispatch(setFetching(false))
    }
  }

export const sendChatMsg =
  (chat: ChatDto, content: string) =>
  async (_dispatch: Dispatch, getState: () => Store) => {
    const store: Store = getState()
    const {
      auth: { user },
    } = store

    if (!user || !chat) {
      return
    }

    const apolloClient = selectApolloClient(store)

    const member = chat.members.find(({ id }) => id === user.id)

    // needs to be an admin member before can send a message
    if (member?.role !== ChatMemberRole.ADMIN) {
      await apolloClient.mutate<AddMemberToChatData, AddMemberToChatVariables>({
        mutation: ADD_MEMBER_TO_CHAT_MUTATION,
        variables: {
          chatId: chat.id,
          userId: user.id,
          role: ChatMemberRole.ADMIN,
        },
      })
    }

    await apolloClient.mutate<
      CreateChatMessageData,
      CreateChatMessageVariables
    >({
      mutation: CREATE_CHAT_MESSAGE_MUTATION,
      variables: {
        content,
        chatId: chat.id,
      },
    })
  }

export const markChatSeen =
  (chatId: string, seen: boolean) =>
  async (_dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    await apolloClient.mutate<unknown, SetChatSeenByAdminVariables>({
      mutation: SET_CHAT_SEEN_BY_ADMIN_MUTATION,
      variables: {
        chatId,
        seenByAdmin: seen,
      },
    })
  }

export const markChatReclamationResolved =
  (chatId: string, reclamationResolved: boolean) =>
  async (_dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    await apolloClient.mutate<unknown, SetChatReclamationResolvedVariables>({
      mutation: SET_CHAT_RECLAMATION_RESOLVED_MUTATION,
      variables: {
        chatId,
        isAboutReclamation: !reclamationResolved,
      },
    })
  }

export const fetchUnseenChatsCount =
  () => async (dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    const { data } = await apolloClient.query<GetUnseenAdminChatsCountData>({
      query: GET_UNSEEN_CHATS_COUNT_QUERY,
    })

    dispatch(setUnseenChatsCount(data.unseenAdminChatsCount))
  }
