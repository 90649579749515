import moment, { Moment } from "moment"

import { NEWS_DATE_FORMAT } from "./constants"

export const formatNewsTime = (date: number | Date | Moment | string) => {
  return moment(date).format(NEWS_DATE_FORMAT)
}

export const getStorageNewsPath = (id: string) => `/images/news/${id}`

export const computePageNumber = (props: { offset: number; limit: number }) =>
  (props.offset + props.limit) / props.limit
