import { Col, Radio, Row, Typography } from "antd"
import { RobotOutlined } from "@ant-design/icons"
import { FC, useCallback } from "react"
import { PlatformProviderService } from "../../../payments/constants"

const { Title } = Typography

type Props = {
  platformProvider: PlatformProviderService | undefined
  setPlatformProvider: (value: PlatformProviderService) => void
}

export const PlatformProviderPicker: FC<Props> = (props) => {
  const { platformProvider, setPlatformProvider } = props

  const platformProviderChangeHandler = useCallback(
    (event) => setPlatformProvider(event.target.value),
    [setPlatformProvider],
  )

  return (
    <>
      {/* Platform provider type section */}
      <Row gutter={[24, 12]} className="title-block">
        <Col>
          <RobotOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Platform Provider
          </Title>
        </Col>
      </Row>

      <Row
        gutter={[24, 12]}
        className="title-block"
        align="middle"
        justify="space-between"
      >
        <Col xs={24} lg={24}>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <Title className="question-header-title" level={4}>
                Select platform provider:
              </Title>
            </Col>
            <Col xs={24}>
              <Radio.Group
                value={platformProvider}
                onChange={platformProviderChangeHandler}
              >
                <Radio value={PlatformProviderService.HERO_PAYMENTS_OY}>
                  Taltek Asennus Oy / Hero24
                </Radio>
                <Radio value={PlatformProviderService.HERO24_OY}>
                  Hero Payments Oy
                </Radio>
                <Radio value={PlatformProviderService.HERO24_B2B_OY}>
                  Sankaripalvelut Oy
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
