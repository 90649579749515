import React from "react"
import { connect } from "formik"
import getPath from "lodash/get"

import { FieldAdapterProps } from "./types"

const FieldAdapter = (props: FieldAdapterProps) => {
  const { formik, component, name, defaultValue, defaultChecked, disabled } =
    props

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props
    if (onChange) {
      onChange(e)
    } else {
      formik && formik.handleChange(e)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = props
    if (onBlur) {
      onBlur(e)
    } else {
      formik && formik.handleBlur(e)
    }
  }

  let { value, checked } = props
  value = value || ""
  checked = checked || false

  const meta = {
    touched: getPath(formik && formik.touched, name),
    error: getPath(formik && formik.errors, name),
  }

  const input = {
    onBlur,
    onChange,
    onSelect: props.onSelect,
    name,
    value,
    checked,
    defaultValue,
    defaultChecked,
    disabled,
  }

  if (!component) {
    return null
  }

  return React.createElement(component, {
    ...props,
    input,
    meta,
  })
}

export default connect(FieldAdapter)
