import { gql } from "@apollo/client"
import { OfferRequestDB, PickStrategy } from "hero24-types"
import { FirebaseAdapter, convertListToMap } from "../../../../common/utilities"

type PickServiceProviderDB = Exclude<
  OfferRequestDB["data"]["pickServiceProvider"],
  undefined
>

export type OfferRequestDataPickServiceProvider = {
  preferred?: string[]
  pickStrategy: PickStrategy
}

export const OfferRequestDataPickServiceProviderAdapter = new FirebaseAdapter<
  PickServiceProviderDB,
  OfferRequestDataPickServiceProvider
>({
  toFirebase: (graphql) => ({
    pickStrategy: graphql.pickStrategy,
    preferred: graphql.preferred
      ? convertListToMap(graphql.preferred, true)
      : undefined,
  }),
  toGraphQL: (firebase) => ({
    pickStrategy: firebase.pickStrategy,
    preferred: firebase.preferred ? Object.keys(firebase.preferred) : undefined,
  }),
})

export const OFFER_REQUEST_DATA_PICK_SERVICE_PROVIDER_FRAGMENT = gql`
  fragment OfferRequestDataPickServiceProviderInfo on OfferRequestDataPickServiceProviderDto {
    pickStrategy
    preferred
  }
`
