import React, { FC, useEffect, useMemo, useState } from "react"
import { Button, DatePicker, Descriptions, Image, Input, Upload } from "antd"
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import firebase from "firebase"
import moment from "moment"
import debounce from "lodash/debounce"

import { News } from "../../graphql/fragments"
import { useForceUpdater, useUpdateEffect } from "../../../common/hooks"
import { getStorageNewsPath } from "../../utils"
import { editNewsThunk } from "../../thunk-actions"

const NewsRow: FC<{ news: News }> = ({ news }) => {
  const [title, setTitle] = useState(news.title)
  const [label, setLabel] = useState(news.label)
  const [description, setDescription] = useState(news.description)
  const [startAt, setStartAt] = useState(+news.startAt)
  const [endAt, setEndAt] = useState(+news.endAt)
  const [link, setLink] = useState(news.link || null)

  const [imageUrl, setImageUrl] = useState<string>("")
  const [updateImageRef, updateImage] = useForceUpdater()
  const [imageLoading, setImageLoading] = useState(false)

  const imagePath = getStorageNewsPath(news.id)

  const dispatch = useDispatch()

  useEffect(() => {
    firebase.storage().ref(imagePath).getDownloadURL().then(setImageUrl)
  }, [imagePath, updateImageRef])

  const updateNewsDebounced = useMemo(
    () =>
      debounce((news: News) => {
        dispatch(editNewsThunk(news))
      }, 800),
    [dispatch],
  )

  useUpdateEffect(() => {
    updateNewsDebounced({
      id: news.id,
      title,
      description,
      startAt,
      endAt,
      link: link || "",
      label,
    })
  }, [
    title,
    description,
    startAt,
    endAt,
    link,
    news.id,
    updateNewsDebounced,
    label,
  ])

  return (
    <Descriptions column={2} size="middle">
      <Descriptions.Item>
        <Descriptions column={1} size="middle">
          <Descriptions.Item label="Title">
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value || "")}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            <Input.TextArea
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value || "")}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Label">
            <Input
              value={label}
              onChange={(e) => setLabel(e.target.value || "")}
            />
          </Descriptions.Item>
          <Descriptions.Item label="StartAt/EndAt">
            <DatePicker.RangePicker
              value={
                [
                  moment(startAt),
                  moment(endAt),
                ] as any /* TODO: handle odd types */
              }
              onChange={(values) => {
                setStartAt(values?.[0] ? +values[0] : +news.startAt)
                setEndAt(values?.[1] ? +values[1] : +news.endAt)
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="External link">
            {typeof link !== "string" && (
              <PlusCircleOutlined onClick={() => setLink(news.link || "")} />
            )}
            {typeof link === "string" && (
              <Input
                value={link}
                onChange={(e) => setLink(e.target.value || "")}
                suffix={<DeleteOutlined onClick={() => setLink(null)} />}
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      </Descriptions.Item>
      <Descriptions.Item>
        <Descriptions column={1} layout="vertical" colon={false}>
          <Descriptions.Item
            label={
              <Upload
                multiple={false}
                showUploadList={false}
                customRequest={async ({ file, onSuccess }) => {
                  try {
                    setImageLoading(true)

                    await firebase
                      .storage()
                      .ref(imagePath)
                      .put(file as Blob)

                    onSuccess && onSuccess("ok")
                    updateImage()
                  } finally {
                    setImageLoading(false)
                  }
                }}
              >
                <Button type="primary" loading={imageLoading}>
                  Upload image
                </Button>
              </Upload>
            }
          >
            <Image src={imageUrl} width={300} style={{ cursor: "pointer" }} />
          </Descriptions.Item>
        </Descriptions>
      </Descriptions.Item>
    </Descriptions>
  )
}

export default (news: News) => {
  return <NewsRow news={news} />
}
