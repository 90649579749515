import React, { useCallback, useEffect, useMemo, useState } from "react"
import _ from "lodash"
import moment, { Moment } from "moment-timezone"
import { Row, Col, Typography } from "antd"
import CalendarDay from "./CalendarDay"
import { DateQuestion } from "../../../types"
import { OfferRequestDateQuestion } from "hero24-types"

const { Text } = Typography

const dateFormat = "YYYY-MM-DD"
const daysOffset = 30

interface PeriodPickerProps {
  question: DateQuestion | OfferRequestDateQuestion
  isSubQuestion?: boolean
  onSuitableTimeSelected: (props: {
    dateKey: string
    day: string
    startTime: Moment
    endTime: Moment
  }) => void
}

const PeriodPicker = (props: PeriodPickerProps) => {
  const [showCalendar, setShowCalendar] = useState(false)

  useEffect(() => {
    if (props.question.preferredTime) {
      setShowCalendar(false)
    }
  }, [props.question.preferredTime])

  const getDefaultDates = useCallback((now: Moment) => {
    const afterdates = [...Array(daysOffset + 1)].map((x, idx) =>
      now.clone().add(idx, "days"),
    )
    const preferredDay = moment(moment(now, "x").format(dateFormat))
    return { preferredDay, flexible: afterdates }
  }, [])

  const dates = useMemo(() => {
    const now = moment()
    return getDefaultDates(now)
  }, [getDefaultDates])

  const renderCalendar = useCallback(
    () => (
      <Row>
        <Col
          span={24}
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text strong type="success">
            Select flexible time periods for the service.
          </Text>
        </Col>
        {dates.flexible.map((x, idx) => {
          return (
            <CalendarDay
              key={x.toString()}
              day={x}
              selectedDates={_.keys(props.question.suitableTimes)}
              onTimeSelected={props.onSuitableTimeSelected}
              isFirst={idx === 0}
            />
          )
        })}
      </Row>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.question.suitableTimes,
      props.question.suitableTimesCount, // props.question.suitableTimesCount is necessary because it will tell us when props.question.suitableTimes has changed
      dates,
      props.onSuitableTimeSelected,
    ],
  )

  const onShowCalendar = useCallback(() => {
    setShowCalendar(true)
  }, [setShowCalendar])

  return useMemo(
    () => (
      <>
        {!showCalendar ? (
          <h4
            onClick={onShowCalendar}
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontWeight: "bold",
              cursor: "pointer",
              color: "#ea4435",
            }}
          >
            Select flexible times
          </h4>
        ) : null}
        {showCalendar ? renderCalendar() : null}
      </>
    ),
    [renderCalendar, showCalendar, onShowCalendar],
  )
}

export default PeriodPicker
