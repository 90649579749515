import { createSelector } from "reselect"
import { Store } from "../core/types"
import { User } from "./types"

const getUsers = (state: Store) => state.user.users
export const isLoading = (state: Store) => !!state.user.loading
export const selectUsersMap = (state: Store) => state.users.users

export const getUserById = (state: Store, id?: string) =>
  id ? state.users.users[id] : undefined

export const getSelectedUsersId = (state: Store) => state.user.selected
export const getListUsers = createSelector(getUsers, (users: User[]) => users)
