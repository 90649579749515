import { Dispatch } from "redux"

import { Store } from "../../core"
import { AppThunkAction } from "../../common/types"
import { getNewsList } from "../graphql/queries"
import { addNewsResult, setNewsListLoading } from "../actions"

export const getAllNewsThunk: AppThunkAction =
  () => async (dispatch: Dispatch, getState: () => Store) => {
    try {
      dispatch(setNewsListLoading(true))

      const { newsListData } = await getNewsList(getState(), {})

      if (newsListData) {
        dispatch(addNewsResult(newsListData))
      }
    } finally {
      dispatch(setNewsListLoading(false))
    }
  }
