import React, { useMemo } from "react"
import moment from "moment-timezone"
import { OfferRequest } from "../../types"
import { UsersState } from "../../../../users/list/types"
import { findOfferRequestDeadline, isPrepaidSuccessfully } from "../../../utils"
import { OfferRequestStatus } from "../../../../common/components/listComponent/OfferRequestStatus"
import { ColumnsType } from "antd/lib/table"
import { RangePickerProps } from "antd/lib/date-picker"
import { DateAndTime } from "../../../../common/components/listComponent/DateAndTime"
import { Prepaid } from "../../../../common/components/listComponent/Prepaid"
import { CategoryName } from "../../../../common/components/listComponent/Category"
import { CategoriesState } from "../../../../buyer/categories/types"
import { UserName } from "../../../../common/components/listComponent/UserName"
import { UserEmail } from "../../../../common/components/listComponent/UserEmail"
import { UserPhone } from "../../../../common/components/listComponent/UserPhone"
import { Note } from "../../../../common/components/listComponent/Note"
import { FeesState } from "../../../../fees/types"
import { Button, DatePicker, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"

const noteTexts = {
  expiringPrepaid:
    "Expire Soon! PREPAID offerRequest about to expire without ACCEPTED offers!",
  unpaidMaterial:
    "Accept Manually! Needs to be accepted manually. (Prepaid gig but the time is different than what customer requested)",
}

const getNoteForOfferRequest = (
  offerRequest: OfferRequest,
  fees: FeesState["allFees"],
) => {
  if (fees) {
    if (offerRequest.data.status === "open") {
      const deadline = findOfferRequestDeadline(offerRequest)
      if (moment(deadline, "x").diff(moment(), "hours") < 24) {
        if (isPrepaidSuccessfully(offerRequest)) {
          return noteTexts.expiringPrepaid
        }
      }
    } else if (offerRequest.fees) {
      const unpaidFee = Object.keys(offerRequest.fees).find(
        (id) => fees[id]?.status === "pending",
      )
      if (unpaidFee) {
        return noteTexts.unpaidMaterial
      }
    }
  }
  return ""
}

type Column = ColumnsType<OfferRequest>

const getColumns: (
  categories: CategoriesState,
  users: UsersState,
  fees: FeesState["allFees"],
) => Column = (categories, users, fees) => {
  const createdColumn: Column[0] = {
    key: "Created",
    title: "Created",
    render: (offerRequest: OfferRequest) => (
      <DateAndTime time={offerRequest.data.initial.createdAt} short />
    ),
    sorter: (offerRequestA: OfferRequest, offerRequestB: OfferRequest) => {
      const createdA = offerRequestA.data.initial.createdAt
      const createdB = offerRequestB.data.initial.createdAt
      return createdA > createdB ? 1 : -1
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const rawRange =
        typeof selectedKeys[0] === "string"
          ? JSON.parse(selectedKeys[0])
          : [null, null]

      const range = [
        rawRange[0] && moment(rawRange[0]),
        rawRange[1] && moment(rawRange[1]),
      ]

      return (
        <>
          <Space direction="vertical" style={{ padding: 8 }}>
            <DatePicker.RangePicker
              size="small"
              value={range as RangePickerProps["value"]}
              onChange={(value) => setSelectedKeys([JSON.stringify(value)])}
              placeholder={["Begin of range", "End of range"]}
            />

            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </Space>
        </>
      )
    },
    onFilter: (value: unknown, offerRequest: OfferRequest) => {
      const rawRange =
        typeof value === "string" ? JSON.parse(value) : [null, null]

      const range = [
        rawRange[0] ? moment(rawRange[0]) : null,
        rawRange[1] ? moment(rawRange[1]) : null,
      ]

      const startTime = offerRequest.data.initial.createdAt || 0
      const endTime = offerRequest.data.initial.createdAt || 0

      const isBetween = (time: number) =>
        !!(range[0]?.isSameOrBefore(time) && range[1]?.isSameOrAfter(time))

      return isBetween(endTime) || isBetween(startTime)
    },
  }

  const dueDateColumn: Column[0] = {
    key: "deadline",
    title: "Deadline",
    render: (offerRequest: OfferRequest) => (
      <DateAndTime time={findOfferRequestDeadline(offerRequest)} />
    ),
    defaultSortOrder: "descend",
    sorter: (offerRequestA: OfferRequest, offerRequestB: OfferRequest) => {
      const deadlineA = findOfferRequestDeadline(offerRequestA) || 0
      const deadlineB = findOfferRequestDeadline(offerRequestB) || 0
      return deadlineA > deadlineB ? 1 : -1
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const rawRange =
        typeof selectedKeys[0] === "string"
          ? JSON.parse(selectedKeys[0])
          : [null, null]

      const range = [
        rawRange[0] && moment(rawRange[0]),
        rawRange[1] && moment(rawRange[1]),
      ]

      return (
        <>
          <Space direction="vertical" style={{ padding: 8 }}>
            <DatePicker.RangePicker
              size="small"
              value={range as RangePickerProps["value"]}
              onChange={(value) => setSelectedKeys([JSON.stringify(value)])}
              placeholder={["Begin of range", "End of range"]}
            />

            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </Space>
        </>
      )
    },
    onFilter: (value: unknown, offerRequest: OfferRequest) => {
      const rawRange =
        typeof value === "string" ? JSON.parse(value) : [null, null]

      const range = [
        rawRange[0] ? moment(rawRange[0]) : null,
        rawRange[1] ? moment(rawRange[1]) : null,
      ]

      const startTime = findOfferRequestDeadline(offerRequest) || 0
      const endTime = findOfferRequestDeadline(offerRequest) || 0

      const isBetween = (time: number) =>
        !!(range[0]?.isSameOrBefore(time) && range[1]?.isSameOrAfter(time))

      return isBetween(endTime) || isBetween(startTime)
    },
  }

  const statusColumn: Column[0] = {
    key: "status",
    title: "Status",
    render: (offerRequest: OfferRequest) => (
      <OfferRequestStatus offerRequest={offerRequest} />
    ),
    sorter: (offerRequestA: OfferRequest, offerRequestB: OfferRequest) => {
      if (offerRequestA.data.status === offerRequestB.data.status) {
        return 0
      } else if (offerRequestA.data.status === "open") {
        return -1
      } else {
        return offerRequestB.data.status.localeCompare(
          offerRequestA.data.status,
          undefined,
        )
      }
    },
    filters: [
      { text: "OPEN", value: "open" },
      { text: "ACCEPTED", value: "accepted" },
      { text: "COMPLETED", value: "completed" },
      { text: "CANCELLED", value: "cancelled" },
      { text: "EXPIRED", value: "expired" },
    ],
    onFilter: (value: any, offerRequest: OfferRequest) =>
      offerRequest.data.status === value,
  }

  const prePaidColumn: Column[0] = {
    key: "Prepaid",
    title: "Prepaid?",
    render: (offerRequest: OfferRequest) => (
      <Prepaid offerRequest={offerRequest} />
    ),
    filters: [
      { text: "PAID", value: "paid" },
      { text: "WAITING", value: "waiting" },
    ],
    onFilter: (value: any, offerRequest: OfferRequest) =>
      offerRequest.data.initial.prepaid === value,
  }

  const categoryColumn: Column[0] = {
    key: "category",
    title: "Category",
    render: (offerRequest: OfferRequest) => (
      <CategoryName
        category={categories.categories[offerRequest.data.initial.category]}
      />
    ),
    filters: Object.values(categories.categories)
      .sort((a, b) => (a.name.en || "").localeCompare(b.name.en || ""))
      .map((category) => ({
        text: category.name.en,
        value: category.id,
      })),
    onFilter: (value: any, offerRequest: OfferRequest) =>
      offerRequest.data.initial.category === value,
  }

  const customerColumn: Column[0] = {
    key: "buyerProfile",
    title: "Customer",
    render: (offerRequest: OfferRequest) => (
      <>
        <UserName user={users.users[offerRequest.data.initial.buyerProfile]} />
        <br />
        <UserEmail user={users.users[offerRequest.data.initial.buyerProfile]} />
        <br />
        <UserPhone user={users.users[offerRequest.data.initial.buyerProfile]} />
      </>
    ),
    sorter: (offerRequestA: OfferRequest, offerRequestB: OfferRequest) => {
      const aName =
        users.users[offerRequestA.data.initial.buyerProfile]?.data.name
      const bName =
        users.users[offerRequestB.data.initial.buyerProfile]?.data.name
      if (aName && bName) {
        return aName.localeCompare(bName)
      } else if (aName) {
        return -1
      } else if (bName) {
        return 1
      } else {
        return 0
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by buyers name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, offerRequest: OfferRequest) => {
      const user =
        users.users && users.users[offerRequest.data.initial.buyerProfile]
      return typeof value === "string" && user
        ? user.data?.name.toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const subscriptionColumn: Column[0] = {
    key: "subscription",
    title: "Subscription",
    render: (offerRequest: OfferRequest) => {
      return offerRequest.subscription != null
        ? offerRequest.subscription.subscriptionType.toUpperCase()
        : "---"
    },
    filters: ["daily", "weekly", "bi-weekly", "monthly"].map((el) => ({
      text: el.toUpperCase(),
      value: el,
    })),
    onFilter: (value: any, offerRequest: OfferRequest) =>
      offerRequest.subscription?.subscriptionType === value,
  }

  const hasOffersColumn: Column[0] = {
    key: "offers",
    title: "Offers",
    render: (offerRequest: OfferRequest) =>
      offerRequest.offers && Object.keys(offerRequest.offers).length,
    sorter: (offerRequestA: OfferRequest, offerRequestB: OfferRequest) => {
      const aL = offerRequestA.offers
        ? Object.keys(offerRequestA.offers).length
        : 0
      const bL = offerRequestB.offers
        ? Object.keys(offerRequestB.offers).length
        : 0
      if (aL === bL) {
        return 0
      } else if (aL > bL) {
        return -1
      } else {
        return 1
      }
    },
  }

  const noteColumn: Column[0] = {
    key: "note",
    title: "Note!",
    render: (offerRequest: OfferRequest) => {
      const note = getNoteForOfferRequest(offerRequest, fees)
      return <Note note={note} />
    },
    filters: Object.values(noteTexts)
      .sort((a, b) => a.localeCompare(b))
      .map((noteText) => ({
        text: noteText,
        value: noteText,
      })),
    onFilter: (value: any, offerRequest: OfferRequest) =>
      value === getNoteForOfferRequest(offerRequest, fees),
  }

  return [
    noteColumn,
    createdColumn,
    dueDateColumn,
    statusColumn,
    prePaidColumn,
    categoryColumn,
    customerColumn,
    subscriptionColumn,
    hasOffersColumn,
  ]
}

export default (
  categories: CategoriesState,
  users: UsersState,
  fees: FeesState["allFees"],
) =>
  useMemo(() => getColumns(categories, users, fees), [categories, users, fees])
