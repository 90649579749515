import firebase from "firebase"

import { AppThunkAction } from "../../common/types"
import { removeNews } from "../graphql/mutations"
import { newsRemoved, setNewsRemoving } from "../actions"
import { getStorageNewsPath } from "../utils"

type Args = [string]

const removeNewsImage = async (newsId: string) => {
  try {
    await firebase.storage().ref(getStorageNewsPath(newsId)).delete()
    return true
  } catch (err) {
    return false
  }
}

export const removeNewsThunk: AppThunkAction<Args> =
  (id) => async (dispatch, getState) => {
    try {
      dispatch(setNewsRemoving({ id, removing: true }))

      await removeNewsImage(id)
      await removeNews(getState(), id)

      dispatch(newsRemoved(id))
    } finally {
      dispatch(setNewsRemoving({ id, removing: false }))
    }
  }
