import { mergeModules } from "./core"
import auth from "./auth"
import payments from "./payments"
import fees from "./fees"
import pageNotFound from "./pageNotFound"
import defaultRouter from "./defaultRouter"
import buyer from "./buyer"
import seller from "./seller"
import user from "./user"
import users from "./users"
import offerRequests from "./offerRequests"
import offers from "./offers"
import chat from "./chat"
import paymentTransactions from "./paymentTransactions"
import messageLogs from "./messageLogs"
import images from "./images"
import promotions from "./promotions"
import categories from "./categories"
import subscriptions from "./subscriptions"
import netvisor from "./netvisor"
import apolloClient from "./apolloClient"
import common from "./common"
import news from "./news"

//  All modules with "routes" property have to be before the pageNotFound module
export default mergeModules(
  apolloClient,
  auth,
  payments,
  common,
  fees,
  user,
  buyer,
  seller,
  users,
  subscriptions,
  offerRequests,
  offers,
  chat,
  news,
  paymentTransactions,
  netvisor,
  messageLogs,
  defaultRouter,
  promotions,
  categories,
  pageNotFound,
  images,
)
