import { AppThunkAction } from "../../common/types"
import { subscribeOnNewsRemoved } from "../graphql/subscriptions"
import { newsRemoved } from "../actions"

let unsubscribe: null | (() => void) = null

export const subscribeOnNewsRemovalThunk: AppThunkAction =
  () => (dispatch, getState) => {
    unsubscribe && unsubscribe()

    const { newsRemovedUnsubscribe } = subscribeOnNewsRemoved(getState(), {
      next: ({ data }) => {
        if (data?.newsRemoved) {
          dispatch(newsRemoved(data.newsRemoved.id))
        }
      },
    })

    unsubscribe = newsRemovedUnsubscribe
  }
