import React, { ChangeEvent, useMemo } from "react"
import { FieldAdapter as Field } from "../../../../common/form"
import { RenderField, RenderSelect } from "../../../../common/components"
import { Button, Checkbox, Form, Input, Space, Select } from "antd"
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { CreateQuestionForm as ICreateQuestionForm } from "../../../../buyer/questions/types"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import {
  additionalQuestions,
  types,
} from "../../../../buyer/questions/constants"

const { Option } = Select

interface Props {
  values: ICreateQuestionForm
  handleSelectType: (value: React.ReactText) => void
  handleChangeOptionInputField: (
    id: string,
    key: string,
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  handleAddOption: () => void
  handleChangeChecked: (field: string) => (e: CheckboxChangeEvent) => void
  handleChangeOptionName: (
    id: string,
    lang: string,
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  handleChangeDoubleLanguageField: (
    field: "name" | "placeholder" | "extra_placeholder",
    language: "en" | "fi",
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  handleRemoveOption: (optionId: string) => void
}

const CreateQuestionForm = ({
  values,
  handleSelectType,
  handleChangeOptionInputField,
  handleAddOption,
  handleChangeChecked,
  handleChangeDoubleLanguageField,
  handleChangeOptionName,
  handleRemoveOption,
}: Props) => {
  const AdditionalComponents = useMemo(() => {
    return values.type && additionalQuestions[values.type] ? (
      <>
        {additionalQuestions[values.type].includes("placeholder") && (
          <Field
            name="placeholder.en"
            component={RenderField}
            type="text"
            label="Placeholder (En)"
            value={values.placeholder.en}
            onChange={handleChangeDoubleLanguageField("placeholder", "en")}
          />
        )}
        {additionalQuestions[values.type].includes("placeholder") && (
          <Field
            name="placeholder.fi"
            component={RenderField}
            type="text"
            label="Placeholder (Fi)"
            value={values.placeholder.fi}
            onChange={handleChangeDoubleLanguageField("placeholder", "fi")}
          />
        )}
        {additionalQuestions[values.type].includes("defaultValue") && (
          <Field
            name="defaultValue"
            component={RenderField}
            type="text"
            label="Default value"
            value={values.defaultValue}
          />
        )}
        {additionalQuestions[values.type].includes("extra_placeholder") && (
          <Field
            name="extra_placeholder.en"
            component={RenderField}
            type="text"
            label="Extra placeholder (En)"
            value={values.extra_placeholder.en}
            onChange={handleChangeDoubleLanguageField(
              "extra_placeholder",
              "en",
            )}
          />
        )}
        {additionalQuestions[values.type].includes("extra_placeholder") && (
          <Field
            name="extra_placeholder.fi"
            component={RenderField}
            type="text"
            label="Extra placeholder (Fi)"
            value={values.extra_placeholder.fi}
            onChange={handleChangeDoubleLanguageField(
              "extra_placeholder",
              "fi",
            )}
          />
        )}
        {additionalQuestions[values.type].includes("options") && (
          <Form.Item label="Optionals">
            {Object.keys(values.options).map((key) => (
              <Space
                style={{
                  display: "flex",
                  marginBottom: 8,
                  flexWrap: "wrap",
                }}
                align="baseline"
                key={key}
              >
                <Form.Item>
                  <Input
                    value={values.options[key].id}
                    placeholder="ID"
                    onChange={handleChangeOptionInputField(key, "id")}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    value={values.options[key].name?.en}
                    placeholder="Option (En)"
                    onChange={handleChangeOptionName(key, "en")}
                  />
                </Form.Item>
                <CloseCircleOutlined onClick={() => handleRemoveOption(key)} />
                <Form.Item>
                  <Input
                    value={values.options[key].name?.fi}
                    placeholder="Option (Fi)"
                    onChange={handleChangeOptionName(key, "fi")}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    value={values.options[key].order}
                    placeholder="Order"
                    type="number"
                    onChange={handleChangeOptionInputField(key, "order")}
                  />
                </Form.Item>
              </Space>
            ))}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddOption}
            >
              Add Option
            </Button>
          </Form.Item>
        )}
      </>
    ) : null
  }, [
    handleAddOption,
    handleChangeDoubleLanguageField,
    handleChangeOptionInputField,
    handleChangeOptionName,
    handleRemoveOption,
    values.defaultValue,
    values.extra_placeholder.en,
    values.extra_placeholder.fi,
    values.options,
    values.placeholder.en,
    values.placeholder.fi,
    values.type,
  ])

  return (
    <Form>
      <Field
        name="type"
        component={RenderSelect}
        label="Type"
        value={values.type}
        onSelect={handleSelectType}
      >
        {types.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Field>
      <Field
        name="id"
        component={RenderField}
        type="text"
        label="ID"
        value={values.id}
      />
      <Field
        name="name.en"
        component={RenderField}
        type="text"
        label="Question (En)"
        value={values.name.en}
        onChange={handleChangeDoubleLanguageField("name", "en")}
      />
      <Field
        name="name.fi"
        component={RenderField}
        type="text"
        label="Question (Fi)"
        value={values.name.fi}
        onChange={handleChangeDoubleLanguageField("name", "fi")}
      />
      <Form.Item label="Optional">
        <Checkbox
          checked={values.optional}
          onChange={handleChangeChecked("optional")}
        />
      </Form.Item>
      <Field
        name="order"
        component={RenderField}
        type="number"
        label="Order"
        value={values.order}
      />
      {AdditionalComponents}
    </Form>
  )
}

export default CreateQuestionForm
