import * as Yup from "yup"
import { RecordType } from "../types"

export enum AvailableLanguages {
  ENGLISH = "en",
  FINNISH = "fi",
}

export type ExistingSchemas = string | AvailableLanguages

export type IValidationSchema<FormType extends Record<string, unknown>> =
  Yup.ObjectSchema<Yup.Shape<RecordType | undefined, FormType>, RecordType>
