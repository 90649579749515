import React from "react"
import AuthRoute from "../../common/AuthRoute"
import { SubscriptionList } from "./components/containers"
import { ReloadOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <ReloadOutlined />,
      name: "Subscriptions",
      path: "subscriptions",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/subscriptions"
      path="/subscriptions"
      render={() => <SubscriptionList />}
    />,
  ],
}
