import { gql } from "@apollo/client"
import { NEWS_FRAGMENT } from "../../fragments"

export const MUTATION = gql`
  ${NEWS_FRAGMENT}

  mutation CreateNews($input: NewsCreationInput!) {
    createNews(input: $input) {
      ...NewsInfo
    }
  }
`
