import React from "react"
import { Auth } from "./containers"
import reducer from "./reducer"
import { LoadingUser } from "./containers/Loading"
import { Blocked } from "./containers/Blocked"
import { LoginRoute } from "./routes/LoginRoute"
import { LoadingUserRoute } from "./routes/LoadingRoute"
import { UserBlockedRoute } from "./routes/BlockedRoute"

export default {
  routes: [
    <LoginRoute
      key="/login"
      exact
      path="/login"
      redirect="/"
      renderComponent={<Auth />}
    />,
    <LoadingUserRoute
      key="/loading-user"
      exact
      path="/loading-user"
      redirect="/"
      renderComponent={<LoadingUser />}
    />,
    <UserBlockedRoute
      key="/blocked-user"
      exact
      path="/blocked-user"
      redirect="/"
      renderComponent={<Blocked />}
    />,
  ],
  reducer,
}
