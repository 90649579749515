import React from "react"
import { Button, Form, Input, Select } from "antd"
import { Category } from "../../../buyer/categories/types"
import { updateCategory } from "../../../buyer/categories/actions"
import { useDispatch } from "react-redux"
import { CategoryDB, CategorySubscriptionDB } from "hero24-types"
import _ from "lodash"

const { Option } = Select

interface Props {
  category: Category
}

enum EDiscountFormat {
  fixed = "fixed",
  percentage = "percentage",
}

const CategorySubscription = ({ category }: Props) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [changesSaved, setChangesSaved] = React.useState(false)

  function submitCategoryChanges(
    categoryId: string,
    category: Partial<CategoryDB>,
  ) {
    if (!changesSaved) {
      setChangesSaved(true)
      dispatch(updateCategory(categoryId, category))
      setTimeout(() => {
        setChangesSaved(false)
      }, 1200)
    }
  }

  return (
    <Form
      form={form}
      size="large"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      layout="horizontal"
      style={{ minWidth: 450 }}
      initialValues={{
        discount: _.get(category.subscriptions, "default.discount", ""),
        discountFormat: _.get(
          category.subscriptions,
          "default.discountFormat",
          "",
        ),
      }}
      onFinish={(values: Omit<CategorySubscriptionDB, "categoryId">) => {
        if (values.discount && values.discountFormat)
          submitCategoryChanges(category.id, {
            subscriptions: {
              default: {
                ...values,
                discount: Number(values.discount),
                categoryId: category.id,
              },
            },
          })
      }}
    >
      <Form.Item name="discount" label="Discount">
        <Input />
      </Form.Item>
      <Form.Item name="discountFormat" label="Discount Format">
        <Select>
          <Option value={EDiscountFormat.fixed}>Fixed</Option>
          <Option value={EDiscountFormat.percentage}>Percentage</Option>
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" disabled={changesSaved}>
        {changesSaved ? "Changes Saved..." : "Confirm"}
      </Button>
    </Form>
  )
}

export default CategorySubscription
