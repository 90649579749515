import { gql } from "@apollo/client"

export type SetChatSeenByAdminVariables = {
  seenByAdmin: boolean
  chatId: string
}

export const SET_CHAT_SEEN_BY_ADMIN_MUTATION = gql`
  mutation SetChatSeenByAdmin($chatId: String!, $seenByAdmin: Boolean!) {
    setChatSeenByAdmin(chatId: $chatId, seenByAdmin: $seenByAdmin)
  }
`
