import React from "react"
import { Collapse, Space } from "antd"
import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons"
import Text from "antd/lib/typography/Text"
import { Offer } from "../../../offers/list/types"
import { Purchases } from "./Purchases"
import { Earnings } from "./Earnings"
import { Netvisor } from "./Netvisor"
import { SendReceiptToEmail } from "./SendReceiptToEmail"
import { SendReceiptToCurrentUserEmail } from "./SendReceiptToCurrentUserEmail"
import { TimeAndWorkHours } from "./TimeAndWorkHours/TimeAndWorkHours"
import { Vats } from "../OfferRequest/Vats"
import { getAuthUser } from "../../../auth/selectors"
import { getSellerById } from "../../../seller/selectors"
import { useSelector } from "react-redux"
import { Store } from "../../../core/types"
import { getUserById } from "../../../user/selectors"

interface Props {
  offer: Offer
}

export const OfferView = ({ offer }: Props) => {
  const seller = useSelector((state: Store) =>
    getSellerById(state, offer.data.initial.sellerProfile),
  )

  const user = useSelector((state: Store) =>
    getUserById(state, offer.data.initial.buyerProfile),
  )

  const currentUser = useSelector(getAuthUser)

  if (!seller || !user || !currentUser) return null

  return (
    <>
      <Text>
        OfferId: {offer.id} OfferRequestId: {offer.data.initial.offerRequest}
      </Text>
      <Collapse defaultActiveKey={["when"]}>
        {offer.status === "completed" || offer.netvisorSalesInvoiceId ? (
          <Collapse.Panel
            header={
              <>
                Netvisor{" "}
                {offer.netvisorSalesInvoiceId &&
                offer.netvisorPurchaseInvoiceId ? (
                  <CheckCircleFilled />
                ) : (
                  <CheckCircleOutlined />
                )}
              </>
            }
            key="netvisor"
          >
            <Netvisor offer={offer} />
          </Collapse.Panel>
        ) : null}
        {["accepted", "completed"].includes(offer.status) ? (
          <Collapse.Panel header="Purchases" key="purchases">
            <Purchases offer={offer} />
          </Collapse.Panel>
        ) : null}
        <Collapse.Panel header="Vats" key="vats">
          <Vats offerRequestId={offer.data.initial.offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Time & Workhours" key="when">
          <TimeAndWorkHours offer={offer} />
        </Collapse.Panel>
        {["cancelled", "completed"].includes(offer.status) ? (
          <>
            <Collapse.Panel header="Earnings" key="earnings">
              <Earnings offer={offer} />
            </Collapse.Panel>
            <Collapse.Panel
              header="Send receipt to end user"
              key="receipt-user"
            >
              <Space>
                <SendReceiptToEmail
                  offer={offer}
                  user={user}
                  seller={seller}
                  isSeller={false}
                />

                <SendReceiptToEmail
                  offer={offer}
                  user={user}
                  seller={seller}
                  isSeller
                />
              </Space>
            </Collapse.Panel>
            <Collapse.Panel
              header="Send receipt to me (Accounting)"
              key="receipt-me"
            >
              <Space>
                <SendReceiptToCurrentUserEmail
                  offer={offer}
                  user={currentUser}
                  isSeller={false}
                />

                <SendReceiptToCurrentUserEmail
                  offer={offer}
                  user={currentUser}
                  isSeller
                />
              </Space>
            </Collapse.Panel>
          </>
        ) : null}
      </Collapse>
    </>
  )
}
