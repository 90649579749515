import React from "react"
import { green, blue, red, orange } from "@ant-design/colors"
import { Select, Tooltip } from "antd"
import { useDispatch } from "react-redux"
import { setOfferRequestStatus } from "../../../offerRequests/list/actions"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"

interface Props {
  offerRequest: OfferRequest
  readonly?: boolean
}

const statuses: Array<OfferRequest["data"]["status"]> = [
  "open",
  "cancelled",
  "accepted",
  "expired",
  "completed",
]

export const getStatusColor = (
  status: Props["offerRequest"]["data"]["status"],
) => {
  switch (status) {
    case "accepted":
      return green.primary
    case "completed":
      return blue.primary
    case "cancelled":
      return red.primary
    case "expired":
      return orange.primary
    case "open":
    default:
      return "black"
  }
}

export const OfferRequestStatus = (props: Props) => {
  const dispatch = useDispatch()
  const { offerRequest } = props
  const currentStatus = offerRequest.data.status

  if (props.readonly) {
    return (
      <span
        style={{
          fontSize: 14,
          color: getStatusColor(offerRequest.data.status),
        }}
      >
        {currentStatus.toUpperCase()}
      </span>
    )
  }

  if (["accepted", "completed"].includes(currentStatus)) {
    return (
      <Tooltip
        placement="topLeft"
        title={`To change OfferRequest from ${currentStatus.toLocaleUpperCase()} change the Offer-status.`}
      >
        <span
          style={{
            color: getStatusColor(offerRequest.data.status),
          }}
        >
          {currentStatus.toUpperCase()}
        </span>
      </Tooltip>
    )
    // } else if (
    //   currentStatus === "open" &&
    //   offerRequest.data.initial.prepaid === "paid" &&
    // ) {
    //   return (
    //     <Tooltip
    //       placement="topLeft"
    //       title={`If you want to 'accept' prepaid offerrequest set the offer as accepted. To 'cancel' contact developer (requires returning money and changes in Netvisor`}
    //     >
    //       <span
    //         style={{
    //           fontSize: 14,
    //           color: getStatusColor(offerRequest.data.status),
    //         }}
    //       >
    //         {currentStatus.toUpperCase()}
    //       </span>
    //     </Tooltip>
    //   )
  } else {
    return (
      <Select
        defaultValue={currentStatus}
        onSelect={(status) => {
          if (status === "open" || status === "cancelled") {
            dispatch(
              setOfferRequestStatus({
                id: offerRequest.id,
                status,
              }),
            )
          } else {
            alert("not allowed! (should never happen)")
          }
        }}
      >
        {statuses.map((status) => {
          // accepted, completed needs to be set to the offer not offeRequest
          // expired should be done by cloud not user
          const disabled =
            ["accepted", "completed", "expired"].includes(status) &&
            status !== currentStatus

          return (
            <Select.Option key={status} value={status} disabled={disabled}>
              <span
                style={
                  !disabled
                    ? {
                        fontSize: 14,
                        color: getStatusColor(status),
                      }
                    : { fontSize: 14 }
                }
              >
                {status.toUpperCase()}
              </span>
            </Select.Option>
          )
        })}
      </Select>
    )
  }
}
