import { MenuProps, notification } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import { FeeDB } from "hero24-types"
import { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { changeFeeStatus } from "../../../../../actions"
import { ERROR_MESSAGE, feePopconfirmData } from "../constants"
import { FeeStatusEnum } from "../types"
import Popconfirm from "../../../../Popconfirm"

interface Props {
  fee: FeeDB
  feeId: string
}

const useMenuItems = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { fee, feeId } = props

  const getOnMenuOkClickHandler = useCallback(
    (value: FeeStatusEnum) => async () => {
      setLoading(true)
      try {
        await dispatch(
          changeFeeStatus({
            id: feeId,
            status: value,
          }),
        )
      } catch {
        notification.error({ message: ERROR_MESSAGE })
      } finally {
        setLoading(false)
      }
    },
    [dispatch, feeId],
  )

  const items: MenuProps["items"] = useMemo(
    () =>
      Object.entries(feePopconfirmData).map((prop, key): ItemType => {
        return {
          label: (
            <Popconfirm
              title={prop[1].title}
              onConfirm={getOnMenuOkClickHandler(prop[0] as FeeStatusEnum)}
              loading={loading}
            >
              {prop[1].itemLabel}
            </Popconfirm>
          ),
          key,
          disabled: fee.status === prop[1].feeStatus,
        }
      }),
    [fee, loading, getOnMenuOkClickHandler],
  )

  return { items }
}

export default useMenuItems
