import { ReactElement } from "react"
import { Middleware, ReducersMapObject } from "redux"
import { Module, Modules, SideBar } from "../types"

export const mergeModules = (...modules: Module[]): Modules => {
  const reducers = modules.reduce((acc: ReducersMapObject, module) => {
    if (module.hasOwnProperty("reducer")) {
      const { reducer } = module
      return reducer ? { ...acc, ...reducer } : acc
    } else {
      const { reducers } = module
      return reducers ? { ...acc, ...reducers } : acc
    }
  }, {})
  const routes = modules.reduce(
    (acc: ReactElement[], { routes }) => (routes ? [...acc, ...routes] : acc),
    [],
  )
  const sideBarItems = modules.reduce(
    (acc: SideBar[], { sideBarItems }) =>
      sideBarItems ? [...acc, ...sideBarItems] : acc,
    [],
  )
  const middlewares = modules.reduce(
    (acc: Middleware[], { middlewares }) =>
      middlewares ? [...acc, ...middlewares] : acc,
    [],
  )
  return { reducers, routes, sideBarItems, middlewares }
}
