import React from "react"
import moment from "moment-timezone"
import { Link } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"
import { Button, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import get from "lodash/get"

import { User } from "../../types"

type Column = ColumnsType<User>

const getColumns: () => ColumnsType<User> = () => {
  const createdColumn: Column[0] = {
    title: "Created",
    render: (user: User) =>
      get(user, "data.createdAt", "") &&
      moment(get(user, "data.createdAt"), "x").fromNow(),
    key: "userData.createdAt",
    sorter: (userA: User, userB: User) =>
      get(userA, "data.createdAt", 0) > get(userB, "data.createdAt", 0)
        ? 1
        : -1,
  }

  const nameColumn: Column[0] = {
    title: "Name",
    render: (user: User) =>
      user?.data?.name && user?.isCreatedFromWeb
        ? `${user?.data?.name} (Stilaus user)`
        : `${user?.data?.name} (App user)`,
    key: "user.data.name",
    sorter: (userA: User, userB: User) =>
      get(userA, "data.name", "").localeCompare(get(userB, "data.name", "")),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, user: User) => {
      return typeof value === "string"
        ? get(user, "data.name", "").toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const emailColumn: Column[0] = {
    title: "Email",
    render: (user: User) => get(user, "data.email", ""),
    key: "user.data.email",
    sorter: (userA: User, userB: User) =>
      get(userA, "data.email", "").localeCompare(get(userB, "data.email", "")),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by email`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, user: User) => {
      return typeof value === "string"
        ? get(user, "data.email", "")
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const phoneColumn: Column[0] = {
    title: "Phone",
    render: (user: User) => user?.data?.phone || "",
    key: "user.data.phone",
    sorter: (userA: User, userB: User) =>
      get(userA, "data.phone", "").localeCompare(get(userB, "data.phone", "")),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by phone`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, user: User) => {
      return typeof value === "string"
        ? get(user, "data.phone", "")
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  const offerRequestsColumn: Column[0] = {
    title: "OfferRequests",
    render: (user: User) =>
      user.offerRequests ? (
        <Link to={`/offer_requests/${user.id}`}>
          {Object.keys(user.offerRequests).length}
        </Link>
      ) : (
        "-"
      ),
    key: "offerRequests",
    filters: [
      {
        text: "Has requests",
        value: "yes",
      },
      {
        text: "No requests",
        value: "no",
      },
    ],
    onFilter: (value: unknown, user: User) => {
      console.log(value)
      return value === "yes"
        ? Object.keys(get(user, "offerRequests", {})).length > 0
        : Object.keys(get(user, "offerRequests", {})).length === 0
    },
    sorter: (left, right) => {
      const leftRequests = Object.keys(get(left, "offerRequests", {})).length
      const rightRequests = Object.keys(get(right, "offerRequests", {})).length

      return leftRequests - rightRequests
    },
  }

  return [
    createdColumn,
    nameColumn,
    emailColumn,
    phoneColumn,
    offerRequestsColumn,
  ]
}

export default getColumns
