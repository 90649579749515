import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"
import { getAllSellers, approveSeller } from "../../../actions"
import { getCategories } from "../../../../buyer/categories/actions"
import { getColumns } from "../table"
import { SellerState, Seller } from "../../../types"
import { CategoriesState } from "../../../../buyer/categories/types"
import { SellerView } from "../../../../common/components/Seller/Seller"

const SellerList = () => {
  const dispatch = useDispatch()

  const sellers = useSelector(({ seller }: { seller: SellerState }) =>
    Object.values(seller.sellers),
  )

  const allCategories = useSelector(
    ({ categories }: { categories: CategoriesState }) => {
      return categories.categories
    },
  )

  const handleGetAllSellers = () => dispatch(getAllSellers())

  const handleApproveSeller = (id: string, isApprove: boolean) => {
    dispatch(approveSeller(id, isApprove))
  }

  useEffect(() => {
    dispatch(getAllSellers())
    dispatch(getCategories())
  }, [dispatch])

  const columns = getColumns(
    sellers,
    allCategories,
    handleApproveSeller,
    handleGetAllSellers,
  )

  const expandedRowRender = () => {
    const renderExpandedRows = (seller: Seller) => {
      return <SellerView seller={seller} allCategories={allCategories} />
    }
    return renderExpandedRows
  }

  return (
    <section style={{ textAlign: "center" }}>
      <h2>Seller List</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: { [key: string]: any }) => record.id}
        dataSource={sellers}
        columns={columns || []}
        pagination={{
          pageSize: 25,
        }}
        expandedRowRender={expandedRowRender()}
        loading={!sellers.length}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default SellerList
