import { Col, Radio, Row, Typography } from "antd"
import { WalletOutlined } from "@ant-design/icons"
import { RadioChangeEvent } from "antd/lib/radio/interface"
import { FC, useCallback } from "react"
import { PaymentService, SendingChannel } from "../constants"

const { Title } = Typography

type Props = {
  sendInvoiceWith: SendingChannel | undefined
  setSendInvoiceWith: (value: SendingChannel) => void
  prePayWith: PaymentService | undefined
  setPrePayWith: (value: PaymentService) => void
  postpaid: boolean
  setPostpaid: (value: boolean) => void
}

export const PaymentMethodPicker: FC<Props> = (props) => {
  const {
    prePayWith,
    sendInvoiceWith,
    postpaid,
    setSendInvoiceWith,
    setPostpaid,
    setPrePayWith,
  } = props

  const sendInvoiceWithChangeHandler = useCallback(
    (event: RadioChangeEvent) => setSendInvoiceWith(event.target.value),
    [setSendInvoiceWith],
  )

  const prePayWithChangeHandler = useCallback(
    (event) => setPrePayWith(event.target.value),
    [setPrePayWith],
  )

  const postpaidChangeHandler = useCallback(
    (event) => setPostpaid(event.target.value === "yes"),
    [setPostpaid],
  )

  return (
    <>
      {/* Payment type section */}
      <Row gutter={[24, 12]} className="title-block">
        <Col>
          <WalletOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Payment
          </Title>
        </Col>
      </Row>

      <Row
        gutter={[24, 12]}
        className="title-block"
        align="middle"
        justify="space-between"
      >
        <Col xs={24} lg={12}>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <Title className="question-header-title" level={4}>
                Customer pays with:
              </Title>
            </Col>
            <Col xs={24}>
              <Radio.Group
                value={prePayWith}
                onChange={prePayWithChangeHandler}
              >
                <Radio value={PaymentService.STRIPE}>Stripe</Radio>
                <Radio value={PaymentService.NETVISOR}>Netvisor</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {/* 
        {prePayWith === PaymentService.STRIPE && (
          <Col xs={24} lg={12}>
            <Row gutter={[24, 12]}>
              <Col xs={24}>
                <Title className="question-header-title" level={4}>
                  Send Stripe link with:
                </Title>
              </Col>
              <Col xs={24}>
                <Radio.Group
                  value={sendInvoiceWith}
                  onChange={sendInvoiceWithChangeHandler}
                >
                  <Radio value={SendingChannel.SMS}>SMS</Radio>
                  <Radio value={SendingChannel.EMAIL}>Email</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        )}
        */}
        <Col xs={24} lg={12}>
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <Radio.Group
                value={postpaid ? "yes" : "no"}
                onChange={postpaidChangeHandler}
              >
                <Radio value="no">Prepaid</Radio>
                <Radio value="yes">Postpaid</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24}>
              <div style={{ fontSize: 10 }}>
                Choosing Netvisor means you need to send invoice manually.
                <br />
                WILL NOT BE SHOWN IN APP UNTIL MARKED AS PAID AND APPROVED BY
                BUYER!
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
