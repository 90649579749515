import React, { ChangeEvent } from "react"
import { CreatePackageForm as ICreatePackageForm } from "../../../../buyer/packages/types"
import { FieldAdapter as Field } from "../../../../common/form"
import { RenderField } from "../../../../common/components"
import { Button, Form, Input, Space } from "antd"
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"

interface Props {
  values: ICreatePackageForm
  handleChangeDoubleLanguageField: (
    field: keyof Pick<ICreatePackageForm, "name" | "description">,
    language: "en" | "fi",
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  handleRemoveOption: (
    field: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">,
    cityId: string,
  ) => void
  handleChangeCityInput: (
    field: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">,
    key: string,
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  handleAddOption: (
    field: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">,
  ) => void
}

const cities: {
  cityType: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">
  label: string
}[] = [
  { cityType: "citiesIncluded", label: "Cities included" },
  { cityType: "citiesExcluded", label: "Cities excluded" },
]

const CreatePackageForm = ({
  values,
  handleChangeDoubleLanguageField,
  handleRemoveOption,
  handleChangeCityInput,
  handleAddOption,
}: Props) => {
  return (
    <Form>
      <Field
        name="id"
        component={RenderField}
        type="text"
        label="ID"
        value={values.id}
      />
      <Field
        name="name.en"
        component={RenderField}
        type="text"
        label="Package (En)"
        value={values.name.en}
        onChange={handleChangeDoubleLanguageField("name", "en")}
      />
      <Field
        name="name.fi"
        component={RenderField}
        type="text"
        label="Package (Fi)"
        value={values.name.fi}
        onChange={handleChangeDoubleLanguageField("name", "fi")}
      />
      <Field
        name="description.en"
        component={RenderField}
        type="text"
        label="Description (En)"
        value={values.description.en}
        onChange={handleChangeDoubleLanguageField("description", "en")}
      />
      <Field
        name="description.fi"
        component={RenderField}
        type="text"
        label="Description (Fi)"
        value={values.description.fi}
        onChange={handleChangeDoubleLanguageField("description", "fi")}
      />
      <Field
        name="order"
        component={RenderField}
        type="number"
        label="Order"
        value={values.order}
      />
      <Field
        name="pricePerHour"
        component={RenderField}
        type="number"
        label="Price/Hour (€)"
        value={values.pricePerHour}
      />
      <Field
        name="recommendedDuration"
        component={RenderField}
        type="string"
        label="Recommended Duration (h)"
        value={values.recommendedDuration}
      />
      {cities.map(({ cityType, label }) => (
        <Form.Item label={label} key={cityType}>
          {Object.keys(values[cityType]).map((key) => (
            <Space
              style={{
                display: "flex",
                marginBottom: 8,
                flexWrap: "wrap",
              }}
              align="baseline"
              key={key}
            >
              <Form.Item>
                <Input
                  value={values[cityType][key]}
                  placeholder="City"
                  onChange={handleChangeCityInput(cityType, key)}
                />
              </Form.Item>
              <CloseCircleOutlined
                onClick={() => handleRemoveOption(cityType, key)}
              />
            </Space>
          ))}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddOption(cityType)}
          >
            Add City
          </Button>
        </Form.Item>
      ))}
    </Form>
  )
}

export default CreatePackageForm
