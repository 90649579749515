import { Alert, Button, Col, Form, Popconfirm, Row, Select } from "antd"
import { FormikProps, withFormik } from "formik"
import { MutableRefObject, useEffect } from "react"

import { RenderField, RenderSelect } from "../../../common/components"
import { FieldAdapter as Field } from "../../../common/form"

import { CreateUserForm as ICreateUserForm, ResetFormRef } from "./types"
import { ValidationSchema } from "./validationSchema"
import { AvailableLanguages } from "../../../common/yupValidation/types"

const Option = Select.Option

interface CreateUserFormProps {
  onSubmit: (values: any, callback: () => void) => void
  isAlreadyExists: boolean
  resetFormRef?: MutableRefObject<ResetFormRef>
  loading: boolean
  isPopconfirmPresent?: boolean
  popconfirmTitle?: string
  popconfirmOkText?: string
  popconfirmCancelText?: string
}

const CreateUserForm = (
  props: CreateUserFormProps & FormikProps<ICreateUserForm>,
) => {
  const {
    values,
    isValid,
    isAlreadyExists,
    resetForm,
    resetFormRef,
    loading,
    setFieldValue,
    handleSubmit,
    isPopconfirmPresent,
    popconfirmTitle,
    popconfirmOkText,
    popconfirmCancelText,
  } = props

  useEffect(() => {
    if (resetFormRef) {
      resetFormRef.current = { resetForm }
    }
  }, [resetFormRef, resetForm])

  const onLanguageSelect = (value: React.ReactText) => {
    setFieldValue("language", value)
  }

  const submitButton = (
    <Button
      style={{ width: "100%" }}
      size="large"
      type="primary"
      htmlType="submit"
      loading={loading}
      disabled={!isValid || loading}
    >
      Submit
    </Button>
  )

  return (
    <Form name="user" onSubmitCapture={handleSubmit}>
      <Field
        name="firstName"
        component={RenderField}
        type="text"
        label="First Name"
        value={values.firstName}
      />
      <Field
        name="lastName"
        component={RenderField}
        type="text"
        label="Last Name"
        value={values.lastName}
      />
      <Field
        name="email"
        component={RenderField}
        type="text"
        label="Email"
        value={values.email}
      />
      <Field
        name="phone"
        component={RenderField}
        type="text"
        label="Phone number"
        value={values.phone}
      />
      <Field
        name="language"
        component={RenderSelect}
        label="Select user language"
        value={values.language}
        onSelect={onLanguageSelect}
      >
        <Option value="en">English</Option>
        <Option value="fi">Finnish</Option>
      </Field>

      <Row gutter={24}>
        {isAlreadyExists && (
          <Col>
            <Alert message={"User already exists"} type={"error"} />
          </Col>
        )}
        <Col>
          {isPopconfirmPresent ? (
            <Popconfirm
              disabled={!isValid || loading}
              title={popconfirmTitle}
              onConfirm={() => handleSubmit()}
              okText={popconfirmOkText}
              cancelText={popconfirmCancelText}
            >
              {submitButton}
            </Popconfirm>
          ) : (
            submitButton
          )}
        </Col>
      </Row>
    </Form>
  )
}

const createUserFormWithFormik = withFormik<
  CreateUserFormProps,
  ICreateUserForm
>({
  mapPropsToValues: () => ({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    language: AvailableLanguages.ENGLISH,
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values, resetForm)
  },
  validationSchema: ValidationSchema,
  displayName: "CreateUserForm",
})

export default createUserFormWithFormik(CreateUserForm)
