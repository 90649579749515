import { useEffect, useState } from "react"
import { Field, Formik, Form } from "formik"
import { Button, Row, Col, Descriptions } from "antd"

import { UpdateOfferPurchaseInput } from "../../graphql/mutations/updateOfferPurchase/index"

interface CreatePurchaseFormProps {
  onSubmit: (values: UpdateOfferPurchaseInput) => void
  purchase: UpdateOfferPurchaseInput
}

export const PurchaseForm = (props: CreatePurchaseFormProps) => {
  const { purchase, onSubmit } = props

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDisabled(purchase.pricePerHour <= 0 || purchase.duration <= 0)
  }, [purchase])

  return (
    <Formik initialValues={purchase} onSubmit={onSubmit}>
      {({ values: { duration, pricePerHour } }) => (
        <Form>
          <Descriptions
            layout="vertical"
            size="small"
            bordered
            style={{
              margin: 0,
              padding: 0,
              border: 0,
              background: "white",
            }}
          >
            <Descriptions.Item label="Price per hour">
              <Field
                name="pricePerHour"
                type="number"
                style={{ width: "100%", height: "100%" }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              <Field
                name="duration"
                type="number"
                style={{ width: "100%", height: "100%" }}
              />
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            layout="horizontal"
            size="small"
            bordered
            style={{
              margin: "10px 0px",
              padding: 0,
              border: 0,
              background: "white",
            }}
          >
            <Descriptions.Item label="Total">
              {(duration * pricePerHour).toFixed(2)} €
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={24}>
            <Col>
              <Button
                style={{ width: "100%" }}
                size="large"
                type="primary"
                htmlType="submit"
                disabled={disabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
