import { gql } from "@apollo/client"
import { UserDB, Address as AddressDB } from "hero24-types"
import {
  ADDRESS_FRAGMENT,
  Address,
  AddressAdapter,
} from "../../../common/graphql/fragments"
import { FirebaseAdapter } from "../../../common/utilities"

export type UserDataAddress = {
  key: string
  address: Address
}

export const USER_DATA_ADDRESS_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}

  fragment UserDataAddressInfo on UserDataAddressDto {
    address {
      ...AddressInfo
    }
    key
  }
`

export const UserDataAddressAdapter = new FirebaseAdapter<
  Required<UserDB["data"]>["addresses"],
  UserDataAddress[]
>({
  toFirebase: (graphql) =>
    Object.fromEntries(
      graphql.map(({ key, address }) => [
        key,
        AddressAdapter.toFirebase(address),
      ]),
    ),
  toGraphQL: (firebase) =>
    Object.keys(firebase).map((key) => ({
      key,
      address: AddressAdapter.toGraphQL(firebase[key] as AddressDB),
    })),
})
