import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"

import { getColumns } from "./helpers"
import { selectPromotions } from "../../store/selectors"
import { getCategories as selectCategories } from "../../../buyer/categories/selectors"
import { getCategories } from "../../../buyer/categories/actions"
import { getPromotionsWithCategories } from "../../utils"
import { PromotionsWithCategories } from "../../types"

const PromotionList = () => {
  const dispatch = useDispatch()
  const promotions = useSelector(selectPromotions)
  const categories = useSelector(selectCategories)

  const [promotionsWithCategories, setPromotionsWithCategories] =
    useState<PromotionsWithCategories>([])

  useEffect(() => {
    setPromotionsWithCategories(
      getPromotionsWithCategories(promotions, categories),
    )
  }, [promotions, categories])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  const columns = getColumns(categories)

  return (
    <Table
      tableLayout="auto"
      rowKey={({ id }) => id}
      dataSource={promotionsWithCategories}
      columns={columns || []}
      pagination={{
        pageSize: 25,
      }}
      scroll={{ x: 800 }}
    />
  )
}

export default PromotionList
