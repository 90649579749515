import { gql } from "@apollo/client"
import { OFFER_REQUEST_FRAGMENT } from "../../fragments"

export const MUTATION = gql`
  ${OFFER_REQUEST_FRAGMENT}

  mutation CreateOfferRequest($input: OfferRequestCreationInput!) {
    createOfferRequest(input: $input) {
      ...OfferRequestInfo
    }
  }
`
