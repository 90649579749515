import actions from "./actions"
import description from "./description"
import endAt from "./endAt"
import link from "./link"
import startAt from "./startAt"
import title from "./title"

export default () => [
  title(),
  description(),
  startAt(),
  endAt(),
  link(),
  actions(),
]
