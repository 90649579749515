import { SUBSCRIPTION_INTERVAL } from "hero24-types"
import { Values } from "../common/types"

export const SUBSCRIPTION_PERIOD: { [key: string]: SUBSCRIPTION_INTERVAL } = {
  Daily: "daily",
  Weekly: "weekly",
  Biweekly: "bi-weekly",
  Monthly: "monthly",
}

export const PaymentService = {
  NETVISOR: "netvisor",
  STRIPE: "stripe",
} as const

export const PlatformProviderService = {
  HERO_PAYMENTS_OY: "hero_payments_oy",
  HERO24_OY: "hero24_oy",
  HERO24_B2B_OY: "hero24_b2b_oy",
} as const

export type PlatformProviderService = Values<typeof PlatformProviderService>

export type PaymentService = Values<typeof PaymentService>

export const SendingChannel = {
  EMAIL: "email",
  SMS: "sms",
} as const

export type SendingChannel = Values<typeof SendingChannel>
