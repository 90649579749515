import React, { useEffect, useState } from "react"
import { Image } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { getImage } from "../../actions"
import { getImagesByIds } from "../../selectors"
import { Image as ImageType } from "../../types"

import ImageSkeleton from "../ImageSkeleton"

import classes from "./ImageGroup.module.css"

interface Props {
  imageIds: string[]
}

interface IsImageLoaded {
  [key: string]: boolean
}

const ImageGroup = ({ imageIds }: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState<IsImageLoaded>({})

  const images = useSelector(getImagesByIds(imageIds))

  const dispatch = useDispatch()

  useEffect(() => {
    imageIds.forEach((id) => dispatch(getImage(id)))
  }, [dispatch, imageIds])

  const renderImage = (imageData: ImageType, isLoaded: boolean) => {
    const classNames = `${classes.image} ${
      isLoaded ? "" : classes.unloadedImage
    }`

    return (
      <Image
        src={imageData.globalPath}
        preview={true}
        loading="lazy"
        className={classNames}
        onLoad={() => {
          setIsImageLoaded((prevState) => ({
            ...prevState,
            [imageData.id]: true,
          }))
        }}
      />
    )
  }

  return (
    <div className={classes.imageGroup}>
      {imageIds.map((id, index) => {
        const image = images.find((image) => image.id === id)

        if (image) {
          const isLoaded = isImageLoaded[image.id]

          return (
            <React.Fragment key={index}>
              {isLoaded ? null : <ImageSkeleton />}
              {renderImage(image, isLoaded)}
            </React.Fragment>
          )
        }

        return <ImageSkeleton key={index} />
      })}
    </div>
  )
}

export default ImageGroup
