import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"
import {
  OFFER_REQUEST_QUESTION_OPTION_FRAGMENT,
  OfferRequestQuestionOption,
  OfferRequestQuestionOptionAdapter,
} from "./OfferRequestQuestionOptionInfo"
import { PlainOfferRequestQuestion } from "../../../types"
import { gql } from "@apollo/client"
import { FirebaseAdapter } from "../../../../common/utilities"
import { TRANSLATION_FIELD_FRAGMENT } from "../../../../common/graphql/fragments"

type QuestionType = "checkbox"

type PlainOfferRequestCheckBoxQuestion = PlainOfferRequestQuestion & {
  type: QuestionType
}

export interface OfferRequestCheckBoxQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  options: OfferRequestQuestionOption[]
}

export const OfferRequestCheckBoxQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestCheckBoxQuestion,
  OfferRequestCheckBoxQuestion
>({
  toFirebase: (graphql) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(graphql),
    type: "checkbox" as QuestionType,
    options: graphql.options.map((option) =>
      OfferRequestQuestionOptionAdapter.toFirebase(option),
    ),
  }),
  toGraphQL: (firebase) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(firebase),
    type: "checkbox" as QuestionType,
    options: firebase.options.map((option) =>
      OfferRequestQuestionOptionAdapter.toGraphQL(option),
    ),
  }),
})

export const OFFER_REQUEST_CHECK_BOX_QUESTION_FRAGMENT = gql`
  ${OFFER_REQUEST_QUESTION_OPTION_FRAGMENT}
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestCheckBoxQuestionInfo on OfferRequestCheckBoxQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    options {
      ...OfferRequestQuestionOptionInfo
    }
  }
`
