import { OfferRequestQuestion } from "hero24-types"
import omit from "lodash/omit"
import isString from "lodash/isString"

import {
  DependencyId,
  PlainOfferRequestQuestion,
  OmittedDependencyIdPlainOfferRequestQuestion,
} from "../types"
import { QUESTION_FLAT_ID_NAME } from "../constants"
import { fillQuestion } from "./fillQuestion"

export const offerRequestQuestionsToTree = (
  questions: PlainOfferRequestQuestion[],
): OfferRequestQuestion[] => {
  const mainQuestions: PlainOfferRequestQuestion[] = []

  const questionById: Record<
    DependencyId,
    OmittedDependencyIdPlainOfferRequestQuestion
  > = {}

  questions.forEach((withDepsIdQuestion) => {
    const dependencyId = withDepsIdQuestion[QUESTION_FLAT_ID_NAME]

    const question = omit(
      withDepsIdQuestion,
      QUESTION_FLAT_ID_NAME,
    ) as OmittedDependencyIdPlainOfferRequestQuestion

    if (isString(dependencyId)) {
      questionById[dependencyId] = question
    } else {
      mainQuestions.push(question)
    }
  })

  return mainQuestions.map((question) => fillQuestion(question, questionById))
}
