import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  setInvoice,
  setPrePay,
  setPostpaid,
} from "../../../../payments/actions"
import { PaymentMethodPicker } from "../../../../payments/components"
import { PaymentService, SendingChannel } from "../../../../payments/constants"
import {
  selectPostpaid,
  selectPrePayWith,
  selectSendInvoiceWith,
} from "../../../../payments/selectors"

const Payment = () => {
  const dispatch = useDispatch()

  const prePayWith = useSelector(selectPrePayWith)
  const postpaid = useSelector(selectPostpaid)
  const sendInvoiceWith = useSelector(selectSendInvoiceWith)

  const setSendInvoiceWithHandler = useCallback(
    (sendingWay: SendingChannel) => dispatch(setInvoice(sendingWay)),
    [dispatch],
  )

  const setPrePayWithHandler = useCallback(
    (paymentService: PaymentService) => dispatch(setPrePay(paymentService)),
    [dispatch],
  )

  const setPostpaidHandler = useCallback(
    (isPostpaid: boolean) => dispatch(setPostpaid(isPostpaid ? "yes" : "no")),
    [dispatch],
  )

  return (
    <section className="section">
      <PaymentMethodPicker
        postpaid={postpaid === "yes"}
        prePayWith={prePayWith}
        sendInvoiceWith={sendInvoiceWith}
        setSendInvoiceWith={setSendInvoiceWithHandler}
        setPrePayWith={setPrePayWithHandler}
        setPostpaid={setPostpaidHandler}
      />
    </section>
  )
}

export default Payment
