import * as Yup from "yup"
import { ExistingSchemas } from "../../types"

export type isFieldRequiredFunction = (
  schema: Yup.StringSchema<any>,
  isRequired: boolean,
) => Yup.Schema<ExistingSchemas>

export const isFieldRequired: isFieldRequiredFunction = (schema, isRequired) =>
  isRequired ? schema.required("Required") : schema
