import { gql } from "@apollo/client"
import { OfferRequestDB } from "hero24-types"
import { FirebaseAdapter } from "../../../../common/utilities"

type ChangesAcceptedDB = Required<OfferRequestDB["data"]>["changesAccepted"]

export type OfferRequestDataChangesAccepted = {
  detailsChangeAccepted: boolean
  timeChangeAccepted: boolean
}

export const OfferRequestDataChangesAcceptedAdapter = new FirebaseAdapter<
  ChangesAcceptedDB,
  OfferRequestDataChangesAccepted
>({
  toFirebase: ({ detailsChangeAccepted, timeChangeAccepted }) => ({
    detailsChangeAccepted: detailsChangeAccepted,
    timeChangeAccepted: timeChangeAccepted,
  }),
  toGraphQL: ({ detailsChangeAccepted, timeChangeAccepted }) => ({
    detailsChangeAccepted: detailsChangeAccepted,
    timeChangeAccepted: timeChangeAccepted,
  }),
})

export const OFFER_REQUEST_DATA_CHANGES_ACCEPTED_FRAGMENT = gql`
  fragment OfferRequestDataChangesAcceptedInfo on OfferRequestDataChangesAcceptedDto {
    detailsChangeAccepted
    timeChangeAccepted
  }
`
