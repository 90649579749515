import { ApolloClient } from "@apollo/client"
import {
  OfferRequestDB,
  OfferRequestDateQuestion,
  PaidStatus,
} from "hero24-types"
import {
  GetOfferRequestData,
  GetOfferRequestVariables,
  GET_OFFER_REQUEST_QUERY,
} from "../graphql/queries/GetOfferRequest"
import { PaymentService } from "../../payments/constants"
import { OfferRequestAdapter } from "../graphql/fragments"

export const getDateQuestion = (offerRequest: OfferRequestDB) => {
  return offerRequest.data.initial.questions.find(
    (question) => question.type === "date",
  ) as OfferRequestDateQuestion | undefined
}

export const isPrepaidSuccessfully = (offerRequest: OfferRequestDB) => {
  return (
    offerRequest.data.initial.prePayWith === PaymentService.NETVISOR ||
    (offerRequest.data.initial.prePayWith === PaymentService.STRIPE &&
      offerRequest.data.initial.prepaid === PaidStatus.PAID)
  )
}

export const findOfferRequestDeadline = (offerRequest: OfferRequestDB) => {
  const dateQuestion = getDateQuestion(offerRequest)

  if (offerRequest.data.lastAgreedStartTime) {
    return offerRequest.data.lastAgreedStartTime
  }

  if (!dateQuestion) {
    return undefined
  } else if (dateQuestion.suitableTimes) {
    const suitableTimes = Object.values(dateQuestion.suitableTimes)
    const last = suitableTimes.pop()
    return last ? last.endTime : undefined
  } else {
    return dateQuestion.preferredTime || undefined
  }
}

export const getOfferRequestFromDB = async (
  id: string,
  apolloClient: ApolloClient<unknown>,
) => {
  const {
    data: { offerRequest },
  } = await apolloClient.query<GetOfferRequestData, GetOfferRequestVariables>({
    query: GET_OFFER_REQUEST_QUERY,
    variables: { id },
  })

  if (!offerRequest) {
    return undefined
  }

  return OfferRequestAdapter.toFirebase(offerRequest)
}

export * from "./fillQuestion"
export * from "./offerRequestQuestionsToArray"
export * from "./offerRequestQuestionsToTree"
