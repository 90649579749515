import { gql } from "@apollo/client"
import { ChatMemberDto } from "../fragments/ChatMemberInfo"

export type AddMemberToChatData = {
  addMemberToChat: boolean
}

export type AddMemberToChatVariables = {
  chatId: string
  role: ChatMemberDto["role"]
  userId: string
}

export const ADD_MEMBER_TO_CHAT_MUTATION = gql`
  mutation AddMemberToChat(
    $chatId: String!
    $userId: String!
    $role: ChatMemberRole!
  ) {
    addMemberToChat(chatId: $chatId, userId: $userId, role: $role)
  }
`
