import { PurchaseInvoice } from "./containers"
import React from "react"
import AuthRoute from "../common/AuthRoute"

export const PURCHASE_INVOICE_ROUTE = "purchaseInvoice"

export default [
  <AuthRoute
    exact
    key={PURCHASE_INVOICE_ROUTE}
    path={`/${PURCHASE_INVOICE_ROUTE}`}
    render={() => <PurchaseInvoice />}
  />,
]
