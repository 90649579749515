import { Col, Row } from "antd"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import NewsAdderButton from "../components/NewsAdderButton"
import NewsList from "../components/NewsList"
import {
  getAllNewsThunk,
  subscribeOnNewsUpdatesThunk,
  subscribeOnNewsRemovalThunk,
  subscribeOnNewsAddingThunk,
} from "../thunk-actions"

const News = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllNewsThunk())
    dispatch(subscribeOnNewsUpdatesThunk())
    dispatch(subscribeOnNewsRemovalThunk())
    dispatch(subscribeOnNewsAddingThunk())
  }, [dispatch])

  return (
    <Row>
      <Col xs={24}>
        <Row justify="end" style={{ paddingRight: 20 }}>
          <NewsAdderButton />
        </Row>
      </Col>
      <Col xs={24} style={{ marginTop: 10 }}>
        <NewsList />
      </Col>
    </Row>
  )
}

export default News
