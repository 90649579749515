import React from "react"
import { Tooltip } from "antd"

import { Categories } from "../../../../buyer/categories/types"
import { Promotions } from "../../../types"

const getCategoriesOptions = (
  categories: Categories,
  activePromotions: Promotions,
  plannedPromotions: Promotions,
) => {
  const categoriesWithActivePromotions = activePromotions.map(
    ({ data: { categoryId } }) => categoryId,
  )

  const categoriesWithPlannedPromotions = plannedPromotions.map(
    ({ data: { categoryId } }) => categoryId,
  )

  return Object.values(categories).map(({ id, name }) => {
    const isDisabledCategory =
      categoriesWithActivePromotions.includes(id) ||
      categoriesWithPlannedPromotions.includes(id)

    return {
      label: isDisabledCategory ? (
        <Tooltip title="This category already has an active or planned promotion">
          {name.en}
        </Tooltip>
      ) : (
        name.en
      ),
      value: id,
      disabled: isDisabledCategory,
    }
  })
}

export default getCategoriesOptions
