import { ApolloThunkSubscription } from "../../../../apolloClient/types"
import { apolloSubscription } from "../../../../apolloClient/utils"
import { News } from "../../fragments"
import { SUBSCRIPTION } from "./subscription"

type Data = {
  newsUpdated: News
}

export const subscribeOnNewsUpdated: ApolloThunkSubscription<Data> = (
  state,
  observer,
) => {
  const observable = apolloSubscription<Data, never>({
    state,
    query: SUBSCRIPTION,
  })

  const subscription = observable.subscribe(observer)

  return {
    newsUpdatedUnsubscribe: () => subscription.unsubscribe(),
  }
}
