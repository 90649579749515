import React from "react"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { List } from "antd"

interface Props {
  offerRequest: OfferRequest
}

export const OffersList = (props: Props) => {
  const offers =
    props.offerRequest.offers && Object.keys(props.offerRequest.offers)
  if (!offers?.length) {
    return <span>-</span>
  }
  return (
    <List
      dataSource={offers}
      renderItem={(offerId) => <List.Item>{offerId}</List.Item>}
    />
  )
}
