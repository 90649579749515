import { Store } from "../core/types"
import { Image } from "./types"

export const getImagesByIds =
  (imageIds: string[]) =>
  (state: Store): Image[] =>
    imageIds.reduce(
      (acc, curr) => (state.images[curr] ? [...acc, state.images[curr]] : acc),
      [] as Image[],
    )
