import React from "react"
import { Descriptions } from "antd"
import { Category } from "../../../buyer/categories/types"
import { Questions } from "../../../buyer/questions/types"
import CategoryInfo from "./Info"
import CategoryQuestions from "./Questions"
import CategoryPackages from "./Packages"
import CategoryRecommended from "./Recommended"
import CategorySubscription from "./Subscription"

interface Props {
  category: Category
  questions: Questions
}

const CategoryView = ({ category, questions }: Props) => {
  return (
    <>
      <Descriptions title="Ids">
        <Descriptions.Item label="CategoryID">{category.id}</Descriptions.Item>
      </Descriptions>
      <Descriptions title="Info">
        <Descriptions.Item>
          <CategoryInfo category={category} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"} title="Questions">
        <Descriptions.Item>
          <CategoryQuestions category={category} questions={questions} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"} title="Packages">
        <Descriptions.Item>
          <CategoryPackages category={category} packages={category.packages} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={"stretch-row"} title="Recommended">
        <Descriptions.Item>
          <CategoryRecommended
            category={category}
            recommended={category.recommended}
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Subscription">
        <Descriptions.Item>
          <CategorySubscription category={category} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export default CategoryView
