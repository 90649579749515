import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"
import { getAllSellers } from "../../../actions"
import { getSellersWithReviews } from "../../../selectors"
import { Review } from "../../../types"
import { getColumns, expandedRow } from "../table"
import EditReviewModal from "./EditReviewModal"

const ReviewsList = () => {
  const [isEditReview, setIsEditReview] = useState(false)
  const [review, setReview] = useState<Review>()

  const dispatch = useDispatch()

  const sellers = useSelector(getSellersWithReviews)

  useEffect(() => {
    dispatch(getAllSellers())
  }, [dispatch])

  const columns = getColumns(sellers)

  const openModal = (review: Review) => {
    setReview(review)
    setIsEditReview(true)
  }

  const closeModal = () => {
    setIsEditReview(false)
  }

  return (
    <section style={{ textAlign: "center" }}>
      <h2>Reviews List</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: { [key: string]: any }) => record.id}
        dataSource={sellers}
        columns={columns || []}
        pagination={{
          pageSize: 25,
        }}
        expandedRowRender={expandedRow(openModal)}
        loading={!sellers.length}
        scroll={{ x: 800 }}
      />
      <EditReviewModal
        isOpen={isEditReview}
        closeModal={closeModal}
        review={review}
      />
    </section>
  )
}

export default ReviewsList
