import {
  TRANSLATION_FIELD_FRAGMENT,
  TranslationField,
  TranslationFieldAdapter,
} from "../../../../common/graphql/fragments"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"
import { PlainOfferRequestQuestion } from "../../../types"
import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"

import { gql } from "@apollo/client"

type QuestionType = "textarea"

type PlainOfferRequestTextAreaQuestion = PlainOfferRequestQuestion & {
  type: QuestionType
}

export interface OfferRequestTextAreaQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  placeholder?: Maybe<TranslationField>
  value?: Maybe<string>
}

export const OfferRequestTextAreaQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestTextAreaQuestion,
  OfferRequestTextAreaQuestion
>({
  toFirebase: (graphql) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(graphql),
    type: "textarea" as QuestionType,
    placeholder: graphql.placeholder
      ? TranslationFieldAdapter.toFirebase(graphql.placeholder)
      : null,
    value: graphql.value || null,
  }),
  toGraphQL: (firebase) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(firebase),
    type: "textarea" as QuestionType,
    placeholder:
      firebase.placeholder &&
      TranslationFieldAdapter.toGraphQL(firebase.placeholder),
    value: firebase.value,
  }),
})

export const OFFER_REQUEST_TEXT_AREA_QUESTION_FRAGMENT = gql`
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestTextAreaQuestionInfo on OfferRequestTextAreaQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    placeholder {
      ...TranslationFieldInfo
    }
    value
  }
`
