import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { grey } from "@ant-design/colors"
import { getAllSellers } from "../../../seller/actions"
import { getSellers } from "../../../seller/selectors"
import { Select, Typography, Row, Col, Radio } from "antd"
import { UserAddOutlined } from "@ant-design/icons"
import { Seller } from "../../../seller/types"
import { setDoerPickStrategy } from "../actions"

const { Option } = Select
const { Title } = Typography

export const DoerStep = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllSellers())
  }, [dispatch])

  const sellers = useSelector(getSellers)
  const [filteredSellers, setFilteredSellers] = useState<Seller[]>([])
  const [selectedSeller, setSelectedSeller] = useState<Seller>()

  const [pickStrategy, setPickStrategy] = useState<"first" | "choose">("first")

  useEffect(() => {
    if (selectedSeller?.id) {
      dispatch(
        setDoerPickStrategy({
          pickStrategy,
          preferred: {
            [selectedSeller?.id]: true,
          },
        }),
      )
    }
  }, [dispatch, pickStrategy, selectedSeller])

  const onSearchSellers = useCallback(
    (keyword: string) => {
      setFilteredSellers(
        Object.entries(sellers)
          .filter(([, seller]) =>
            seller.sellerData.companyName
              .trim()
              .toLowerCase()
              .startsWith(keyword.toLowerCase()),
          )
          .map(([, seller]) => seller),
      )
    },
    [sellers],
  )

  const options = useMemo(() => {
    return filteredSellers.map((seller) => {
      const metaStyle = {
        fontSize: "0.7rem",
        color: grey.primary,
      }
      return (
        <Option
          key={seller.id}
          value={seller.id}
          disabled={!seller.sellerData.isApproved}
        >
          {seller.sellerData.companyName}
          {"  "}
          <>
            <br />
            <span style={metaStyle}>
              {"  "}
              {seller.sellerData.companyEmail}
            </span>
          </>
        </Option>
      )
    })
  }, [filteredSellers])

  const handleChange = (id: string) => {
    setSelectedSeller(sellers[id])
  }

  return (
    <section className="section">
      <Row
        gutter={[24, 12]}
        className="title-block"
        align="middle"
        justify="space-between"
      >
        <Col xs={24} lg={12}>
          <UserAddOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Preferred doer
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24} style={{ marginTop: 6, marginBottom: 6 }}>
          <Select
            size="large"
            showSearch
            value={selectedSeller?.id}
            placeholder="Search seller"
            style={{ width: "100%" }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={onSearchSellers}
            onChange={handleChange}
            // loading={loading}
            // notFoundContent={loading ? <Spin size="small" /> : null}
            dropdownMatchSelectWidth={false}
          >
            {options.length > 0 ? options : null}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <div style={{ marginTop: 10 }}>
            If no preferred doer or preferred doer declines
          </div>
          <Radio.Group
            value={pickStrategy}
            onChange={(e) => setPickStrategy(e.target.value)}
          >
            <Radio value="first">
              First offer will be accepted automatically
            </Radio>
            <Radio value="choose">Let customer choose from offers</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </section>
  )
}
