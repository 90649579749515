import firebase from "firebase/app"
import { PromotionsDB } from "hero24-types"
import { Dispatch } from "redux"

import { getArrayFromObject } from "../../../common/utilities"
import { FIREBASE_PATH_PROMOTIONS } from "../../constants"
import { setPromotions } from "./"

const subscribeToPromotions = (dispatch: Dispatch) => {
  const ref = firebase.database().ref(FIREBASE_PATH_PROMOTIONS)

  ref.on("value", (snapshot) => {
    const dbPromotions = snapshot.val()

    if (dbPromotions) {
      const promotions = getArrayFromObject<PromotionsDB, "id">(
        dbPromotions,
        "id",
      )

      dispatch(setPromotions(promotions))
    }
  })
}

export default subscribeToPromotions
