import React from "react"
import { Button, Checkbox, Form, Input, Select } from "antd"
import { Category } from "../../../buyer/categories/types"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { updateCategory } from "../../../buyer/categories/actions"
import { CategoryDB } from "hero24-types"

const { Option } = Select

interface Props {
  category: Category
}

enum EAddressType {
  basic = "basic",
  delivery = "delivery",
}

const inputFields = [
  { name: "categoryNameEn", label: "Category name (EN)" },
  { name: "categoryNameFi", label: "Category name (FI)" },
  { name: "minimumDuration", label: "Minimum Duration (h)" },
  { name: "minimumPricePerHour", label: "Minimum Price/Hour (€)" },
  { name: "defaultPrice", label: "Default Price/Hour (€)" },
  { name: "defaultCustomerVAT", label: "Customer VAT" },
  { name: "defaultServiceProviderVAT", label: "Service provider VAT" },
  { name: "netvisorKey", label: "Netvisor key" },
]

const CategoryInfo = ({ category }: Props) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [changesSaved, setChangesSaved] = React.useState(false)

  function submitCategoryChanges(
    categoryId: string,
    category: Partial<CategoryDB>,
  ) {
    if (!changesSaved) {
      setChangesSaved(true)
      dispatch(updateCategory(categoryId, category))
      setTimeout(() => {
        setChangesSaved(false)
      }, 1200)
    }
  }

  return (
    <Form
      form={form}
      size="large"
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 16,
      }}
      layout="horizontal"
      style={{ minWidth: 450 }}
      initialValues={{
        ..._.pick(category, [
          "minimumDuration",
          "minimumPricePerHour",
          "defaultPrice",
          "netvisorKey",
          "defaultCustomerVAT",
          "defaultServiceProviderVAT",
          "isServiceProviderVATLocked",
          "isConstructionVAT",
        ]),
        categoryNameEn: category.name.en,
        categoryNameFi: category.name.fi,
        addresses: category.addresses.type,
      }}
      onFinish={(values) => {
        const _category: Partial<CategoryDB> = {
          ..._.omit(values, ["categoryNameEn", "categoryNameFi"]),
          addresses: {
            ...(values.addresses === EAddressType.basic
              ? { main: true }
              : { from: true, to: true }),
          },
          name: { en: values.categoryNameEn, fi: values.categoryNameFi },
        }
        submitCategoryChanges(category.id, _category)
      }}
    >
      {inputFields.map((field) => (
        <Form.Item {...field} key={field.name}>
          <Input />
        </Form.Item>
      ))}
      <Form.Item
        name="isServiceProviderVATLocked"
        valuePropName="checked"
        label="VAT Locked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="isConstructionVAT"
        valuePropName="checked"
        label="Construction VAT"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item name="addresses" label="Addresses">
        <Select>
          <Option value={EAddressType.basic}>Basic</Option>
          <Option value={EAddressType.delivery}>Delivery</Option>
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" disabled={changesSaved}>
        {changesSaved ? "Changes Saved..." : "Confirm"}
      </Button>
    </Form>
  )
}

export default CategoryInfo
