import firebase from "firebase/app"
import { OfferDB } from "hero24-types"
import { FIREBASE_PATH_OFFERS } from "./list/constants"

export const getOfferFromDB = async (id: string) => {
  const ref = await firebase.database().ref(FIREBASE_PATH_OFFERS).child(id)

  const snapshot = await ref.once("value")
  return snapshot.val() as OfferDB
}
