import { PlusOutlined, UserAddOutlined } from "@ant-design/icons"
import { Badge, Button, Col, Row, Typography } from "antd"
import isEmpty from "lodash/isEmpty"
import debounce from "lodash/debounce"
import { useDispatch, useSelector } from "react-redux"
import SearchUser from "../../../common/components/SearchUser"
import { GET_USERS } from "../../../user/actionTypes"
import { getListUsers, getSelectedUsersId } from "../../../user/selectors"
import { searchUsers, selectUser } from "../../../user/actions"
import { UserState } from "../../../user/types"
const { Title } = Typography

interface UserStepProps {
  addUser: () => void
}

interface UserInterface {
  user: UserState
}

const UserStep = ({ addUser }: UserStepProps) => {
  const dispatch = useDispatch()
  const selectedUserId = useSelector(getSelectedUsersId)
  const users = useSelector(getListUsers)
  const loading = useSelector(({ user }: UserInterface) => user.loading)

  const onSearchUsers = debounce((value) => {
    dispatch(searchUsers(value))
  }, 800)

  const handleChange = (value: string): void => {
    dispatch(selectUser(value))
  }

  const onOpenCreateUserModal = () => {
    dispatch({
      type: GET_USERS,
      payload: [],
    })
    addUser()
  }

  return (
    <section className="section">
      <Row
        gutter={[24, 12]}
        className="title-block"
        align="middle"
        justify="space-between"
      >
        <Col xs={24} lg={12}>
          <UserAddOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            User
          </Title>
        </Col>
        <Col xs={24} lg={12}>
          <Button
            block
            className="button-with-header"
            icon={<PlusOutlined />}
            type="primary"
            onClick={onOpenCreateUserModal}
          >
            Create new user
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Title className="question-header-title" level={4}>
            Select user
          </Title>
        </Col>
        <Col>
          {selectedUserId && !isEmpty(selectedUserId) ? (
            <Badge
              count="Ok"
              style={{ marginLeft: 12, backgroundColor: "green" }}
            />
          ) : (
            <Badge
              count="Mandatory"
              style={{ marginLeft: 12, backgroundColor: "red" }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24} style={{ marginTop: 6, marginBottom: 6 }}>
          <SearchUser
            selectedUserId={selectedUserId}
            handleChange={handleChange}
            onSearchUsers={onSearchUsers}
            users={users}
            loading={loading ?? false}
          />
        </Col>
      </Row>
    </section>
  )
}

export default UserStep
