import { gql } from "@apollo/client"

import { GraphQLPagination } from "../../../common/graphql/types"
import { CHAT_INFO_FRAGMENT, ChatDto } from "../fragments/ChatInfo"
import { ChatsFilter, ChatsOrderBy } from "../../types"

export type GetChatsData = {
  chats: GraphQLPagination<ChatDto>
}

export type GetChatsVariables = {
  offset?: number
  limit?: number
  filter?: ChatsFilter
  ordersBy?: ChatsOrderBy[]
}

export const GET_CHATS_QUERY = gql`
  ${CHAT_INFO_FRAGMENT}

  query GetChats(
    $limit: Int
    $offset: Int
    $filter: ChatsFilterInput
    $ordersBy: [ChatsOrderInput!]
  ) {
    chats(
      limit: $limit
      offset: $offset
      filter: $filter
      ordersBy: $ordersBy
    ) {
      total
      hasNextPage
      endCursor
      edges {
        cursor
        node {
          ...ChatInfo
        }
      }
    }
  }
`
