import { OfferRequestQuestion } from "hero24-types"
import { Category } from "../categories/types"
import * as TYPES from "./actionTypes"
import { CreateQuestionForm } from "./types"
import { normalizeCreatedQuestion } from "./utils"
import { getCategories } from "../categories/actions"
import { ThunkDispatch } from "redux-thunk"
import firebase from "firebase/app"
import { FIREBASE_PATH } from "../categories/constants"

interface CheckboxOptionCheckedProps {
  questionId: string
  optionId: string
  checked: boolean
}
export interface CheckboxOptionChecked {
  type: typeof TYPES.QUESTION_CHECKBOX_OPTION_CHECKED
  payload: CheckboxOptionCheckedProps
}
export const checkboxOptionChecked: (
  payload: CheckboxOptionCheckedProps,
) => CheckboxOptionChecked = (payload) => {
  return {
    type: TYPES.QUESTION_CHECKBOX_OPTION_CHECKED,
    payload,
  }
}

interface RadioOptionCheckedProps {
  questionId: string
  optionId: string
}
export interface RadioOptionChecked {
  type: typeof TYPES.QUESTION_RADIO_OPTION_CHECKED
  payload: RadioOptionCheckedProps
}
export const radioOptionChecked: (
  payload: RadioOptionCheckedProps,
) => RadioOptionChecked = (payload) => {
  return {
    type: TYPES.QUESTION_RADIO_OPTION_CHECKED,
    payload,
  }
}

interface UpdateErrorVisibilityProps {
  questionId: string
  showError: boolean
}
export interface UpdateErrorVisibility {
  type: typeof TYPES.UPDATE_ERROR_VISIBILITY
  payload: UpdateErrorVisibilityProps
}
export const updateErrorVisibility: (
  payload: UpdateErrorVisibilityProps,
) => UpdateErrorVisibility = (payload) => {
  return {
    type: TYPES.UPDATE_ERROR_VISIBILITY,
    payload,
  }
}

interface TextChangedProps {
  questionId: string
  value: string
}
export interface TextChanged {
  type: typeof TYPES.QUESTION_TEXT_CHANGED
  payload: TextChangedProps
}
export const textChanged: (payload: TextChangedProps) => TextChanged = (
  payload,
) => {
  return {
    type: TYPES.QUESTION_TEXT_CHANGED,
    payload,
  }
}

interface NumberChangedProps {
  questionId: string
  value: number
}
export interface NumberChanged {
  type: typeof TYPES.QUESTION_NUMBER_CHANGED
  payload: NumberChangedProps
}
export const numberChanged: (payload: NumberChangedProps) => NumberChanged = (
  payload,
) => {
  return {
    type: TYPES.QUESTION_NUMBER_CHANGED,
    payload,
  }
}

interface PreferredTimeChangedProps {
  questionId: string
  date: number
}
export interface PreferredTimeChanged {
  type: typeof TYPES.QUESTION_PREFFERED_TIME_CHANGED
  payload: PreferredTimeChangedProps
}
export const preferredTimeChanged: (
  payload: PreferredTimeChangedProps,
) => PreferredTimeChanged = (payload) => {
  return {
    type: TYPES.QUESTION_PREFFERED_TIME_CHANGED,
    payload,
  }
}

interface OnSuitableTimeSelectedProps {
  questionId: string
  dateKey: string
  day: string
  startTime: string // Unix Millisecond Timestamp
  endTime: string
}
export interface OnSuitableTimeSelected {
  type: typeof TYPES.QUESTION_SUITABLE_TIME_SELECTED
  payload: OnSuitableTimeSelectedProps
}
export const onSuitableTimeSelected: (
  payload: OnSuitableTimeSelectedProps,
) => OnSuitableTimeSelected = (payload) => {
  return {
    type: TYPES.QUESTION_SUITABLE_TIME_SELECTED,
    payload,
  }
}

interface DurationChangeProps {
  questionId: string
  value: number
}

export interface DurationChange {
  type: typeof TYPES.QUESTION_DURATION_CHANGED
  payload: DurationChangeProps
}

export const durationChange: (
  payload: DurationChangeProps,
) => DurationChange = (payload) => {
  return {
    type: TYPES.QUESTION_DURATION_CHANGED,
    payload,
  }
}

export interface UpdateQuestions {
  type: typeof TYPES.UPDATE_QUESTIONS
  payload: {
    questions: OfferRequestQuestion[]
    category: Category
  }
}

export const updateQuestions = (
  questions: OfferRequestQuestion[],
  category: Category,
): UpdateQuestions => {
  return {
    type: TYPES.UPDATE_QUESTIONS,
    payload: { questions, category },
  }
}

export const createAndUpdateQuestion =
  (
    categoryId: string,
    values: CreateQuestionForm,
    initialQuestionId?: string,
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const questionDB = normalizeCreatedQuestion(values)

    const questionRef: firebase.database.Reference = firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("questions")

    if (initialQuestionId && initialQuestionId !== values.id)
      await questionRef.child(initialQuestionId).remove()

    await questionRef.child(values.id).set(questionDB)

    dispatch(getCategories())
  }

export const removeQuestion =
  (categoryId: string, questionId: string) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("questions")
      .child(questionId)
      .remove()

    dispatch(getCategories())
  }
