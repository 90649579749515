import { gql } from "@apollo/client"
import { Label } from "hero24-types"
import { FirebaseAdapter } from "../../utilities"

export type TranslationField = {
  en: string
  fi: string
}

export const TranslationFieldAdapter = new FirebaseAdapter<
  Label,
  TranslationField
>({
  toFirebase: ({ en, fi }) => ({ en, fi }),
  toGraphQL: ({ en, fi }) => ({ en, fi }),
})

export const TRANSLATION_FIELD_FRAGMENT = gql`
  fragment TranslationFieldInfo on TranslationFieldDto {
    en
    fi
  }
`
