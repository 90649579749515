import { ApolloClientState, ApolloClientReducer } from "./types"
import instance from "./instance"

const INITIAL_STATE: ApolloClientState = {
  client: instance,
}

const apolloClient: ApolloClientReducer = (state = INITIAL_STATE) => {
  return state
}

export default { apolloClient }
