import { gql } from "@apollo/client"
import { AddressesAnswered } from "hero24-types"

import { FirebaseAdapter } from "../../../../common/utilities"
import {
  ADDRESS_FRAGMENT,
  Address,
  AddressAdapter,
} from "../../../../common/graphql/fragments"

export type DeliveryAddresses = {
  type: "delivery"
  from: Address
  to: Address
}

export const DeliveryAddressesAdapter = new FirebaseAdapter<
  AddressesAnswered & { type: "delivery" },
  DeliveryAddresses
>({
  toFirebase: ({ type, from, to }) => ({
    type,
    from: AddressAdapter.toFirebase(from),
    to: AddressAdapter.toFirebase(to),
  }),
  toGraphQL: ({ type, from, to }) => ({
    type,
    from: AddressAdapter.toFirebase(from),
    to: AddressAdapter.toFirebase(to),
  }),
})

export const DELIVERY_ADDRESSES_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}

  fragment DeliveryAddressesInfo on DeliveryAddressesDto {
    type
    from {
      ...AddressInfo
    }
    to {
      ...AddressInfo
    }
  }
`
