export const SET_PLATFORM_PROVIDER = "@iweb/SET_PLATFORM_PROVIDER"
export const SET_PRE_PAY_WITH = "@iweb/SET_PRE_PAY_WITH"
export const SET_POST_PAID = "@iweb/SET_POST_PAID"
export const SET_INVOICE = "@iweb/SET_INVOICE"
export const SET_FIXED_PRICE = "@iweb/SET_FIXED_PRICE"
export const SET_TOTAL_DURATION = "@iweb/SET_TOTAL_DURATION"
export const SET_MINIMUM_DURATION = "@iweb/SET_MINIMUM_DURATION"
export const SET_CUSTOMER_VAT = "@iweb/SET_CUSTOMER_VAT"
export const SET_HERO24_CUT = "@iweb/SET_HERO24_CUT"
export const SET_SERVICE_PROVIDER_VAT = "@iweb/SET_SERVICE_PROVIDER_VAT"
export const RESET_SUBSCRIPTION = "@iweb/RESET_SUBSCRIPTION"
export const SET_SUBSCRIPTION_ENABLED = "@iweb/SET_SUBSCRIPTION_ENABLED"
export const SET_SUBSCRIPTION_INTERVAL = "@iweb/SET_SUBSCRIPTION_INTERVAL"
