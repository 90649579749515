import "firebase/auth"
import "firebase/storage"
import "firebase/database"
import "firebase/functions"

import React from "react"
import ReactDOM from "react-dom"

import * as serviceWorker from "./serviceWorker"
import modules from "./modules"

import App from "./App"
import "./index.less"

ReactDOM.render(<App {...modules} />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
