import { Store } from "../core"

export const selectNewsList = (state: Store) => state.news.newsList

export const selectNewsListFetching = (state: Store) =>
  state.news.newsListFetching

export const selectNewsCreating = (state: Store) => state.news.newsCreating

export const selectNewsRemovingById = (id: string) => (state: Store) =>
  state.news.newsRemoving[id]

export const selectNewsEditingById = (id: string) => (state: Store) =>
  state.news.newsEditing[id]

export const selectNewsPagination = (state: Store) => state.news.pagination
