import { Action } from "./actions"
import { ActionType } from "./constants"
import { CommonReducer, CommonState } from "./types"

export const INITIAL_STATE: CommonState = {}

const common: CommonReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ActionType.SET_ENTRY_PATH_IF_NOT_EXIST: {
      if (state.protectedEntryPath) {
        return state
      }

      return {
        ...state,
        protectedEntryPath: action.payload,
      }
    }

    default:
      return state
  }
}

export default { common }
