import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import { setIsApprovedByBuyer } from "../../../../../offerRequests/list/actions"
import { OperationStatus } from "../components"

type FinishStatus = `${OperationStatus}` | null

export type ApprovedByBuyerCallback = (status: `${OperationStatus}`) => void

interface UseSetIsApprovedByBuyer {
  offerRequest: OfferRequest
  callback?: ApprovedByBuyerCallback
}

export const useSetIsApprovedByBuyer = (props: UseSetIsApprovedByBuyer) => {
  const { offerRequest, callback } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [finishedStatus, setFinishedStatus] = useState<FinishStatus>(null)

  const setIsApproved = useCallback(async (): Promise<FinishStatus> => {
    try {
      await dispatch(
        setIsApprovedByBuyer({
          id: offerRequest.id,
          isApprovedByBuyer: !offerRequest.isApprovedByBuyer,
        }),
      )
      return OperationStatus.SUCCESS
    } catch {
      return OperationStatus.ERROR
    }
  }, [dispatch, offerRequest.id, offerRequest.isApprovedByBuyer])

  const onConfirm = useCallback(async () => {
    setLoading(true)
    const setIsApprovedStatus = await setIsApproved()
    setFinishedStatus(setIsApprovedStatus)
    setLoading(false)
  }, [setIsApproved])

  useEffect(() => {
    if (callback && finishedStatus !== null) {
      callback(finishedStatus)
      setFinishedStatus(null)
    }
  }, [callback, finishedStatus])

  return { onConfirm, loading }
}
