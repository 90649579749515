import React, { useEffect, MutableRefObject } from "react"
import * as Yup from "yup"
import { withFormik, FormikProps } from "formik"
import { Form, Button, Row, Col, Descriptions } from "antd"
import { FieldAdapter as Field } from "../../../common/form"
import { RenderField } from "../../../common/components"
import { FeeInputs } from "../../../fees/types"
import { FeeDB } from "hero24-types"

interface CreateFeeFormProps {
  onSubmit: (values: FeeInputs, cb: () => void) => void
  resetFormRef: MutableRefObject<any>
  loading: boolean
  fee?: FeeDB
}

const CreateFeeForm = (props: CreateFeeFormProps & FormikProps<FeeInputs>) => {
  const { values, isValid, resetForm, resetFormRef, loading, handleSubmit } =
    props

  useEffect(() => {
    resetFormRef.current = { resetForm }
  }, [resetFormRef, resetForm])

  const materialCost = values.quantity * values.unitPrice
  const platformFeePercentageAsDecimal = values.platformFeePercentage / 100
  const total = materialCost / (1 - platformFeePercentageAsDecimal)
  const hero24Cut = total - materialCost

  return (
    <Form name="user" onSubmitCapture={handleSubmit}>
      <Field
        name="description"
        component={RenderField}
        type="text"
        label="Name"
        value={values.description}
      />
      <Field
        name="unitPrice"
        component={RenderField}
        type="number"
        label="Unit price €"
        value={`${values.unitPrice}`}
      />
      <Field
        name="quantity"
        component={RenderField}
        type="number"
        label="Quantity"
        value={`${values.quantity}`}
      />
      <Field
        name="platformFeePercentage"
        component={RenderField}
        type="number"
        label="Hero24 cut as %"
        disabled={!!props.fee}
        value={`${values.platformFeePercentage}`}
      />

      <Descriptions
        layout="vertical"
        size="small"
        bordered
        style={{ margin: "45px 0 45px 0", padding: 0, border: 0 }}
      >
        <Descriptions.Item label="TOTAL (VAT incl)">
          {Number(total).toFixed(2)} €
        </Descriptions.Item>
        <Descriptions.Item
          label={`Materials ${100 - values.platformFeePercentage}%`}
        >
          {Number(materialCost).toFixed(2)} €
        </Descriptions.Item>
        <Descriptions.Item label={`Hero24 ${values.platformFeePercentage}%`}>
          {Number(hero24Cut).toFixed(2)} €
        </Descriptions.Item>
      </Descriptions>

      <Row gutter={24}>
        <Col>
          <Button
            style={{ width: "100%" }}
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!isValid || loading}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ValidationSchema = Yup.object().shape<FeeInputs>({
  description: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  quantity: Yup.number().min(1, "How many units?").required("Required"),
  unitPrice: Yup.number().min(1, "Add valid price (€)").required("Required"),
  platformFeePercentage: Yup.number()
    .min(0, "Add valid %")
    .max(100, "Add valid %")
    .required("Required"),
})

const createFeeFormWithFormik = withFormik<CreateFeeFormProps, FeeInputs>({
  mapPropsToValues: (props) => ({
    platformFeePercentage: props.fee?.platformFeePercentage || 10,
    description: props.fee?.data.description || "",
    quantity: props.fee?.data.quantity || 1,
    unitPrice: props.fee?.data.unitPrice || 20,
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values, resetForm)
  },
  validationSchema: ValidationSchema,
  displayName: "CreateFeeForm",
  enableReinitialize: true,
})

export default createFeeFormWithFormik(CreateFeeForm)
