import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons"
import { Alert, Button, Modal, Popconfirm, Typography } from "antd"
import debounce from "lodash/debounce"
import { FC, useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SearchUser from "../../../../../../common/components/SearchUser"
import { editUserAdminStatus } from "../../../../../../user/actions"
import { User, UsersState } from "../../../../types"
import { User as UserInfo } from "../../../../../../user/graphql/fragments"
import { MenuItemsKeys, StatusChangerModals } from "../constants"
import { StatusModalProps } from "./types"
import {
  errorMessageChangePermissions,
  getSuccessMessageChange,
  popconfirm,
} from "./constants"
import { Maybe } from "graphql/jsutils/Maybe"
import { getSubmitButtonTitle } from "../../../../utils"

const ChangeModal: FC<StatusModalProps> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const users = useSelector(({ users }: { users: UsersState }) => {
    return Object.values(users.users)
  })
  const [foundUsers, setFoundUsers] = useState<User[]>(users)
  const [selectedUser, setSelectedUser] = useState<Maybe<User | UserInfo>>(null)
  const [updatedUser, setUpdatedUser] = useState<Maybe<User | UserInfo>>(null)
  const [loading, setLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [error, setError] = useState<Maybe<string>>(null)
  const submitButtonTitle = getSubmitButtonTitle({ loading, selectedUser })

  const onSearchUsers = useMemo(
    () =>
      debounce((value) => {
        setFoundUsers(
          users.filter((user) =>
            user.data.email.toLowerCase().includes(value.toLowerCase()),
          ),
        )
      }, 800),
    [users],
  )

  const handleChange = useCallback(
    (value: string): void => {
      const foundUser = users.find((user) => user.id === value)
      setSelectedUser(foundUser ?? null)
    },
    [users],
  )

  const onSubmitClickHandler = useCallback(async () => {
    setLoading(true)
    if (selectedUser) {
      try {
        await dispatch(
          editUserAdminStatus({
            id: selectedUser.id,
            isAdmin: !selectedUser.isAdmin,
          }),
        )
      } catch (e) {
        setError(errorMessageChangePermissions)
      } finally {
        setUpdatedUser(selectedUser)
        setLoading(false)
        setIsFinished(true)
      }
    }
  }, [dispatch, selectedUser])

  return (
    <Modal
      title={StatusChangerModals.EXISTING.modalTitle}
      open={open}
      onCancel={() =>
        closeModal({ selectedUser: updatedUser, key: MenuItemsKeys.EXISTING })
      }
      footer={false}
      width={600}
      closable={!loading}
    >
      {isFinished ? (
        <Alert
          message={error || getSuccessMessageChange(selectedUser?.data.email)}
          type={error ? "error" : "success"}
        />
      ) : (
        <>
          <SearchUser
            selectedUserId={selectedUser?.id}
            onSearchUsers={onSearchUsers}
            handleChange={handleChange}
            users={foundUsers}
            loading={loading}
            disabled={loading}
          />
          {selectedUser && (
            <>
              <Typography style={{ marginTop: 16 }}>
                {selectedUser.data.email} currently{" "}
                {selectedUser.isAdmin ? "has" : "hasn't"} admin permissions
              </Typography>
              <Popconfirm
                title={popconfirm.changeTitle}
                onConfirm={onSubmitClickHandler}
                okText={popconfirm.okText}
                cancelText={popconfirm.cancelText}
              >
                <Button
                  icon={
                    selectedUser.isAdmin ? (
                      <MinusSquareOutlined />
                    ) : (
                      <PlusSquareOutlined />
                    )
                  }
                  block
                  loading={loading}
                  style={{ marginTop: 32 }}
                  type="primary"
                >
                  {submitButtonTitle}
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default ChangeModal
