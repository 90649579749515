import React from "react"
import { green, red } from "@ant-design/colors"
import { SUBSCRIPTION_STATUS } from "hero24-types"
import { Subscription } from "../../../subscriptions/types"

interface Props {
  subscription: Subscription
}

export const getStatusColor = (status: SUBSCRIPTION_STATUS) => {
  switch (status) {
    case "active":
      return green.primary
    case "closed":
      return red.primary
    default:
      return "black"
  }
}

export const SubscriptionStatus = ({ subscription }: Props) => {
  if (!subscription) return null
  return (
    <span
      style={{
        fontSize: 14,
        color: getStatusColor(subscription.status || ""),
      }}
    >
      {(subscription.status || "").toUpperCase()}
    </span>
  )
}
