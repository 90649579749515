import React from "react"
import { CreateRecommendedForm as ICreateRecommendedForm } from "../../../../buyer/recommended/types"
import { FieldAdapter as Field } from "../../../../common/form"
import { RenderField, RenderSelect } from "../../../../common/components"
import { Form, Select } from "antd"

const { Option } = Select

interface Props {
  values: ICreateRecommendedForm
  categories: JSX.Element[]
  images: string[]
  handleSelectType: (field: string) => (imageName: React.ReactText) => void
  handleSearchCategory: (keyword: string) => void
}

const CreateRecommendedForm = ({
  values,
  categories,
  handleSelectType,
  handleSearchCategory,
  images,
}: Props) => {
  return (
    <Form>
      <Field
        name="id"
        component={RenderField}
        type="text"
        label="ID"
        value={values.id}
      />
      <Form.Item label="CategoryID">
        <Select
          size="large"
          showSearch
          value={values.categoryId || undefined}
          placeholder="CategoryID"
          style={{ width: "100%" }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearchCategory}
          onChange={handleSelectType("categoryId")}
          dropdownMatchSelectWidth={false}
        >
          {categories.length ? categories : null}
        </Select>
      </Form.Item>
      <Field
        name="imageName"
        component={RenderSelect}
        label="Image"
        value={values.imageName}
        onSelect={handleSelectType("imageName")}
      >
        {images.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Field>
      <Field
        name="link"
        component={RenderField}
        type="text"
        label="Link"
        value={values.link}
      />
    </Form>
  )
}

export default CreateRecommendedForm
