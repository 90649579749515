import firebase from "firebase/app"
import { Dispatch } from "redux"

import { FIREBASE_PATH_OFFER_REQUESTS } from "../offerRequests/list/constants"
import { OfferRequests } from "../offerRequests/list/types"

import { FIREBASE_PATH_SUBSCRIPTIONS } from "./constants"
import { getSubscriptionsSuccess } from "./actionTypes"
import * as actions from "./actionTypes"

import { SubscriptionDB } from "hero24-types"
import { Subscription } from "./types"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { Store } from "../core"
import { selectApolloClient } from "../apolloClient/selectors"
import {
  UnbindUserOfferRequestsData,
  UnbindUserOfferRequestsVariables,
  UNBIND_USER_OFFER_REQUESTS_MUTATION,
} from "../user/graphql/mutations"

export const getAllSubscriptions = () => async (dispatch: Dispatch) => {
  const subscriptionsRef = await firebase
    .database()
    .ref(FIREBASE_PATH_SUBSCRIPTIONS)

  const subscriptionsSnapshot = await subscriptionsRef.once("value")

  const subscriptions: {
    [id: string]: SubscriptionDB
  } = subscriptionsSnapshot.val()

  const subscriptionsState = Object.fromEntries(
    Object.entries(subscriptions || {}).map((subscriptionItem) => {
      return [
        subscriptionItem[0],
        {
          id: subscriptionItem[0],
          ...subscriptionItem[1],
        },
      ]
    }),
  )

  dispatch(getSubscriptionsSuccess(subscriptionsState))
}

async function _removeSubscription(
  subscription: Subscription,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  offerRequests?: OfferRequests,
) {
  const db = firebase.database()

  for (const offerRequestId in subscription.offerRequests) {
    const order = offerRequests && offerRequests[offerRequestId]
    const buyerId = order && order.data?.initial?.buyerProfile

    await db.ref(FIREBASE_PATH_OFFER_REQUESTS).child(offerRequestId).set(null)

    if (buyerId) {
      await apolloClient.mutate<
        UnbindUserOfferRequestsData,
        UnbindUserOfferRequestsVariables
      >({
        mutation: UNBIND_USER_OFFER_REQUESTS_MUTATION,
        variables: {
          offerRequestIds: [offerRequestId],
          userId: buyerId,
        },
      })
    }
  }

  await db.ref(FIREBASE_PATH_SUBSCRIPTIONS).child(subscription.id).set(null)
}

export const removeSubscription =
  (subscription: Subscription, offerRequests?: OfferRequests) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(actions.removeSubscription(subscription.id))

    const apolloClient = selectApolloClient(getState())

    _removeSubscription(subscription, apolloClient, offerRequests)
      .then(() => {
        dispatch(actions.removeSubscriptionSuccess(subscription.id))
      })
      .catch((err) => {
        dispatch(actions.removeSubscriptionFailure(subscription.id, err))
      })
  }
