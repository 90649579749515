import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { resetSubscriptionState } from "../../../payments/actions"
import {
  PlatformProvider,
  PriceAndDuration,
  Subscription,
  Payment,
  Vat,
} from "./components"

const PaymentStep = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSubscriptionState())
  }, [dispatch])

  return (
    <>
      <PlatformProvider />
      <PriceAndDuration />
      <Subscription />
      <Payment />
      <Vat />
    </>
  )
}

export default PaymentStep
