import { FC, PropsWithChildren } from "react"
import { Typography } from "antd"

import { PaymentService } from "../../../../../payments/constants"
import { getPrePayWithColor } from "../utils"

type Props = PropsWithChildren<{
  prePayWith: PaymentService
}>

export const PrepayWithPrefix: FC<Props> = ({ children, prePayWith }) => (
  <>
    <Typography.Text
      style={{
        color: getPrePayWithColor(prePayWith),
        textTransform: "capitalize",
      }}
    >
      {prePayWith}
    </Typography.Text>
    {"/"}
    {children}
  </>
)
