import React, { useCallback, useMemo, useState } from "react"
import { ModalProps } from "antd/lib/modal"
import { FormikProps, withFormik } from "formik"
import { Modal, Select } from "antd"
import { CreateRecommendedForm } from "../../../../buyer/recommended/types"
import CreateRecommendedFormComponent from "./CreateRecommendedForm"
import { useSelector } from "react-redux"
import { Store } from "../../../../core/types"

const { Option } = Select

interface Props extends ModalProps {
  onSubmit: (values: CreateRecommendedForm) => void
  initialState?: CreateRecommendedForm
}

const images = [
  "cleaning_home",
  "cleaning_renovation",
  "cleaning_moving",
  "cleaning_windows",
  "moving_service",
  "delivery",
  "moving_lifting",
  "handyman_furnitures",
  "renovation",
  "plumber",
  "electrician",
  "walls",
  "yard_work",
  "cleaning_snow",
  "learning_languages",
  "learning_music",
  "personal_trainer",
  "personal_training_program",
  "personal_nutrition_program",
  "photo_portrait",
  "photo_wedding",
  "photo_events",
  "photo_party",
  "photo_product",
  "photo_apartment",
  "air_conditioning",
  "electronic_appliance",
  "food_transport",
  "home_appliance",
  "interior_design",
  "it_support",
  "property_maintenance",
  "domestic_help",
]

const initialValues: CreateRecommendedForm = {
  id: "",
  categoryId: "",
  imageName: "",
  link: "",
}

const CreateRecommended = ({
  values,
  handleSubmit,
  setFieldValue,
  ...modalProps
}: Props & FormikProps<CreateRecommendedForm>) => {
  const [searchCategory, setSearchCategory] = useState<string>("")

  const categories = useSelector((state: Store) => state.categories.categories)

  const handleClickOk = () => handleSubmit()
  const handleSelectType = (field: string) => (imageName: React.ReactText) =>
    setFieldValue(field, imageName)

  const handleSearchCategory = useCallback(
    (keyword: string) => setSearchCategory(keyword),
    [],
  )
  const options = useMemo(() => {
    return Object.keys(categories)
      .filter((category) =>
        category.trim().toLowerCase().startsWith(searchCategory.toLowerCase()),
      )
      .map((category) => {
        return (
          <Option key={category} value={category}>
            {category}
          </Option>
        )
      })
  }, [categories, searchCategory])

  const recommendedId = React.useRef(values && values.id)

  return (
    <Modal
      {...modalProps}
      title={
        recommendedId.current ? "Edit recommended" : "Create new recommended"
      }
      onOk={handleClickOk}
    >
      <CreateRecommendedFormComponent
        values={values}
        categories={options}
        images={images}
        handleSelectType={handleSelectType}
        handleSearchCategory={handleSearchCategory}
      />
    </Modal>
  )
}

const createRecommendedFormWithFormik = withFormik<
  Props,
  CreateRecommendedForm
>({
  mapPropsToValues: ({ initialState = {} }) => ({
    ...initialValues,
    ...initialState,
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values)
    resetForm()
  },
  displayName: "CreateRecommendedForm",
})

export default createRecommendedFormWithFormik(CreateRecommended)
