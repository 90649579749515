import { EuroCircleFilled } from "@ant-design/icons"
import { Button, Popover, Typography, message } from "antd"
import { PaidStatus } from "hero24-types"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import { PaymentService } from "../../../../../payments/constants"
import Popconfirm from "../../../Popconfirm"
import { markOfferRequestPrepaid } from "../utils"
import { ApprovedByBuyerStatus } from "./ApprovedByBuyerStatus"
import { PrepaidStatusView } from "./PrepaidStatusView"
import {
  MARK_NETVISOR_ORDER_PAID_POPCONFIRM_TITLE,
  MARK_NETVISOR_ORDER_PAID_POPOVER_CONTENT,
  errorMessageMarkNetvisorOrderPaid,
  succeedMessageMarkNetvisorOrderPaid,
} from "./constants"

type Props = {
  offerRequest: OfferRequest
}

const { Text } = Typography

export const NetvisorPrepaidStatus: FC<Props> = ({ offerRequest }) => {
  const [messageApi, contextHolder] = message.useMessage()

  const { isApprovedByBuyer } = offerRequest
  const { postpaid, prepaid, sendInvoiceWith } = offerRequest.data.initial
  const [loading, setLoading] = useState(false)

  const [paid, setPaid] = useState<PaidStatus | undefined>(prepaid)
  const [errorMarkNetvisorOrderPaid, setErrorMarkNetvisorOrderPaid] =
    useState("")

  const showMessage = useCallback((): void => {
    messageApi.open({
      type: errorMarkNetvisorOrderPaid ? "error" : "success",
      content:
        errorMarkNetvisorOrderPaid || succeedMessageMarkNetvisorOrderPaid,
    })
  }, [errorMarkNetvisorOrderPaid, messageApi])

  useEffect(() => {
    setPaid(prepaid)
  }, [prepaid])

  const markNetvisorOrderPaid = useCallback(async () => {
    setLoading(true)
    try {
      await markOfferRequestPrepaid(offerRequest.id)
      setPaid(PaidStatus.PAID)
    } catch {
      setErrorMarkNetvisorOrderPaid(errorMessageMarkNetvisorOrderPaid)
    }
    showMessage()
    setLoading(false)
  }, [offerRequest.id, showMessage])

  const postOrPrePaid = useMemo(
    () => (postpaid !== "yes" ? "Prepaid" : "Postpaid"),
    [postpaid],
  )

  const isOfferPaid = useMemo(
    () => paid === PaidStatus.PAID || offerRequest.data.status !== "open",
    [offerRequest.data.status, paid],
  )

  return (
    <>
      {contextHolder}
      <Text>{postOrPrePaid}</Text>
      {"/"}
      <ApprovedByBuyerStatus isApprovedByBuyer={isApprovedByBuyer} />
      {"/"}
      {isOfferPaid ? (
        <PrepaidStatusView
          prepaid={paid}
          prePayWith={PaymentService.NETVISOR}
          sendInvoiceWith={sendInvoiceWith}
          postpaid={postpaid}
        />
      ) : (
        <Popconfirm
          title={MARK_NETVISOR_ORDER_PAID_POPCONFIRM_TITLE}
          loading={loading}
          onConfirm={markNetvisorOrderPaid}
        >
          <Popover
            trigger="hover"
            content={MARK_NETVISOR_ORDER_PAID_POPOVER_CONTENT}
          >
            <Button
              shape="circle"
              icon={<EuroCircleFilled />}
              style={{ margin: 5 }}
            />
          </Popover>
        </Popconfirm>
      )}
    </>
  )
}
