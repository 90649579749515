import React, { useState } from "react"
import moment from "moment-timezone"
import { Row, Col, DatePicker } from "antd"
import { Offer } from "../../../../offers/list/types"
import { useDispatch } from "react-redux"
import { setOfferAgreedTime } from "../../../../offers/list/actions"

interface Props {
  offer: Offer
}

export const OfferedTime = (props: Props) => {
  const [offeredTime, setOfferedTime] = useState(
    props.offer.data.initial.agreedStartTime,
  )
  const dispatch = useDispatch()
  const changeOfferedTime = (offeredTime: number) => {
    dispatch(
      setOfferAgreedTime({
        id: props.offer.id,
        agreedTime: offeredTime,
      }),
    )
    setOfferedTime(offeredTime)
  }
  return (
    <Row>
      <Col xs={24}>
        <DatePicker
          showTime
          onChange={(newOfferedTime) =>
            newOfferedTime
              ? changeOfferedTime(Number(newOfferedTime.format("x")))
              : null
          }
          value={moment(offeredTime, "x")}
          disabled={props.offer.status === "completed"}
          clearIcon={false}
          showSecond={false}
        />
      </Col>
    </Row>
  )
}
