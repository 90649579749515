import { gql } from "@apollo/client"
import { NEWS_FRAGMENT } from "../../fragments"

export const SUBSCRIPTION = gql`
  ${NEWS_FRAGMENT}

  subscription SubscribeOnNewsAdded {
    newsAdded {
      ...NewsInfo
    }
  }
`
