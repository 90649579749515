import * as TYPES from "./actionTypes"
import { OffersState, OffersReducer, Offer } from "./types"

export const INITIAL_STATE: OffersState = {
  offers: {},
}

const offers: OffersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_OFFERS: {
      return {
        ...state,
        offers: action.payload,
      }
    }
    case TYPES.SET_OFFER_STATUS:
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.payload.id]: {
            ...state.offers[action.payload.id],
            status: action.payload.status,
          },
        },
      }
    case TYPES.SET_OFFER_AGREED_TIME: {
      const offer = {
        ...state.offers[action.payload.id],
      }
      offer.data.initial.agreedStartTime = action.payload.agreedTime
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.payload.id]: offer,
        },
      }
    }
    case TYPES.SET_WORKHOURS: {
      const updatedOffer: Offer = {
        ...state.offers[action.offerId],
        data: {
          ...state.offers[action.offerId].data,
          actualStartTime: action.actualStartTime,
          actualCompletedTime: action.actualCompletedTime,
          pauseDurationMS: action.pauseDurationMS,
          workTime: action.workTime,
        },
      }
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.offerId]: updatedOffer,
        },
      }
    }
    case TYPES.OFFER_UPDATED:
      return {
        ...state,
        offers: {
          ...state.offers,
          [action.payload.id]: action.payload,
        },
      }
    default:
      return state
  }
}

export default { offers }
