import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { offerRequestCreate, setPromotionDisabled } from "../actions"
import {
  getSelectedCategory,
  hasInvalidAddressForCategory,
  getAddressValuesForCategory,
} from "../../categories/selectors"
import {
  categoryHasInvalidQuestions,
  getQuestionsForOfferRequest,
} from "../../questions/selectors"
import { getSelectedUsersId } from "../../../user/selectors"
import { QuestionList, AddressQuestionList } from "../../questions/components"
import {
  Button,
  Typography,
  Row,
  Col,
  Popover,
  Empty,
  notification,
} from "antd"
import { getAuthUser } from "../../../auth/selectors"
import {
  hasValidFixedPriceSelected,
  hasValidFixedDurationSelected,
} from "../../../payments/selectors"
import { FormOutlined } from "@ant-design/icons"
import { getCategories } from "../../categories/actions"
import { Store } from "../../../core"

const { Title } = Typography

const QuestionsStep = () => {
  const dispatch = useDispatch()

  const selectedCategory = useSelector(getSelectedCategory)

  const hasQuestionErrors = useSelector(
    categoryHasInvalidQuestions(selectedCategory?.id),
  )
  const hasAddressErrors = useSelector(hasInvalidAddressForCategory)

  const hasDuration = useSelector(hasValidFixedDurationSelected)
  const hasPrice = useSelector(hasValidFixedPriceSelected)

  const addresses = useSelector(getAddressValuesForCategory)
  const questions = useSelector(
    getQuestionsForOfferRequest(selectedCategory?.id),
  )

  const user = useSelector(getSelectedUsersId)

  const adminUser = useSelector(getAuthUser)

  const promotionDisabled = useSelector(
    (state: Store) => state.offerRequest.promotionDisabled,
  )

  const submit = () => {
    if (!selectedCategory || !user || !addresses || !adminUser) return
    dispatch(
      offerRequestCreate({
        adminUser: adminUser.id,
        buyerProfile: user,
        category: selectedCategory,
        addresses: addresses,
        questions: questions,
        promotionDisabled,
      }),
    )
    notification.success({
      message: "Offer request created",
      duration: 3,
    })
    dispatch(getCategories())
    dispatch(setPromotionDisabled(false))
  }

  const renderSubmitBtn = () => {
    return !hasDuration ||
      !hasPrice ||
      hasQuestionErrors ||
      hasAddressErrors ||
      !user ? (
      <Popover content="Please fill the missing details">
        <Button
          style={{ marginTop: 24 }}
          size="large"
          type="primary"
          block
          disabled
        >
          Submit
        </Button>
      </Popover>
    ) : (
      <Button
        style={{ marginTop: 24 }}
        size="large"
        type="primary"
        block
        onClick={submit}
      >
        Submit
      </Button>
    )
  }
  return (
    <section className="section">
      <Row
        gutter={12}
        className="title-block"
        align="middle"
        justify="space-between"
      >
        <Col xs={24}>
          <FormOutlined className="sub-title-icon" />
          <Title className="sub-title" level={4}>
            Questions
          </Title>
        </Col>
      </Row>
      {!selectedCategory && (
        <Empty description="Please select a category first" />
      )}
      {selectedCategory && (
        <>
          <QuestionList category={selectedCategory} />
          <AddressQuestionList category={selectedCategory} />
          {renderSubmitBtn()}
        </>
      )}
    </section>
  )
}

export default QuestionsStep
