import React, { cloneElement, useEffect, useMemo } from "react"
import { Provider, useDispatch } from "react-redux"
import { Router, Switch } from "react-router-dom"
import firebase from "firebase/app"
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth"
import firebaseConfig from "./firebaseConfig"
import { createReduxStore, history } from "./modules/core"
import { PageLayout } from "./modules/common/components"
import { Modules } from "./modules/core/types"
import { updateUser } from "./modules/auth/actions"

import { ConfigProvider } from "antd"
// eslint-disable-next-line camelcase
import en_GB from "antd/lib/locale-provider/en_GB"
import "moment/locale/en-gb" // important!

const firebaseApp = firebase.initializeApp(firebaseConfig)

const AdminWrapper = (props: {
  children: React.ReactElement
  user: WrappedComponentProps["user"]
}) => {
  const { user } = props
  const dispatch = useDispatch()
  useEffect(() => {
    if (user === null) {
      // Authentication finished and no user found
      dispatch(updateUser(undefined))
    } else if (user) {
      // Authentication finished and user found
      dispatch(
        updateUser({
          id: user.uid,
          displayName: user.displayName || "",
          email: user.email || "",
        }),
      )
    } else {
      // user is undefined which means authentication is still loading
      return
    }
  }, [dispatch, user])
  return <>{props.children}</>
}

const App = ({
  signInWithGoogle,
  signOut,
  user,
  reducers,
  middlewares = [],
  routes,
  sideBarItems,
}: Partial<WrappedComponentProps & Modules>) => {
  if (reducers === undefined) {
    throw new Error("no reducers")
  }
  const AppRouter = () => {
    return (
      <Switch>
        {routes
          ? routes.map((comp: React.ReactElement) =>
              cloneElement(comp, { signInWithGoogle }),
            )
          : null}
      </Switch>
    )
  }

  const store = useMemo(
    () => createReduxStore(reducers, {}, middlewares),
    [reducers, middlewares],
  )

  return (
    // eslint-disable-next-line camelcase
    <ConfigProvider locale={en_GB}>
      <Provider store={store}>
        <AdminWrapper user={user}>
          <Router history={history}>
            <PageLayout signOut={signOut} sideBarItems={sideBarItems}>
              <AppRouter />
            </PageLayout>
          </Router>
        </AdminWrapper>
      </Provider>
    </ConfigProvider>
  )
}

const firebaseAppAuth = firebaseApp.auth()

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App)
