import * as TYPES from "./actionTypes"

import {
  BuyerOfferRequestsState,
  OfferRequestsReducer as OfferRequests,
} from "./types"

export const INITIAL_STATE: BuyerOfferRequestsState = {
  selected: undefined,
  pickServiceProvider: undefined,
  promotionDisabled: false,
}

const offerRequest: OfferRequests = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.OFFER_REQUEST_CREATED: {
      const { id } = action.payload
      return {
        ...state,
        selected: id,
      }
    }
    case TYPES.SET_PROMOTION_DISABLED: {
      return {
        ...state,
        promotionDisabled: action.payload,
      }
    }
    case TYPES.SET_DOER_PICK_STRATEGY: {
      return {
        ...state,
        pickServiceProvider: action.pickServiceProvider,
      }
    }
    default:
      return state
  }
}

export default { offerRequest }
