import React from "react"
import { Area } from "./Area"

interface Props {
  areas?: string[]
}

export const AreaList = ({ areas }: Props) => {
  if (areas) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: 50,
          overflowY: "scroll",
        }}
      >
        {[...areas]
          .sort((a, b) => a.localeCompare(b))
          .map((area) => (
            <Area key={area} area={area} />
          ))}
      </div>
    )
  }
  return null
}
