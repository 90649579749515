import { gql } from "@apollo/client"
import { GraphQLSellerProfile } from "../adapters/GraphQLSellerProfile"
import { GraphQLSellerProfileData } from "../adapters/GraphQLSellerProfileData"

export type EditSellerProfileDataData = {
  editSellerData: GraphQLSellerProfile
}
export type EditSellerProfileDataVariables = {
  id: string
  data: Partial<GraphQLSellerProfileData>
}

export const EDIT_SELLER_PROFILE_MUTATION = gql`
  mutation EditSellerProfileData(
    $id: String!
    $data: PartialSellerProfileDataInput!
  ) {
    editSellerData(id: $id, data: $data) {
      id
      rating
      reviews
      data {
        photoURL
        companyName
        companyEmail
        heroBIOText
        categories
        companyVAT
        langs
        city
        streetAddress
        postalCode
        yearsOfExperience
        workAreas
        certificate
        weeksOfSentPurchaseInvoices
      }
    }
  }
`
