import React from "react"
import { Modal } from "antd"
import { ModalProps } from "antd/lib/modal"
import { FormikProps, withFormik } from "formik"
import {
  CreatePackageForm as ICreatePackageForm,
  CreatePackageForm,
} from "../../../../buyer/packages/types"
import CreatePackageFormComponent from "./CreatePackageForm"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"

interface Props extends ModalProps {
  onSubmit: (values: CreatePackageForm) => void
  initialState?: CreatePackageForm
}

const initialValues: CreatePackageForm = {
  id: "",
  name: {
    en: "",
    fi: "",
  },
  description: {
    en: "",
    fi: "",
  },
  order: "",
  pricePerHour: "",
  recommendedDuration: "",
  citiesIncluded: {},
  citiesExcluded: {},
}

const CreatePackage = ({
  values,
  setFieldValue,
  handleSubmit,
  ...modalProps
}: Props & FormikProps<CreatePackageForm>) => {
  const handleChangeDoubleLanguageField =
    (field: "name" | "description", language: "en" | "fi") =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setFieldValue(field, { ...values[field], [language]: e.target.value })

  const handleRemoveOption = (
    field: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">,
    cityId: string,
  ) => setFieldValue(field, _.omit(values[field], cityId))

  const handleChangeCityInput =
    (
      field: keyof Pick<
        ICreatePackageForm,
        "citiesIncluded" | "citiesExcluded"
      >,
      key: string,
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setFieldValue(field, { ...values[field], [key]: e.target.value })

  const handleAddOption = (
    field: keyof Pick<ICreatePackageForm, "citiesIncluded" | "citiesExcluded">,
  ) =>
    setFieldValue(field, {
      ...values[field],
      [uuidv4()]: "",
    })

  const handleClickOk = () => handleSubmit()

  const packageId = React.useRef(values && values.id)

  return (
    <Modal
      {...modalProps}
      title={packageId.current ? "Edit package" : "Create new package"}
      onOk={handleClickOk}
    >
      <CreatePackageFormComponent
        values={values}
        handleChangeDoubleLanguageField={handleChangeDoubleLanguageField}
        handleRemoveOption={handleRemoveOption}
        handleChangeCityInput={handleChangeCityInput}
        handleAddOption={handleAddOption}
      />
    </Modal>
  )
}

const createPackageFormWithFormik = withFormik<Props, CreatePackageForm>({
  mapPropsToValues: ({ initialState = {} }) => ({
    ...initialValues,
    ...initialState,
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values)
    resetForm()
  },
  displayName: "CreatePackageForm",
})

export default createPackageFormWithFormik(CreatePackage)
