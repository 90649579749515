import { notification } from "antd"
import firebase from "firebase/app"

type Data = {
  offerIds: string[]
  week: string
  weekComparingLocale: string
}

const functionPath =
  "netvisorPurchaseInvoiceHttpsIndex-importPurchaseInvoiceBatch"

const useImportPurchaseInvoiceBatch = () => {
  return {
    importPurchaseInvoiceBatch: async (data: Data) => {
      try {
        await firebase.functions().httpsCallable(functionPath)(data)
      } catch (e) {
        console.error(e)
        notification.error({
          message:
            "Something went wrong during creating purchase invoice batch.",
        })
      }
    },
  }
}

export default useImportPurchaseInvoiceBatch
