import React, { FC, useState } from "react"
import { Button, Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { SearchOutlined } from "@ant-design/icons"

import { DebounceSelect } from "../../../common/components"
import { updateChatsFilters } from "../../reducer"
import { selectUsersMap } from "../../../user/selectors"

// Usage of DebounceSelect
interface CustomerValue {
  label: string
  value: string
}

type Props = {
  confirm: () => void
}

const CustomerColumnFilter: FC<Props> = ({ confirm }) => {
  const [value, setValue] = useState<CustomerValue[]>([])

  const users = useSelector(selectUsersMap)

  const dispatch = useDispatch()

  const clearFilters = () => {
    dispatch(
      updateChatsFilters({
        customerIds: undefined,
      }),
    )
    setValue([])
  }

  const fetchCustomerOptions = async (
    value: string,
  ): Promise<CustomerValue[]> =>
    Object.values(users)
      .filter(({ data }) =>
        data.name.toLowerCase().includes(value.toLowerCase()),
      )
      .map(({ data, id }) => ({ label: data.name, value: id }))

  return (
    <div style={{ padding: 8 }}>
      <DebounceSelect
        mode="multiple"
        value={value}
        placeholder="Search by customer name"
        fetchOptions={fetchCustomerOptions}
        onChange={(newValue) => {
          setValue(newValue as CustomerValue[])
        }}
        style={{ width: 200, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            dispatch(
              updateChatsFilters({
                customerIds: value.length
                  ? value.map(({ value }) => value)
                  : undefined,
              }),
            )
            confirm()
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
}
export default CustomerColumnFilter
