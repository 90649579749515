import React from "react"
import { useSelector } from "react-redux"
import { getSelectedCategory } from "./categories/selectors"
import { getFeesToSave } from "../fees/selectors"
import {
  getSelectedFixedDuration,
  getSelectedFixedPrice,
  getSubscriptionStatus,
} from "../payments/selectors"
import { getFeeTotalWithHero24Cut } from "../fees/utils"

const OfferRequestSummary = () => {
  const fees = useSelector(getFeesToSave)
  const selectedFixedPrice = useSelector(getSelectedFixedPrice)
  const selectedFixedDuration = useSelector(getSelectedFixedDuration)
  const selectedCategory = useSelector(getSelectedCategory)
  const isSubscriptionEnabled = useSelector(getSubscriptionStatus)

  if (!selectedCategory || !selectedFixedPrice || !selectedFixedDuration) {
    return null
  }

  let serviceTotal = selectedFixedPrice * selectedFixedDuration

  const feesTotal = fees
    ? fees.reduce((acc, fee) => {
        return (
          acc +
          getFeeTotalWithHero24Cut({
            platformFeePercentage: fee.platformFeePercentage,
            data: {
              quantity: fee.quantity,
              unitPrice: fee.unitPrice,
              description: fee.description,
            },
          })
        )
      }, 0)
    : 0

  let subscriptionDiscoutLine = ""
  if (isSubscriptionEnabled && selectedCategory.subscriptions?.default) {
    const subscription = selectedCategory.subscriptions.default
    subscriptionDiscoutLine = " - "
    if (subscription.discountFormat === "fixed") {
      subscriptionDiscoutLine += subscription.discount.toFixed(2) + "€"
      serviceTotal -= subscription.discount
    } else {
      const fixedDiscount = +Number(subscription.discount / 100).toFixed(2)
      subscriptionDiscoutLine +=
        serviceTotal.toFixed(2) + " * " + subscription.discount.toFixed() + "%"
      serviceTotal -= serviceTotal * fixedDiscount
    }
  }

  const total = serviceTotal + feesTotal

  return (
    <div
      style={{
        position: "fixed",
        left: 15,
        bottom: 15,
        width: 220,
        borderRadius: 5,
        border: "1px solid black",
        backgroundColor: "white",
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <h1>{selectedCategory.name.en.toUpperCase()}</h1>
      <ul
        style={{
          paddingLeft: 15,
        }}
      >
        <li>
          {selectedFixedDuration}h * {selectedFixedPrice}€ $
          {subscriptionDiscoutLine} = {serviceTotal}€
        </li>
        {fees
          ? fees.map((fee) => (
              <li>
                {getFeeTotalWithHero24Cut({
                  platformFeePercentage: fee.platformFeePercentage,
                  data: {
                    quantity: fee.quantity,
                    unitPrice: fee.unitPrice,
                    description: fee.description,
                  },
                })}
                € / {fee.description}
              </li>
            ))
          : null}
      </ul>
      <p>TOTAL: {total.toFixed(2)}€</p>
    </div>
  )
}

export default OfferRequestSummary
