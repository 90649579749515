import { ComponentProps } from "react"
import { VatInput } from "./components"
import { VatName } from "./types"

export const VatInputMapper: Record<
  VatName,
  ComponentProps<typeof VatInput>
> = {
  [VatName.CUSTOMER_VAT]: {
    label: "CustomerVAT",
    name: VatName.CUSTOMER_VAT,
  },
  [VatName.SERVICE_PROVIDER_VAT]: {
    label: "ServiceProviderVAT",
    name: VatName.SERVICE_PROVIDER_VAT,
  },
  [VatName.HERO24_CUT]: {
    label: "Hero24Cut",
    name: VatName.HERO24_CUT,
  },
}
