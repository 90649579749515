import React from "react"
import { Form, Input, Radio } from "antd"
import { useDispatch } from "react-redux"
import { User } from "../../../users/list/types"
import { editUser, EditUser } from "../../../users/list/actions"
import { debounce } from "lodash"

interface Props {
  user: User
}

export const UserInfo = (props: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const changeValues = (
    changedValue: Partial<EditUser["payload"]["values"]>,
    values: EditUser["payload"]["values"],
  ) => {
    const updatedValues = {
      ...values,
    }
    if (!changedValue.name) {
      updatedValues.name = `${updatedValues.firstName} ${updatedValues.lastName}`
      form.setFieldsValue({
        name: updatedValues.name,
      })
    }
    dispatch(
      editUser({
        id: props.user.id,
        values: updatedValues,
      }),
    )
  }
  const debouncedOnChangeValues = debounce(changeValues, 1000)
  return (
    <Form
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      layout="horizontal"
      initialValues={
        {
          email: props.user.data.email || null,
          name: props.user.data.name || null,
          firstName: props.user.data.firstName || null,
          lastName: props.user.data.lastName || null,
          phone: props.user.data.phone || null,
          iban: props.user.data.iban || null,
          language: props.user.data.language || null,
        } as EditUser["payload"]["values"]
      }
      onValuesChange={(changedValue, values) => {
        debouncedOnChangeValues(
          changedValue,
          values as EditUser["payload"]["values"],
        )
      }}
    >
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="FirstName" name="firstName">
        <Input />
      </Form.Item>
      <Form.Item label="LastName" name="lastName">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Iban" name="iban">
        <Input />
      </Form.Item>
      <Form.Item label="Language" name="language">
        <Radio.Group>
          <Radio.Button value="en">English</Radio.Button>
          <Radio.Button value="fi">Finnish</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}
