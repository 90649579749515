import { grey, lime } from "@ant-design/colors"
import { PaymentService } from "../../../../payments/constants"
import { PaidStatus } from "hero24-types"

export const PRE_PAY_WITH_COLORS: Record<PaymentService, string | undefined> = {
  [PaymentService.STRIPE]: grey[8],
  [PaymentService.NETVISOR]: grey[4],
}

export const PRE_PAY_WITH_DEFAULT_COLOR = grey[1]

export const PREPAID_STATUS_COLORS: Record<PaidStatus, string | undefined> = {
  [PaidStatus.PAID]: lime.primary,
  [PaidStatus.WAITING]: grey.primary,
}

export const PREPAID_STATUS_DEFAULT_COLOR = grey[1]

export const CHANGE_PAYMENT_METHOD_POPOVER_CONTENT = "Change payment method"
