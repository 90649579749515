import { GraphQLPagination } from "../../../../common/graphql/types"
import { User } from "../../fragments"
import { ApolloThunkQuery } from "../../../../apolloClient/types"
import { apolloQuery } from "../../../../apolloClient/utils"
import { QUERY } from "./query"

interface Variables {
  limit?: number
  offset?: number
  search?: string
}

type Data = {
  users: GraphQLPagination<User>
}

export const getUsersThunk: ApolloThunkQuery<Data, [Variables]> = async (
  state,
  variables,
) => {
  const { data, error } = await apolloQuery<Data, Variables>({
    state,
    query: QUERY,
    variables,
  })

  return {
    usersData: data?.users,
    usersError: error,
  }
}
