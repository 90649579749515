export enum ActionType {
  SET_ENTRY_PATH_IF_NOT_EXIST = "common/SET_ENTRY_PATH_IF_NOT_EXIST",
  CHANGE_FEE_STATUS = "common/CHANGE_FEE_STATUS",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Scope {
  ADMIN = "admin",
  USER = "user",
}

export const SCOPE_SPECIFIER_HEADER_NAME = "x-app-scope"

/**
 * Its null. It's needed for types compatibility
 * Try to avoid it
 * @example
 * null
 */
export const FIREBASE_NULL = null as unknown as undefined

export const DEFAULT_GRAPHQL_RESPONSE = "response"
