import React from "react"
import { useDispatch } from "react-redux"
import _ from "lodash"

import { checkboxOptionChecked, radioOptionChecked } from "../../../actions"

import { Row, Col } from "antd"

import OptionView from "./OptionView"

import { RadioQuestion, CheckBoxQuestion, QuestionOption } from "../../../types"

type Question = RadioQuestion | CheckBoxQuestion

const isChecked = (question: Question, option: QuestionOption) =>
  question.type === "checkbox"
    ? !!option.checked
    : option.id === question.selectedOption

interface OptionQuestionProps {
  question: Question
  isSubQuestion?: boolean
}

const OptionQuestion = (props: OptionQuestionProps) => {
  const dispatch = useDispatch()

  const onChecked = (option: QuestionOption, optionId: string) => {
    if (props.question.type === "checkbox") {
      dispatch(
        checkboxOptionChecked({
          questionId: props.question.id,
          checked: !option.checked,
          optionId,
        }),
      )
    } else {
      dispatch(
        radioOptionChecked({
          questionId: props.question.id,
          optionId,
        }),
      )
    }
  }

  const getOrderedOptions = (): QuestionOption[] => {
    return _(props.question.options)
      .map((item, key) => _.assign(item, { id: key }))
      .orderBy(["order", "asc"])
      .value()
  }

  return (
    <Row>
      <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
        {getOrderedOptions().map((option, idx) => {
          return (
            <OptionView
              key={option.id}
              last={getOrderedOptions().length === idx + 1}
              name={option.name && option.name.en}
              type={props.question.type}
              checked={isChecked(props.question, option)}
              onChecked={() => onChecked(option, option.id)}
              subQuestions={option.questions}
              isSubQuestion={props.isSubQuestion}
            />
          )
        })}
      </Col>
    </Row>
  )
}

export default OptionQuestion
