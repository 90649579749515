import firebase from "firebase"
import { createClient } from "graphql-ws"
import { ApolloClient, InMemoryCache, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { setContext } from "@apollo/client/link/context"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { onError } from "@apollo/client/link/error"

import config from "../../config"
import { SCOPE_SPECIFIER_HEADER_NAME, Scope } from "../common/constants"

const httpLink = new BatchHttpLink({
  uri: config.apollo.apiUrl,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
})

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from firebase if it exists
  const token = await firebase.auth().currentUser?.getIdToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.apollo.apiUrl.replace("http", "ws"),
    shouldRetry: () => true,
    connectionParams: async () => {
      // get the authentication token from firebase if it exists
      const token = await firebase.auth().currentUser?.getIdToken()
      // return the connection param with authorization to handle it on the server side
      return {
        authorization: token ? `Bearer ${token}` : "",
        [SCOPE_SPECIFIER_HEADER_NAME]: Scope.ADMIN,
      }
    },
  }),
)

const appScopeLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    [SCOPE_SPECIFIER_HEADER_NAME]: Scope.ADMIN,
  },
}))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  errorLink.concat(wsLink),
  authLink.concat(appScopeLink).concat(errorLink).concat(httpLink),
)

export default new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    possibleTypes: {
      AddressesAnsweredDto: ["BasicAddressesDto", "DeliveryAddressesDto"],
      OfferRequestQuestionDto: [
        "OfferRequestRadioQuestionDto",
        "OfferRequestCheckBoxQuestionDto",
        "OfferRequestTextAreaQuestionDto",
        "OfferRequestListPickerDto",
        "OfferRequestNumberQuestionDto",
        "OfferRequestDateQuestionDto",
        "OfferRequestImageQuestionDto",
        "OfferRequestNumberInputQuestionDto",
      ],
    },
    typePolicies: {
      OfferRequestQuestionOptionDto: {
        keyFields: false,
      },
      OfferRequestRadioQuestionDto: {
        keyFields: false,
      },
      OfferRequestCheckBoxQuestionDto: {
        keyFields: false,
      },
      OfferRequestTextAreaQuestionDto: {
        keyFields: false,
      },
      OfferRequestListPickerDto: {
        keyFields: false,
      },
      OfferRequestNumberQuestionDto: {
        keyFields: false,
      },
      OfferRequestDateQuestionDto: {
        keyFields: false,
      },
      OfferRequestImageQuestionDto: {
        keyFields: false,
      },
      OfferRequestNumberInputQuestionDto: {
        keyFields: false,
      },
      ChatMemberDto: {
        keyFields: false,
      },
    },
  }),
})
