import React from "react"
import { Typography } from "antd"

const { Paragraph } = Typography

export const Blocked = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "25%" }}>
      <Paragraph>NO ACCESS</Paragraph>
      <Paragraph>
        Only for admins. Ask more at the slack channel or if you are a developer
        you can add your uuid in adminUsers collection in firebase DB
      </Paragraph>
    </div>
  )
}
