export const buttonTitles = {
  give: "Give admin permissions",
  takeOut: "Take out admin permissions",
  wait: "Wait a moment...",
}

export const popconfirm = {
  changeTitle: "Confirm action on change user's permissions",
  createTitle: "Confirm creating administrator",
  okText: "Continue",
  cancelText: "Cancel",
}

export const getSuccessMessageChange = (email?: string) =>
  `Admin permissions for ${email} was change successful`

export const getSuccessMessageCreate = (email?: string) =>
  `User with email: ${email} was created and have admin permissions`

export const errorMessageCreateAdmin =
  "Something went wrong. Search in existed users or check form fields"

export const errorMessageChangePermissions = "Something went wrong."

export const errorPhoneNumber = "Invalid phone number"
