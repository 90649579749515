import { gql } from "@apollo/client"
import { OfferRequestDB } from "hero24-types"

import {
  OFFER_REQUEST_CHAT_FRAGMENT,
  OfferRequestChat,
  OfferRequestChatAdapter,
} from "./OfferRequestChatInfo"
import {
  OFFER_REQUEST_DATA_FRAGMENT,
  OfferRequestData,
  OfferRequestDataAdapter,
} from "./OfferRequestDataInfo"
import {
  OFFER_REQUEST_REFUND_FRAGMENT,
  OfferRequestRefund,
  OfferRequestRefundAdapter,
} from "./OfferRequestRefundInfo"
import {
  OFFER_REQUEST_STRIPE_PAYMENT_FRAGMENT,
  OfferRequestStripePayment,
  OfferRequestStripePaymentAdapter,
} from "./OfferRequestStripePaymentInfo"
import {
  OFFER_REQUEST_SUBSCRIPTION_FRAGMENT,
  OfferRequestSubscription,
  OfferRequestSubscriptionAdapter,
} from "./OfferRequestSubscriptionInfo"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter, convertListToMap } from "../../../../common/utilities"

export type OfferRequest = {
  id: string
  data: OfferRequestData
  chats?: Maybe<OfferRequestChat[]>
  subscription?: Maybe<OfferRequestSubscription>
  stripeSubscriptionId?: Maybe<string>
  stripePaymentIntentId?: Maybe<string>
  customerVAT?: Maybe<number>
  hero24Cut: Maybe<number>
  serviceProviderVAT?: Maybe<number>
  netvisorSalesOrderId?: Maybe<string>
  netvisorSalesInvoiceNumber?: Maybe<number>
  minimumDuration?: Maybe<number>
  sendToNetvisor?: Maybe<number>
  isApproved?: Maybe<boolean>
  isApprovedByBuyer?: Maybe<boolean>
  offers?: Maybe<string[]>
  fees?: Maybe<string[]>
  paymentParamsId?: Maybe<string> // legacy
  paymentInfoId?: Maybe<string>
  paymentTransactions?: Maybe<string[]>
  offerRequestChanged?: Maybe<boolean>
  offerRrequestChangeAccepted?: Maybe<boolean>
  refund?: Maybe<OfferRequestRefund>
  stripePayment?: Maybe<OfferRequestStripePayment> // Added temp
}

export const OfferRequestAdapter = new FirebaseAdapter<
  OfferRequestDB & { id: string },
  OfferRequest
>({
  toFirebase: (graphql) => ({
    id: graphql.id,
    data: OfferRequestDataAdapter.toFirebase(graphql.data),
    offers: graphql.offers ? convertListToMap(graphql.offers, true) : undefined,
    fees: graphql.fees ? convertListToMap(graphql.fees, true) : undefined,
    paymentTransactions: graphql.paymentTransactions
      ? convertListToMap(graphql.paymentTransactions, true)
      : undefined,
    refund: graphql.refund
      ? OfferRequestRefundAdapter.toFirebase(graphql.refund)
      : undefined,
    stripePayment: graphql.stripePayment
      ? OfferRequestStripePaymentAdapter.toFirebase(graphql.stripePayment)
      : undefined,
    subscription: graphql.subscription
      ? OfferRequestSubscriptionAdapter.toFirebase(graphql.subscription)
      : undefined,
    chats: graphql.chats
      ? Object.fromEntries(
          graphql.chats.map((chat) => [
            chat.sellerProfileId,
            OfferRequestChatAdapter.toFirebase(chat),
          ]),
        )
      : undefined,
    stripeSubscriptionId: graphql.stripeSubscriptionId ?? undefined,
    stripePaymentIntentId: graphql.stripePaymentIntentId ?? undefined,
    customerVAT: graphql.customerVAT ?? undefined,
    hero24Cut: graphql.hero24Cut ?? undefined,
    serviceProviderVAT: graphql.serviceProviderVAT ?? undefined,
    netvisorSalesOrderId: graphql.netvisorSalesOrderId ?? undefined,
    netvisorSalesInvoiceNumber: graphql.netvisorSalesInvoiceNumber ?? undefined,
    minimumDuration: graphql.minimumDuration ?? undefined,
    sendToNetvisor: graphql.sendToNetvisor ?? undefined,
    isApproved: graphql.isApproved ?? undefined,
    isApprovedByBuyer: graphql.isApprovedByBuyer ?? undefined,
    paymentParamsId: graphql.paymentParamsId ?? undefined,
    paymentInfoId: graphql.paymentInfoId ?? undefined,
    offerRequestChanged: graphql.offerRequestChanged ?? undefined,
    offerRrequestChangeAccepted:
      graphql.offerRrequestChangeAccepted ?? undefined,
  }),
  toGraphQL: (firebase) => ({
    id: firebase.id,
    stripeSubscriptionId: firebase.stripeSubscriptionId,
    stripePaymentIntentId: firebase.stripePaymentIntentId,
    customerVAT: firebase.customerVAT,
    serviceProviderVAT: firebase.serviceProviderVAT,
    hero24Cut: firebase.hero24Cut,
    netvisorSalesOrderId: firebase.netvisorSalesOrderId,
    netvisorSalesInvoiceNumber: firebase.netvisorSalesInvoiceNumber,
    minimumDuration: firebase.minimumDuration,
    sendToNetvisor: firebase.sendToNetvisor,
    isApproved: firebase.isApproved,
    isApprovedByBuyer: firebase.isApprovedByBuyer,
    paymentParamsId: firebase.paymentParamsId,
    paymentInfoId: firebase.paymentInfoId,
    offerRequestChanged: firebase.offerRequestChanged,
    offerRrequestChangeAccepted: firebase.offerRrequestChangeAccepted,
    data: OfferRequestDataAdapter.toGraphQL(firebase.data),
    offers: firebase.offers ? Object.keys(firebase.offers) : undefined,
    fees: firebase.fees ? Object.keys(firebase.fees) : undefined,
    paymentTransactions:
      firebase.paymentTransactions && Object.keys(firebase.paymentTransactions),
    refund: firebase.refund
      ? OfferRequestRefundAdapter.toGraphQL(firebase.refund)
      : undefined,
    stripePayment: firebase.stripePayment
      ? OfferRequestStripePaymentAdapter.toGraphQL(firebase.stripePayment)
      : undefined,
    subscription: firebase.subscription
      ? OfferRequestSubscriptionAdapter.toGraphQL(firebase.subscription)
      : undefined,
    chats: firebase.chats
      ? Object.values(firebase.chats).map((chat) =>
          OfferRequestChatAdapter.toGraphQL(chat),
        )
      : undefined,
  }),
})

export const OFFER_REQUEST_FRAGMENT = gql`
  ${OFFER_REQUEST_DATA_FRAGMENT}
  ${OFFER_REQUEST_CHAT_FRAGMENT}
  ${OFFER_REQUEST_SUBSCRIPTION_FRAGMENT}
  ${OFFER_REQUEST_REFUND_FRAGMENT}
  ${OFFER_REQUEST_STRIPE_PAYMENT_FRAGMENT}

  fragment OfferRequestInfo on OfferRequestDto {
    id
    data {
      ...OfferRequestDataInfo
    }
    chats {
      ...OfferRequestChatInfo
    }
    subscription {
      ...OfferRequestSubscriptionInfo
    }
    refund {
      ...OfferRequestRefundInfo
    }
    stripePayment {
      ...OfferRequestStripePaymentInfo
    }
    stripeSubscriptionId
    stripePaymentIntentId
    customerVAT
    serviceProviderVAT
    netvisorSalesOrderId
    netvisorSalesInvoiceNumber
    minimumDuration
    sendToNetvisor
    isApproved
    isApprovedByBuyer
    offers
    fees
    paymentParamsId
    paymentInfoId
    paymentTransactions
    offerRequestChanged
    offerRrequestChangeAccepted
  }
`
