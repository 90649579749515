import { parsePhoneNumberFromString } from "libphonenumber-js"
import * as Yup from "yup"
import { isFieldRequired } from "./utils/isFieldRequired"
import { Maybe } from "../../types"

export const phoneField: (isRequired: boolean) => Yup.Schema<string> = (
  isRequired,
) =>
  isFieldRequired(
    Yup.string()
      .test(
        "countryCode",
        "Must include country code",
        (phone: Maybe<string>) => {
          return !!phone && phone.startsWith("+")
        },
      )
      .test(
        "isValidNumber",
        "Must be valid phonenumber",
        (phone: Maybe<string>) => {
          const isPhoneNumberValid = parsePhoneNumberFromString(
            phone ?? "",
          )?.isValid()

          return Boolean(isPhoneNumberValid)
        },
      ),
    isRequired,
  )
