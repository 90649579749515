import React from "react"
import { ProjectOutlined } from "@ant-design/icons"

import AuthRoute from "../../common/AuthRoute"
import { OfferList } from "./components/containers"
import reducer from "./reducer"

export * from "./selectors"

export default {
  sideBarItems: [
    {
      icon: <ProjectOutlined />,
      name: "Offers by Sellers",
      path: "offers",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/offers"
      path="/offers"
      render={() => <OfferList />}
    />,
  ],
  reducer,
}
