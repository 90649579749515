import { SellerProfileDB } from "hero24-types"

export abstract class GraphQLSellerProfileData {
  abstract photoURL: string
  abstract companyName: string
  abstract companyEmail: string
  abstract heroBIOText?: string
  abstract categories?: string[]
  abstract companyVAT: string
  abstract langs: string[]
  abstract city: string
  abstract streetAddress: string
  abstract postalCode: string
  abstract yearsOfExperience?: number
  abstract workAreas?: string[]
  abstract certificate?: string
  abstract weeksOfSentPurchaseInvoices?: number

  static convertFromFirebaseType(
    sellerProfileData: SellerProfileDB["data"],
  ): GraphQLSellerProfileData {
    return {
      ...sellerProfileData,
      categories:
        sellerProfileData.categories &&
        Object.keys(sellerProfileData.categories),
    }
  }

  static converToFirebaseType(
    sellerProfileData: GraphQLSellerProfileData,
  ): SellerProfileDB["data"] {
    return {
      ...sellerProfileData,
      categories:
        sellerProfileData.categories &&
        Object.fromEntries(
          sellerProfileData.categories.map((id) => [id, true]),
        ),
    }
  }
}
