import React from "react"
import { Form, Input } from "antd"
import { useDispatch } from "react-redux"
import { debounce } from "lodash"
import { Seller } from "../../../seller/types"
import {
  updateSellerInfo,
  UpdateSellerInfoProps,
} from "../../../seller/actions"

interface Props {
  seller: Seller
}

export const SellerInfo = ({ seller }: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const changeValues = (
    changedValue: Partial<UpdateSellerInfoProps["sellerData"]>,
    values: Partial<UpdateSellerInfoProps["sellerData"]>,
  ) => {
    dispatch(
      updateSellerInfo({
        sellerId: seller.id,
        sellerData: values,
      }),
    )
  }
  const debouncedOnChangeValues = debounce(changeValues, 1000)
  return (
    <Form
      form={form}
      size="large"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      layout="horizontal"
      style={{ minWidth: 400 }}
      initialValues={
        {
          companyEmail: seller.sellerData.companyEmail,
          companyName: seller.sellerData.companyName,
          companyVAT: seller.sellerData.companyVAT,
        } as Partial<UpdateSellerInfoProps["sellerData"]>
      }
      onValuesChange={(changedValue, values) => {
        debouncedOnChangeValues(
          changedValue,
          values as Partial<UpdateSellerInfoProps["sellerData"]>,
        )
      }}
    >
      <Form.Item label="Company name" name="companyName">
        <Input />
      </Form.Item>
      <Form.Item label="Company email" name="companyEmail">
        <Input />
      </Form.Item>
      <Form.Item label="Company VAT" name="companyVAT">
        <Input />
      </Form.Item>
    </Form>
  )
}
