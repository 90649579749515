import {
  OfferRequestSubscription as OfferRequestSubscriptionDB,
  SUBSCRIPTION_INTERVAL,
} from "hero24-types"
import { gql } from "@apollo/client"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

export type OfferRequestSubscription = {
  subscriptionId: string
  subscriptionType: SUBSCRIPTION_INTERVAL
  initialRequest?: Maybe<boolean>
  priceDiscount?: Maybe<number>
  discountFormat?: Maybe<"fixed" | "percentage">
  currentAnchorDate: number
  nextAnchorDate: number
}

export const OfferRequestSubscriptionAdapter = new FirebaseAdapter<
  OfferRequestSubscriptionDB,
  OfferRequestSubscription
>({
  toFirebase: (graphql) => ({
    subscriptionId: graphql.subscriptionId,
    subscriptionType: graphql.subscriptionType,
    initialRequest: graphql.initialRequest ?? undefined,
    priceDiscount: graphql.priceDiscount ?? undefined,
    discountFormat: graphql.discountFormat ?? undefined,
    currentAnchorDate: graphql.currentAnchorDate,
    nextAnchorDate: graphql.nextAnchorDate,
  }),
  toGraphQL: (firebase) => ({
    subscriptionId: firebase.subscriptionId,
    subscriptionType: firebase.subscriptionType,
    initialRequest: firebase.initialRequest,
    priceDiscount: firebase.priceDiscount,
    discountFormat: firebase.discountFormat,
    currentAnchorDate: firebase.currentAnchorDate,
    nextAnchorDate: firebase.nextAnchorDate,
  }),
})

export const OFFER_REQUEST_SUBSCRIPTION_FRAGMENT = gql`
  fragment OfferRequestSubscriptionInfo on OfferRequestSubscriptionDto {
    subscriptionId
    subscriptionType
    initialRequest
    priceDiscount
    discountFormat
    currentAnchorDate
    nextAnchorDate
  }
`
