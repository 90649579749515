import { Badge, Space } from "antd"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUnseenChatsCount } from "../../selectors"
import { fetchUnseenChatsCount } from "../../actions"
import { useSubscribeOnChatSeenByAdminUpdates } from "../hooks/useSubscribeOnChatSeenByAdminUpdates"

export const NavLabel = () => {
  const dispatch = useDispatch()
  const unseenChatsCount = useSelector(selectUnseenChatsCount)

  useEffect(() => {
    dispatch(fetchUnseenChatsCount())
  }, [dispatch])

  useSubscribeOnChatSeenByAdminUpdates()

  return (
    <Space direction="horizontal">
      Chats
      <Badge
        count={unseenChatsCount}
        size="small"
        style={{ display: "inline-block" }}
      />
    </Space>
  )
}
