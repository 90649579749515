import React from "react"
import { ColumnsType } from "antd/lib/table"
import { PaymentTransaction } from "../../types"
import { UsersState } from "../../../../users/list/types"
import { UserName } from "../../../../common/components/listComponent/UserName"
import { CategoriesState } from "../../../../buyer/categories/types"
import { CategoryName } from "../../../../common/components/listComponent/Category"
import { formatDateAndTime } from "../../../../common/utils"
import { ColumnText } from "../../../../common/components/listComponent/ColumnText"
import { OfferRequestsState } from "../../../../offerRequests/list/types"
import { Button, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"

type Column = ColumnsType<PaymentTransaction>

const getColumns = (
  customers: UsersState,
  categories: CategoriesState,
  offerRequests: OfferRequestsState,
) => {
  const dateColumn: Column[0] = {
    title: "Date",
    render: (paymentTransaction: PaymentTransaction) => (
      <ColumnText>
        {paymentTransaction.createdAt &&
          formatDateAndTime(paymentTransaction.createdAt, "dateAndTimeFormat")}
      </ColumnText>
    ),
    key: "paymentTransaction.createdAt",
    defaultSortOrder: "descend",
    sorter: (
      paymentTransactionA: PaymentTransaction,
      paymentTransactionB: PaymentTransaction,
    ) => {
      const a = paymentTransactionA.createdAt
      const b = paymentTransactionB.createdAt
      return a && b ? (a > b ? 1 : -1) : a ? 1 : -1
    },
  }

  const statusColumn: Column[0] = {
    title: "Status",
    render: (paymentTransaction: PaymentTransaction) => (
      <ColumnText>{paymentTransaction.status}</ColumnText>
    ),
    key: "paymentTransaction.status",
  }

  const buyerColumn: Column[0] = {
    title: "Customer",
    render: (paymentTransaction: PaymentTransaction) => (
      <UserName user={customers?.users[paymentTransaction.buyerId]} />
    ),
    key: "paymentTransaction.buyerId",
    sorter: (
      paymentTransactionA: PaymentTransaction,
      paymentTransactionB: PaymentTransaction,
    ) => {
      const aName =
        customers?.users[paymentTransactionA.buyerId]?.data.name ||
        paymentTransactionA.buyerId
      const bName =
        customers?.users[paymentTransactionB.buyerId]?.data.name ||
        paymentTransactionB.buyerId
      return aName.localeCompare(bName)
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by customer name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, paymentTransaction: PaymentTransaction) => {
      const customerName =
        customers.users[paymentTransaction.buyerId]?.data.name

      return typeof value === "string"
        ? customerName.toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const categoryColumn: Column[0] = {
    key: "paymentTransaction.status",
    title: "Category",
    render: (paymentTransaction: PaymentTransaction) => {
      const offerRequest =
        offerRequests.offerRequests[paymentTransaction.offerRequestId]
      if (!offerRequest) {
        return ""
      }
      return (
        <CategoryName
          category={categories.categories[offerRequest.data.initial.category]}
        />
      )
    },
    filters: Object.values(categories.categories)
      .sort((a, b) => (a.name.en || "").localeCompare(b.name.en || ""))
      .map((category) => ({
        text: category.name.en,
        value: category.id,
      })),
    onFilter: (value: any, paymentTransaction: PaymentTransaction) =>
      offerRequests.offerRequests[paymentTransaction.offerRequestId]?.data
        .initial.category === value,
  }

  const amountColumn: Column[0] = {
    title: "Amount",
    render: (paymentTransaction: PaymentTransaction) => (
      <ColumnText>{paymentTransaction.totalAmount}</ColumnText>
    ),
    key: "paymentTransaction.totalAmount",
  }

  const orderIdColumn: Column[0] = {
    title: "OrderId",
    render: (paymentTransaction: PaymentTransaction) => (
      <ColumnText>{paymentTransaction.offerRequestId}</ColumnText>
    ),
    key: "paymentTransaction.offerRequestId",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by Order Id`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, paymentTransaction: PaymentTransaction) => {
      return typeof value === "string"
        ? paymentTransaction.offerRequestId
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    },
  }

  return [
    dateColumn,
    statusColumn,
    amountColumn,
    buyerColumn,
    categoryColumn,
    orderIdColumn,
  ]
}

export default getColumns
