import { useEffect, useRef } from "react"

export const usePrevious = <T>(value: T) => {
  const next = useRef(value)
  const previous = useRef(value)

  useEffect(() => {
    previous.current = next.current
    next.current = value
  }, [value])

  return previous.current
}
