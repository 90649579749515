import { gql } from "@apollo/client"
import { ChatMemberRole } from "../constants"

export type ChatMemberDto = {
  id: string
  role: ChatMemberRole
  lastOpened: number
}

export const CHAT_MEMBER_INFO_FRAGMENT = gql`
  fragment ChatMemberInfo on ChatMemberDto {
    id
    role
    lastOpened
  }
`
