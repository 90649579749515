import { Store } from "../core"
import { ChatState } from "./types"

const getChatState = (state: Store): ChatState => state.chat

export const selectChatsFetching = (state: Store) =>
  getChatState(state).fetching

export const selectChats = (state: Store) => getChatState(state).chats

export const selectChatsFilters = (state: Store) =>
  getChatState(state).chatsFilter

export const selectChatsOrderBy = (state: Store) =>
  getChatState(state).chatsOrderBy

export const selectNewChatCount = (state: Store) =>
  getChatState(state).newChatCount

export const selectUnseenChatsCount = (state: Store) =>
  getChatState(state).unseenChatsCount
