import { createSelector } from "reselect"

import { Store } from "../../core/types"
import { Category } from "./types"
import { AddressesAnswered, Address } from "hero24-types"

import { AddressTypes, AddressFields } from "./constants"
import { getOfferRequests } from "../../offerRequests/list/selectors"

const getSelectedCategoryId = (state: Store) => state.categories.selected

const getState = (state: Store) => state

export const getCategories = (state: Store) => state.categories.categories

export const getSelectedCategory = createSelector(
  [getSelectedCategoryId, getCategories],
  (id, categories) => (id ? categories[id] : undefined),
)

export const getCategoryById = (categoryId: string) =>
  createSelector([getCategories], (categories) => categories[categoryId])

export const getOfferRequestCategory = (offerRequestId: string) =>
  createSelector(
    [getOfferRequests, getCategories],
    (offerRequests, categories) => {
      if (!offerRequestId) return

      const categoryId = offerRequests[offerRequestId]?.data.initial.category

      return categories[categoryId]
    },
  )

export const getAddressValuesForAddressType = createSelector(
  [
    getSelectedCategory,
    (_: Store, addressType: AddressTypes): AddressTypes => addressType,
  ],
  (category: Category | undefined, addressType: AddressTypes) => {
    if (!category) {
      return undefined
    }

    const emptyAddress: Address = {
      city: "",
      postalCode: "",
      streetAddress: "",
    }

    if (category.addresses.type === "basic") {
      if (addressType === "main") {
        return category.addresses.main === true
          ? emptyAddress
          : category.addresses.main
      }
    }

    if (category.addresses.type === "delivery") {
      if (addressType === "to") {
        return category.addresses.to === true
          ? emptyAddress
          : category.addresses.to
      }
      if (addressType === "from") {
        return category.addresses.from === true
          ? emptyAddress
          : category.addresses.from
      }
    }

    return undefined
  },
)

export const getAddressValuesForCategory = createSelector(
  [getSelectedCategory, getState],
  (
    category: Category | undefined,
    state: Store,
  ): AddressesAnswered | undefined => {
    if (!category) {
      return undefined
    }

    if (category.addresses.type === "basic") {
      const mainAddress = getAddressValuesForAddressType(state, "main")

      return mainAddress
        ? {
            type: "basic",
            main: mainAddress,
          }
        : undefined
    } else {
      const fromAddress = getAddressValuesForAddressType(state, "from")
      const toAddress = getAddressValuesForAddressType(state, "to")

      return fromAddress && toAddress
        ? {
            type: "delivery",
            from: fromAddress,
            to: toAddress,
          }
        : undefined
    }
  },
)

const validateAddress = (address: Address | true | undefined) => {
  if (!address || address === true) {
    return {
      city: true,
      postalCode: true,
      streetAddress: true,
    }
  } else {
    return {
      city: address.city.length < 1,
      postalCode: address.postalCode.length < 1,
      streetAddress: address.streetAddress.length < 1,
    }
  }
}

interface AddressTypeMainValidated {
  main: {
    [field in AddressFields]: boolean
  }
}

interface AddressTypeDeliveryValidated {
  from: {
    [field in AddressFields]: boolean
  }
  to: {
    [field in AddressFields]: boolean
  }
}

const invalidAddressValuesForCategory = createSelector(
  getSelectedCategory,
  (category: Category | undefined) => {
    if (!category) {
      return undefined
    }
    const { addresses } = category
    if (addresses.type === "basic") {
      return {
        main: validateAddress(addresses.main),
      }
    } else {
      return {
        from: validateAddress(addresses.from),
        to: validateAddress(addresses.to),
      }
    }
  },
)

export const hasInvalidAddressForCategory = createSelector(
  [invalidAddressValuesForCategory, getSelectedCategory],
  (
    validated:
      | AddressTypeMainValidated
      | AddressTypeDeliveryValidated
      | undefined,
    category: Category | undefined,
  ): boolean => {
    if (!category) {
      return false
    }
    const { addresses } = category

    if (addresses.type === "basic") {
      return (
        Object.values((validated as AddressTypeMainValidated).main).filter(
          (value) => value === true,
        ).length > 0
      )
    } else {
      return (
        Object.values((validated as AddressTypeDeliveryValidated).to).filter(
          (value) => value === true,
        ).length > 0 ||
        Object.values((validated as AddressTypeDeliveryValidated).from).filter(
          (value) => value === true,
        ).length > 0
      )
    }
  },
)
