import { OperationVariables, SubscriptionOptions } from "@apollo/client"

import { Store } from "../../core"
import { selectApolloClient } from "../selectors"

export type ApolloSubscriptionOptions<
  Data,
  Variables extends OperationVariables = OperationVariables,
> = SubscriptionOptions<Variables, Data> & {
  state: Store
}

export const apolloSubscription = <Data, Variables extends OperationVariables>({
  state,
  ...options
}: ApolloSubscriptionOptions<Data | null, Variables>) => {
  const apolloClient = selectApolloClient(state)

  return apolloClient.subscribe<Data | null, Variables>(options)
}
