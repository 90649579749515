import { CategoryDB, OfferRequestDB } from "hero24-types"
import moment, { Moment } from "moment-timezone"
import { Category } from "../buyer/categories/types"
import { OfferRequest } from "../offerRequests/list/types"
import { Offer } from "../offers/list/types"
import { HERO24_CUT_DEFAULT } from "./utilities/price"

type Order = "desc" | "asc"
interface SortableByCreatedAt {
  createdAt: number
}

export function sortByCreatedAt<T extends SortableByCreatedAt>(
  objects: T[],
  order: Order,
): T[] {
  return [...objects].sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return order === "desc" ? 1 : -1
    } else if (a.createdAt > b.createdAt) {
      return order === "desc" ? -1 : 1
    }
    return 0
  })
}

interface SortableByOrder {
  order: number
}

export function sortByOrder<T extends SortableByOrder>(
  objects: T[],
  order: "desc" | "asc",
): T[] {
  return [...objects].sort((a, b) => {
    if (a.order < b.order) {
      return order === "desc" ? -1 : 1
    } else if (a.order > b.order) {
      return order === "desc" ? 1 : -1
    }
    return 0
  })
}

interface SortableByStartTime {
  startTime: number
}

export function sortByStartTime<T extends SortableByStartTime>(
  objects: T[],
  order: "desc" | "asc",
): T[] {
  return [...objects].sort((a, b) => {
    if (a.startTime < b.startTime) {
      return order === "desc" ? -1 : 1
    } else if (a.startTime > b.startTime) {
      return order === "desc" ? 1 : -1
    }
    return 0
  })
}

export const getMoment = (date: string | number | Date | Moment) => {
  if (moment.isMoment(date)) {
    return date
  } else if (moment.isDate(date)) {
    return moment(date).tz("Europe/Helsinki")
  } else if (Number.isInteger(Number(date))) {
    // timestamp
    return moment(date, "x").tz("Europe/Helsinki") // timestamp
  } else {
    return moment(date).tz("Europe/Helsinki")
  }
}

type Format =
  | "timeFormat"
  | "dateAndTimeFormat"
  | "dateAndTimeFormatNoYear"
  | "dateAndTimeFormatWithDay"
  | "timeFormatToday"
  | "timeFormatYesterday"
  | "timeFormatWeek"

const formats = {
  timeFormat: "HH:mm",
  dateAndTimeFormat: "DD.MM.YYYY HH:mm",
  dateAndTimeFormatNoYear: "DD.MM HH:mm",
  dateAndTimeFormatWithDay: "ddd MMM D [at] HH:mm",
  timeFormatToday: "[Today] HH:mm",
  timeFormatYesterday: "[Yesterday] HH:mm",
  timeFormatWeek: "ddd HH:mm",
}

export const formatDateAndTime = (
  date: string | number | Date | Moment,
  format: Format,
) => {
  return getMoment(date).format(formats[format])
}

export const getTimeRemainingInMinutes = (time: number | Moment | Date) => {
  const currentTime = moment()
  const deadlineTime = getMoment(time)
  return deadlineTime.diff(currentTime, "minutes")
}

export const getTimeDifferenceString = (minutesRemaining: number) => {
  let diffInMinutes
  let ago
  if (minutesRemaining > 0) {
    diffInMinutes = minutesRemaining
    ago = ""
  } else {
    diffInMinutes = -1 * minutesRemaining
    ago = "ago"
  }

  const hours = Math.floor(diffInMinutes / 60)
  const minutes = diffInMinutes - hours * 60
  if (hours > 0) {
    return `${hours}h ${minutes}min${ago}`
  }
  return `${minutes}min${ago}`
}

export const getOfferStartDateString = (offer: Offer) => {
  return formatDateAndTime(
    offer.data.actualStartTime || offer.data.initial.agreedStartTime,
    "dateAndTimeFormat",
  )
}

export const getTimePassedInHours = (time: number) => {
  const currentTime = moment()
  const timeInPast = getMoment(time)
  return currentTime.diff(timeInPast, "hours")
}

export const getPurchasedOfferDuration = (offer: Offer) => {
  return (
    offer.data.initial.purchase.duration +
    (offer.data.extensions
      ? offer.data.extensions.reduce((extensionsDuration, extension) => {
          return extensionsDuration + extension.duration
        }, 0)
      : 0)
  )
}

export const getActualOfferDuration = (
  offer: Offer,
  category?: Pick<CategoryDB, "minimumDuration">,
) => {
  const { workTime, actualStartTime, actualCompletedTime, pauseDurationMS } =
    offer.data

  let usedDuration: moment.Duration | undefined
  if (workTime) {
    const diffInMS = workTime.reduce((total, work) => {
      if (work.endTime) {
        return (
          total +
          moment(work.endTime, "x").diff(
            moment(work.startTime, "x"),
            "milliseconds",
          )
        )
      } else {
        return total
      }
    }, 0)

    if (diffInMS) {
      usedDuration = moment.duration(diffInMS, "milliseconds")
    }
  } else if (actualStartTime && actualCompletedTime) {
    const start = moment(actualStartTime, "x")
    const end = moment(actualCompletedTime, "x")
    usedDuration = moment.duration(
      end.diff(start, "milliseconds") - (pauseDurationMS || 0),
      "milliseconds",
    )
  }

  if (usedDuration) {
    const minimumDuration = category?.minimumDuration
      ? moment.duration(category.minimumDuration, "h")
      : usedDuration

    return usedDuration.asMilliseconds() > minimumDuration.asMilliseconds()
      ? usedDuration
      : minimumDuration
  } else {
    return undefined
  }
}

export interface Time {
  hours?: number
  minutes?: number
  seconds?: number
}

export function millisecondsToTime(durationMilliseconds: number): Time {
  const result: Time = {}
  const seconds = Math.floor((durationMilliseconds / 1000) % 60)
  if (seconds) {
    result.seconds = seconds
  }
  const minutes = Math.floor((durationMilliseconds / (1000 * 60)) % 60)
  if (minutes) {
    result.minutes = minutes
  }
  const hours = Math.floor((durationMilliseconds / (1000 * 60 * 60)) % 24)
  if (hours) {
    result.hours = hours
  }

  return result
}

export const getStartEndString = (props: {
  startTime: Moment | number
  endTime?: Moment | number
}) => {
  const startTimeMoment = getMoment(props.startTime)
  const startTimeString = formatDateAndTime(
    startTimeMoment,
    "dateAndTimeFormatNoYear",
  )
  const endTimeMoment = props.endTime && getMoment(props.endTime)
  const endTimeString =
    endTimeMoment && endTimeMoment.dayOfYear() !== startTimeMoment.dayOfYear()
      ? formatDateAndTime(endTimeMoment, "dateAndTimeFormatNoYear")
      : endTimeMoment
      ? formatDateAndTime(endTimeMoment, "timeFormat")
      : "?"
  return `${startTimeString} - ${endTimeString}`
}

export const getWorkedDurationString = (
  initialWorkedDuration: moment.Duration,
  minimumDurationNumber: number,
  purchasedDurationNumber: number,
) => {
  const minimumDuration = moment.duration(minimumDurationNumber, "h")
  const purchasedDuration = moment.duration(purchasedDurationNumber, "h")
  const startWorkedDuration = initialWorkedDuration.clone()
  let endWorkedDuration: moment.Duration = startWorkedDuration.clone()

  if (startWorkedDuration.seconds()) {
    startWorkedDuration.add(1, "minute") // to round up minutes
  }

  if (startWorkedDuration.asHours() < minimumDuration.asHours()) {
    endWorkedDuration = minimumDuration.clone()
  } else {
    endWorkedDuration = moment.duration(
      Math.ceil(endWorkedDuration.asHours()),
      "h",
    )

    if (endWorkedDuration.asHours() > purchasedDuration.asHours()) {
      return `${purchasedDuration.asHours()}h`
    }
  }

  return `${endWorkedDuration.asHours()}h`
}

export const isPreferredTimeInThePast = (preferredTime?: Moment | number) => {
  if (!preferredTime) {
    return false
  }
  const preferredTimeMoment = getMoment(preferredTime)
  return preferredTimeMoment.diff(moment(), "hours") <= 0
}

export const getTimeSent = (props: {
  previousDate?: number
  createdAt: number
}) => {
  if (
    props.previousDate &&
    (props.createdAt - props.previousDate) / 1000 / 60 < 1
  ) {
    // less that a minute since previous message
    return null
  }

  const createdAtMoment = getMoment(props.createdAt)
  const todayMoment = moment()

  // TODAY
  if (
    todayMoment.format("YYYY-MM-DD") === createdAtMoment.format("YYYY-MM-DD")
  ) {
    return formatDateAndTime(createdAtMoment, "timeFormatToday")
  }

  // YESTERDAY
  if (
    todayMoment.subtract(1, "days").format("YYYY-MM-DD") ===
    createdAtMoment.format("YYYY-MM-DD")
  ) {
    return formatDateAndTime(createdAtMoment, "timeFormatYesterday")
  }

  // WITHIN A WEEK
  const differenceFromToday = Number(moment().format("x")) - props.createdAt
  if (differenceFromToday / 1000 / 60 / 60 / 24 / 7 < 1) {
    return formatDateAndTime(createdAtMoment, "timeFormatWeek")
  }

  // OLDER
  return formatDateAndTime(createdAtMoment, "dateAndTimeFormat")
}

export const isDurationMoreThanPurchased = (offer: Offer) => {
  const purchasedDurationAsHours = getPurchasedOfferDuration(offer)
  const completedDuration = getActualOfferDuration(offer)
  return (
    completedDuration &&
    purchasedDurationAsHours &&
    completedDuration.asHours() > purchasedDurationAsHours
  )
}

export interface VATs {
  customerVAT: number
  serviceProviderVAT: number
  hero24Cut: number
}

export const getVAT: (
  offerRequest: OfferRequestDB,
  category: Category,
) => VATs = (offerRequest, category) => {
  const { hero24Cut, serviceProviderVAT, customerVAT } = offerRequest

  return {
    customerVAT: customerVAT ?? category.defaultCustomerVAT,
    serviceProviderVAT:
      serviceProviderVAT ?? category.defaultServiceProviderVAT,
    hero24Cut: hero24Cut ?? HERO24_CUT_DEFAULT,
  }
}

export const getMinimumDuration = (
  offerRequest?: OfferRequest,
  category?: Category,
) => {
  const minimumDuration =
    offerRequest?.minimumDuration === undefined
      ? category?.minimumDuration
      : offerRequest.minimumDuration

  return minimumDuration || 0
}
