import { gql } from "@apollo/client"

export type AttachCategoryToSellerData = {
  attachCategoryToSeller: boolean
}
export type AttachCategoryToSellerVariables = {
  sellerId: string
  categoryId: string
}

export const ATTACH_CATEGORY_TO_SELLER_MUTATION = gql`
  mutation AttachCategoryToSeller($sellerId: String!, $categoryId: String!) {
    attachCategoryToSeller(sellerId: $sellerId, categoryId: $categoryId)
  }
`
