import { gql } from "@apollo/client"
import { BuyerProfileDB } from "hero24-types"

export type GetBuyerProfileData = {
  buyer: BuyerProfileDB | null
}

export type GetBuyerProfileVariables = {
  id: string
}

export const GET_BUYER_PROFILE_QUERY = gql`
  query GetBuyerProfile($id: String!) {
    buyer(id: $id) {
      data {
        displayName
        photoURL
        isCreatedFromWeb
      }
      hasMadeApprovedRequest
    }
  }
`
