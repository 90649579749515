import firebase from "firebase/app"
import React, { useEffect, useState } from "react"
import { OfferRequest } from "../../../../buyer/createOfferRequest/types"
import { Timeline } from "antd"
import { ChangeLog } from "hero24-types"
import { useSelector } from "react-redux"
import { Store } from "../../../../core/types"
import { OfferLogType, OfferRequestLogType } from "./types"
import { LogItem } from "./LogItem"

interface Props {
  offerRequest: OfferRequest
}

const OFFER_REQUESTS_LOGS_PATH = "offerRequestsLogs"
const OFFERS_LOGS_PATH = "offersLogs"

const subscribeToOfferRequestLogs = (
  id: string,
  setLogs: (logs: OfferRequestLogType[]) => void,
) => {
  firebase
    .database()
    .ref(OFFER_REQUESTS_LOGS_PATH)
    .child(id)
    .on("value", (logsSnapshot) => {
      const logs = logsSnapshot.val() as { [key: string]: ChangeLog } | null
      if (logs) {
        setLogs(
          Object.entries(logs).map((logEntry) => ({
            offerRequestId: logEntry[0],
            type: "offerRequestLog",
            ...logEntry[1],
          })),
        )
      }
    })
}

const subscribeToOfferLogs = (
  offerId: string,
  setLogs: (logs: OfferLogType[]) => void,
) => {
  firebase
    .database()
    .ref(OFFERS_LOGS_PATH)
    .child(offerId)
    .on("value", (logsSnapshot) => {
      const logs = logsSnapshot.val() as { [key: string]: ChangeLog } | null
      if (logs) {
        setLogs(
          Object.values(logs).map((log) => ({
            offerId: offerId,
            type: "offerLog",
            ...log,
          })),
        )
      }
    })
}

const unSubscribeToOfferRequestLogs = (id: string) => {
  firebase.database().ref(OFFER_REQUESTS_LOGS_PATH).child(id).off()
}

const unSubscribeToOfferLogs = (id: string) => {
  firebase.database().ref(OFFERS_LOGS_PATH).child(id).off()
}

export const OfferRequestLog = ({ offerRequest }: Props) => {
  const [offerRequestLog, setOfferRequestLog] = useState<OfferRequestLogType[]>(
    [],
  )
  const [offerLogs, setOfferLogs] = useState<{
    [offerId: string]: OfferLogType[]
  }>({})

  const users = useSelector((store: Store) => {
    return store.users
  })

  useEffect(() => {
    subscribeToOfferRequestLogs(offerRequest.id, (logs) =>
      setOfferRequestLog(logs),
    )
    offerRequest.offers &&
      Object.keys(offerRequest.offers).forEach((offerId) => {
        subscribeToOfferLogs(offerId, (logs) => {
          setOfferLogs((offerLogs) => ({
            ...offerLogs,
            [offerId]: logs,
          }))
        })
      })
    return () => {
      unSubscribeToOfferRequestLogs(offerRequest.id)
      offerRequest.offers &&
        Object.keys(offerRequest.offers).forEach((offerId) => {
          unSubscribeToOfferLogs(offerId)
        })
    }
  }, [setOfferRequestLog, setOfferLogs, offerRequest])

  const allLogs = [...offerRequestLog, ...Object.values(offerLogs).flat()].sort(
    (logA, logB) => (logA.time > logB.time ? 1 : -1),
  )

  return (
    <Timeline mode="left">
      {allLogs.map((log) => (
        <LogItem key={log.time} log={log} users={users} />
      ))}
    </Timeline>
  )
}
