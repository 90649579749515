import { gql } from "@apollo/client"
import { OfferRequestChat as OfferRequestChatDB } from "hero24-types"
import { FirebaseAdapter } from "../../../../common/utilities"

export type OfferRequestChat = {
  sellerProfileId: string
  chatId: string
}

export const OfferRequestChatAdapter = new FirebaseAdapter<
  OfferRequestChatDB,
  OfferRequestChat
>({
  toFirebase: ({ chatId, sellerProfileId }) => ({
    chatId,
    sellerProfile: sellerProfileId,
  }),
  toGraphQL: ({ chatId, sellerProfile }) => ({
    chatId,
    sellerProfileId: sellerProfile,
  }),
})

export const OFFER_REQUEST_CHAT_FRAGMENT = gql`
  fragment OfferRequestChatInfo on OfferRequestChatDto {
    sellerProfileId
    chatId
  }
`
