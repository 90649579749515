import React, { ReactNode } from "react"
import { useSelector } from "react-redux"
import { Row, Col } from "antd"

import { Question } from ".."

import { getSortedQuestionsIdsForCategory } from "../../selectors"

import { Category } from "../../../categories/types"

interface Props {
  isSubQuestion?: boolean
  excludeRegExp?: RegExp
}

interface SubQuestionListProps extends Props {
  questionIds: string[]
  category?: undefined
}

interface QuestionListProps extends Props {
  category: Category
}

type OwnProps = SubQuestionListProps | QuestionListProps

const QuestionList = (props: OwnProps) => {
  const questionIds = useSelector(
    getSortedQuestionsIdsForCategory((props as QuestionListProps).category?.id),
  )

  const subQuestionIds = (props as SubQuestionListProps).questionIds || null
  const questionList = props.isSubQuestion ? subQuestionIds : questionIds

  return (
    <Row
      style={props.isSubQuestion ? { marginLeft: 26, flex: 1 } : { flex: 1 }}
    >
      {questionList.reduce<ReactNode[]>((acc, questionId) => {
        if (!props.excludeRegExp?.test(questionId)) {
          return [
            ...acc,
            <Col key={questionId} xs={24}>
              <Question
                questionId={questionId}
                isSubQuestion={props.isSubQuestion}
                category={props.category}
              />
            </Col>,
          ]
        }

        return acc
      }, [])}
    </Row>
  )
}

export default QuestionList
