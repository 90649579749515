import firebase from "firebase/app"
import { Dispatch } from "redux"
import { SellerProfileDB, ReviewDB } from "hero24-types"

import * as TYPES from "./actionTypes"
import { BriefSeller, Seller } from "./types"
import { Store } from "../core/types"
import { FIREBASE_PATH_REVIEWS } from "./constants"
import { selectApolloClient } from "../apolloClient/selectors"
import { getUserThunk } from "../user/graphql/queries"

import {
  GetSellerProfilesData,
  GetSellerProfilesVariables,
  GET_SELLER_PROFILES_QUERY,
} from "./graphql/queries/GetSellerProfiles"
import { GraphQLSellerProfile } from "./graphql/adapters/GraphQLSellerProfile"
import {
  GetSellerProfileData,
  GetSellerProfileVariables,
  GET_SELLER_PROFILE_QUERY,
} from "./graphql/queries/GetSellerProfile"
import {
  EditSellerProfileDataData,
  EditSellerProfileDataVariables,
  EDIT_SELLER_PROFILE_MUTATION,
} from "./graphql/mutations/EditSellerProfileData"
import { GraphQLSellerProfileData } from "./graphql/adapters/GraphQLSellerProfileData"
import {
  AttachCategoryToSellerData,
  AttachCategoryToSellerVariables,
  ATTACH_CATEGORY_TO_SELLER_MUTATION,
} from "./graphql/mutations/AttachCategoryToSeller"
import {
  UnattachCategoryFromSellerData,
  UnattachCategoryFromSellerVariables,
  UNATTACH_CATEGORY_FROM_SELLER_MUTATION,
} from "./graphql/mutations/UnattachCategoryFromSeller"
import {
  RemoveReviewFromSellerData,
  RemoveReviewFromSellerVariables,
  REMOVE_REVIEW_FROM_SELLER_MUTATION,
} from "./graphql/mutations/RemoveReviewFromSeller"
import {
  GetIsSellerApprovedData,
  GetIsSellerApprovedVariables,
  IS_SELLER_APPROVED_QUERY,
} from "./graphql/queries/GetIsSellerApproved"
import {
  SetIsSellerApprovedData,
  SetIsSellerApprovedVariables,
  SET_IS_SELLER_APPROVED_MUTATION,
} from "./graphql/mutations/SetIsSellerApproved"
import {
  GET_BRIEF_SELLER_PROFILES_QUERY,
  GetBriefSellerProfilesData,
  GetBriefSellerProfilesVariables,
} from "./graphql/queries/GetBriefSellerProfiles"
import { UserAdapter } from "../user/graphql/fragments"

export interface GetSellers {
  type: typeof TYPES.GET_SELLERS
  payload: {
    [key: string]: Seller
  }
}

export interface SetBriefSellers {
  type: typeof TYPES.SET_BRIEF_SELLERS
  payload: {
    [key: string]: BriefSeller
  }
}

export const loadAllBriefSellers =
  () => async (dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    const { data } = await apolloClient.query<
      GetBriefSellerProfilesData,
      GetBriefSellerProfilesVariables
    >({
      query: GET_BRIEF_SELLER_PROFILES_QUERY,
    })

    dispatch({
      type: TYPES.SET_BRIEF_SELLERS,
      payload: Object.fromEntries(
        data.sellers.edges.map(({ cursor, node }) => [cursor, node]),
      ),
    })
  }

export const getAllSellers =
  () => async (dispatch: Dispatch, getState: () => Store) => {
    const state = getState()
    const apolloClient = selectApolloClient(state)

    const { data } = await apolloClient.query<
      GetSellerProfilesData,
      GetSellerProfilesVariables
    >({
      query: GET_SELLER_PROFILES_QUERY,
    })

    const sellers: { [id: string]: SellerProfileDB } = Object.fromEntries(
      data.sellers.edges.map(({ node }) => [
        node.id,
        GraphQLSellerProfile.convertToFirebaseType(node),
      ]),
    )

    const dataSellers: {
      [key: string]: Seller
    } = Object.fromEntries(
      await Promise.all(
        Object.entries(sellers).map(async (entry) => {
          const id = entry[0]

          const { userData: graphqlUser } = await getUserThunk(state, id)

          if (!graphqlUser) {
            throw new Error("User is not found")
          }

          const user = UserAdapter.toFirebase(graphqlUser)

          const {
            data: { isSellerApproved },
          } = await apolloClient.query<
            GetIsSellerApprovedData,
            GetIsSellerApprovedVariables
          >({
            query: IS_SELLER_APPROVED_QUERY,
            variables: { id },
          })

          const reviews =
            entry[1].reviews &&
            (await Promise.all(
              Object.keys(entry[1].reviews).map(async (key) => {
                const reviewRef: firebase.database.Reference = await firebase
                  .database()
                  .ref(FIREBASE_PATH_REVIEWS)
                  .child(key)

                const reviewSnapshopValue = await reviewRef.once("value")
                const review: ReviewDB = reviewSnapshopValue.val()
                return {
                  ...review,
                  id: key,
                }
              }),
            ))

          let averageReview: number | undefined = undefined
          if (reviews) {
            averageReview =
              reviews.reduce((acc: number, curr: ReviewDB) => {
                return acc + curr.data?.initial.rating
              }, 0) / reviews.length
          }

          const seller: Seller = {
            id,
            sellerData: {
              ...sellers[id].data,
              isApproved: isSellerApproved,
              netvisorSellerId: user.netvisorSellerId,
            },
            userData: user.data,
            offers: user.offers ? Object.keys(user.offers) : [],
            reviews,
            averageReview,
          }
          return [id, seller]
        }),
      ),
    )

    dispatch({
      type: TYPES.GET_SELLERS,
      payload: dataSellers,
    })
  }

export interface UpdateSeller {
  type: typeof TYPES.UPDATE_SELLER_AVERAGE_REVIEW
  payload: {
    averageReview: number | undefined
    sellerId: string
  }
}
export interface UpdateSellerReviewProps {
  id: string
  text?: string
  rating?: string
  removeFromSeller?: boolean
}
export interface UpdateSellerReview {
  type: typeof TYPES.UPDATE_SELLER_REVIEW
  payload: {
    sellerId: string
    reviewId: string
    reviewData: Partial<ReviewDB["data"]>
    removeFromSeller?: boolean
  }
}

export const editReview =
  (props: UpdateSellerReviewProps) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    const { id, text, rating, removeFromSeller } = props

    const reviewRef: firebase.database.Reference = await firebase
      .database()
      .ref(FIREBASE_PATH_REVIEWS)
      .child(id)

    reviewRef
      .child("data")
      .child("initial")
      .update({
        ...(text ? { text } : {}),
        ...(rating ? { rating: Number(rating) } : {}),
      })

    const reviewSnapshopValue = await reviewRef.once("value")
    const review: ReviewDB = reviewSnapshopValue.val()

    const apolloClient = selectApolloClient(getState())

    if (removeFromSeller) {
      await apolloClient.mutate<
        RemoveReviewFromSellerData,
        RemoveReviewFromSellerVariables
      >({
        mutation: REMOVE_REVIEW_FROM_SELLER_MUTATION,
        variables: {
          sellerId: review.data.initial.sellerProfile,
          reviewId: id,
        },
      })
    }

    dispatch({
      type: TYPES.UPDATE_SELLER_REVIEW,
      payload: {
        sellerId: review.data.initial.sellerProfile,
        reviewId: id,
        reviewData: review.data,
        removeFromSeller,
      },
    })

    const {
      seller: { sellers },
    } = getState()
    const seller = sellers[review.data.initial.sellerProfile]

    let averageReview: number | undefined = undefined
    if (seller?.reviews) {
      averageReview =
        seller.reviews.reduce((acc: number, curr: ReviewDB) => {
          return acc + curr.data.initial.rating
        }, 0) / seller?.reviews.length
    }

    dispatch({
      type: TYPES.UPDATE_SELLER_AVERAGE_REVIEW,
      payload: {
        averageReview,
        sellerId: review.data.initial.sellerProfile,
      },
    })
  }

export const approveSeller =
  (id: string, isApproved: boolean) =>
  async (dispatch: unknown, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    await apolloClient.mutate<
      SetIsSellerApprovedData,
      SetIsSellerApprovedVariables
    >({
      mutation: SET_IS_SELLER_APPROVED_MUTATION,
      variables: {
        sellerId: id,
        isApproved: !!isApproved, // TODO: remove after migration. Its needed to support legacy, where isApproved could be null
      },
    })
  }

export interface UpdateSellerCategoryProps {
  sellerId: string
  category: string
  action: "add" | "remove"
}
export interface UpdateSellerCategory {
  type: typeof TYPES.UPDATE_SELLER_CATEGORY
  payload: UpdateSellerCategoryProps
}
export const updateSellerCategory =
  (props: UpdateSellerCategoryProps) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    const { data } = await apolloClient.query<
      GetSellerProfileData,
      GetSellerProfileVariables
    >({
      query: GET_SELLER_PROFILE_QUERY,
      variables: { id: props.sellerId },
    })

    if (data.seller) {
      if (props.action === "add") {
        await apolloClient.mutate<
          AttachCategoryToSellerData,
          AttachCategoryToSellerVariables
        >({
          mutation: ATTACH_CATEGORY_TO_SELLER_MUTATION,
          variables: {
            sellerId: props.sellerId,
            categoryId: props.category,
          },
        })
      } else {
        await apolloClient.mutate<
          UnattachCategoryFromSellerData,
          UnattachCategoryFromSellerVariables
        >({
          mutation: UNATTACH_CATEGORY_FROM_SELLER_MUTATION,
          variables: {
            sellerId: props.sellerId,
            categoryId: props.category,
          },
        })
      }
    }

    dispatch({
      type: TYPES.UPDATE_SELLER_CATEGORY,
      payload: props,
    })
  }

export interface UpdateSellerInfoProps {
  sellerId: string
  sellerData: Partial<SellerProfileDB["data"]>
}
export interface UpdateSellerInfo {
  type: typeof TYPES.UPDATE_SELLER_DATA
  payload: UpdateSellerInfoProps
}
export const updateSellerInfo =
  (props: UpdateSellerInfoProps) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    const apolloClient = selectApolloClient(getState())

    const { data } = await apolloClient.query<
      GetSellerProfileData,
      GetSellerProfileVariables
    >({
      query: GET_SELLER_PROFILE_QUERY,
      variables: { id: props.sellerId },
    })

    const seller =
      data.seller && GraphQLSellerProfile.convertToFirebaseType(data.seller)

    const sellerData = seller?.data

    if (sellerData) {
      await apolloClient.mutate<
        EditSellerProfileDataData,
        EditSellerProfileDataVariables
      >({
        mutation: EDIT_SELLER_PROFILE_MUTATION,
        variables: {
          id: props.sellerId,
          data: {
            ...props.sellerData,
            ...(props.sellerData.categories
              ? {
                  categories: Object.keys(props.sellerData.categories),
                }
              : {}),
          } as GraphQLSellerProfileData,
        },
      })
    }

    dispatch({
      type: TYPES.UPDATE_SELLER_DATA,
      payload: props,
    })
  }
