import React from "react"
import { FileImageOutlined } from "@ant-design/icons"

import classes from "./ImageSkeleton.module.css"

interface Props {
  skeletonClassName?: string
  skeletonIconClassName?: string
}

const ImageSkeleton = ({ skeletonClassName, skeletonIconClassName }: Props) => (
  <div className={`${classes.imageSkeleton} ${skeletonClassName || ""}`}>
    <FileImageOutlined
      className={`${classes.imageSkeletonIcon} ${skeletonIconClassName || ""}`}
    />
  </div>
)

export default ImageSkeleton
