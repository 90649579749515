import { Categories, Category } from "../../buyer/categories/types"
import { Promotions, PromotionsWithCategories } from "../types"

const getPromotionsWithCategories = (
  promotions: Promotions,
  categories: Categories,
): PromotionsWithCategories => {
  return promotions.map((promotion) => {
    const { categoryId } = promotion.data

    const category = Object.values(categories).find(
      ({ id }) => id === categoryId,
    ) as Category

    return { ...promotion, category }
  })
}

export default getPromotionsWithCategories
