import { FC } from "react"
import { Input, Form } from "antd"

interface Props {
  label: string
  name: string
}

export const VatInput: FC<Props> = (props) => {
  const { label, name } = props

  return (
    <Form.Item label={label} name={name}>
      <Input />
    </Form.Item>
  )
}
