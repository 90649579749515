import firebase from "firebase/app"
import { Dispatch } from "redux"
import { NEW_MESSAGE_LOG } from "./actionTypes"
import { MessageLog } from "./types"
import { FIREBASE_PATH_MESSAGE_LOGS } from "./constants"
import { MessageLogDB } from "hero24-types"

export interface NewMessageLog {
  type: typeof NEW_MESSAGE_LOG
  payload: MessageLog
}

const subscribeToMessageLogs = async (dispatch: Dispatch) => {
  const messageLogsRef = await firebase
    .database()
    .ref(FIREBASE_PATH_MESSAGE_LOGS)

  await messageLogsRef.on("child_added", (snap) => {
    const messageLog: MessageLogDB = snap.val()
    if (snap.key) {
      const action: NewMessageLog = {
        type: NEW_MESSAGE_LOG,
        payload: {
          ...messageLog,
          id: snap.key,
        },
      }
      dispatch(action)
    }
  })
}

export const getMessageLogs = () => async (dispatch: Dispatch) => {
  await subscribeToMessageLogs(dispatch)
}
