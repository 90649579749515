import { FeePopconfirmData, FeeStatusLabels } from "./types"

export const feePopconfirmData: FeePopconfirmData = {
  PAID: {
    title: "Mark this fee as paid?",
    itemLabel: "Mark as paid",
    feeStatus: "paid",
  },
  PENDING: {
    title: "Mark this fee as pending?",
    itemLabel: "Mark as pending",
    feeStatus: "pending",
  },
}

export const feeStatusLabels: FeeStatusLabels = {
  paid: "Paid",
  pending: "Waiting for payment",
}

export const ERROR_MESSAGE = "Fee status change error was occured"
