import firebase from "firebase/app"
import { Dispatch } from "redux"
import * as TYPES from "./actionTypes"
import { FeeDB } from "hero24-types"
import { FeeInputs } from "./types"
import { ThunkDispatch } from "redux-thunk"
import { Store } from "../core/types"

export interface AddFeeToBeSaved {
  type: typeof TYPES.ADD_FEE_TO_BE_SAVED
  payload: FeeInputs
}

export const addFeeToBeSaved: (payload: FeeInputs) => AddFeeToBeSaved = (
  payload,
) => {
  return {
    type: TYPES.ADD_FEE_TO_BE_SAVED,
    payload,
  }
}

export interface RemoveFeeToBeSaved {
  type: typeof TYPES.REMOVE_FEE_TO_BE_SAVED
  index: number
}

export const removeFeeToBeSaved: (index: number) => RemoveFeeToBeSaved = (
  index,
) => {
  return {
    type: TYPES.REMOVE_FEE_TO_BE_SAVED,
    index,
  }
}

export interface SavedFees {
  type: typeof TYPES.SAVED_FEES
  feeIds: string[]
  offerRequestId: string
}

export const saveFees =
  (offerRequestId: string, buyerId: string) =>
  async (
    dispatch: ThunkDispatch<Store, any, SavedFees>,
    getState: () => Store,
  ) => {
    const {
      fees: { feesToSave },
    }: Store = getState()

    if (!feesToSave) {
      return
    }

    const feesRef: firebase.database.Reference = await firebase
      .database()
      .ref("fees")

    const addedFees: string[] = []
    await Promise.all(
      feesToSave.map(async (feeInputs) => {
        const fee: FeeDB = {
          data: {
            description: feeInputs.description,
            quantity: feeInputs.quantity,
            unitPrice: feeInputs.unitPrice,
          },
          platformFeePercentage: feeInputs.platformFeePercentage,
          feeCategory: "material",
          status: "pending",
          offerRequest: offerRequestId,
          customerVAT: 24,
          serviceProviderVAT: 24,
          createdAt: firebase.database.ServerValue.TIMESTAMP as number,
          userId: buyerId,
        }
        // tslint:disable-next-line:no-unsafe-any
        const addedRef = await feesRef.push(fee)
        if (addedRef.key) {
          addedFees.push(addedRef.key)
        }
      }),
    )

    dispatch({
      type: TYPES.SAVED_FEES,
      offerRequestId,
      feeIds: addedFees,
    })
  }

export interface GetAllFees {
  type: typeof TYPES.GET_ALL_FEES
  payload: {
    [key: string]: FeeDB
  }
}

export const getAllFees = () => async (dispatch: Dispatch) => {
  const feesRef = firebase.database().ref("fees")

  const feesSnapshot = await feesRef.once("value")

  const fees: {
    [id: string]: FeeDB
  } = feesSnapshot.val() || {}

  const feesState = Object.fromEntries(
    Object.entries(fees).map((feeEntry) => {
      return [
        feeEntry[0],
        {
          id: feeEntry[0],
          ...feeEntry[1],
        },
      ]
    }),
  )

  dispatch({
    type: TYPES.GET_ALL_FEES,
    payload: feesState,
  })
}
