import React from "react"
import { Category } from "../../../buyer/categories/types"
import { CategoryName } from "./Category"

interface Props {
  categories?: Category[]
}

export const CategoryList = ({ categories }: Props) => {
  if (categories) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: 50,
          overflowY: "scroll",
        }}
      >
        {categories
          .sort((a, b) => a.name.en.localeCompare(b.name.en))
          .map((category) => (
            <CategoryName key={category.id} category={category} />
          ))}
      </div>
    )
  }
  return null
}
