import * as Yup from "yup"
import { IValidationSchema } from "../../../../common/yupValidation/types"
import { CreateUserForm } from "../types"
import validators from "../../../../common/yupValidation/fieldsValidators"

export const ValidationSchema: IValidationSchema<CreateUserForm> =
  Yup.object().shape<CreateUserForm>({
    firstName: validators.stringField(2, 50, true),
    lastName: validators.stringField(2, 50, true),
    email: validators.emailField(true),
    phone: validators.phoneField(true),
    language: validators.languageField,
  })
