import { Typography } from "antd"
import { FC } from "react"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"

const { Text } = Typography

interface Props {
  isApprovedByBuyer: OfferRequest["isApprovedByBuyer"]
}

export const ApprovedByBuyerStatus: FC<Props> = ({ isApprovedByBuyer }) => {
  if (isApprovedByBuyer) {
    return <Text type="success">Approved</Text>
  }
  return <Text type="warning">Waiting approval</Text>
}
