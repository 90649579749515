import filter from "lodash/filter"
import { createSelector } from "reselect"

import { getCategoryById } from "../categories/selectors"

import { Questions, Question, QuestionOption, QuestionOptions } from "./types"
import { Category } from "../categories/types"
import { Store } from "../../core/types"
import { OfferRequestQuestion, OfferRequestQuestionOption } from "hero24-types"
import { sortByOrder } from "../../common/utils"
import { getOfferRequestById } from "../../offerRequests/list/selectors"

const getState = (state: Store) => state

export const getQuestions = (state: Store) => state.questions.questions
export const getCategoriesQuestions = (state: Store) =>
  state.categories.categoryQuestions

export const getQuestionById = (state: Store, id: string) =>
  state.questions.questions[id] ? state.questions.questions[id] : undefined

export const getQuestionsForCategory = (categoryId = "") =>
  createSelector(
    [getCategoryById(categoryId), getQuestions],
    (category: Category | undefined, questions: Questions) => {
      const result: Question[] = []

      if (category) {
        category.questions.forEach((id: string) => {
          const question = questions[id]

          if (question) {
            result.push(question)
          }
        })
      }

      return result
    },
  )

const getOfferRequestQuestionOptionQuestions = (
  state: Store,
  questions: QuestionOption["questions"],
): OfferRequestQuestion[] | undefined => {
  if (!questions) {
    return undefined
  } else {
    const questionOptionQuestions: OfferRequestQuestion[] = []
    questions.forEach((id) => {
      const question = getQuestionById(state, id)
      if (question) {
        const questionNested = getOfferRequestQuestion(state, question) // eslint-disable-line @typescript-eslint/no-use-before-define
        if (questionNested) {
          questionOptionQuestions.push(questionNested)
        }
      }
    })
    return questionOptionQuestions
  }
}

const getOfferRequestQuestionOption = (
  state: Store,
  option: QuestionOption,
): OfferRequestQuestionOption => {
  return {
    id: option.id,
    checked: option.checked || null,
    name: option.name || null,
    order: option.order || null,
    questions: option.questions
      ? getOfferRequestQuestionOptionQuestions(state, option.questions) || null
      : null,
  }
}

const getOfferRequestQuestion = (
  state: Store,
  question: Question,
): OfferRequestQuestion => {
  if (question.type === "radio") {
    return {
      ...question,
      name: question.name || null,
      selectedOption: question.selectedOption || null,
      options: Object.values(question.options).map((option) =>
        getOfferRequestQuestionOption(state, option),
      ),
    }
  } else if (question.type === "checkbox") {
    return {
      ...question,
      name: question.name || null,
      options: Object.values(question.options).map((option) =>
        getOfferRequestQuestionOption(state, option),
      ),
    }
  } else if (question.type === "date") {
    return {
      ...question,
      name: question.name || null,
      preferredTime: question.preferredTime || null,
      suitableTimesCount: question.suitableTimesCount || null,
      suitableTimes: question.suitableTimes || null,
    }
  } else if (question.type === "image") {
    return {
      ...question,
      name: question.name || null,
      images: question.images || null,
      imageCount: question.imageCount || null,
    }
  } else if (question.type === "number") {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || question.value === 0 ? question.value : null,
    }
  } else if (question.type === "number_input") {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || null,
      // eslint-disable-next-line camelcase
      extra_placeholder: question.extra_placeholder || null,
    }
  } else if (question.type === "list") {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || null,
    }
  } else {
    return {
      ...question,
      name: question.name || null,
      placeholder: question.placeholder || null,
      value: question.value || null,
    }
  }
}

export const getQuestionsForOfferRequest = (categoryId = "") =>
  createSelector(
    [getState, getQuestionsForCategory(categoryId)],
    (state: Store, questions: Question[]): OfferRequestQuestion[] => {
      let offerRequestQuestions: OfferRequestQuestion[] = []
      offerRequestQuestions = Object.values(questions).map((question) =>
        getOfferRequestQuestion(state, question),
      )
      return offerRequestQuestions
    },
  )

export const isNotValid = (question: Question, stateQuestions: Questions) => {
  if (!question || !!stateQuestions[question.id].optional) {
    return false
  }

  switch (question.type) {
    case "checkbox":
      return filter(question.options, { checked: true }).length < 1
    case "radio":
      return !question.selectedOption
    case "number_input":
    case "textarea":
    case "number":
    case "list":
      return !question.value && question.value !== 0
    case "date": {
      let isError = true

      if (
        question.preferredTime &&
        !question.hasOwnProperty("suitableTimesCount")
      ) {
        isError = false
      } else if (
        question.preferredTime &&
        question.hasOwnProperty("suitableTimesCount") &&
        question.suitableTimesCount === 0
      ) {
        isError = false
      } else if (
        question.preferredTime &&
        question.hasOwnProperty("suitableTimesCount") &&
        question.suitableTimesCount !== 0
      ) {
        isError = false
      } else if (
        !question.preferredTime &&
        question.hasOwnProperty("suitableTimesCount") &&
        question.suitableTimesCount !== 0
      ) {
        isError = false
      }

      return isError
    }
    default:
      return false
  }
}

const validateOptions = (
  options: QuestionOptions,
  stateQuestions: Questions,
) => {
  let optionErrors: string[] = []
  Object.values(options).forEach((option) => {
    if (option.checked && option.questions) {
      const optionQuestions: Question[] = []
      Object.values(option.questions).forEach((id) => {
        const optionQuestion = stateQuestions[id]
        if (optionQuestion) {
          optionQuestions.push(optionQuestion)
        }
      })

      optionErrors = [
        ...optionErrors,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        ...validateQuestions(optionQuestions, stateQuestions),
      ]
    }
  })
  return optionErrors
}

const validateQuestions = (
  questions: Question[],
  stateQuestions: Questions,
) => {
  let errors = questions
    .filter((question) => isNotValid(question, stateQuestions))
    .map((question) => question.id)

  questions.forEach((question) => {
    if (question.type === "checkbox" || question.type === "radio") {
      const optionErrors = validateOptions(question.options, stateQuestions)
      errors = [...errors, ...optionErrors]
    }
  })
  return errors
}

export const getErrorsForCategory = (categoryId = "") =>
  createSelector(
    [getQuestionsForCategory(categoryId), getQuestions, getCategoriesQuestions],
    (
      categoryEditableQuestions: Question[],
      editableQuestions: Questions,
      categoriesQuestions: Questions,
    ) => {
      const questions = Object.fromEntries(
        Object.entries(editableQuestions).map(([id, question]) => {
          const { optional, position, showError } =
            categoriesQuestions[id] || {}

          return [
            id,
            {
              ...question,
              optional,
              position,
              showError,
            },
          ]
        }),
      )

      return validateQuestions(categoryEditableQuestions, questions)
    },
  )

export const categoryHasInvalidQuestions = (categoryId = "") =>
  createSelector([getErrorsForCategory(categoryId)], (errors: string[]) => {
    return errors && errors.length > 0
  })

export const getSortedQuestionsIdsForCategory = (categoryId: string) =>
  createSelector(
    [getCategoryById(categoryId), getQuestions],
    (category: Category | undefined, questions: Questions) => {
      if (!category) {
        return []
      } else {
        const categoryQuestions = category.questions.map(
          (questionId) => questions[questionId],
        )
        const orderedQuestions = sortByOrder(categoryQuestions, "desc")
        return orderedQuestions.map((question) => question.id)
      }
    },
  )

export const getOfferRequestQuestions = (offerRequestId: string) =>
  createSelector([getOfferRequestById(offerRequestId)], (offerRequest) => {
    if (!offerRequestId) return

    return offerRequest.data.initial.questions
  })
