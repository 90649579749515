import { Descriptions, Card } from "antd"
import React from "react"

import { PreferredTime } from "./PreferredTime"
import { SuitableTimes } from "./SuitableTimes"

import { OfferRequest } from "../../../offerRequests/list/types"

interface Props {
  offerRequest: OfferRequest
}

export const OfferRequestDates = ({ offerRequest }: Props) => {
  return (
    <>
      <Card style={{ background: "transparent" }} bordered={false} size="small">
        <Descriptions
          layout="horizontal"
          size="small"
          bordered
          style={{
            margin: 0,
            padding: 0,
            border: 0,
            background: "white",
          }}
        >
          <Descriptions.Item label="Preferred time">
            <PreferredTime offerRequest={offerRequest} />
          </Descriptions.Item>
          <Descriptions.Item label="Flexible times">
            <SuitableTimes offerRequest={offerRequest} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}
