import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { News } from "../../fragments"
import { MUTATION } from "./mutation"

type Data = {
  editNews: News
}

type NewsEditingInput = Pick<News, "id"> & Partial<News>

type Variables = {
  input: NewsEditingInput
}

type Args = [NewsEditingInput]

type Fields = "data" | "errors"

export const editNews: ApolloThunkMutation<Data, Args, Fields> = async (
  state,
  input,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables: { input },
  })

  return {
    editNewsData: data?.editNews,
    editNewsErrors: errors,
  }
}
