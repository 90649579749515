import { gql } from "@apollo/client"
import { NEWS_FRAGMENT } from "../../fragments"

export const MUTATION = gql`
  ${NEWS_FRAGMENT}

  mutation EditNews($input: NewsEditingInput!) {
    editNews(input: $input) {
      ...NewsInfo
    }
  }
`
