import React, { ReactNode } from "react"
import Text from "antd/lib/typography/Text"

interface Props {
  children: ReactNode
}

export const ColumnText = ({ children }: Props) => (
  <Text
    style={{
      textTransform: "capitalize",
    }}
  >
    {children}
  </Text>
)
