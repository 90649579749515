import firebase from "firebase"

import { AppThunkAction } from "../../common/types"
import { createNews } from "../graphql/mutations"
import { getStorageNewsPath } from "../utils"
import { newsCreated, setNewsCreating } from "../actions"

type Variables = Parameters<typeof createNews>[1]
type ThunkArgs = [Variables["input"], File | Blob]

export const createNewsThunk: AppThunkAction<ThunkArgs> =
  (input, image) => async (dispatch, getState) => {
    try {
      dispatch(setNewsCreating(true))

      const { createNewsData } = await createNews(getState(), {
        input,
      })

      if (createNewsData) {
        try {
          await firebase
            .storage()
            .ref(getStorageNewsPath(createNewsData.id))
            .put(image)
        } catch (err) {
          console.error(err)
        }

        dispatch(newsCreated(createNewsData))
      }
    } finally {
      dispatch(setNewsCreating(false))
    }
  }
