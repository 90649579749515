import React from "react"
import { Descriptions } from "antd"
import { useSelector } from "react-redux"

import {
  getActualOfferDuration,
  getWorkedDurationString,
  getMinimumDuration,
  getPurchasedOfferDuration,
} from "../../../utils"
import { Offer } from "../../../../offers/list/types"
import { OfferedTime } from "./OfferedTime"
import { WorkHours } from "./WorkHours"
import { getOfferRequestCategory } from "../../../../buyer/categories/selectors"
import { getOfferRequestById } from "../../../../offerRequests/list/selectors"

interface Props {
  offer: Offer
}

export const TimeAndWorkHours = (props: Props) => {
  const { offer } = props

  const category = useSelector(
    getOfferRequestCategory(offer.data?.initial.offerRequest),
  )
  const offerRequest = useSelector(
    getOfferRequestById(offer.data.initial.offerRequest),
  )

  const minimumDuration = getMinimumDuration(offerRequest, category)
  const purchasedDuration = getPurchasedOfferDuration(props.offer)
  const workedDuration = getActualOfferDuration(props.offer)
  const workedDurationString =
    workedDuration &&
    getWorkedDurationString(workedDuration, minimumDuration, purchasedDuration)

  return (
    <>
      <Descriptions
        layout="vertical"
        size="small"
        bordered
        style={{ margin: 0, padding: 0, border: 0 }}
      >
        <Descriptions.Item label="Offered start time">
          <OfferedTime offer={props.offer} />
        </Descriptions.Item>
      </Descriptions>
      <WorkHours offer={props.offer} />
      <Descriptions
        layout="vertical"
        size="small"
        bordered
        style={{ marginTop: 15, padding: 0, border: 0 }}
      >
        <Descriptions.Item label="Purchased duration">
          {purchasedDuration}h
        </Descriptions.Item>
        <Descriptions.Item label="Minimum duration">
          {minimumDuration}h
        </Descriptions.Item>
        <Descriptions.Item label="Worked duration">
          {workedDurationString || "Not completed"}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
