import { gql } from "@apollo/client"
import { ChatDto } from "../fragments/ChatInfo"

export type ChatAddedData = {
  chatAdded: Pick<ChatDto, "id" | "inviteAdmin" | "seenByAdmin">
}

export const CHAT_ADDED_SUBSCRIPTION = gql`
  subscription ChatAdded {
    chatAdded {
      id
      inviteAdmin
      seenByAdmin
    }
  }
`
