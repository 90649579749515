import { gql } from "@apollo/client"

import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"

export type Data = {
  [DEFAULT_GRAPHQL_RESPONSE]: boolean
}

export type Variables = {
  input: {
    isAdmin: boolean
    id: string
  }
}

export const MUTATION = gql`
  mutation EditUserAdminStatus($input: UserAdminStatusEditInput!) {
    ${DEFAULT_GRAPHQL_RESPONSE}: editUserAdminStatus(input: $input)
  }
`
