import { PlusOutlined } from "@ant-design/icons"
import { Button, Card, Descriptions } from "antd"
import Modal from "antd/lib/modal/Modal"
import firebase from "firebase/app"
import { FeeDB, FeeStatus } from "hero24-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import CreateFee from "../../../../buyer/createOfferRequest/feeStep/createFee"
import { OfferRequest } from "../../../../buyer/createOfferRequest/types"
import { addFeeToBeSaved, saveFees } from "../../../../fees/actions"
import { FeeInputs } from "../../../../fees/types"
import { getFeeTotalWithHero24Cut } from "../../../../fees/utils"
import { FeesLog } from "./FeeLogs"
import FeeStatusChange from "./components/FeeStatusChange"

interface Props {
  offerRequest: OfferRequest
}

const subscribeToFee = (id: string, setFee: (fee: FeeDB) => void) => {
  firebase
    .database()
    .ref("fees")
    .child(id)
    .on("value", (feeSnapshot) => {
      const fee = feeSnapshot.val() as FeeDB
      setFee(fee)
    })
}

const unSubscribeToFee = (id: string) => {
  firebase.database().ref("fees").child(id).off()
}

const editFee = async (id: string, feeData: FeeDB["data"]) => {
  await firebase.database().ref("fees").child(id).child("data").update(feeData)
}

const cancelFee = async (id: string) => {
  await firebase
    .database()
    .ref("fees")
    .child(id)
    .child("status")
    .set("cancelled" as FeeStatus)
}

export const Fees = (props: Props) => {
  const [fees, setFees] = useState<{ [id: string]: FeeDB }>(() => ({}))
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [feeToEdit, setFeeToEdit] = useState<{ fee: FeeDB; id: string }>()
  const [showFeeLogs, setShowFeeLogs] = useState<string>()
  const resetFormRef = useRef<{ resetForm: () => void }>(null)
  const isLoading = false
  const dispatch = useDispatch()

  const onEdit = (id: string, fee: FeeDB) => {
    setFeeToEdit({
      id,
      fee,
    })
  }

  const saveEdit = (values: FeeInputs) => {
    if (feeToEdit) {
      editFee(feeToEdit.id, {
        description: values.description,
        quantity: values.quantity,
        unitPrice: values.unitPrice,
      })
      setFeeToEdit(undefined)
    }
  }

  const onSubmit = (data: FeeInputs) => {
    dispatch(addFeeToBeSaved(data))
    dispatch(
      saveFees(
        props.offerRequest.id,
        props.offerRequest.data.initial.buyerProfile,
      ),
    )
    setShowCreateForm(false)
  }

  useEffect(() => {
    Object.keys(props.offerRequest.fees || {}).forEach((id) => {
      subscribeToFee(id, (fee) => {
        setFees((fees) => ({
          ...fees,
          [id]: fee,
        }))
        setFeeToEdit(undefined)
      })
    })
    return () => {
      Object.keys(props.offerRequest.fees || {}).forEach((id) => {
        unSubscribeToFee(id)
      })
    }
  }, [props.offerRequest.fees, setFees, setFeeToEdit])

  const feesEntries = Object.entries(fees)

  return (
    <>
      <Modal
        title="Edit material fee"
        open={!!feeToEdit}
        onCancel={() => setFeeToEdit(undefined)}
        footer={false}
      >
        <CreateFee
          onSubmit={saveEdit}
          resetFormRef={resetFormRef}
          loading={isLoading}
          fee={feeToEdit?.fee}
        />
      </Modal>
      <Modal
        title="Add new material fee"
        open={showCreateForm}
        onCancel={() => setShowCreateForm(false)}
        footer={false}
      >
        <CreateFee
          onSubmit={onSubmit}
          resetFormRef={resetFormRef}
          loading={isLoading}
        />
      </Modal>
      {showFeeLogs && (
        <Modal
          title="Fee logs"
          open={!!showFeeLogs?.length}
          onCancel={() => setShowFeeLogs(undefined)}
          footer={false}
        >
          <FeesLog id={showFeeLogs} />
        </Modal>
      )}
      <Card style={{ background: "transparent" }} bordered={false} size="small">
        {feesEntries.length ? (
          Object.entries(fees).map(([id, fee]) => {
            const materialCostTotal = getFeeTotalWithHero24Cut(fee)

            if (fee.status === "cancelled") {
              return null
            }

            return (
              <Descriptions
                key={id}
                layout="horizontal"
                size="small"
                bordered
                column={2}
                style={{
                  background: "white",
                }}
                title={`Material fee: "${fee.data.description}"`}
                extra={
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button type="text" block onClick={() => cancelFee(id)}>
                      Cancel
                    </Button>
                    <Button type="link" block onClick={() => onEdit(id, fee)}>
                      Edit
                    </Button>
                  </div>
                }
              >
                <Descriptions.Item label="Cost">
                  {fee.data.quantity} x {fee.data.unitPrice}€
                </Descriptions.Item>
                <Descriptions.Item label="Hero24">
                  {fee.platformFeePercentage}%
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  {materialCostTotal}€
                </Descriptions.Item>
                <Descriptions.Item label="Amount paid">
                  {(fee.amountPaid || 0).toFixed(2)}€
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <FeeStatusChange fee={fee} feeId={id} />
                </Descriptions.Item>
                <Descriptions.Item label="Logs">
                  <Button
                    onClick={() => {
                      setShowFeeLogs(showFeeLogs === id ? undefined : id)
                    }}
                  >
                    Show logs
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            )
          })
        ) : (
          <Descriptions
            key={`no_fees_${props.offerRequest.id}`}
            layout="horizontal"
            size="small"
            title="No added material fees"
          ></Descriptions>
        )}
        <Button
          icon={<PlusOutlined />}
          type="default"
          onClick={() => setShowCreateForm(true)}
          style={{ marginTop: 10 }}
        >
          Add material fee
        </Button>
      </Card>
    </>
  )
}
