import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import map from "lodash/map"

import { selectApolloClient } from "../../../apolloClient/selectors"
import {
  CHAT_UPDATED_SUBSCRIPTION,
  ChatUpdatedData,
  ChatUpdatedVariables,
} from "../../graphql/subscriptions/ChatUpdated"
import { updateChat } from "../../reducer"
import { selectChats } from "../../selectors"
import { ApolloClient } from "@apollo/client"

type Subscription = ReturnType<
  ReturnType<ApolloClient<unknown>["subscribe"]>["subscribe"]
>

export const useSubscribeOnChatUpdates = () => {
  const dispatch = useDispatch()

  const chats = useSelector(selectChats)

  // need to handle the array-ref updates
  const chatIdsJson = useMemo(
    () => JSON.stringify(map(chats?.edges || [], "cursor")),
    [chats],
  )
  const apolloClient = useSelector(selectApolloClient)

  const subscription = useRef<Subscription>()

  useEffect(() => {
    subscription.current?.unsubscribe()
    subscription.current = apolloClient
      .subscribe<ChatUpdatedData, ChatUpdatedVariables>({
        query: CHAT_UPDATED_SUBSCRIPTION,
        variables: {
          chatIds: JSON.parse(chatIdsJson),
        },
      })
      .subscribe({
        next: ({ data }) => data && dispatch(updateChat(data.chatUpdated)),
      })

    return () => {
      subscription.current?.unsubscribe()
    }
  }, [apolloClient, dispatch, chatIdsJson])
}
