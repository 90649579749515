import { CategorySubscriptionDB } from "hero24-types"

export const printSubscriptionDiscount = (
  subscription?: CategorySubscriptionDB,
) => {
  if (subscription?.discountFormat === "fixed") {
    return subscription.discount.toFixed(2) + "€"
  }
  if (subscription?.discountFormat === "percentage") {
    return subscription.discount.toFixed() + "%"
  }
  return ""
}
