export const QUESTION_CHECKBOX_OPTION_CHECKED =
  "@iweb/QUESTION_CHECKBOX_OPTION_CHECKED"
export const QUESTION_RADIO_OPTION_CHECKED =
  "@iweb/QUESTION_RADIO_OPTION_CHECKED"
export const UPDATE_ERROR_VISIBILITY = "@iweb/UPDATE_ERROR_VISIBILITY"
export const QUESTION_TEXT_CHANGED = "@iweb/QUESTION_TEXT_CHANGED"
export const QUESTION_NUMBER_CHANGED = "@iweb/QUESTION_NUMBER_CHANGED"
export const QUESTION_PREFFERED_TIME_CHANGED =
  "@iweb/QUESTION_PREFFERED_TIME_CHANGED"
export const QUESTION_DURATION_CHANGED = "@iweb/QUESTION_DURATION_CHANGED"
export const QUESTION_SUITABLE_TIME_SELECTED =
  "@iweb/QUESTION_SUITABLE_TIME_SELECTED"
export const UPDATE_QUESTIONS = "@iweb/UPDATE_QUESTIONS"
