import { gql } from "@apollo/client"

export type SetIsSellerApprovedData = {
  setIsSellerApproved: boolean
}
export type SetIsSellerApprovedVariables = {
  sellerId: string
  isApproved: boolean
}

export const SET_IS_SELLER_APPROVED_MUTATION = gql`
  mutation SetIsSellerApproved($sellerId: String!, $isApproved: Boolean!) {
    setIsSellerApproved(sellerId: $sellerId, isApproved: $isApproved)
  }
`
