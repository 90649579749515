import React from "react"
import { NotificationOutlined } from "@ant-design/icons"

import { NEWS_ROUTE } from "./routes"

export default [
  {
    icon: <NotificationOutlined />,
    name: "News",
    path: NEWS_ROUTE,
  },
]
