import * as TYPES from "./actionTypes"
import { PaymentTransactionsState, PaymentTransactionsReducer } from "./types"

export const INITIAL_STATE: PaymentTransactionsState = {
  paymentTransactions: {},
}

const paymentTransactions: PaymentTransactionsReducer = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case TYPES.GET_PAYMENT_TRANSACTIONS: {
      return {
        ...state,
        paymentTransactions: action.payload,
      }
    }
    default:
      return state
  }
}

export default { paymentTransactions }
