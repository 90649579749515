import React from "react"
import { Typography, Popover } from "antd"

const { Text } = Typography
interface Props {
  note: string
  type?: "secondary" | "success" | "warning" | "danger"
}

export const Note = ({ note, type }: Props) => (
  <Popover content={note}>
    <Text
      ellipsis
      type={type || "warning"}
      strong
      style={{ maxWidth: 150, display: "flex", cursor: "help" }}
    >
      {note}
    </Text>
  </Popover>
)
