import React, { useEffect, useState } from "react"
import { CategoryStep } from "./categoryStep"
import { PaymentStep } from "./paymentStep"
import { FeeStep } from "./feeStep"
import { SearchUserStep } from "./searchUserStep"
import { CreateUser } from "./createUserStep"
import { QuestionsStep } from "./questionsStep"
import { Row, Col } from "antd"
import OfferRequestSummary from "../summary"
import { DoerStep } from "./doerStep"
import { useDispatch } from "react-redux"
import { subscribeToPromotions } from "../../promotions/store/actions"
import { unsubscribeFromPromotions } from "../../promotions/queries"

const OfferRequest = () => {
  const [isCreateUser, setIsCreateUser] = useState(false)

  const dispatch = useDispatch()

  const toggleModal = () => {
    setIsCreateUser(!isCreateUser)
  }

  useEffect(() => {
    dispatch(subscribeToPromotions)

    return () => unsubscribeFromPromotions()
  }, [dispatch])

  return (
    <Row gutter={48}>
      <Col xs={24} lg={12}>
        <SearchUserStep addUser={toggleModal} />
        <CreateUser isOpen={isCreateUser} closeModal={toggleModal} />
        <PaymentStep />
        <FeeStep />
        <DoerStep />
        <CategoryStep />
      </Col>
      <Col xs={24} lg={12}>
        <QuestionsStep />
      </Col>
      <OfferRequestSummary />
    </Row>
  )
}

export default OfferRequest
