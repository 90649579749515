import { gql } from "@apollo/client"
import { CHAT_INFO_FRAGMENT, ChatDto } from "../fragments/ChatInfo"

export type ChatUpdatedData = {
  chatUpdated: ChatDto
}

export type ChatUpdatedVariables = {
  chatIds: string[]
}

export const CHAT_UPDATED_SUBSCRIPTION = gql`
  ${CHAT_INFO_FRAGMENT}

  subscription ChatUpdated($chatIds: [String!]!) {
    chatUpdated(chatIds: $chatIds) {
      ...ChatInfo
    }
  }
`
