import { OfferRequestRadioQuestion } from "./OfferRequestRadioQuestionInfo"
import { OfferRequestCheckBoxQuestion } from "./OfferRequestCheckBoxQuestionInfo"
import { OfferRequestTextAreaQuestion } from "./OfferRequestTextAreaQuestionInfo"
import { OfferRequestListPicker } from "./OfferRequestListPickerInfo"
import { OfferRequestNumberQuestion } from "./OfferRequestNumberQuestionInfo"
import { OfferRequestDateQuestion } from "./OfferRequestDateQuestionInfo"
import { OfferRequestImageQuestion } from "./OfferRequestImageQuestionInfo"
import { OfferRequestNumberInputQuestion } from "./OfferRequestNumberInputQuestionInfo"
import { PlainOfferRequestQuestion } from "../../../types"
import {
  OfferRequestQuestion,
  OfferRequestQuestionAdapter,
} from "./OfferRequestQuestionInfo"
import { FirebaseAdapter } from "../../../../common/utilities"
import { TypeSafeRequired } from "../../../../common/types"

export class OfferRequestQuestionInput {
  radio?: OfferRequestRadioQuestion
  checkbox?: OfferRequestCheckBoxQuestion
  textarea?: OfferRequestTextAreaQuestion
  list?: OfferRequestListPicker
  number?: OfferRequestNumberQuestion
  date?: OfferRequestDateQuestion
  image?: OfferRequestImageQuestion
  numberInput?: OfferRequestNumberInputQuestion
}

export const OfferRequestQuestionInputAdapter = new FirebaseAdapter<
  PlainOfferRequestQuestion,
  OfferRequestQuestionInput
>({
  toFirebase() {
    throw new Error("Should be never used")
  },
  toGraphQL(firebase) {
    const questionInput: TypeSafeRequired<OfferRequestQuestionInput> = {
      checkbox: undefined,
      date: undefined,
      image: undefined,
      list: undefined,
      number: undefined,
      numberInput: undefined,
      radio: undefined,
      textarea: undefined,
    }

    const question = OfferRequestQuestionAdapter.toGraphQL(
      firebase as PlainOfferRequestQuestion,
    ) as OfferRequestQuestion

    switch (question.type) {
      case "checkbox":
        questionInput.checkbox = question
        break
      case "date":
        questionInput.date = question
        break
      case "image":
        questionInput.image = question
        break
      case "list":
        questionInput.list = question
        break
      case "number":
        questionInput.number = question
        break
      case "number_input":
        questionInput.numberInput = question
        break
      case "radio":
        questionInput.radio = question
        break
      case "textarea":
        questionInput.textarea = question
        break
      default:
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;((guard: never) => {
          throw new Error(`Unknown question type ${guard}`)
        })(question)
    }

    return questionInput
  },
})
