import React from "react"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { Descriptions, Form, Input } from "antd"
import { useDispatch } from "react-redux"
import { debounce } from "lodash"
import { AddressesAnswered, Address } from "hero24-types"
import { setOfferRequestAddresses } from "../../../offerRequests/list/actions"

interface Props {
  offerRequest: OfferRequest
}

export const Addresses = (props: Props) => {
  const dispatch = useDispatch()

  const changeAddresses = (addresses: AddressesAnswered) => {
    dispatch(
      setOfferRequestAddresses({
        id: props.offerRequest.id,
        addresses,
      }),
    )
  }
  const debouncedOnChangeAddresses = debounce(changeAddresses, 1000)

  const addresses = props.offerRequest.data.initial.addresses
  if (addresses.type === "basic") {
    return (
      // <Card style={{ background: "transparent" }} bordered={false} size="small">
      <Descriptions
        layout="horizontal"
        size="small"
        bordered
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          background: "white",
        }}
      >
        <Descriptions.Item>
          <Form
            style={{ marginLeft: 24 }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            layout="horizontal"
            initialValues={
              {
                streetAddress: addresses.main.streetAddress,
                city: addresses.main.city,
                postalCode: addresses.main.postalCode,
              } as Address
            }
            onValuesChange={(changedValue, values) => {
              debouncedOnChangeAddresses({
                ...addresses,
                main: values as Address,
              })
            }}
          >
            <Form.Item label="StreetAddress" name="streetAddress">
              <Input />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="PostalCode" name="postalCode">
              <Input />
            </Form.Item>
          </Form>
        </Descriptions.Item>
      </Descriptions>
      // </Card>
    )
  } else {
    return (
      // <Card style={{ background: "transparent" }} bordered={false} size="small">
      <Descriptions
        layout="horizontal"
        size="small"
        bordered
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          background: "white",
        }}
      >
        <Descriptions.Item label="From">
          <Form
            style={{ marginLeft: 24 }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            layout="horizontal"
            initialValues={
              {
                streetAddress: addresses.from.streetAddress,
                city: addresses.from.city,
                postalCode: addresses.from.postalCode,
              } as Address
            }
            onValuesChange={(changedValue, values) => {
              debouncedOnChangeAddresses({
                ...addresses,
                from: values as Address,
              })
            }}
          >
            <Form.Item label="StreetAddress" name="streetAddress">
              <Input />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="PostalCode" name="postalCode">
              <Input />
            </Form.Item>
          </Form>
        </Descriptions.Item>
        <Descriptions.Item label="To">
          <Form
            style={{ marginLeft: 24 }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            layout="horizontal"
            initialValues={
              {
                streetAddress: addresses.to.streetAddress,
                city: addresses.to.city,
                postalCode: addresses.to.postalCode,
              } as Address
            }
            onValuesChange={(changedValue, values) => {
              debouncedOnChangeAddresses({
                ...addresses,
                to: values as Address,
              })
            }}
          >
            <Form.Item label="StreetAddress" name="streetAddress">
              <Input />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="PostalCode" name="postalCode">
              <Input />
            </Form.Item>
          </Form>
        </Descriptions.Item>
      </Descriptions>
      // </Card>
    )
  }
}
