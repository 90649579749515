import Form, { FormItemProps } from "antd/lib/form"
import React, { ReactChild, ReactChildren } from "react"

const { Item: FormItem } = Form

interface Props {
  required?: boolean
  children: ReactChildren | ReactChild
}

const FormField = ({
  required,
  rules = [],
  children,
  ...props
}: Props & FormItemProps) => {
  const finalRules = required
    ? [...rules, { required: true, message: "This field is mandatory" }]
    : rules

  return (
    <FormItem rules={finalRules} {...props}>
      {children}
    </FormItem>
  )
}

export default FormField
