import {
  createStore,
  combineReducers,
  applyMiddleware,
  Store,
  DeepPartial,
  Middleware,
  ReducersMapObject,
} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

const createReduxStore = (
  reducers: ReducersMapObject,
  initialState: DeepPartial<any>,
  middlewares: Middleware[],
): Store => {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares, thunk)),
  )
}

export default createReduxStore
