import React from "react"
import AuthRoute from "../../common/AuthRoute"
import { StarOutlined } from "@ant-design/icons"
import { ReviewsList } from "./components/containers"

export default {
  sideBarItems: [
    {
      icon: <StarOutlined />,
      name: "Reviews",
      path: "reviews",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/reviews"
      path="/reviews"
      render={() => <ReviewsList />}
    />,
  ],
}
