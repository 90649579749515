import { ThunkDispatch } from "redux-thunk"

import * as TYPES from "./actionTypes"
import { getUserThunk } from "../user/graphql/queries"
import { UserAdapter } from "../user/graphql/fragments"

interface UpdateUserProps {
  id: string
  displayName: string
  email: string
}

interface UserPropsFromDatabase {
  isAdmin: boolean
}

export interface UpdateUser {
  type: typeof TYPES.UPDATE_USER
  user: (UpdateUserProps & UserPropsFromDatabase) | undefined
}

export const updateUser =
  (user: UpdateUserProps | undefined) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    if (!user) {
      return dispatch({
        type: TYPES.UPDATE_USER,
        user: undefined,
      })
    }

    const state = getState()

    const { userData: graphqlUser } = await getUserThunk(state, user.id)

    if (!graphqlUser) {
      throw new Error("User is not found")
    }

    // tslint:disable-next-line:no-unsafe-any
    const userDB = UserAdapter.toFirebase(graphqlUser)

    return dispatch({
      type: TYPES.UPDATE_USER,
      user: {
        ...user,
        isAdmin: !!userDB.isAdmin,
      },
    })
  }
