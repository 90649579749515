import React from "react"
import { Row, Col, Checkbox } from "antd"
import { Seller } from "../../../seller/types"
import { Categories } from "../../../buyer/categories/types"
import { useDispatch } from "react-redux"
import {
  UpdateSellerCategoryProps,
  updateSellerCategory,
} from "../../../seller/actions"

interface Props {
  seller: Seller
  allCategories: Categories
}

export const SellerCategoriesView = ({ seller, allCategories }: Props) => {
  const dispatch = useDispatch()

  const handleSellerCategoriesChanged = (props: UpdateSellerCategoryProps) => {
    dispatch(updateSellerCategory(props))
  }
  const selectedCategories =
    seller.sellerData.categories && Object.keys(seller.sellerData.categories)
  return (
    <Row>
      {Object.keys(allCategories)
        .sort((a, b) => a.localeCompare(b))
        .map((category) => {
          return (
            <Col xs={7} key={category}>
              <Checkbox
                checked={selectedCategories?.includes(category)}
                onChange={(e) => {
                  handleSellerCategoriesChanged({
                    sellerId: seller.id,
                    category,
                    action: e.target.checked ? "add" : "remove",
                  })
                }}
                value={category}
                style={{ lineHeight: "32px" }}
              >
                {category}
              </Checkbox>
            </Col>
          )
        })}
    </Row>
  )
}
