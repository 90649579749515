import _ from "lodash"
import {
  converterToQuestionsDB,
  normalizeQuestionsFromCategories,
  normalizeQuestions,
} from "./utils"
import {
  QuestionsState,
  CheckBoxQuestion,
  RadioQuestion,
  TextAreaQuestion,
  NumberQuestion,
  DateQuestion,
  ListPicker,
  NumberInputQuestion,
  QuestionsReducer as Questions,
} from "./types"

import { GET_CATEGORIES } from "../categories/actionTypes"
import * as TYPES from "./actionTypes"

export const INITIAL_STATE: QuestionsState = {
  questions: {},
  selectedDateQuestionId: undefined,
}

const questions: Questions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      return {
        ...state,
        questions: normalizeQuestionsFromCategories(action.payload),
      }
    }

    case TYPES.QUESTION_TEXT_CHANGED: {
      const { questionId, value } = action.payload
      return {
        ...state,
        questions: {
          ...state.questions,
          [questionId]: {
            ...(state.questions[questionId] as
              | TextAreaQuestion
              | NumberInputQuestion),
            value,
          },
        },
      }
    }

    case TYPES.QUESTION_NUMBER_CHANGED: {
      const { questionId, value } = action.payload
      return {
        ...state,
        questions: {
          ...state.questions,
          [questionId]: {
            ...(state.questions[questionId] as NumberQuestion),
            value,
          },
        },
      }
    }

    case TYPES.QUESTION_CHECKBOX_OPTION_CHECKED: {
      const { questionId, optionId, checked } = action.payload
      if (state.questions[questionId].type === "checkbox") {
        const existingQuestion = state.questions[questionId] as CheckBoxQuestion
        const updatedQuestion: CheckBoxQuestion = {
          ...existingQuestion,
          options: {
            ...existingQuestion.options,
            [optionId]: {
              ...existingQuestion.options[optionId],
              checked: checked,
            },
          },
        }
        return {
          ...state,
          questions: {
            ...state.questions,
            [questionId]: updatedQuestion,
          },
        }
      }
      return state
    }

    case TYPES.QUESTION_RADIO_OPTION_CHECKED: {
      const { questionId, optionId } = action.payload
      if (state.questions[questionId].type === "radio") {
        return {
          ...state,
          questions: {
            ...state.questions,
            [questionId]: {
              ...(state.questions[questionId] as RadioQuestion),
              selectedOption: optionId,
            },
          },
        }
      }
      return state
    }

    case TYPES.UPDATE_ERROR_VISIBILITY: {
      const { questionId, showError } = action.payload
      return {
        ...state,
        questions: {
          ...state.questions,
          [questionId]: {
            ...state.questions[questionId],
            showError: showError,
          },
        },
      }
    }

    case TYPES.QUESTION_PREFFERED_TIME_CHANGED: {
      const { questionId, date } = action.payload

      if (state.questions[questionId].type === "date") {
        const existingQuestion = state.questions[questionId] as DateQuestion
        return {
          ...state,
          questions: {
            ...state.questions,
            [questionId]: {
              ...existingQuestion,
              preferredTime: date,
              suitableTimes: undefined,
              suitableTimesCount: undefined,
            },
          },
        }
      }
      return state
    }

    case TYPES.QUESTION_SUITABLE_TIME_SELECTED: {
      const { questionId, dateKey, day, startTime, endTime } = action.payload
      if (state.questions[questionId].type === "date") {
        const existingQuestion = state.questions[questionId] as DateQuestion
        const currentSuitableTimes = existingQuestion.suitableTimes || {}
        const updatedSuitableTimes = currentSuitableTimes[dateKey]
          ? _.omit(currentSuitableTimes, dateKey)
          : _.set(currentSuitableTimes, dateKey, {
              day: day,
              startTime: +startTime,
              endTime: +endTime,
            })
        return {
          ...state,
          questions: {
            ...state.questions,
            [questionId]: {
              ...existingQuestion,
              suitableTimes: updatedSuitableTimes,
              suitableTimesCount: Object.keys(updatedSuitableTimes).length,
              preferredTime: undefined,
            },
          },
        }
      }
      return state
    }

    case TYPES.QUESTION_DURATION_CHANGED: {
      const { questionId, value } = action.payload

      return {
        ...state,
        questions: {
          ...state.questions,
          [questionId]: {
            ...(state.questions[questionId] as ListPicker),
            value,
          },
        },
      }
    }

    case TYPES.UPDATE_QUESTIONS: {
      const questionsDB = converterToQuestionsDB(action.payload.questions)
      const questions = normalizeQuestions(
        questionsDB,
        action.payload.category.id,
      )
      return {
        ...state,
        questions: { ...state.questions, ...questions },
      }
    }

    default:
      return state
  }
}

export default { questions }
