import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { createUser } from "../../../user/actions"

import { getListUsers, isLoading } from "../../../user/selectors"

import CreateUserForm from "./CreateUserForm"
import { Modal } from "antd"

import { CreateUserForm as ICreateUserForm } from "./types"
import { GET_USERS } from "../../../user/actionTypes"
import { UserDB } from "hero24-types"
import { parsePhoneNumberFromString } from "libphonenumber-js"

interface CreateUserProps {
  isOpen: boolean
  closeModal: () => void
}

const CreateUser = ({ isOpen, closeModal }: CreateUserProps) => {
  const resetFormRef = useRef<{ resetForm: () => void }>(null)
  const dispatch = useDispatch()
  const users = useSelector(getListUsers)
  const loading = useSelector(isLoading)

  const onSubmit: (values: ICreateUserForm, resetForm: () => void) => void = (
    values,
    resetForm,
  ) => {
    const phone = parsePhoneNumberFromString(values.phone)
    if (!phone) {
      throw new Error("invalid number")
    }
    const userData: Omit<UserDB["data"], "createdAt"> = {
      email: values.email,
      emailVerified: true,
      name: `${values.firstName} ${values.lastName}`,
      firstName: values.firstName,
      lastName: values.lastName,
      language: values.language || "en",
      photoURL: "",
      phone: phone.format("E.164"),
    }

    dispatch(
      createUser(userData, () => {
        resetForm()
        closeModal()
      }),
    )
  }

  const handleCloseModal = () => {
    dispatch({
      type: GET_USERS,
      payload: [],
    })
    if (resetFormRef.current && resetFormRef.current.resetForm) {
      resetFormRef.current.resetForm()
    }
    closeModal()
  }

  return (
    <Modal
      title="Create new user"
      open={isOpen}
      onCancel={handleCloseModal}
      footer={false}
    >
      <CreateUserForm
        onSubmit={onSubmit}
        isAlreadyExists={!!users.length}
        resetFormRef={resetFormRef}
        loading={loading}
      />
    </Modal>
  )
}

export default CreateUser
