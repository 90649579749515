import { ChatsOrderColumn } from "../../../constants"

export enum ChatColumnName {
  OFFER_ID = "offerId",
  OFFER_REQUEST_ID = "offerRequestId",
  SELLER = "seller",
  CUSTOMER = "customer",
  IS_ADMIN_INVITED = "isAdminInvited",
  IS_ABOUT_RECLAMATION = "isAboutReclamation",
}

export const SortColumnBinding: Partial<
  Record<ChatColumnName, ChatsOrderColumn>
> = {
  [ChatColumnName.OFFER_ID]: ChatsOrderColumn.OFFER,
  [ChatColumnName.IS_ADMIN_INVITED]: ChatsOrderColumn.IS_ADMIN_INVITED,
  [ChatColumnName.IS_ABOUT_RECLAMATION]: ChatsOrderColumn.IS_ABOUT_RECLAMATION,
}
