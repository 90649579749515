import firebase from "firebase/app"
import { PromotionDB } from "hero24-types"
import { FIREBASE_PATH_PROMOTIONS } from "../constants"

const updatePromotion = async (id: string, promotion: PromotionDB) => {
  const ref = firebase.database().ref(FIREBASE_PATH_PROMOTIONS).child(id)

  await ref.update(promotion)
}

export default updatePromotion
