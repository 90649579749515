import * as TYPES from "./actionTypes"
import { SellerState, SellerReducer as Seller, Review } from "./types"
import { SellerProfileDB } from "hero24-types"

export const INITIAL_STATE: SellerState = {
  sellers: {},
  briefSellers: {},
}

const seller: Seller = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_BRIEF_SELLERS: {
      return {
        ...state,
        briefSellers: action.payload,
      }
    }
    case TYPES.GET_SELLERS: {
      return {
        ...state,
        sellers: action.payload,
      }
    }
    case TYPES.UPDATE_SELLER_CATEGORY: {
      const currentCategories =
        state.sellers[action.payload.sellerId].sellerData.categories || {}
      const updatedCategories: SellerProfileDB["data"]["categories"] =
        action.payload.action === "add"
          ? {
              ...currentCategories,
              [action.payload.category]: true,
            }
          : Object.fromEntries(
              Object.entries(currentCategories).filter(
                (entry) => entry[0] !== action.payload.category,
              ),
            )
      return {
        ...state,
        sellers: {
          ...state.sellers,
          [action.payload.sellerId]: {
            ...state.sellers[action.payload.sellerId],
            sellerData: {
              ...state.sellers[action.payload.sellerId].sellerData,
              categories: updatedCategories,
            },
          },
        },
      }
    }
    case TYPES.UPDATE_SELLER_DATA: {
      return {
        ...state,
        sellers: {
          ...state.sellers,
          [action.payload.sellerId]: {
            ...state.sellers[action.payload.sellerId],
            sellerData: {
              ...state.sellers[action.payload.sellerId].sellerData,
              ...action.payload.sellerData,
            },
          },
        },
      }
    }
    case TYPES.UPDATE_SELLER_REVIEW: {
      const reviews: Review[] | undefined = state.sellers[
        action.payload.sellerId
      ].reviews
        ?.map((review) => {
          if (review.id === action.payload.reviewId) {
            return action.payload.removeFromSeller
              ? null
              : {
                  ...review,
                  data: {
                    ...review.data,
                    ...action.payload.reviewData,
                  },
                }
          }
          return review
        })
        .filter((review) => !!review) as Review[]

      return {
        ...state,
        sellers: {
          ...state.sellers,
          [action.payload.sellerId]: {
            ...state.sellers[action.payload.sellerId],
            reviews,
          },
        },
      }
    }
    case TYPES.UPDATE_SELLER_AVERAGE_REVIEW: {
      return {
        ...state,
        sellers: {
          ...state.sellers,
          [action.payload.sellerId]: {
            ...state.sellers[action.payload.sellerId],
            averageReview: action.payload.averageReview,
          },
        },
      }
    }
    default:
      return state
  }
}

export default { seller }
