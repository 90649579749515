import firebase from "firebase/app"
import _ from "lodash"
import { MainAddressDB, CategoryDB, CategoriesDB } from "hero24-types"
import { Categories, MainAddress, DeliveryAddress } from "./types"
import { FIREBASE_PATH } from "./constants"

/*
  Takes 'categories' object and replaces 'questions' with array of ids.
  Ids are formed like this: ['categoryKey/questionKey1', 'categoryKey/questionKey2/questionKey21']
*/
const getAddresses = (category: CategoryDB) => {
  return (category.addresses as MainAddressDB).main
    ? ({
        type: "basic",
        main: true,
      } as MainAddress)
    : ({
        type: "delivery",
        from: true,
        to: true,
      } as DeliveryAddress)
}

export const normalizeCategories: (categories: CategoriesDB) => Categories = (
  categories,
) => {
  return _.mapValues(
    categories,
    (category: CategoryDB, categoryKey: string) => {
      return {
        ...category,
        id: categoryKey,
        addresses: getAddresses(category),
        questions: category.questions
          ? Object.keys(category.questions).map(
              (questionKey) => `${categoryKey}/${questionKey}`,
            )
          : [],
      }
    },
  )
}

export const getCategoryFromDB = async (id: string) => {
  const ref = await firebase.database().ref(FIREBASE_PATH).child(id)

  const snapshot = await ref.once("value")
  return snapshot.val() as CategoryDB
}
