import { Categories, Category } from "../buyer/categories/types"
import Text from "antd/lib/typography/Text"
import { Button, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import React from "react"
import { ColumnsType } from "antd/lib/table"

export type Column = ColumnsType<Category>

const renderList = (list: string[]) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 100,
        overflowY: "auto",
      }}
    >
      {list
        .sort((a, b) => a.localeCompare(b))
        .map((item) => (
          <Text
            key={item}
            style={{
              fontSize: 14,
              textTransform: "capitalize",
              whiteSpace: "pre-wrap",
            }}
          >
            {item}
          </Text>
        ))}
    </div>
  )
}

const normalizeFilterArray = (list: string[]) =>
  Array.from(new Set(list))
    .map((question) => ({
      text: question,
      value: question,
    }))
    .sort((a, b) => a.value.localeCompare(b.value))

const getTableColumns = (categories: Categories): Column => [
  {
    title: "Category",
    key: "categoryData.name.en",
    render: (category: Category) => <Text>{category.name.en}</Text>,
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.name.en.localeCompare(sellerB.name.en),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, category: Category) => {
      return category.name.en.toLowerCase().includes(value.toLowerCase())
    },
  },
  {
    title: "Min Duration",
    key: "categoryData.minimumDuration",
    render: (category: Category) => <Text>{category.minimumDuration}</Text>,
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.minimumDuration - sellerB.minimumDuration,
  },
  {
    title: "Min Price/h",
    key: "categoryData.minimumPricePerHour",
    render: (category: Category) => <Text>{category.minimumPricePerHour}</Text>,
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.minimumPricePerHour - sellerB.minimumPricePerHour,
  },
  {
    title: "Default Price/h",
    key: "categoryData.defaultPrice",
    render: (category: Category) => <Text>{category.defaultPrice}</Text>,
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.defaultPrice - sellerB.defaultPrice,
  },
  {
    title: "Customer VAT",
    key: "categoryData.defaultCustomerVAT",
    render: (category: Category) => <Text>{category.defaultCustomerVAT}</Text>,
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.defaultCustomerVAT - sellerB.defaultCustomerVAT,
  },
  {
    title: "Service Provider VAT",
    key: "categoryData.defaultServiceProviderVAT",
    render: (category: Category) => (
      <Text>{category.defaultServiceProviderVAT}</Text>
    ),
    sorter: (sellerA: Category, sellerB: Category) =>
      sellerA.defaultServiceProviderVAT - sellerB.defaultServiceProviderVAT,
  },
  {
    title: "Netvisor Key",
    key: "categoryData.netvisorKey",
    render: (category: Category) => <Text>{category.netvisorKey}</Text>,
    sorter: (sellerA: Category, sellerB: Category) => {
      return sellerA.netvisorKey && sellerB.netvisorKey
        ? sellerA.netvisorKey - sellerB.netvisorKey
        : 0
    },
  },
  {
    title: "Questions",
    key: "questions",
    render: ({ questions }: Category) => renderList(questions),
    filters: normalizeFilterArray(
      Object.values(categories).reduce(
        (acc, curr) => [...acc, ...curr.questions],
        [] as string[],
      ),
    ),
    onFilter: (value: any, category: Category) =>
      category.questions.includes(value),
  },
  {
    title: "Recommended",
    key: "recommended",
    render: ({ recommended }: Category) =>
      renderList(recommended ? Object.keys(recommended) : []),
    filters: normalizeFilterArray(
      Object.values(categories).reduce(
        (acc, curr) =>
          curr.recommended ? [...acc, ...Object.keys(curr.recommended)] : acc,
        [] as string[],
      ),
    ),
    onFilter: (value: any, category: Category) =>
      !!(category.recommended && category.recommended[value]),
  },
  {
    title: "Packages",
    key: "packages",
    render: ({ packages }: Category) =>
      renderList(packages ? Object.keys(packages) : []),
    filters: normalizeFilterArray(
      Object.values(categories).reduce(
        (acc, curr) =>
          curr.packages ? [...acc, ...Object.keys(curr.packages)] : acc,
        [] as string[],
      ),
    ),
    onFilter: (value: any, category: Category) =>
      !!(category.packages && category.packages[value]),
  },
]

export default getTableColumns
