import { useEffect, useState } from "react"
import { Field, Formik, Form } from "formik"
import { Button, Row, Col, Descriptions } from "antd"

import { UpdateOfferRequestPurchaseInput } from "../../../graphql/mutations/updateOfferRequestPurchase/index"

interface CreatePurchaseFormProps {
  onSubmit: (values: UpdateOfferRequestPurchaseInput) => void
  purchase: UpdateOfferRequestPurchaseInput
}

export const PurchaseForm = (props: CreatePurchaseFormProps) => {
  const { purchase, onSubmit } = props

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDisabled(purchase.fixedPrice <= 0 || purchase.fixedDuration <= 0)
  }, [purchase])

  return (
    <Formik initialValues={purchase} onSubmit={onSubmit}>
      {({ values: { fixedDuration, fixedPrice } }) => (
        <Form>
          <Descriptions
            layout="vertical"
            size="small"
            bordered
            style={{
              margin: 0,
              padding: 0,
              border: 0,
              background: "white",
            }}
          >
            <Descriptions.Item label="Price per hour">
              <Field
                name="fixedPrice"
                type="number"
                style={{ width: "100%", height: "100%" }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              <Field
                name="fixedDuration"
                type="number"
                style={{ width: "100%", height: "100%" }}
              />
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            layout="horizontal"
            size="small"
            bordered
            style={{
              margin: "10px 0px",
              padding: 0,
              border: 0,
              background: "white",
            }}
          >
            <Descriptions.Item label="Total">
              {(fixedDuration * fixedPrice).toFixed(2)} €
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={24}>
            <Col>
              <Button
                style={{ width: "100%" }}
                size="large"
                type="primary"
                htmlType="submit"
                disabled={disabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
