import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"

import { getAuthUser, isFetchingUser } from "../auth/selectors"
import { selectEntryPath } from "../common/selectors"

const DefaultRoute = () => {
  const user = useSelector(getAuthUser)
  const isFechingUser = useSelector(isFetchingUser)
  const entryPath = useSelector(selectEntryPath)

  if (isFechingUser) {
    return <Redirect to="/loading-user" />
  } else if (!user) {
    return <Redirect to="/login" />
  } else if (user && !user.isAdmin) {
    return <Redirect to="/blocked-user" />
  } else {
    return (
      <Redirect
        to={
          entryPath === "/" || !entryPath ? "/create_offer_request" : entryPath
        }
      />
    )
  }
}

export default {
  routes: [<Route key="/" exact path="/" component={DefaultRoute} />],
}
