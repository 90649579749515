export const GET_OFFER_REQUESTS = "@iweb/GET_OFFER_REQUESTS"
export const SET_OFFER_REQUEST_STATUS = "@iweb/SET_OFFER_REQUEST_STATUS"
export const SET_OFFER_REQUEST_PREFERRED_TIME =
  "@iweb/SET_OFFER_REQUEST_PREFERRED_TIME"
export const SET_OFFER_REQUEST_ADDRESSES = "@iweb/SET_OFFER_REQUEST_ADDRESSES"
export const SET_OFFER_REQUEST_QUESTIONS = `@iweb/SET_OFFER_REQUEST_QUESTIONS`
export const SET_OFFER_REQUEST_VATS = `@iweb/SET_OFFER_REQUEST_VATS`
export const SET_OFFER_REQUEST_REFUND = "@iweb/SET_OFFER_REQUEST_REFUND"
export const SET_OFFER_REQUEST_APPROVED_BY_BUYER =
  "@iweb/SET_OFFER_REQUEST_APPROVED_BY_BUYER"
export const SET_OFFER_REQUEST_STRIPE_PAYMENT =
  "@iweb/SET_OFFER_REQUEST_STRIPE_PAYMENT"
