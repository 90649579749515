import React from "react"
import AuthRoute from "../../common/AuthRoute"
import reducer from "./reducer"
import { UserList } from "./components/containers"
import { UserOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <UserOutlined />,
      name: "Customers",
      path: "users_list",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/users_list"
      path="/users_list"
      render={() => <UserList />}
    />,
  ],
  reducer,
}
