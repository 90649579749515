import {
  OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW,
  OfferRequestBaseQuestion,
  OfferRequestBaseQuestionAdapter,
} from "./OfferRequestBaseQuestionInfo"
import { PlainOfferRequestQuestion } from "../../../types"
import { gql } from "@apollo/client"
import { FirebaseAdapter } from "../../../../common/utilities"
import { Maybe } from "../../../../common/types"
import { TRANSLATION_FIELD_FRAGMENT } from "../../../../common/graphql/fragments"

type QuestionType = "image"

type PlainOfferRequestImageQuestion = PlainOfferRequestQuestion & {
  type: QuestionType
}

export interface OfferRequestImageQuestion
  extends OfferRequestBaseQuestion<QuestionType> {
  images?: Maybe<string[]>

  imageCount?: Maybe<number>
}

export const OfferRequestImageQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestImageQuestion,
  OfferRequestImageQuestion
>({
  toFirebase: (graphql) => ({
    ...OfferRequestBaseQuestionAdapter.toFirebase(graphql),
    type: "image" as QuestionType,
    images: graphql.images
      ? Object.fromEntries(
          graphql.images.map((image) => [image, true as const]),
        )
      : null,
    imageCount: graphql.imageCount ?? null,
  }),
  toGraphQL: (firebase) => ({
    ...OfferRequestBaseQuestionAdapter.toGraphQL(firebase),
    type: "image" as QuestionType,
    images: firebase.images && Object.keys(firebase.images),
    imageCount: firebase.imageCount,
  }),
})

export const OFFER_REQUEST_IMAGE_QUESTION_FRAGMENT = gql`
  ${TRANSLATION_FIELD_FRAGMENT}

  fragment OfferRequestImageQuestionInfo on OfferRequestImageQuestionDto {
    ${OFFER_REQUEST_BASE_QUESTION_FRAGMENT_RAW}
    images
    imageCount
  }
`
