import { OperationVariables, QueryOptions } from "@apollo/client"

import { Store } from "../../core"
import { selectApolloClient } from "../selectors"

export type ApolloQueryOptions<
  Data,
  Variables extends OperationVariables = OperationVariables,
> = QueryOptions<Variables, Data> & {
  state: Store
}

export const apolloQuery = <Data, Variables extends OperationVariables>({
  state,
  ...options
}: ApolloQueryOptions<Data | null, Variables>) => {
  const apolloClient = selectApolloClient(state)

  return apolloClient.query<Data | null, Variables>(options)
}
