import React from "react"
import { Typography } from "antd"

import {
  isActivePromotion,
  isExpiredPromotion,
  isPlannedPromotion,
} from "../../utils"
import {
  ACTIVE_PROMOTION_STATUS_COLOR,
  EXPIRED_PROMOTION_STATUS_COLOR,
  PLANNED_PROMOTION_STATUS_COLOR,
} from "../../constants"

const { Text } = Typography

interface Props {
  startDate?: number
  endDate?: number
  status?: "active" | "planned" | "expired"
}

const PromotionStatus = ({ startDate, endDate, status }: Props) => {
  if (
    startDate && endDate
      ? isActivePromotion(startDate, endDate)
      : status === "active"
  ) {
    return <Text style={{ color: ACTIVE_PROMOTION_STATUS_COLOR }}>Active</Text>
  }

  if (startDate ? isPlannedPromotion(startDate) : status === "planned") {
    return (
      <Text style={{ color: PLANNED_PROMOTION_STATUS_COLOR }}>Planned</Text>
    )
  }

  if (endDate ? isExpiredPromotion(endDate) : status === "expired") {
    return (
      <Text style={{ color: EXPIRED_PROMOTION_STATUS_COLOR }}>Expired</Text>
    )
  }

  return null
}

export default PromotionStatus
