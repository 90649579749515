import { Maybe } from "graphql/jsutils/Maybe"
import { buttonTitles } from "../components/containers/components/modals/constants"
import { User } from "../types"
import { User as UserInfo } from "../../../user/graphql/fragments"

interface GetSubmitButtonTitleArgs {
  loading: boolean
  selectedUser: Maybe<User | UserInfo>
}

export const getSubmitButtonTitle = (args: GetSubmitButtonTitleArgs) => {
  const { loading, selectedUser } = args

  if (loading) {
    return buttonTitles.wait
  } else {
    return selectedUser?.isAdmin ? buttonTitles.takeOut : buttonTitles.give
  }
}
