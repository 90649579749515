import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "antd"
import { getAllPaymentTransactions } from "../../actions"
import { getColumns } from "../table"
import { PaymentTransactionsState, PaymentTransaction } from "../../types"
import { getAllSellers } from "../../../../seller/actions"
import { UsersState } from "../../../../users/list/types"
import { getAllUsers } from "../../../../users/list/actions"
import { getAllOfferRequests } from "../../../../offerRequests/list/actions"
import { OfferRequestsState } from "../../../../offerRequests/list/types"
import { getCategories } from "../../../../buyer/categories/actions"
import { CategoriesState } from "../../../../buyer/categories/types"

const PaymentTransactionsList = () => {
  const dispatch = useDispatch()

  const paymentTransactions = useSelector(
    ({
      paymentTransactions,
    }: {
      paymentTransactions: PaymentTransactionsState
    }) => {
      return paymentTransactions
    },
  )

  const customers = useSelector(({ users }: { users: UsersState }) => {
    return users
  })

  const offerRequests = useSelector(
    ({ offerRequests }: { offerRequests: OfferRequestsState }) => {
      return offerRequests
    },
  )

  const categories = useSelector(
    ({ categories }: { categories: CategoriesState }) => {
      return categories
    },
  )

  useEffect(() => {
    dispatch(getAllPaymentTransactions())
    dispatch(getAllSellers())
    dispatch(getAllUsers())
    dispatch(getAllOfferRequests())
    dispatch(getCategories())
  }, [dispatch])

  const columns = getColumns(customers, categories, offerRequests)

  const expandedRowRender = () => {
    const renderExpandedRows = (paymentTransaction: PaymentTransaction) => {
      return (
        <a
          href={`https://dashboard.stripe.com/payments/${paymentTransaction.stripePaymentIntentId}`}
        >{`https://dashboard.stripe.com/payments/${paymentTransaction.stripePaymentIntentId}`}</a>
      )
    }
    return renderExpandedRows
  }

  const paymentTransactionsArray = Object.values(
    paymentTransactions.paymentTransactions,
  ).filter((pt) => pt.status === "paid")
  return (
    <section style={{ textAlign: "center" }}>
      <h2>PaymentTransactions (since 21.2.2021)</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: { [key: string]: any }) => record.id}
        dataSource={paymentTransactionsArray}
        columns={columns || []}
        pagination={{
          pageSize: 25,
        }}
        expandedRowRender={expandedRowRender()}
        loading={!paymentTransactionsArray || !paymentTransactionsArray.length}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default PaymentTransactionsList
