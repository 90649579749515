import { News } from "./containers"
import React from "react"
import AuthRoute from "../common/AuthRoute"

export const NEWS_ROUTE = "news"

export default [
  <AuthRoute
    exact
    key={NEWS_ROUTE}
    path={`/${NEWS_ROUTE}`}
    render={() => <News />}
  />,
]
