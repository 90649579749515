import { gql } from "@apollo/client"

import {
  OFFER_REQUEST_CHECK_BOX_QUESTION_FRAGMENT,
  OfferRequestCheckBoxQuestion,
  OfferRequestCheckBoxQuestionAdapter,
} from "./OfferRequestCheckBoxQuestionInfo"
import {
  OFFER_REQUEST_DATE_QUESTION_FRAGMENT,
  OfferRequestDateQuestion,
  OfferRequestDateQuestionAdapter,
} from "./OfferRequestDateQuestionInfo"
import {
  OFFER_REQUEST_IMAGE_QUESTION_FRAGMENT,
  OfferRequestImageQuestion,
  OfferRequestImageQuestionAdapter,
} from "./OfferRequestImageQuestionInfo"
import {
  OFFER_REQUEST_LIST_PICKER_QUESTION_FRAGMENT,
  OfferRequestListPicker,
  OfferRequestListPickerAdapter,
} from "./OfferRequestListPickerInfo"
import {
  OFFER_REQUEST_NUMBER_INPUT_QUESTION_FRAGMENT,
  OfferRequestNumberInputQuestion,
  OfferRequestNumberInputQuestionAdapter,
} from "./OfferRequestNumberInputQuestionInfo"
import {
  OFFER_REQUEST_NUMBER_QUESTION_FRAGMENT,
  OfferRequestNumberQuestion,
  OfferRequestNumberQuestionAdapter,
} from "./OfferRequestNumberQuestionInfo"
import {
  OFFER_REQUEST_RADIO_QUESTION_FRAGMENT,
  OfferRequestRadioQuestion,
  OfferRequestRadioQuestionAdapter,
} from "./OfferRequestRadioQuestionInfo"
import {
  OFFER_REQUEST_TEXT_AREA_QUESTION_FRAGMENT,
  OfferRequestTextAreaQuestion,
  OfferRequestTextAreaQuestionAdapter,
} from "./OfferRequestTextAreaQuestionInfo"

import { PlainOfferRequestQuestion } from "../../../types"
import { FirebaseAdapter } from "../../../../common/utilities"

export type OfferRequestQuestion =
  | OfferRequestRadioQuestion
  | OfferRequestCheckBoxQuestion
  | OfferRequestTextAreaQuestion
  | OfferRequestListPicker
  | OfferRequestNumberQuestion
  | OfferRequestDateQuestion
  | OfferRequestImageQuestion
  | OfferRequestNumberInputQuestion

export const OfferRequestQuestionAdapter = new FirebaseAdapter<
  PlainOfferRequestQuestion,
  OfferRequestQuestion
>({
  toFirebase(graphql) {
    const question = graphql as OfferRequestQuestion

    switch (question.type) {
      case "radio":
        return OfferRequestRadioQuestionAdapter.toFirebase(question)
      case "checkbox":
        return OfferRequestCheckBoxQuestionAdapter.toFirebase(question)
      case "date":
        return OfferRequestDateQuestionAdapter.toFirebase(question)
      case "image":
        return OfferRequestImageQuestionAdapter.toFirebase(question)
      case "list":
        return OfferRequestListPickerAdapter.toFirebase(question)
      case "number":
        return OfferRequestNumberQuestionAdapter.toFirebase(question)
      case "textarea":
        return OfferRequestTextAreaQuestionAdapter.toFirebase(question)
      case "number_input":
        return OfferRequestNumberInputQuestionAdapter.toFirebase(question)
    }
  },
  toGraphQL(firebase) {
    const question = firebase as PlainOfferRequestQuestion

    switch (question.type) {
      case "radio":
        return OfferRequestRadioQuestionAdapter.toGraphQL(question)
      case "checkbox":
        return OfferRequestCheckBoxQuestionAdapter.toGraphQL(question)
      case "date":
        return OfferRequestDateQuestionAdapter.toGraphQL(question)
      case "image":
        return OfferRequestImageQuestionAdapter.toGraphQL(question)
      case "list":
        return OfferRequestListPickerAdapter.toGraphQL(question)
      case "number":
        return OfferRequestNumberQuestionAdapter.toGraphQL(question)
      case "textarea":
        return OfferRequestTextAreaQuestionAdapter.toGraphQL(question)
      case "number_input":
        return OfferRequestNumberInputQuestionAdapter.toGraphQL(question)
    }
  },
})

export const OFFER_REQUEST_QUESTION_FRAGMENT = gql`
  ${OFFER_REQUEST_RADIO_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_CHECK_BOX_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_TEXT_AREA_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_LIST_PICKER_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_NUMBER_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_DATE_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_IMAGE_QUESTION_FRAGMENT}
  ${OFFER_REQUEST_NUMBER_INPUT_QUESTION_FRAGMENT}

  fragment OfferRequestQuestionInfo on OfferRequestQuestionDto {
    ... on OfferRequestRadioQuestionDto {
      ...OfferRequestRadioQuestionInfo
    }

    ... on OfferRequestCheckBoxQuestionDto {
      ...OfferRequestCheckBoxQuestionInfo
    }

    ... on OfferRequestTextAreaQuestionDto {
      ...OfferRequestTextAreaQuestionInfo
    }

    ... on OfferRequestListPickerDto {
      ...OfferRequestListPickerInfo
    }

    ... on OfferRequestNumberQuestionDto {
      ...OfferRequestNumberQuestionInfo
    }

    ... on OfferRequestDateQuestionDto {
      ...OfferRequestDateQuestionInfo
    }

    ... on OfferRequestImageQuestionDto {
      ...OfferRequestImageQuestionInfo
    }

    ... on OfferRequestNumberInputQuestionDto {
      ...OfferRequestNumberInputQuestionInfo
    }
  }
`
