import React from "react"
import { Form, Input } from "antd"

import { FormikTouched, FormikErrors } from "formik"

const FormItem = Form.Item

interface InputProps {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  value?: string
  checked?: boolean
  defaultValue?: string
  defaultChecked?: boolean
  disabled?: boolean
}

export interface RenderFieldProps {
  input?: InputProps
  label?: string
  type?: string
  placeholder?: string
  meta: {
    touched: boolean | FormikTouched<any> | undefined
    error: string | FormikErrors<any> | undefined
  }
}

const RenderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  placeholder,
}: RenderFieldProps): React.ReactElement => {
  let validateStatus:
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating"
    | undefined = ""
  if (touched && error) {
    validateStatus = "error"
  }

  return (
    <FormItem
      label={label}
      validateStatus={validateStatus}
      help={touched && error}
    >
      <div>
        <Input
          {...input}
          placeholder={label || placeholder}
          type={type}
          size="large"
          style={{ cursor: "pointer" }}
        />
      </div>
    </FormItem>
  )
}

export default RenderField
