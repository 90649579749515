import { gql } from "@apollo/client"

export class News {
  id: string
  title: string
  description: string
  label: string
  link?: string | null
  startAt: number
  endAt: number

  constructor(news: News) {
    this.id = news.id
    this.title = news.title
    this.description = news.description
    this.label = news.label
    this.link = news.link
    this.startAt = news.startAt
    this.endAt = news.endAt
  }
}

export const NEWS_FRAGMENT = gql`
  fragment NewsInfo on NewsDto {
    id
    title
    description
    label
    link
    startAt
    endAt
  }
`
