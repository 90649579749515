import React, { ChangeEvent } from "react"
import { Modal } from "antd"
import { FormikProps, withFormik } from "formik"
import { CreateQuestionForm } from "../../../../buyer/questions/types"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { v4 as uuidv4 } from "uuid"
import { ModalProps } from "antd/lib/modal"
import CreateQuestionFormComponent from "./CreateQuestionForm"
import _ from "lodash"

interface Props extends ModalProps {
  onSubmit: (values: CreateQuestionForm) => void
  initialState?: Partial<CreateQuestionForm>
}

const initialValues: CreateQuestionForm = {
  name: {
    en: "",
    fi: "",
  },
  optional: false,
  order: "",
  type: "textarea",
  showError: false,
  position: "",
  id: "",
  placeholder: {
    en: "",
    fi: "",
  },
  // eslint-disable-next-line camelcase
  extra_placeholder: {
    en: "",
    fi: "",
  },
  defaultValue: "",
  options: {},
}

const CreateQuestion = ({
  values,
  setFieldValue,
  resetForm,
  handleSubmit,
  ...modalProps
}: Props & FormikProps<CreateQuestionForm>) => {
  const handleSelectType = (value: React.ReactText) => {
    resetForm()
    setFieldValue("type", value)
  }
  const handleChangeChecked = (field: string) => (e: CheckboxChangeEvent) =>
    setFieldValue(field, e.target.checked)

  const handleChangeOptionInputField =
    (id: string, key: string) => (e: ChangeEvent<HTMLInputElement>) =>
      setFieldValue("options", {
        ...values.options,
        [id]: { ...values.options[id], [key]: e.target.value },
      })

  const handleChangeOptionName =
    (id: string, lang: string) => (e: ChangeEvent<HTMLInputElement>) =>
      setFieldValue("options", {
        ...values.options,
        [id]: {
          ...values.options[id],
          name: { ...values.options[id].name, [lang]: e.target.value },
        },
      })

  const handleChangeDoubleLanguageField =
    (
      field: "name" | "placeholder" | "extra_placeholder",
      language: "en" | "fi",
    ) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      setFieldValue(field, { ...values[field], [language]: e.target.value })

  const handleAddOption = () =>
    setFieldValue("options", {
      ...values.options,
      [uuidv4()]: {
        id: "",
        name: { en: "", fi: "" },
        order: "",
      },
    })

  const handleRemoveOption = (optionId: string) =>
    setFieldValue("options", _.omit(values.options, optionId))

  const handleClickOk = () => handleSubmit()

  const questionId = React.useRef(values && values.id)

  return (
    <Modal
      {...modalProps}
      title={questionId.current ? "Edit question" : "Create new question"}
      onOk={handleClickOk}
    >
      <CreateQuestionFormComponent
        values={values}
        handleSelectType={handleSelectType}
        handleChangeChecked={handleChangeChecked}
        handleChangeOptionInputField={handleChangeOptionInputField}
        handleAddOption={handleAddOption}
        handleChangeDoubleLanguageField={handleChangeDoubleLanguageField}
        handleChangeOptionName={handleChangeOptionName}
        handleRemoveOption={handleRemoveOption}
      />
    </Modal>
  )
}

const createQuestionFormWithFormik = withFormik<Props, CreateQuestionForm>({
  mapPropsToValues: ({ initialState = {} }) => ({
    ...initialValues,
    ...initialState,
  }),
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values)
    resetForm()
  },
  displayName: "CreateQuestionForm",
  enableReinitialize: true,
})

export default createQuestionFormWithFormik(CreateQuestion)
