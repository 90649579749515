import * as TYPES from "./actionTypes"
import { ImagesState, ImagesReducer } from "./types"

export const INITIAL_STATE: ImagesState = {}

const images: ImagesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_IMAGE: {
      return { ...state, [payload.id]: payload }
    }

    default:
      return state
  }
}

export default { images }
