import React, { useState } from "react"
import { DatePicker, Space, Button, Descriptions } from "antd"
import { Offer } from "../../../../offers/list/types"
import { WorkTime } from "hero24-types"
import {
  setWorkhours,
  SetWorkhoursProps,
} from "../../../../offers/list/actions"
import { useDispatch } from "react-redux"
import { isEqual } from "lodash"
import { getMoment, isDurationMoreThanPurchased } from "../../../utils"

const { RangePicker } = DatePicker

interface Props {
  offer: Offer
}

const calculatePauseDurationMS = (workTimes: WorkTime[]) => {
  let pauseDurationMS = 0
  workTimes.forEach((workTime, index) => {
    const previousWorkTime = workTimes[index - 1]
    if (index > 0 && workTime.startTime && previousWorkTime.endTime) {
      pauseDurationMS += getMoment(workTime.startTime).diff(
        getMoment(previousWorkTime.endTime),
        "milliseconds",
      )
    }
  })
  return pauseDurationMS
}

const WorkSessionRow = (props: {
  workTime: WorkTime
  onChange: (workTime: WorkTime) => void
  onRemove: () => void
  isLast: boolean
  isFirst: boolean
}) => {
  return (
    <>
      <RangePicker
        allowEmpty={[false, false]}
        value={[
          getMoment(props.workTime.startTime),
          props.workTime.endTime ? getMoment(props.workTime.endTime) : null,
        ]}
        onChange={(range) =>
          props.onChange({
            startTime:
              range && range[0]
                ? Number(range[0].format("x"))
                : props.workTime.startTime,
            endTime:
              range && range[1]
                ? Number(range[1].format("x"))
                : props.workTime.endTime,
          })
        }
        format="YYYY-MM-DD HH:mm"
        showTime
        showSecond={false}
      />
      {!props.isFirst ? <Button onClick={props.onRemove}>Remove</Button> : null}
    </>
  )
}

export const WorkHours = (props: Props) => {
  const dispatch = useDispatch()
  const { offer } = props
  const [workTimes, setWorkTimes] = useState<WorkTime[]>(
    offer.data.workTime || [
      {
        startTime:
          offer.data.actualStartTime || offer.data.initial.agreedStartTime,
        endTime:
          offer.data.actualCompletedTime ||
          (offer.data.actualStartTime || offer.data.initial.agreedStartTime) +
            60 * 60 * 1000,
      },
    ],
  )

  const needsToBeSaved =
    !offer.data.actualCompletedTime || !isEqual(offer.data.workTime, workTimes)
  const isMoreThanPurchased = isDurationMoreThanPurchased({
    ...props.offer,
    data: {
      ...props.offer.data,
      workTime: workTimes,
      pauseDurationMS: calculatePauseDurationMS(workTimes),
      actualStartTime: workTimes[0].startTime,
      actualCompletedTime: workTimes[workTimes.length - 1].endTime,
    },
  })

  const onConfirm = () => {
    const actualCompletedTime = workTimes[workTimes.length - 1].endTime
    if (actualCompletedTime) {
      const updatedOfferData: SetWorkhoursProps = {
        offerId: props.offer.id,
        workTime: workTimes,
        pauseDurationMS: calculatePauseDurationMS(workTimes),
        actualStartTime: workTimes[0].startTime,
        actualCompletedTime,
      }
      dispatch(setWorkhours(updatedOfferData))
    }
  }

  return (
    <Descriptions
      layout="vertical"
      size="small"
      bordered
      style={{ marginTop: 15, padding: 0, border: 0 }}
    >
      <Descriptions.Item label="Workhours">
        <Space direction="vertical">
          {workTimes.map((workTime, index) => (
            <WorkSessionRow
              key={index}
              workTime={workTime}
              isLast={index === workTimes.length - 1}
              isFirst={index === 0}
              onChange={(newWorkTime) => {
                const updatedWorkTimes = [...workTimes]
                updatedWorkTimes[index] = newWorkTime
                setWorkTimes(updatedWorkTimes)
              }}
              onRemove={() => {
                setWorkTimes(workTimes.filter((v, i) => i !== index))
              }}
            />
          ))}
          <Button
            block
            onClick={() => {
              const { endTime } = workTimes[workTimes.length - 1]
              if (endTime) {
                setWorkTimes([
                  ...workTimes,
                  {
                    startTime: endTime,
                    endTime: endTime + 60 * 60 * 1000,
                  },
                ])
              }
            }}
          >
            Add row
          </Button>
          <Button
            disabled={!needsToBeSaved || !!isMoreThanPurchased}
            block
            type="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
          {isMoreThanPurchased && "Duration is longer than purchased"}
        </Space>
      </Descriptions.Item>
    </Descriptions>
  )
}
