import { ThunkDispatch } from "redux-thunk"

import { ActionType } from "./constants"
import {
  UpdateOfferRequestPurchaseInput,
  updateOfferRequestPurchaseThunk,
} from "./graphql/mutations/updateOfferRequestPurchase/index"

import {
  UpdateOfferPurchaseInput,
  updateOfferPurchaseThunk,
} from "./graphql/mutations/updateOfferPurchase/index"

import { Dispatch } from "redux"
import { Store } from "../core"
import { FeeStatusEnum } from "./components/OfferRequest/Fees/components/types"
import { changeFeeStatusThunk } from "./graphql/mutations/changeFeeStatus"

export interface SetEntryPathIfNotExistAction {
  type: ActionType.SET_ENTRY_PATH_IF_NOT_EXIST
  payload: string
}

export const setEntryPathIfNotExist = (
  entryPath: string,
): SetEntryPathIfNotExistAction => ({
  payload: entryPath,
  type: ActionType.SET_ENTRY_PATH_IF_NOT_EXIST,
})

export type Action = SetEntryPathIfNotExistAction

export const updateOfferRequestPurchase =
  (purchaseData: UpdateOfferRequestPurchaseInput, callback: () => void) =>
  async (_dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const state = getState()

    const { updateOfferRequestPurchaseErrors } =
      await updateOfferRequestPurchaseThunk(state, purchaseData)

    if (updateOfferRequestPurchaseErrors) {
      console.error("Update offer request purchase failed")
    }

    if (callback) {
      callback()
    }
  }

export const updateOfferPurchase =
  (purchaseData: UpdateOfferPurchaseInput, callback: () => void) =>
  async (_dispatch: ThunkDispatch<any, any, any>, getState: () => Store) => {
    const state = getState()

    const { updateOfferPurchaseErrors } = await updateOfferPurchaseThunk(
      state,
      purchaseData,
    )

    if (updateOfferPurchaseErrors) {
      console.error("Update offer request purchase failed")
    }

    if (callback) {
      callback()
    }
  }

export interface ChangeFeeStatusProps {
  type: typeof ActionType.CHANGE_FEE_STATUS
  input: { status: FeeStatusEnum; id: string }
}

export const changeFeeStatus =
  (input: ChangeFeeStatusProps["input"]) =>
  async (_dispatch: Dispatch, getState: () => Store) => {
    await changeFeeStatusThunk(getState(), { input })
  }
