import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import moment, { Moment } from "moment-timezone"

import { preferredTimeChanged, onSuitableTimeSelected } from "../../../actions"

import { DatePicker, Row, Col } from "antd"
import { SuitableTimesPicker } from "../suitableTimesPicker"
import { DateQuestion as IDateQuestion } from "../../../types"

interface DateQuestionProps {
  question: IDateQuestion
  isSubQuestion?: boolean
}

const DateQuestion = ({ question, isSubQuestion }: DateQuestionProps) => {
  const dispatch = useDispatch()

  const disabledDate = useCallback((current: Moment | null): boolean => {
    if (current === null) {
      return false
    }
    return current && current < moment().startOf("day")
  }, [])

  const range = useCallback((current: number) => {
    const result = []
    for (let i = current; i >= 0; i--) {
      result.push(i)
    }
    return result
  }, [])

  const disabledTime = useCallback(
    (current?: moment.Moment | null | undefined) => {
      if (current && current.isAfter(moment(), "day")) {
        return {}
      } else {
        return {
          disabledHours: () => range(+moment().format("HH")),
        }
      }
    },
    [range],
  )

  const onOk = useCallback(
    (value: Moment | null) => {
      if (value === null) {
        return
      }

      dispatch(
        preferredTimeChanged({
          questionId: question.id,
          date: +value.format("x"),
        }),
      )
    },
    [dispatch, question.id],
  )

  const handleSuitableTimeSelected = useCallback(
    ({
      dateKey,
      day,
      startTime,
      endTime,
    }: {
      dateKey: string
      day: string
      startTime: Moment
      endTime: Moment
    }) => {
      dispatch(
        onSuitableTimeSelected({
          questionId: question.id,
          dateKey,
          day,
          startTime: startTime.format("x"),
          endTime: endTime.format("x"),
        }),
      )
    },
    [dispatch, question.id],
  )

  return (
    <Row>
      <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
        <DatePicker
          style={{ width: "100%" }}
          size="large"
          showTime={{ format: "HH:mm" }}
          format="DD-MM-YYYY HH:mm"
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          onOk={onOk}
          value={
            question.preferredTime ? moment(question.preferredTime, "x") : null
          }
          allowClear={false}
          showToday={false}
        />
        <SuitableTimesPicker
          isSubQuestion={isSubQuestion}
          question={question}
          onSuitableTimeSelected={handleSuitableTimeSelected}
        />
      </Col>
    </Row>
  )
}

export default DateQuestion
