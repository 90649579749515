import { gql } from "@apollo/client"
import {
  SUITABLE_TIME_ITEM_FRAGMENT,
  SuitableTimeItem,
  SuitableTimeItemAdapter,
} from "./SuitableTimeItemInfo"
import { SuitableTimes, SuitableTime as SuitableTimeDB } from "hero24-types"
import { FirebaseAdapter } from "../../../common/utilities"

export type SuitableTime = {
  key: string
  time: SuitableTimeItem
}

export const SuitableTimeAdapter = new FirebaseAdapter<
  SuitableTimes,
  SuitableTime[]
>({
  toFirebase: (graphql) =>
    Object.fromEntries(
      graphql.map(({ key, time }) => [
        key,
        SuitableTimeItemAdapter.toFirebase(time),
      ]),
    ),
  toGraphQL: (firebase) =>
    Object.keys(firebase).map((key) => ({
      key,
      time: SuitableTimeItemAdapter.toGraphQL(firebase[key] as SuitableTimeDB),
    })),
})

export const SUITABLE_TIME_FRAGMENT = gql`
  ${SUITABLE_TIME_ITEM_FRAGMENT}

  fragment SuitableTimeInfo on SuitableTimeDto {
    key
    time {
      ...SuitableTimeItemInfo
    }
  }
`
