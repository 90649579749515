const getArrayFromObject = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Input extends { [id: string]: any },
  Id extends string,
>(
  obj: Input,
  keyAsPropName: Id,
): Array<Input[keyof Input] & Record<Id, string>> => {
  return Object.entries(obj).map(
    ([key, value]): Input[keyof Input] & Record<Id, string> =>
      ({
        [keyAsPropName]: key,
        ...value,
      } as Input[keyof Input] & Record<Id, string>),
  )
}

export default getArrayFromObject
