import { createSelector } from "reselect"
import { Store } from "../core/types"
import { Seller } from "./types"

const pickStore = (state: Store) => state.seller

export const getSellers = (state: Store) => pickStore(state).sellers

export const getSellerById = (state: Store, id: string) =>
  pickStore(state).sellers[id]

export const getSellersWithReviews = createSelector([getSellers], (sellers) => {
  const sWithReviews = Object.values(sellers).filter((seller: Seller) => {
    return seller.reviews
  })

  return sWithReviews
})

export const getSellerByIdSelector = (id?: string) => (store: Store) =>
  id ? pickStore(store).sellers[id] : undefined

export const selectBriefSellers = (store: Store) => store.seller.briefSellers

export const selectBriefSellerById = (id?: string) => (store: Store) =>
  id ? store.seller.briefSellers[id] : undefined
