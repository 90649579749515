import { ThunkDispatch } from "redux-thunk"
import firebase from "firebase/app"
import { FIREBASE_PATH } from "../categories/constants"
import { getCategories } from "../categories/actions"
import { CreateRecommendedForm } from "./types"
import _ from "lodash"

export const createAndUpdateRecommended =
  (
    categoryId: string,
    values: CreateRecommendedForm,
    initialRecommendedId?: string,
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const recommendedDB = _.omit(values, "id")

    const packagesRef: firebase.database.Reference = firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("recommended")

    if (initialRecommendedId && initialRecommendedId !== values.id)
      await packagesRef.child(initialRecommendedId).remove()

    await packagesRef.child(values.id).set(recommendedDB)

    dispatch(getCategories())
  }

export const removeRecommended =
  (categoryId: string, recommendedId: string) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await firebase
      .database()
      .ref(FIREBASE_PATH)
      .child(categoryId)
      .child("recommended")
      .child(recommendedId)
      .remove()

    dispatch(getCategories())
  }
