import { OfferRequestDB } from "hero24-types"

import {
  OFFER_REQUEST_CHANGED_QUESTIONS_FRAGMENT,
  OfferRequestChangedQuestions,
  OfferRequestChangedQuestionsAdapter,
} from "./OfferRequestChangedQuestionsInfo"

import { gql } from "@apollo/client"
import { Maybe } from "../../../../common/types"
import { FirebaseAdapter } from "../../../../common/utilities"

type RequestedChangesDB = Exclude<
  OfferRequestDB["data"]["requestedChanges"],
  undefined
>

export type OfferRequestDataRequestedChanges = {
  createdAt: number
  reason?: Maybe<string>
  changedQuestions: OfferRequestChangedQuestions
}

export const OfferRequestDataRequestedChangesAdapter = new FirebaseAdapter<
  RequestedChangesDB,
  OfferRequestDataRequestedChanges
>({
  toFirebase: ({ reason, createdAt, changedQuestions }) => ({
    created: createdAt,
    reason: reason ?? undefined,
    changedQuestions:
      OfferRequestChangedQuestionsAdapter.toFirebase(changedQuestions),
  }),
  toGraphQL: ({ reason, created, changedQuestions }) => ({
    reason,
    createdAt: created,
    changedQuestions:
      OfferRequestChangedQuestionsAdapter.toGraphQL(changedQuestions),
  }),
})

export const OFFER_REQUEST_DATA_REQUESTED_CHANGES_FRAGMENT = gql`
  ${OFFER_REQUEST_CHANGED_QUESTIONS_FRAGMENT}

  fragment OfferRequestDataRequestedChangesInfo on OfferRequestDataRequestedChangesDto {
    reason
    createdAt
    changedQuestions {
      ...OfferRequestChangedQuestionsInfo
    }
  }
`
