import { normalizeUsers } from "./utils"
import { UserState, UserReducer as User } from "./types"
import * as TYPES from "./actionTypes"
import { OFFER_REQUEST_CREATED } from "../buyer/createOfferRequest/actionTypes"

export const INITIAL_STATE: UserState = {
  users: [],
  user: undefined,
  validated: {},
  fetching: true,
  selected: undefined,
  loading: false,
}

const user: User = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_USERS: {
      return {
        ...state,
        users: normalizeUsers(action.payload),
      }
    }
    case TYPES.USER_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case TYPES.SELECT_USER: {
      return {
        ...state,
        selected: action.payload,
      }
    }
    case OFFER_REQUEST_CREATED: {
      return {
        ...state,
        selected: undefined,
      }
    }
    default:
      return state
  }
}

export default { user }
