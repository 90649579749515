import React from "react"
import { Timeline } from "antd"
import { DateAndTime } from "../../listComponent/DateAndTime"
import { getStatusColor } from "../../listComponent/OfferRequestStatus"
import { UsersState } from "../../../../users/list/types"
import Text from "antd/lib/typography/Text"
import { OfferLogType, OfferRequestLogType } from "./types"

export const LogItem = ({
  log,
  users,
}: {
  log: OfferLogType | OfferRequestLogType
  users: UsersState
}) => {
  const statusText = (status?: string | null) => (
    <Text
      style={{
        color: getStatusColor(status as any),
      }}
    >
      {`${status}`.toLocaleUpperCase()}
    </Text>
  )
  const user = log.authId && users.users[log.authId]
  return (
    <Timeline.Item
      key={`${log.type}${log.time}`}
      color={getStatusColor(log.after)}
    >
      <DateAndTime time={log.time} short />:{" "}
      <Text>
        {log.type === "offerRequestLog"
          ? "OfferRequest"
          : `Offer (${log.offerId})`}
      </Text>
      <br />
      <Text>
        {user
          ? `${
              user.data.name || `${user.data.firstName} ${user.data.lastName}`
            }`
          : "automatically "}{" "}
        changed {log.key.toUpperCase()} from {statusText(log.before)} to{" "}
        {statusText(log.after)}
      </Text>
      {user ? (
        <>
          <br />
          <Text>{user.data.email}</Text>
        </>
      ) : null}
    </Timeline.Item>
  )
}
