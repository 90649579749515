import React from "react"
import { UserName } from "../../../../common/components/listComponent/UserName"
import { Button, Input, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { ColumnType } from "antd/lib/table"
import { Users } from "../../../../users/list/types"
import { OfferRequests } from "../../../../offerRequests/list/types"
import { CategoryName } from "../../../../common/components/listComponent/Category"
import { Categories } from "../../../../buyer/categories/types"
import { SubscriptionStatus } from "../../../../common/components/listComponent/SubscriptionStatus"
import SubscriptionType from "../../../../common/components/listComponent/SubscriptionType"
import { DateAndTime } from "../../../../common/components"
import { BillingStatus } from "./BillingStatus"
import { Subscription } from "../../../types"

export type SubscriptionColumn = ColumnType<Subscription>

export const getSubscriptionsColumns = (
  usersCollection: Users,
  ordersCollection: OfferRequests,
  categoriesCollection: Categories,
): SubscriptionColumn[] => {
  const createdColumn: SubscriptionColumn = {
    key: "created_at",
    title: "Created",
    render: (subscription: Subscription) => (
      <DateAndTime time={subscription.startDate} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      const timestampA = subscriptionA.startDate
      const timestampB = subscriptionB.startDate
      return timestampA - timestampB || 0
    },
  }

  const currentBillingDate: SubscriptionColumn = {
    key: "billing_date_start",
    title: "Current Period Start",
    render: (subscription: Subscription) => (
      <DateAndTime time={subscription.currentPeriodStart} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      const timestampA = subscriptionA.currentPeriodStart
      const timestampB = subscriptionB.currentPeriodStart
      return timestampA - timestampB || 0
    },
  }

  const nextBillingDate: SubscriptionColumn = {
    key: "billing_date_end",
    title: "Current Period End",
    render: (subscription: Subscription) => (
      <DateAndTime time={subscription.currentPeriodEnd} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      const timestampA = subscriptionA.currentPeriodEnd
      const timestampB = subscriptionB.currentPeriodEnd
      return timestampA - timestampB || 0
    },
  }

  const billingStatus: SubscriptionColumn = {
    key: "billing_status",
    title: "Billing Status",
    render: (subscription: Subscription) => (
      <BillingStatus subscription={subscription} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      const billingStatusA: string = subscriptionA.billingStatus || ""
      const billingStatusB: string = subscriptionB.billingStatus || ""
      return billingStatusA.localeCompare(billingStatusB)
    },
  }

  const categoryColumn: SubscriptionColumn = {
    key: "category",
    title: "Category",
    render: (subscription: Subscription) => {
      const offerRequest =
        ordersCollection[subscription.activeRequest]?.data.initial
      const offerCategory = categoriesCollection[offerRequest?.category]
      return <CategoryName category={offerCategory} />
    },
    filters: Object.values(categoriesCollection)
      .sort((a, b) => (a.name.en || "").localeCompare(b.name.en || ""))
      .map((category) => ({
        text: category.name.en,
        value: category.id,
      })),
    onFilter: (value: any, subscription: Subscription) => {
      const offerRequest =
        ordersCollection[subscription.activeRequest]?.data.initial
      const orderCategory = offerRequest?.category
      return orderCategory === value
    },
  }

  const customerColumn: SubscriptionColumn = {
    key: "buyer_profile",
    title: "Customer",
    render: (subscription: Subscription) => (
      <UserName user={usersCollection[subscription.subscriberId]} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      const aName = usersCollection[subscriptionA.subscriberId]?.data.name
      const bName = usersCollection[subscriptionB.subscriberId]?.data.name
      if (aName && bName) {
        return aName.localeCompare(bName)
      } else if (aName) {
        return -1
      } else if (bName) {
        return 1
      } else {
        return 0
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search by buyers name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, subscription: Subscription) => {
      const user = usersCollection[subscription.subscriberId]
      return typeof value === "string" && user
        ? user.data?.name.toLowerCase().includes(value.toLowerCase())
        : false
    },
  }

  const statusColumn: SubscriptionColumn = {
    key: "status",
    title: "Status",
    render: (subscription: Subscription) => (
      <SubscriptionStatus subscription={subscription} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      return (subscriptionA.status || "").localeCompare(
        subscriptionB.status || "",
      )
    },
  }

  const typeColumn: SubscriptionColumn = {
    key: "subscription_interval",
    title: "Type",
    render: (subscription: Subscription) => (
      <SubscriptionType subscription={subscription} />
    ),
    sorter: (subscriptionA: Subscription, subscriptionB: Subscription) => {
      return (subscriptionA.interval || "").localeCompare(
        subscriptionB.interval || "",
      )
    },
    filters: [
      { text: "DAILY", value: "daily" },
      { text: "WEEKLY", value: "weekly" },
      { text: "BI-WEEKLY", value: "bi-weekly" },
      { text: "MONTHLY", value: "monthly" },
    ],
    onFilter: (value: any, subscription: Subscription) =>
      subscription.interval === value,
  }

  return [
    createdColumn,
    currentBillingDate,
    nextBillingDate,
    billingStatus,
    customerColumn,
    categoryColumn,
    statusColumn,
    typeColumn,
  ]
}
