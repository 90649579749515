import React from "react"
import { Descriptions } from "antd"

const renderDetails = (value: React.ReactNode): React.ReactNode => {
  if (typeof value === "string" || typeof value === "number") {
    return <span key={value}>{value}</span>
  } else if (Array.isArray(value)) {
    return value.map((v) => renderDetails(v))
  } else if (React.isValidElement(value)) {
    return value
  }
  return null
}

export const DetailsRow = (props: {
  title: React.ReactNode
  value: React.ReactNode
}) => {
  return (
    <Descriptions style={{ border: 0 }}>
      <Descriptions.Item label={props.title}>
        {renderDetails(props.value)}
      </Descriptions.Item>
    </Descriptions>
  )
}
