import { Subscription } from "./types"

export const GET_SUBSCRIPTIONS = "@iweb/GET_SUBSCRIPTIONS"

export const DEL_SUBSCRIPTIONS_STARTED = "@iweb/DEL_SUBSCRIPTIONS_STARTED"
export const DEL_SUBSCRIPTIONS_SUCCESS = "@iweb/DEL_SUBSCRIPTIONS_SUCCESS"
export const DEL_SUBSCRIPTIONS_FAILURE = "@iweb/DEL_SUBSCRIPTIONS_FAILURE"

export interface GetSubscriptions {
  type: typeof GET_SUBSCRIPTIONS
  payload: {
    [key: string]: Subscription
  }
}

export interface RemoveSubscription {
  type: typeof DEL_SUBSCRIPTIONS_STARTED
  payload: {
    subscriptionId: string
  }
}

export interface RemoveSubscriptionSuccess {
  type: typeof DEL_SUBSCRIPTIONS_SUCCESS
  payload: {
    subscriptionId: string
  }
}

export interface RemoveSubscriptionFailure {
  type: typeof DEL_SUBSCRIPTIONS_FAILURE
  payload: {
    subscriptionId: string
    error: Error
  }
}

export type RemoveSubscriptionActions =
  | RemoveSubscription
  | RemoveSubscriptionSuccess
  | RemoveSubscriptionFailure

export function getSubscriptionsSuccess(subscriptions: {
  [k: string]: Subscription
}): GetSubscriptions {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: subscriptions,
  }
}

export function removeSubscription(subscriptionId: string): RemoveSubscription {
  return {
    type: DEL_SUBSCRIPTIONS_STARTED,
    payload: { subscriptionId },
  }
}

export function removeSubscriptionSuccess(
  subscriptionId: string,
): RemoveSubscriptionSuccess {
  return {
    type: DEL_SUBSCRIPTIONS_SUCCESS,
    payload: { subscriptionId },
  }
}

export function removeSubscriptionFailure(
  subscriptionId: string,
  error: Error,
): RemoveSubscriptionFailure {
  return {
    type: DEL_SUBSCRIPTIONS_FAILURE,
    payload: { subscriptionId, error },
  }
}
