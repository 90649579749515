import React from "react"
import AuthRoute from "../../common/AuthRoute"
import reducer from "./reducer"
import { PaymentTransactionsList } from "./components/containers"
import { ProjectOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <ProjectOutlined />,
      name: "Payment transactions",
      path: "paymentTransactions",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/paymentTransactions"
      path="/paymentTransactions"
      render={() => <PaymentTransactionsList />}
    />,
  ],
  reducer,
}
