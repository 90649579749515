import React from "react"
import moment from "moment-timezone"
import { green, blue, red, orange } from "@ant-design/colors"
import { Select, Tooltip } from "antd"
import { useDispatch } from "react-redux"
import { OFFER_STATUS } from "hero24-types"
import { Offer } from "../../../offers/list/types"
import { setOfferStatus } from "../../../offers/list/actions"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"

interface Props {
  offer: Offer
  offerRequest?: OfferRequest
  purchaseInvoiceMode?: boolean
}

const statuses: Array<OFFER_STATUS> = [
  "open",
  "cancelled",
  "accepted",
  "expired",
  "completed",
]

const getStatusColor = (status: OFFER_STATUS) => {
  switch (status) {
    case "accepted":
      return green.primary
    case "completed":
      return blue.primary
    case "cancelled":
      return red.primary
    case "expired":
      return orange.primary
    case "open":
    default:
      return "black"
  }
}

export const OfferStatus = (props: Props) => {
  const dispatch = useDispatch()
  const { offer, offerRequest, purchaseInvoiceMode } = props
  const currentStatus = offer.status

  if (purchaseInvoiceMode) {
    return (
      <span
        style={{
          color: getStatusColor(currentStatus),
        }}
      >
        {currentStatus.toUpperCase()}
      </span>
    )
  }

  if (
    currentStatus === "expired" &&
    moment().diff(moment(offer.data.initial.agreedStartTime, "x"), "hours") > 0
  ) {
    return (
      <Tooltip
        placement="topLeft"
        title={`To change ${currentStatus.toLocaleUpperCase()} the offerred time needs to be in the future. Edit that first and then you can change the status.`}
      >
        <span
          style={{
            color: getStatusColor(currentStatus),
          }}
        >
          {currentStatus.toUpperCase()}
        </span>
      </Tooltip>
    )
  } else if (
    currentStatus === "accepted" &&
    !offerRequest?.data.initial.prePayWith
  ) {
    return (
      <Tooltip
        placement="topLeft"
        title={`This offer has been paid by the customer. CANCELLING requires returning money and other changes. Setting as COMPLETED needs to be done by the doer in the app.`}
      >
        <span
          style={{
            color: getStatusColor(currentStatus),
          }}
        >
          {currentStatus.toUpperCase()}
        </span>
      </Tooltip>
    )
  } else {
    // completed needs to be done by the doer since there are additional steps than just changing the status
    // expired should be done by cloud not user
    const disallowedStatuses: {
      [key in OFFER_STATUS]: string | undefined
    } = {
      expired: "Automatically set when the time runs out",
      completed: undefined,
      // currentStatus === "accepted"
      // ? undefined
      // : "Offer needs to be accepted first",
      cancelled: undefined,
      open: undefined,
      accepted: undefined,
    }
    // if it's not prepaid it then customer should pay for it first before it's accepted
    // or if offerRequest is accepted (there is already accepted offer)
    if (!offerRequest?.data.initial.prePayWith) {
      disallowedStatuses.accepted = "Should be paid first"
    } else if (offer.status === "cancelled") {
      disallowedStatuses.accepted =
        "Can not set cancelled offer to accepted. Order might have changed since this was created. Doer needs to create a new offer."
      disallowedStatuses.open =
        "Can not set cancelled offer to open. Order might have changed since this was created. Doer needs to create a new offer."
    }
    return (
      <Select
        defaultValue={currentStatus as OFFER_STATUS}
        onSelect={(status) => {
          if (
            status === "open" ||
            status === "accepted" ||
            status === "cancelled" ||
            status === "completed"
          ) {
            dispatch(
              setOfferStatus({
                id: offer.id,
                status,
              }),
            )
          } else {
            alert("not allowed! (should never happen)")
          }
        }}
      >
        {statuses.map((status) => {
          const disabled =
            !!disallowedStatuses[status] && status !== currentStatus
          return (
            <Select.Option key={status} value={status} disabled={disabled}>
              {disabled ? (
                <Tooltip placement="topLeft" title={disallowedStatuses[status]}>
                  <span>{status.toUpperCase()}</span>
                </Tooltip>
              ) : (
                <span style={{ color: getStatusColor(status), fontSize: 14 }}>
                  {status.toUpperCase()}
                </span>
              )}
            </Select.Option>
          )
        })}
      </Select>
    )
  }
}
