import { gql } from "@apollo/client"
import { SuitableTime } from "hero24-types"
import { FirebaseAdapter } from "../../../common/utilities"

export type SuitableTimeItem = {
  day: string
  startTime: number
  endTime: number
}

export const SuitableTimeItemAdapter = new FirebaseAdapter<
  SuitableTime,
  SuitableTimeItem
>({
  toFirebase: ({ day, startTime, endTime }) => ({ day, startTime, endTime }),
  toGraphQL: ({ day, startTime, endTime }) => ({ day, startTime, endTime }),
})

export const SUITABLE_TIME_ITEM_FRAGMENT = gql`
  fragment SuitableTimeItemInfo on SuitableTimeItemDto {
    day
    startTime
    endTime
  }
`
