import React from "react"

import PromotionForm, {
  FormPromotionData,
} from "../../components/PromotionForm"
import { FormPromotionToPromotionDB } from "../../components/PromotionForm/utils"
import { updatePromotion } from "../../queries"
import { Promotion } from "../../types"

interface Props {
  promotion: Promotion
  closeModal?: () => void
}

const EditPromotionForm = ({ promotion, closeModal }: Props) => {
  const onFinish = (data: FormPromotionData) => {
    const promotionDB = FormPromotionToPromotionDB(data)

    updatePromotion(promotion.id, promotionDB)

    closeModal && closeModal()
  }

  return <PromotionForm promotion={promotion} onFinish={onFinish} />
}

export default EditPromotionForm
