import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"
import {
  Data,
  MUTATION,
  SetIsApprovedByBuyerInput,
  Variables,
} from "./mutation"

export const setIsApprovedByBuyerThunk: ApolloThunkMutation<
  Data,
  [SetIsApprovedByBuyerInput]
> = async (state, input) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables: { input },
  })

  return {
    responseData: data?.[DEFAULT_GRAPHQL_RESPONSE],
    responseErrors: errors,
  }
}
