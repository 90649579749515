import { gql } from "@apollo/client"
import { NEWS_FRAGMENT } from "../../fragments"

export const QUERY = gql`
  ${NEWS_FRAGMENT}

  query GetNews($id: String!) {
    news(id: $id) {
      ...NewsInfo
    }
  }
`
