import React, { useCallback, useState } from "react"
import { Button, Descriptions } from "antd"
import { Offer } from "../../../offers/list/types"
import firebase from "firebase/app"
import firebaseConfig from "../../../../firebaseConfig"

const netvisorURL =
  firebaseConfig.projectId &&
  firebaseConfig.projectId === "hero24-production-ec006"
    ? "suomi.netvisor.fi"
    : "isv.netvisor.fi"
interface Props {
  offer: Offer
}

export const Netvisor = (props: Props) => {
  const [salesInvoiceSent, setSalesInvoiceSent] = useState(false)
  const [purchaseInvoiceSent, setPurchaseInvoiceSent] = useState(false)
  const [salesInvoiceDeleted, setSalesInvoiceDeleted] = useState(false)
  const [purchaseInvoiceDeleted, setPurchaseInvoiceDeleted] = useState(false)

  const onSendToNetvisorSalesInvoice = useCallback(async () => {
    await firebase
      .database()
      .ref("offers")
      .child(props.offer.id)
      .child("sendToNetvisorSalesInvoice")
      .set(firebase.database.ServerValue.TIMESTAMP as number)
    setSalesInvoiceSent(true)
  }, [props.offer.id])

  const onSendToNetvisorPurchaseInvoice = useCallback(async () => {
    await firebase
      .database()
      .ref("offers")
      .child(props.offer.id)
      .child("sendToNetvisorPurchaseInvoice")
      .set(firebase.database.ServerValue.TIMESTAMP as number)
    setPurchaseInvoiceSent(true)
  }, [props.offer.id])

  const onDeleteNetvisorSalesInvoice = useCallback(async () => {
    await firebase
      .database()
      .ref("offers")
      .child(props.offer.id)
      .child("netvisorSalesInvoiceId")
      .remove()
    setSalesInvoiceDeleted(true)
  }, [props.offer.id])

  const onDeleteNetvisorPurchaseInvoice = useCallback(async () => {
    await firebase
      .database()
      .ref("offers")
      .child(props.offer.id)
      .child("netvisorPurchaseInvoiceId")
      .remove()
    setPurchaseInvoiceDeleted(true)
  }, [props.offer.id])

  return (
    <Descriptions
      layout="horizontal"
      size="small"
      bordered
      style={{
        margin: 0,
        padding: 0,
        border: 0,
        background: "white",
      }}
    >
      {props.offer.netvisorPurchaseInvoiceId && (
        <Descriptions.Item label="Purchase invoice">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${netvisorURL}/evo/accounting/purchaseinvoice/invoice.aspx?id=${props.offer.netvisorPurchaseInvoiceId}`}
          >
            {props.offer.netvisorPurchaseInvoiceId}
          </a>
          <Button
            style={{ marginLeft: 24 }}
            disabled={purchaseInvoiceDeleted}
            type="link"
            onClick={onDeleteNetvisorPurchaseInvoice}
          >
            Delete purchase invoice
          </Button>
        </Descriptions.Item>
      )}
      {props.offer.netvisorSalesInvoiceId &&
      props.offer.status === "completed" ? (
        <Descriptions.Item label="Sales Invoice">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${netvisorURL}/evo/accounting/salesledger/invoice.aspx?id=${props.offer.netvisorSalesInvoiceId}`}
          >
            {props.offer.netvisorSalesInvoiceId}
          </a>
          <Button
            style={{ marginLeft: 24 }}
            disabled={salesInvoiceDeleted}
            type="link"
            onClick={onDeleteNetvisorSalesInvoice}
          >
            Delete sales invoice
          </Button>
        </Descriptions.Item>
      ) : null}
      {props.offer.netvisorSalesInvoiceId &&
      props.offer.status !== "completed" ? (
        <Descriptions.Item label="Sales Order">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${netvisorURL}/evo/accounting/salesledger/order.aspx?id=${props.offer.netvisorSalesInvoiceId}`}
          >
            {props.offer.netvisorSalesInvoiceId}
          </a>
        </Descriptions.Item>
      ) : null}
      {!props.offer.netvisorSalesInvoiceId && (
        <Descriptions.Item>
          <Button
            disabled={salesInvoiceSent}
            type="link"
            block
            onClick={onSendToNetvisorSalesInvoice}
          >
            {salesInvoiceSent
              ? "Netvisor Sales Invoice Sent"
              : "Create Netvisor Sales Invoice"}
          </Button>
        </Descriptions.Item>
      )}
      {!props.offer.netvisorPurchaseInvoiceId && (
        <Descriptions.Item>
          <Button
            disabled={purchaseInvoiceSent}
            type="link"
            block
            onClick={onSendToNetvisorPurchaseInvoice}
          >
            {purchaseInvoiceSent
              ? "Netvisor Purchase Invoice Sent"
              : "Create Netvisor Purchase Invoice"}
          </Button>
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}
