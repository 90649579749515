import { gql } from "@apollo/client"
import { AddressesAnswered as AddressesAnsweredDB } from "hero24-types"

import {
  DELIVERY_ADDRESSES_FRAGMENT,
  DeliveryAddresses,
  DeliveryAddressesAdapter,
} from "./DeliveryAddressesInfo"
import {
  BASIC_ADDRESSES_FRAGMENT,
  BasicAddresses,
  BasicAddressesAdapter,
} from "./BasicAddressesInfo"
import { FirebaseAdapter } from "../../../../common/utilities"

export type AddressesAnswered = BasicAddresses | DeliveryAddresses

export const AddressesAnsweredAdapter = new FirebaseAdapter<
  AddressesAnsweredDB,
  AddressesAnswered
>({
  toFirebase: (graphql) =>
    graphql.type === "basic"
      ? BasicAddressesAdapter.toFirebase(graphql as BasicAddresses)
      : DeliveryAddressesAdapter.toFirebase(graphql as DeliveryAddresses),
  toGraphQL: (firebase) =>
    firebase.type === "basic"
      ? BasicAddressesAdapter.toGraphQL(
          firebase as AddressesAnsweredDB & { type: "basic" },
        )
      : DeliveryAddressesAdapter.toGraphQL(
          firebase as AddressesAnsweredDB & { type: "delivery" },
        ),
})

export const ADDRESSES_ANSWERED_FRAGMENT = gql`
  ${BASIC_ADDRESSES_FRAGMENT}
  ${DELIVERY_ADDRESSES_FRAGMENT}

  fragment AddressesAnsweredInfo on AddressesAnsweredDto {
    ... on BasicAddressesDto {
      ...BasicAddressesInfo
    }
    ... on DeliveryAddressesDto {
      ...DeliveryAddressesInfo
    }
  }
`
