import { AppThunkAction } from "../../common/types"
import { newsEdited, setNewsEditing } from "../actions"
import { editNews } from "../graphql/mutations"

type Variables = Parameters<typeof editNews>[1]

type Args = [Variables]

export const editNewsThunk: AppThunkAction<Args> =
  (variables) => async (dispatch, getState) => {
    const { id } = variables

    try {
      dispatch(setNewsEditing({ id, updating: true }))
      const { editNewsData } = await editNews(getState(), variables)

      if (editNewsData) {
        dispatch(newsEdited(editNewsData))
      }
    } finally {
      dispatch(setNewsEditing({ id, updating: false }))
    }
  }
