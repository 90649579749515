import React, { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { Moment } from "moment-timezone"
import _ from "lodash"
import { getDateQuestion } from "../../../offerRequests/utils"
import { List, Row, Col } from "antd"
import { getStartEndString } from "../../utils"
import { SuitableTimesPicker } from "../../../buyer/questions/components/question/suitableTimesPicker"
import { setOfferRequestTime } from "../../../offerRequests/list/actions"

interface Props {
  offerRequest: OfferRequest
}

export const SuitableTimes = (props: Props) => {
  const dispatch = useDispatch()

  const dateQuestion = getDateQuestion(props.offerRequest)
  const suitableTimes = dateQuestion?.suitableTimes

  const handleSuitableTimeSelected = useCallback(
    ({
      dateKey,
      day,
      startTime,
      endTime,
    }: {
      dateKey: string
      day: string
      startTime: Moment
      endTime: Moment
    }) => {
      const currentSuitableTimes = suitableTimes || {}

      const updatedSuitableTimes = currentSuitableTimes[dateKey]
        ? _.omit(currentSuitableTimes, dateKey)
        : _.set(currentSuitableTimes, dateKey, {
            day: day,
            startTime: +startTime.format("x"),
            endTime: +endTime.format("x"),
          })

      dispatch(
        setOfferRequestTime({
          id: props.offerRequest.id,
          suitableTimes: updatedSuitableTimes,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      suitableTimes,
      props.offerRequest.id,
      dateQuestion?.suitableTimesCount, // required to detect when suitableTimes have changed
    ],
  )

  return useMemo(() => {
    if (!dateQuestion) {
      return null
    } else {
      return (
        <Row>
          {suitableTimes && (
            <Col xs={24}>
              <List
                dataSource={Object.values(suitableTimes)}
                renderItem={(suitableTime) => (
                  <List.Item>
                    {getStartEndString({
                      startTime: suitableTime.startTime,
                      endTime: suitableTime.endTime,
                    })}
                  </List.Item>
                )}
              />
            </Col>
          )}
          {!["completed", "accepted"].includes(
            props.offerRequest.data.status,
          ) && (
            <Col>
              <SuitableTimesPicker
                question={dateQuestion}
                onSuitableTimeSelected={handleSuitableTimeSelected}
              />
            </Col>
          )}
        </Row>
      )
    }
  }, [
    handleSuitableTimeSelected,
    dateQuestion,
    suitableTimes,
    props.offerRequest.data.status,
  ])
}
