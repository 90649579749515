import moment, { Moment } from "moment-timezone"

import { Store } from "../../../modules/core/types"
import { PURCHASE_INVOICE_DATE_GRANULARITY } from "../../netvisor/constants"

export const getPurchaseInvoiceOffersSelector =
  (sellerId?: string, week?: Moment | null) => (store: Store) =>
    week
      ? Object.fromEntries(
          Object.entries(store.offers.offers).filter(
            ([, offer]) =>
              offer.status === "completed" &&
              !offer.netvisorPurchaseInvoiceId &&
              moment(offer.data.actualStartTime).isSame(
                week,
                PURCHASE_INVOICE_DATE_GRANULARITY,
              ) &&
              ((sellerId && offer.data.initial.sellerProfile === sellerId) ||
                !sellerId),
          ),
        )
      : undefined
