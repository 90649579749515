import React from "react"
import * as Yup from "yup"
import { withFormik, FormikProps } from "formik"
import { Form, Button, Row, Col, Select } from "antd"

import { FieldAdapter as Field } from "../../../../common/form"
import { RenderField, RenderSelect } from "../../../../common/components"

import {
  EditReviewForm as IEditReviewForm,
  AvailableRating,
} from "../../../types"

const Option = Select.Option

interface EditReviewFormProps {
  onSubmit: (values: any, cb: () => void) => void
  initialValues: {
    text?: string
    rating?: AvailableRating
    removeFromSeller?: boolean
  }
}

const EditReviewForm = (
  props: EditReviewFormProps & FormikProps<IEditReviewForm>,
) => {
  const { values, isValid, setFieldValue, handleSubmit } = props

  const onRatingSelect = (value: React.ReactText) => {
    setFieldValue("rating", value)
  }

  return (
    <Form name="user" onSubmitCapture={handleSubmit}>
      <Field
        name="text"
        component={RenderField}
        type="text"
        label="Text"
        value={values.text}
      />
      <Field
        name="rating"
        component={RenderSelect}
        label="Select rating"
        value={values.rating}
        onSelect={onRatingSelect}
      >
        <Option value="0">0</Option>
        <Option value="1">1</Option>
        <Option value="2">2</Option>
        <Option value="3">3</Option>
        <Option value="4">4</Option>
        <Option value="5">5</Option>
      </Field>

      <Field
        name="removeFromSeller"
        component={RenderField}
        label="Hide from seller"
        type="checkbox"
        checked={!!values.removeFromSeller}
        onChange={(e) => setFieldValue("removeFromSeller", e.target.checked)}
      />

      <Row gutter={24}>
        <Col>
          <Button
            style={{ width: "100%" }}
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!isValid}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ValidationSchema = Yup.object().shape<IEditReviewForm>({
  text: Yup.string().min(2, "Too Short!").max(5000, "Too Long!"),
  rating: Yup.mixed<"0" | "1" | "2" | "3" | "4" | "5">().oneOf([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
  ]),
})

const editReviewFormWithFormik = withFormik<
  EditReviewFormProps,
  IEditReviewForm
>({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => initialValues,
  handleSubmit: (values, { props: { onSubmit }, resetForm }) => {
    onSubmit(values, resetForm)
  },
  validationSchema: ValidationSchema,
  displayName: "EditReviewForm",
})

export default editReviewFormWithFormik(EditReviewForm)
