import React from "react"

import { Row, Col, Typography } from "antd"
import AddressForm from "./AddressForm"

import { Category } from "../../../categories/types"

const { Title } = Typography

const AddressMain = () => {
  return <AddressForm addressType="main" />
}

const AddressFromTo = () => (
  <Row>
    <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
      <Title
        style={{ marginBottom: 12 }}
        className="question-header-title"
        level={4}
      >
        Start address
      </Title>
      <AddressForm addressType="from" />
    </Col>
    <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
      <Title
        style={{ marginBottom: 12 }}
        className="question-header-title"
        level={4}
      >
        Destination address
      </Title>
      <AddressForm addressType="to" />
    </Col>
  </Row>
)

interface AddressQuestionListProps {
  category: Category
}

const AddressQuestionList = ({ category }: AddressQuestionListProps) => {
  return (
    <Row>
      <Col xs={24}>
        {category.addresses.type === "basic" ? <AddressMain /> : null}
        {category.addresses.type === "delivery" ? <AddressFromTo /> : null}
      </Col>
    </Row>
  )
}

export default AddressQuestionList
