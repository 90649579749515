import { useCallback, useState } from "react"

type SupportedPageSize = 10 | 25 | 50 | 100

export const usePagination = (initialLimit: SupportedPageSize) => {
  const [limit, setLimit] = useState(initialLimit)
  const [offset, setOffset] = useState(0)

  const setPage = useCallback(
    (nextPage: number) => {
      setOffset(limit * (nextPage - 1))
    },
    [limit],
  )

  return {
    page: Math.floor(offset / limit) + 1,
    limit,
    offset,
    setLimit,
    setOffset,
    setPage,
  }
}
