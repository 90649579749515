import * as TYPES from "./actionTypes"
import { normalizeCategories } from "./utils"
import { CategoriesState, CategoriesReducer as Categories } from "./types"
import { OFFER_REQUEST_CREATED } from "../createOfferRequest/actionTypes"
import { normalizeQuestionsFromCategories } from "../questions/utils"

export const INITIAL_STATE: CategoriesState = {
  categories: {},
  categoryQuestions: {},
  selected: undefined,
}

const categories: Categories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CATEGORIES: {
      return {
        ...state,
        categories: normalizeCategories(action.payload),
        categoryQuestions: normalizeQuestionsFromCategories(action.payload),
      }
    }

    case TYPES.UPDATE_CATEGORY: {
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.id]: action.payload,
        },
      }
    }

    case TYPES.SELECT_CATEGORY: {
      return {
        ...state,
        selected: action.payload,
      }
    }
    case OFFER_REQUEST_CREATED: {
      // resets selected category and addresses
      return {
        ...state,
        selected: undefined,
        categories: Object.fromEntries(
          Object.entries(state.categories).map((entry) => {
            if (entry[1].addresses.type === "basic") {
              entry[1].addresses.main = true
            } else if (entry[1].addresses.type === "delivery") {
              entry[1].addresses.to = true
              entry[1].addresses.from = true
            }
            return entry
          }),
        ),
      }
    }
    case TYPES.CATEGORIES_ADDRESS_UPDATED: {
      const { categoryId, addressType, address } = action.payload
      return {
        ...state,
        categories: {
          ...state.categories,
          [categoryId]: {
            ...state.categories[categoryId],
            addresses: {
              ...state.categories[categoryId].addresses,
              [addressType]: address,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export default { categories }
