import React from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  getSelectedCategory,
  getAddressValuesForAddressType,
} from "../../../categories/selectors"

import { addressUpdated } from "../../../categories/actions"

import { Row, Col, Input, Typography, Badge } from "antd"

import { Store } from "../../../../core/types"
import { AddressTypes } from "../../../categories/constants"

const { Title } = Typography

interface AddressFormProps {
  addressType: AddressTypes
}

const AddressForm = (props: AddressFormProps) => {
  const dispatch = useDispatch()
  const category = useSelector(getSelectedCategory)
  const addressValues = useSelector((state: Store) => {
    return getAddressValuesForAddressType(state, props.addressType)
  })

  if (!category || !addressValues) {
    return null
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      addressUpdated({
        categoryId: category.id,
        addressType: props.addressType,
        address: {
          ...addressValues,
          [e.target.name]: e.target.value,
        },
      }),
    )
  }

  return (
    <>
      <Row justify="space-between">
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            City
          </Title>
        </Col>
        <Col xs={24}>
          {addressValues && !addressValues.city ? (
            <Badge
              count="Mandatory"
              style={{ marginLeft: 12, backgroundColor: "red" }}
            />
          ) : (
            <Badge
              count="Ok"
              style={{ marginLeft: 12, backgroundColor: "green" }}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
          <Input
            size="large"
            type="text"
            value={addressValues.city}
            name="city"
            placeholder="City"
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            Street address
          </Title>
        </Col>
        <Col xs={24}>
          {addressValues && !addressValues.streetAddress ? (
            <Badge
              count="Mandatory"
              style={{ marginLeft: 12, backgroundColor: "red" }}
            />
          ) : (
            <Badge
              count="Ok"
              style={{ marginLeft: 12, backgroundColor: "green" }}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
          <Input
            size="large"
            type="text"
            value={addressValues.streetAddress}
            name="streetAddress"
            placeholder="Street 1 A"
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            Postal code
          </Title>
        </Col>
        <Col xs={24}>
          {addressValues && !addressValues.postalCode ? (
            <Badge
              count="Mandatory"
              style={{ marginLeft: 12, backgroundColor: "red" }}
            />
          ) : (
            <Badge
              count="Ok"
              style={{ marginLeft: 12, backgroundColor: "green" }}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={24} style={{ marginTop: 6, marginBottom: 12 }}>
          <Input
            size="large"
            type="text"
            value={addressValues.postalCode}
            name="postalCode"
            placeholder="00123"
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  )
}

export default AddressForm
