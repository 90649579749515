import { Select } from "antd"
import { User } from "../../../../user/types"
import { metaStyle, metaStyleDisabled } from "../constants"

const { Option } = Select

interface SearchSelectOptionProps {
  users: User[]
  user: User
}

const renderSearchSelectOption = ({ users, user }: SearchSelectOptionProps) => {
  const mergedToUser =
    user.mergedTo && users.find(({ id }) => id === user.mergedTo)

  return (
    <Option key={user.id} value={user.id} disabled={!!mergedToUser}>
      <span style={{ marginRight: "3px" }}>{user.data?.name}</span>

      <span style={mergedToUser ? metaStyleDisabled : metaStyle}>
        {user.data?.email} / {user.data?.phone}
      </span>

      <>
        <br />
        {mergedToUser ? (
          <span style={metaStyleDisabled}>merged to {mergedToUser.id}</span>
        ) : (
          <span style={metaStyle}>{user.id}</span>
        )}
      </>
    </Option>
  )
}

export default renderSearchSelectOption
