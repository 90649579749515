import { PromotionDB } from "hero24-types"
import moment from "moment"

import { FormPromotionData } from ".."

const FormPromotionToPromotionDB = (data: FormPromotionData): PromotionDB => {
  const { startDate, endDate } = data

  return {
    data: {
      ...data,
      startDate: moment(startDate).startOf("day").valueOf(),
      endDate: moment(endDate).endOf("day").valueOf(),
    },
  }
}

export default FormPromotionToPromotionDB
