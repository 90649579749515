import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { News } from "../../fragments"
import { MUTATION } from "./mutation"

type Data = {
  createNews: News
}

type Variables = {
  input: Omit<News, "id">
}

type Args = [Variables]

type Fields = "data" | "errors"

export const createNews: ApolloThunkMutation<Data, Args, Fields> = async (
  state,
  variables,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables,
  })

  return {
    createNewsData: data?.createNews,
    createNewsErrors: errors,
  }
}
