import { CheckCircleFilled } from "@ant-design/icons"
import { Button, Popover } from "antd"
import { FC } from "react"
import { OfferRequest } from "../../../../../buyer/createOfferRequest/types"
import Popconfirm from "../../../Popconfirm"
import { useSetIsApprovedByBuyer } from "../hooks/useSetIsApprovedByBuyer"
import {
  APPROVED_BY_BUYER_POPCONFIRM_TITLE,
  APPROVED_BY_BUYER_POPOVER_CONTENT,
  OperationStatus,
} from "./constants"

export type ApprovedByBuyerCallback = (status: `${OperationStatus}`) => void

export interface ApprovedByBuyerProps {
  offerRequest: OfferRequest
  callback?: ApprovedByBuyerCallback
}

export const ApprovedByBuyer: FC<ApprovedByBuyerProps> = (props) => {
  const { offerRequest, callback } = props
  const { isApprovedByBuyer } = offerRequest
  const { onConfirm, loading } = useSetIsApprovedByBuyer({
    offerRequest,
    callback,
  })

  if (isApprovedByBuyer) {
    return null
  }

  return (
    <Popconfirm
      title={APPROVED_BY_BUYER_POPCONFIRM_TITLE}
      loading={loading}
      onConfirm={onConfirm}
    >
      <Popover trigger="hover" content={APPROVED_BY_BUYER_POPOVER_CONTENT}>
        <Button
          shape="circle"
          icon={<CheckCircleFilled />}
          style={{ margin: 5 }}
        />
      </Popover>
    </Popconfirm>
  )
}
