import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubscriptionsList } from "../../../selectors"
import { getAllSubscriptions } from "../../../actions"
import { Subscription } from "../../../types"
import { getSubscriptionsColumns } from "../table"
import { Store } from "../../../../core/types"
import { Table } from "antd"
import { getAllUsers } from "../../../../users/list/actions"
import { getCategories } from "../../../../buyer/categories/actions"
import { getOfferRequests } from "../../../../offerRequests/list/selectors"
import { getAllOfferRequests } from "../../../../offerRequests/list/actions"
import { SubscriptionView } from "./SubscriptionView"

const SubscriptionList = () => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = React.useState(false)

  const users = useSelector((store: Store) => {
    return store.users.users
  })

  const subscriptionsList = useSelector(getSubscriptionsList)

  const offerRequests = useSelector(getOfferRequests)
  const categories = useSelector(({ categories }: Store) => {
    return categories.categories
  })

  function fetchSubscriptionsList() {
    setLoading(true)
    return getAllSubscriptions()
  }

  React.useEffect(() => {
    setLoading(false)
  }, [subscriptionsList])

  React.useEffect(() => {
    dispatch(getAllOfferRequests())
    dispatch(fetchSubscriptionsList())
    dispatch(getAllUsers())
    dispatch(getCategories())
  }, [dispatch])

  const columns = React.useMemo(
    () => getSubscriptionsColumns(users, offerRequests, categories),
    [users, offerRequests, categories],
  )

  return (
    <section style={{ textAlign: "center" }}>
      <h2>Subscriptions</h2>
      <Table
        tableLayout="auto"
        rowKey={(record: { [key: string]: any }) => record.id}
        dataSource={subscriptionsList}
        columns={columns}
        pagination={{
          pageSize: 25,
        }}
        expandedRowRender={(subscription: Subscription) => (
          <SubscriptionView
            subscription={subscription}
            offers={offerRequests}
          />
        )}
        loading={isLoading}
        scroll={{ x: 800 }}
      />
    </section>
  )
}

export default SubscriptionList
