import React from "react"
import { PickStrategy } from "hero24-types"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { Card, Descriptions } from "antd"
import { useSelector } from "react-redux"
import { SellerState } from "../../../seller/types"

interface Props {
  offerRequest: OfferRequest
}

export const PickingDoer = (props: Props) => {
  const sellers = useSelector(({ seller }: { seller: SellerState }) => {
    return seller.sellers
  })
  const pickStrategy: PickStrategy =
    props.offerRequest.data.pickServiceProvider?.pickStrategy || "first"
  const preferredDoers = props.offerRequest.data.pickServiceProvider?.preferred
  return (
    <Card style={{ background: "transparent" }} bordered={false} size="small">
      <Descriptions
        layout="horizontal"
        size="small"
        bordered
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          background: "white",
        }}
      >
        <Descriptions.Item label="Preferred doers">
          {preferredDoers
            ? Object.keys(preferredDoers).map((id) => (
                <div>{sellers[id]?.sellerData.companyName}</div>
              ))
            : "none"}
        </Descriptions.Item>
        <Descriptions.Item label="Choosing doer">
          {preferredDoers ? "If preferred doer is not available, " : ""}
          {pickStrategy === "first"
            ? "first available will be accepted"
            : "customer will select doer themselves"}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
