import React, { useEffect, useMemo, useState } from "react"
import { Descriptions, Comment, Card, Button, Spin } from "antd"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { CategoryDB, OfferDB, OfferRequestDB } from "hero24-types"
import Text from "antd/lib/typography/Text"
import Avatar from "antd/lib/avatar/avatar"

import { CreateMsgFormValues } from "../../../types"
import CreateMsgForm from "../CreateMsgForm"
import { getTimeSent } from "../../../../common/utils"
import ImageGroup from "../../../../images/components/ImageGroup/ImageGroup"

import { getOfferRequestFromDB } from "../../../../offerRequests/utils"
import { getOfferFromDB } from "../../../../offers/utils"
import { getCategoryFromDB } from "../../../../buyer/categories/utils"
import { selectApolloClient } from "../../../../apolloClient/selectors"
import { selectUsersMap } from "../../../../user/selectors"
import { ChatDto } from "../../../graphql/fragments/ChatInfo"

const getMessageContentRenderer = (text: string, imageIds: string[]) => (
  <>
    <ImageGroup imageIds={imageIds} />
    <div>{text}</div>
  </>
)

interface ExpandedRowProps {
  chat: ChatDto
  onMarkAsSeen: (chatId: string, seen: boolean) => void
  onMarkAsResolved: (chatId: string, seen: boolean) => void
  onSubmit: (chat: ChatDto) => (values: CreateMsgFormValues) => void
}

const ExpandedRow = ({
  chat,
  onMarkAsSeen,
  onMarkAsResolved,
  onSubmit,
}: ExpandedRowProps) => {
  const [offer, setOffer] = useState<OfferDB>()
  const [offerRequest, setOfferRequest] = useState<OfferRequestDB>()
  const [category, setCategory] = useState<CategoryDB>()

  const [offerLoading, setOfferLoading] = useState(false)
  const [offerRequestLoading, setOfferRequestLoading] = useState(false)
  const [categoryLoading, setCategoryLoading] = useState(false)

  const users = useSelector(selectUsersMap)
  const apolloClient = useSelector(selectApolloClient)

  const targetOfferRequestId = useMemo(
    () => chat.offerRequestId || offer?.data.initial.offerRequest,
    [chat.offerRequestId, offer],
  )

  const targetCategoryId = useMemo(
    () => offerRequest?.data.initial.category,
    [offerRequest],
  )

  const getOfferData = useCallback(async () => {
    if (chat.offerId) {
      try {
        setOfferLoading(true)

        const offer = await getOfferFromDB(chat.offerId)

        offer && setOffer(offer)
      } finally {
        setOfferLoading(false)
      }
    }
  }, [chat.offerId])

  const getOfferRequestData = useCallback(async () => {
    if (targetOfferRequestId) {
      try {
        setOfferRequestLoading(true)

        const offerRequest = await getOfferRequestFromDB(
          targetOfferRequestId,
          apolloClient,
        )

        offerRequest && setOfferRequest(offerRequest)
      } finally {
        setOfferRequestLoading(false)
      }
    }
  }, [targetOfferRequestId, apolloClient])

  const getCategoryData = useCallback(async () => {
    if (targetCategoryId) {
      try {
        setCategoryLoading(true)
        const category = await getCategoryFromDB(targetCategoryId)

        category && setCategory(category)
      } finally {
        setCategoryLoading(false)
      }
    }
  }, [targetCategoryId])

  useEffect(() => {
    getOfferData()
  }, [getOfferData])

  useEffect(() => {
    getOfferRequestData()
  }, [getOfferRequestData])

  useEffect(() => {
    getCategoryData()
  }, [getCategoryData])

  return (
    <>
      <Text style={{ color: "lightgray" }}>
        ChatId: <b>{chat.id}</b> <br />
        OfferRequestId: <b>{targetOfferRequestId}</b>
        <br /> OfferId: <b>{chat.offerId}</b>
      </Text>
      <Descriptions
        layout="horizontal"
        size="small"
        bordered
        style={{
          margin: 0,
          marginBottom: 20,
          marginTop: 20,
          padding: 0,
          border: 0,
          background: "white",
        }}
      >
        <Descriptions.Item label="Category">
          <Text
            style={{
              textTransform: "capitalize",
            }}
          >
            <Spin
              spinning={offerLoading || offerRequestLoading || categoryLoading}
            />

            {!offerLoading &&
              !offerRequestLoading &&
              !categoryLoading &&
              (category ? category.name.en : "-")}
          </Text>
        </Descriptions.Item>

        <Descriptions.Item label="Offer Status">
          <Text
            style={{
              textTransform: "capitalize",
            }}
          >
            <Spin spinning={offerLoading} />
            {!offerLoading && (offer?.status || "-")}
          </Text>
        </Descriptions.Item>

        <Descriptions.Item label="OfferRequest Status">
          <Text
            style={{
              textTransform: "capitalize",
            }}
          >
            <Spin spinning={offerLoading || offerRequestLoading} />
            {!offerLoading &&
              !offerRequestLoading &&
              (offerRequest?.data.status || "-")}
          </Text>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item>
          <div>
            {chat.messages.map((message) => {
              const senderId: string = message.senderId
              const sender = users[senderId]

              const imageIds: string[] = message.imageIds || []

              return (
                <Comment
                  key={`${message.createdAt}${sender?.id}`}
                  author={sender?.data.name}
                  avatar={<Avatar src={sender?.data.photoURL} />}
                  content={getMessageContentRenderer(message.content, imageIds)}
                  datetime={
                    <Text>
                      {getTimeSent({ createdAt: +message.createdAt })}
                    </Text>
                  }
                />
              )
            })}
            <Button
              type={chat.seenByAdmin ? "default" : "primary"}
              onClick={() => onMarkAsSeen(chat.id, !chat.seenByAdmin)}
              style={{
                marginRight: 15,
              }}
            >
              Mark as unseen
            </Button>
            <Button
              type={chat.isAboutReclamation ? "primary" : "default"}
              onClick={() =>
                onMarkAsResolved(chat.id, !!chat.isAboutReclamation)
              }
            >
              Mark reclamation as resolved
            </Button>
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Card>
        <CreateMsgForm onSubmit={onSubmit(chat)} />
      </Card>
    </>
  )
}

interface GetExpandedRowRenderProps {
  onSendMsg: (chat: ChatDto, content: string) => void
  onMarkAsSeen: (chatId: string, seen: boolean) => void
  onMarkAsResolved: (chatId: string, seen: boolean) => void
}

const getExpandedRowRender = ({
  onSendMsg,
  ...props
}: GetExpandedRowRenderProps) => {
  const onSubmit = (chat: ChatDto) => (values: CreateMsgFormValues) => {
    onSendMsg(chat, values.content)
  }

  const expandedRowRender = (chat: ChatDto) => {
    return <ExpandedRow {...{ ...props, onSubmit, chat }} />
  }

  return expandedRowRender
}

export default getExpandedRowRender
