import { Col, Row } from "antd"
import { FC, memo, useMemo } from "react"

import { UserView } from "../../../../common/components/User/User"

import { User } from "../../types"

interface ExpandedRowProps {
  user: User
}

export const ExpandedRow: FC<ExpandedRowProps> = memo(({ user }) => {
  const userOfferRequest = useMemo(
    () =>
      Object.keys(user.offerRequests ?? {}).map((offerRequestId) => (
        <Col key={offerRequestId} xs={24} style={{ paddingLeft: "1rem" }}>
          {offerRequestId}
        </Col>
      )),
    [user.offerRequests],
  )

  return (
    <>
      <UserView user={user} />
      <Row>{userOfferRequest}</Row>
    </>
  )
})
