import React from "react"
import { Row, Button } from "antd"
import logo from "../../../logo.png"
import "./Auth.less"

interface AuthProps {
  signInWithGoogle?: () => void
}

const Auth = ({ signInWithGoogle }: AuthProps) => {
  return (
    <div className="login-container">
      <div className="login-container-inner">
        <Row justify="center" align="middle">
          <img src={logo} className="login-logo" alt="logo" />
        </Row>
        <Row justify="center" align="middle">
          <Button
            className="login-button"
            type="primary"
            size="large"
            onClick={signInWithGoogle}
          >
            Sign in with Google
          </Button>
        </Row>
      </div>
    </div>
  )
}

export default Auth
