import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"
import { DEFAULT_GRAPHQL_RESPONSE } from "../../../../common/constants"

import { Data, MUTATION, Variables } from "./mutation"

export const createAdminThunk: ApolloThunkMutation<Data, [Variables]> = async (
  state,
  variables,
) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables,
  })

  return {
    responseData: data?.[DEFAULT_GRAPHQL_RESPONSE],
    responseErrors: errors,
  }
}
