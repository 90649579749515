import React from "react"
import { Descriptions } from "antd"
import { Seller } from "../../../seller/types"
import { Categories } from "../../../buyer/categories/types"
import { SellerCategoriesView } from "./Categories"
import { SellerInfo } from "./Info"

interface Props {
  seller: Seller
  allCategories: Categories
}

export const SellerView = ({ seller, allCategories }: Props) => {
  return (
    <>
      <Descriptions title="Ids" bordered>
        <Descriptions.Item label="UserID">{seller.id}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          {seller.userData.phone}
        </Descriptions.Item>
        <Descriptions.Item label="NetvisorSellerID">
          {seller.sellerData.netvisorSellerId}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Info">
        <Descriptions.Item>
          <SellerInfo seller={seller} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Services">
        <Descriptions.Item>
          <SellerCategoriesView seller={seller} allCategories={allCategories} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
