import React, { useState } from "react"
import { Row, Col, Select } from "antd"
import { OfferRequest } from "../../../offerRequests/list/types"
import { useDispatch } from "react-redux"
import { setOfferRequestStripePayment } from "../../../offerRequests/list/actions"
import TextArea from "antd/lib/input/TextArea"
import Text from "antd/lib/typography/Text"
import { STRIPE_PAYMENT_STATUS } from "hero24-types"

interface Props {
  offerRequest: OfferRequest
}

export const stripePaymentStatuses: { [key in STRIPE_PAYMENT_STATUS]: string } =
  {
    waiting: "Waiting",
    paid: "Paid",
  }

export const StripePayment = (props: Props) => {
  const [paymentStatus, setPaymentStatus] = useState<
    STRIPE_PAYMENT_STATUS | undefined
  >(props.offerRequest.stripePayment?.status)

  const [paymentLink, setStripePaymentLink] = useState<string>(
    props.offerRequest.stripePayment?.link || "",
  )

  const [paymentMessage, setPaymentMessage] = useState<string>(
    props.offerRequest.stripePayment?.message || "",
  )

  const dispatch = useDispatch()

  const changePaymentStatus = (status: STRIPE_PAYMENT_STATUS) => {
    dispatch(
      setOfferRequestStripePayment({
        id: props.offerRequest.id,
        status,
        link: paymentLink,
        message: paymentMessage,
      }),
    )
    setPaymentStatus(status)
  }

  const changePaymentLink = (link: string) => {
    dispatch(
      setOfferRequestStripePayment({
        id: props.offerRequest.id,
        status: paymentStatus,
        link,
        message: paymentMessage,
      }),
    )
    setStripePaymentLink(link)
  }

  const changePaymentMessage = (message: string) => {
    dispatch(
      setOfferRequestStripePayment({
        id: props.offerRequest.id,
        status: paymentStatus,
        link: paymentLink,
        message,
      }),
    )
    setPaymentMessage(message)
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={4}>
          <Text>Stripe payment status</Text>
          <br />
          <Select
            defaultValue={paymentStatus}
            onSelect={changePaymentStatus}
            placeholder="Select status"
            dropdownMatchSelectWidth={true}
            style={{ width: 200 }}
          >
            {Object.entries(stripePaymentStatuses).map((status) => (
              <Select.Option
                key={status[0]}
                value={status[0]}
                style={{ textTransform: "capitalize" }}
              >
                {status[0]}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Text>Copy & Paste Stripe link URL</Text>
          <TextArea
            value={paymentLink}
            rows={1}
            onChange={(e) => changePaymentLink(e.target.value)}
          />
        </Col>
        <Col span={8}>
          <Text>Extra message to display (Optional)</Text>
          <TextArea
            value={paymentMessage}
            rows={1}
            onChange={(e) => changePaymentMessage(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
