export const errorMessageMarkNetvisorOrderPaid =
  "Something went wrong. Try again"

export const succeedMessageMarkNetvisorOrderPaid =
  'Offer request in marked as "Paid'

export enum OperationStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export const approvedByBuyerCallbackMessage: Record<OperationStatus, string> = {
  [OperationStatus.ERROR]: "Something went wrong. Try again",
  [OperationStatus.SUCCESS]: 'Offer request in marked as "Approved by buyer',
}

export const APPROVED_BY_BUYER_POPOVER_CONTENT = 'Mark as "Approved by buyer"'
export const APPROVED_BY_BUYER_POPCONFIRM_TITLE =
  'Mark current request as "Approved by buyer"?'

export const MARK_NETVISOR_ORDER_PAID_POPOVER_CONTENT =
  'Mark as "Paid by buyer"'
export const MARK_NETVISOR_ORDER_PAID_POPCONFIRM_TITLE =
  'Mark current request as "Paid"?'
