import React from "react"
import { Button, Typography } from "antd"

const { Text } = Typography

interface Props {
  enabled: boolean
  selected: boolean
  onClick: () => void
  label: string
}

export default ({ enabled, selected, onClick, label }: Props) => {
  if (selected) {
    return (
      <Button type="primary" onClick={onClick}>
        <Text>{label}</Text>
      </Button>
    )
  }
  if (enabled) {
    return (
      <Button onClick={onClick}>
        <Text>{label}</Text>
      </Button>
    )
  }
  return <Button ghost />
}
