import React from "react"
import reducer from "./reducer"
import OfferRequest from "./OfferRequest"
import AuthRoute from "../../common/AuthRoute"
import { AuditOutlined } from "@ant-design/icons"

export default {
  sideBarItems: [
    {
      icon: <AuditOutlined />,
      name: "Create Offer Request",
      path: "create_offer_request",
    },
  ],
  routes: [
    <AuthRoute
      exact
      key="/create_offer_request"
      path="/create_offer_request"
      render={() => <OfferRequest />}
    />,
  ],
  reducer,
}
