import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Space, Typography } from "antd"
import { FeeDB } from "hero24-types"
import { FC } from "react"
import { feeStatusLabels } from "./constants"
import useMenuItems from "./hooks/useMenuItems"

interface Props {
  fee: FeeDB
  feeId: string
}

const FeeStatusChange: FC<Props> = ({ fee, feeId }) => {
  const items = useMenuItems({ fee, feeId })

  if (fee.status === "cancelled") {
    return <Typography>{fee.status}</Typography>
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>{feeStatusLabels[fee.status]}</Typography>
      <Dropdown menu={items}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            Change
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  )
}

export default FeeStatusChange
