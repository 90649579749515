import React from "react"
import { Checkbox, Radio, Col, Row } from "antd"

import QuestionList from "../../questionList/QuestionList"

interface OptionViewProps {
  name?: string
  type: "radio" | "checkbox"
  last: boolean
  isSubQuestion?: boolean
  subQuestions?: string[] | null
  checked?: boolean
  onChecked: () => void
}

const OptionView = (props: OptionViewProps) => {
  return (
    <Row>
      <Col xs={24} style={{ marginBottom: 3 }}>
        {props.type === "checkbox" ? (
          <Checkbox onChange={props.onChecked} checked={props.checked || false}>
            {props.name}
          </Checkbox>
        ) : (
          <Radio onChange={props.onChecked} checked={props.checked || false}>
            {props.name}
          </Radio>
        )}
      </Col>
      <Col xs={24} style={{ marginBottom: 3 }}>
        {props.subQuestions && props.checked ? (
          <QuestionList questionIds={props.subQuestions} isSubQuestion />
        ) : null}
      </Col>
    </Row>
  )
}

export default OptionView
