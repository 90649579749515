import * as TYPES from "./actionTypes"
import { FeesState, FeesReducer } from "./types"

export const INITIAL_STATE: FeesState = {}

const fees: FeesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.ADD_FEE_TO_BE_SAVED: {
      const fees: FeesState["feesToSave"] = [...(state.feesToSave || [])]
      fees.push(action.payload)
      return {
        ...state,
        feesToSave: fees,
      }
    }
    case TYPES.REMOVE_FEE_TO_BE_SAVED: {
      if (!state.feesToSave) {
        return state
      }
      const fees: FeesState["feesToSave"] = [...state.feesToSave]
      fees.splice(action.index, 1)
      return {
        ...state,
        feesToSave: fees,
      }
    }
    case TYPES.GET_ALL_FEES: {
      return {
        ...state,
        allFees: action.payload,
      }
    }
    case TYPES.SAVED_FEES: {
      return INITIAL_STATE
    }
    default: {
      return state
    }
  }
}

export default { fees }
