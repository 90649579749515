import * as TYPES from "./actionTypes"
import { AuthState, AuthReducer as Auth } from "./types"

const INITIAL_STATE: AuthState = {
  user: undefined,
  isFetching: true,
}

const auth: Auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.UPDATE_USER:
      return {
        ...state,
        user: action.user,
        isFetching: false,
      }
    default:
      return state
  }
}

export default { auth }
