import React, { MouseEventHandler, useState } from "react"
import firebaseConfig from "../../../../firebaseConfig"
import { Collapse, Button, Typography, Tooltip } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { OfferRequest } from "../../../buyer/createOfferRequest/types"
import { Addresses } from "./Addresses"
import { FixedValues } from "./FixedValues"
import Text from "antd/lib/typography/Text"
import { OffersList } from "./Offers"
import { Fees } from "./Fees/Fees"
import { PickingDoer } from "./PickingDoer"
import { Questions } from "./Questions.tsx/Questions"
import { OfferRequestDates } from "./OfferRequestDates"
import { Refund } from "./Refund"
import { StripePayment } from "./StripePayment"
import { OfferRequestLog } from "./Log/Log"
import { Vats } from "./Vats"

const { Link } = Typography

interface Props {
  offerRequest: OfferRequest
  openQuestionsModal: (offerRequestId: string) => void
}

export const OfferRequestView = ({
  offerRequest,
  openQuestionsModal,
}: Props) => {
  const [paymentLink, setPaymentLink] = useState(
    offerRequest.paymentInfoId
      ? `${firebaseConfig.apiUrl}/pay/${offerRequest.paymentInfoId}`
      : undefined,
  )

  const getPaymentLink = async () => {
    const results = await fetch(
      `${firebaseConfig.apiUrl}/createPaymentLink?offerRequestId=${offerRequest.id}`,
      {
        method: "GET",
        mode: "cors",
      },
    )
    const link = await results.text()
    setPaymentLink(link)
  }

  const onEditQuestionsClick: MouseEventHandler = (event) => {
    event.stopPropagation()

    openQuestionsModal(offerRequest.id)
  }

  const renderQuestionsHeader = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>Questions & Answers</span>
      <EditOutlined
        style={{ marginLeft: "10px" }}
        onClick={onEditQuestionsClick}
      />
    </div>
  )
  return (
    <>
      <Text>{offerRequest.id}</Text>
      <br />
      <Text>
        Stripe-link for prepayment:{" "}
        {paymentLink ? (
          <a target="__blank" href={paymentLink}>
            {paymentLink}
          </a>
        ) : (
          <Button onClick={getPaymentLink}>Get payment link</Button>
        )}
      </Text>
      <Collapse defaultActiveKey={["dates", "pickingDoer", "purchases"]}>
        <Collapse.Panel header="Add Stripe Link manually" key="stripePayment">
          <StripePayment offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Dates" key="dates">
          <OfferRequestDates offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Picking doer" key="pickingDoer">
          <PickingDoer offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Purchases" key="purchases">
          <FixedValues offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Fees" key="fees">
          <Fees offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Vats" key="vats">
          <Vats offerRequestId={offerRequest.id} />
        </Collapse.Panel>
        <Collapse.Panel header="Addresses" key="addresses">
          <Addresses offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Offers" key="offers">
          <OffersList offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header={renderQuestionsHeader()} key="questions">
          <Questions questions={offerRequest.data.initial.questions} />
        </Collapse.Panel>
        <Collapse.Panel header="Status logs" key="statusLogs">
          <OfferRequestLog offerRequest={offerRequest} />
        </Collapse.Panel>
        <Collapse.Panel header="Refund" key="refund">
          <Tooltip title="Actual refund is done via Stripe">
            <Link
              rel="noopener noreferrer"
              href={`https://dashboard.stripe.com/payments/${offerRequest.stripePaymentIntentId}`}
              target="_blank"
            >
              Payment link (Stripe)
            </Link>
          </Tooltip>
          <br />
          <br />
          <Refund offerRequest={offerRequest} />
        </Collapse.Panel>
      </Collapse>
    </>
  )
}
