import { FC } from "react"
import ChangeModal from "./modals/ChangeModal"
import CreateAdminUserModal from "./modals/CreateAdminUserModal"
import { StatusModalProps } from "./modals/types"

export enum MenuItemsKeys {
  EXISTING = "EXISTING",
  NEW_USER = "NEW_USER",
}

export interface StatusChangerModal {
  buttonTitle: string
  modalTitle: string
  modalComponent: FC<StatusModalProps>
}

export const StatusChangerModals: Record<MenuItemsKeys, StatusChangerModal> = {
  [MenuItemsKeys.EXISTING]: {
    buttonTitle: "Change existed user admin permission",
    modalTitle: "Admin permissions",
    modalComponent: ChangeModal,
  },

  [MenuItemsKeys.NEW_USER]: {
    buttonTitle: "Create new administrator",
    modalTitle: "Create administrator",
    modalComponent: CreateAdminUserModal,
  },
}
