import React from "react"
import Text from "antd/lib/typography/Text"
import { Seller } from "../../../seller/types"

interface Props {
  seller?: Seller
}

export const SellerName = ({ seller }: Props) => (
  <Text
    style={{
      textTransform: "capitalize",
    }}
  >
    {seller ? seller.sellerData.companyName : ""}
  </Text>
)
