import { ApolloThunkMutation } from "../../../../apolloClient/types"
import { apolloMutation } from "../../../../apolloClient/utils"

import { MUTATION } from "./mutation"

type Data = {
  updateOfferRequestPurchase: boolean
}

export type UpdateOfferRequestPurchaseInput = {
  id: string
  fixedDuration: number
  fixedPrice: number
}

type Variables = {
  input: UpdateOfferRequestPurchaseInput
}

export const updateOfferRequestPurchaseThunk: ApolloThunkMutation<
  Data,
  [Variables["input"]]
> = async (state, input) => {
  const { data, errors } = await apolloMutation<Data, Variables>({
    state,
    mutation: MUTATION,
    variables: {
      input,
    },
  })

  return {
    updateOfferRequestPurchaseData: data?.updateOfferRequestPurchase,
    updateOfferRequestPurchaseErrors: errors,
  }
}
