import { gql } from "@apollo/client"
import {
  CHAT_MESSAGE_INFO_FRAGMENT,
  ChatMessageDto,
} from "../fragments/ChatMessageInfo"

export type CreateChatMessageVariables = Pick<
  ChatMessageDto,
  "content" | "chatId"
>

export type CreateChatMessageData = {
  createChatMessage: ChatMessageDto
}

export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  ${CHAT_MESSAGE_INFO_FRAGMENT}

  mutation CreateChatMessage($content: String!, $chatId: String!) {
    createChatMessage(content: $content, chatId: $chatId) {
      ...ChatMessageInfo
    }
  }
`
