import React from "react"
import { CrownOutlined } from "@ant-design/icons"

import AuthRoute from "../../common/AuthRoute"
import { ChatList } from "./containers"
import { NavLabel } from "./components/Label"

export default {
  sideBarItems: [
    {
      icon: <CrownOutlined />,
      name: <NavLabel />,
      path: "chats",
    },
  ],
  routes: [
    <AuthRoute exact key="/chats" path="/chats" render={() => <ChatList />} />,
  ],
}
