import { gql } from "@apollo/client"
import { UserDB } from "hero24-types"

import { Maybe } from "../../../common/types"
import { FirebaseAdapter } from "../../../common/utilities"

export type UserDataActiveRoute = {
  chatId?: Maybe<string>
}

export const USER_DATA_ACTIVE_ROUTE_FRAGMENT = gql`
  fragment UserDataActiveRouteInfo on UserDataActiveRouteDto {
    chatId
  }
`

export const UserDataActiveRouteAdapter = new FirebaseAdapter<
  Required<UserDB["data"]>["activeRoute"],
  UserDataActiveRoute
>({
  toFirebase: ({ chatId }) => ({ chatId: chatId ?? undefined }),
  toGraphQL: ({ chatId }) => ({ chatId }),
})
