import React, { useState } from "react"
import { Row, Col, Select } from "antd"
import { Offer } from "../../../offers/list/types"
import { useDispatch } from "react-redux"
import { setOfferEarnings } from "../../../offers/list/actions"
import TextArea from "antd/lib/input/TextArea"
import Text from "antd/lib/typography/Text"
import { EARNINGS_STATUS } from "hero24-types"

interface Props {
  offer: Offer
}

export const earningsStatuses: { [key in EARNINGS_STATUS]: string } = {
  adjustment: "Adjustment",
  paid: "Paid",
  paid_by_hero_payments: "Paid by Taltek Asennus Oy / Hero24", // eslint-disable-line camelcase
  pending: "Pending", // eslint-disable-line camelcase
  pending_buyer: "Pending buyer", // eslint-disable-line camelcase
  pending_doer: "Pending doer", // eslint-disable-line camelcase
  reclamation: "Reclamation",
  refund: "Refund",
  refund_partial: "Refund (partial)", // eslint-disable-line camelcase
  transit: "In transit",
}

export const Earnings = (props: Props) => {
  const [earningsStatus, setEarningsStatus] = useState<
    EARNINGS_STATUS | undefined
  >(props.offer.earnings?.status)
  const [earningsMessage, setEarningsMessage] = useState<string>(
    props.offer.earnings?.message || "",
  )
  const dispatch = useDispatch()
  const changePaidStatus = (status: EARNINGS_STATUS) => {
    dispatch(
      setOfferEarnings({
        id: props.offer.id,
        status,
        message: earningsMessage,
      }),
    )
    setEarningsStatus(status)
  }
  const changeMessage = (message: string) => {
    dispatch(
      setOfferEarnings({
        id: props.offer.id,
        status: earningsStatus,
        message,
      }),
    )
    setEarningsMessage(message)
  }
  return (
    <>
      <Row>
        <Col span={10}>
          <Text>Status</Text>
          <br />
          <Select
            defaultValue={earningsStatus}
            onSelect={changePaidStatus}
            placeholder="Select status"
            dropdownMatchSelectWidth={true}
            style={{ width: 200 }}
          >
            {Object.entries(earningsStatuses).map((status) => (
              <Select.Option
                key={status[0]}
                value={status[0]}
                style={{ textTransform: "capitalize" }}
              >
                {status[1]}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Text>Message for doer</Text>
          <TextArea
            value={earningsMessage}
            rows={4}
            onChange={(e) => changeMessage(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
